import { useEffect, useMemo, useRef, useState } from "react"
import Highlighter from 'react-highlight-words';
import { LockFilled, UnlockFilled, CheckSquareFilled, DownloadOutlined, CopyOutlined, PrinterOutlined, PlusOutlined, FastBackwardOutlined } from '@ant-design/icons';
import { InputRef, Modal } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useAuth } from "../../contexts/auth";
import { apierp } from '../../services/api';
import { customMessage } from "../../components/template/CustomMessage";
import DatePicker from "react-datepicker";


import useWindowSize from "../../hooks/useWindowSize";

import { Button, Col, Drawer, Form, Input, message, Popconfirm, Radio, Row, Select, Space, Table, Typography } from 'antd';
import SpinnerNew from "../../components/template/SpinnerNew";
import { copyToClipboard, downloadToCsv, formatDecimal, printDiv } from "../../utils/functions";
import Layout from "../../components/template/Layout";
import { useGeral } from "../../contexts/geral";
import Tabela from "../../components/template/Tabela";

import isPermitted from "../../hooks/isPermitted";
import { ColumnType, FilterConfirmProps } from "antd/lib/table/interface";
import { useDadosPrePedidosVenda } from "./useDadosPrePedidosVenda";
import { columnsPrePedidoVenda } from "./columnsPrePedidoVenda";
import { dateFormat } from "../../utils/dateFunctions";
import DrawerFormPrePedidoVenda from "./DrawerFormPrePedidoVenda";

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
const { Option } = Select;

export default function PrePedidoVenda() {

    const [innerW, innerH] = useWindowSize();
    const now = new Date()
    // const [mesref, setMesRef] = useState(now)
    const [mesref, setMesRef] = useState(new Date('2024-04-08 12:00:00'))

    // useEffect(() => {
    //   setMesRef(now)
    // }, [])

    const data = mesref.toISOString().slice(0, 10)
    const { qrEmpresas, qrBlingCategoriasReceitaDespesa, qrBlingVendedores, qrBlingFormasPagamento, qrBlingProdutos, qrPrePedidosVenda } = useDadosPrePedidosVenda(data)
    // if (qrEmpresas.status === "success" && qrBlingCategoriasReceitaDespesa.status === "success" && qrBlingVendedores.status === "success" && qrBlingFormasPagamento.status === "success" && qrBlingProdutos.status === "success" && qrPrePedidosVenda.status === "success") {
    //     // console.log('qrEmpresas-is...', qrEmpresas.isLoading, qrEmpresas.isPending, qrEmpresas.isFetching, qrEmpresas.isRefetching, qrEmpresas.status)
    //     console.log('')
    //     console.log('qrEmpresas', qrEmpresas.data)
    //     // console.log('qrBlingCategoriasReceitaDespesa-is...', qrBlingCategoriasReceitaDespesa.isLoading, qrBlingCategoriasReceitaDespesa.isPending, qrBlingCategoriasReceitaDespesa.isFetching, qrBlingCategoriasReceitaDespesa.isRefetching, qrBlingCategoriasReceitaDespesa.status)
    //     console.log('qrBlingCategoriasReceitaDespesa', qrBlingCategoriasReceitaDespesa.data)
    //     // console.log('qrBlingVendedores-is...', qrBlingVendedores.isLoading, qrBlingVendedores.isPending, qrBlingVendedores.isFetching, qrBlingVendedores.isRefetching, qrBlingVendedores.status)
    //     console.log('qrBlingVendedores', qrBlingVendedores.data)
    //     // console.log('qrBlingFormasPagamento-is...', qrBlingFormasPagamento.isLoading, qrBlingFormasPagamento.isPending, qrBlingFormasPagamento.isFetching, qrBlingFormasPagamento.isRefetching, qrBlingFormasPagamento.status)
    //     console.log('qrBlingFormasPagamento', qrBlingFormasPagamento.data)
    //     // console.log('qrBlingProdutos-is...', qrBlingProdutos.isLoading, qrBlingProdutos.isPending, qrBlingProdutos.isFetching, qrBlingProdutos.isRefetching, qrBlingProdutos.status)
    //     console.log('qrBlingProdutos', qrBlingProdutos.data)
    //     // console.log('qrPrePedidosVenda-is...', qrPrePedidosVenda.isLoading, qrPrePedidosVenda.isPending, qrPrePedidosVenda.isFetching, qrPrePedidosVenda.isRefetching, qrPrePedidosVenda.status)
    //     console.log('qrPrePedidosVenda', qrPrePedidosVenda.data)
    // }

    const [drawerFormPrePedidoVendaVisible, setDrawerFormPrePedidoVendaVisible] = useState(false)
    const [operacao, setOperacao] = useState(null as string | null)

    const colunas = columnsPrePedidoVenda({})

    const handleRow = async (record: any) => {
        // console.log('handleRow', record)
        console.log('handleRow', qrPrePedidosVenda.data[0])
        setDrawerFormPrePedidoVendaVisible(true)
        setOperacao('Alteração')
    }

    const hideDrawerFormPrePedidoVenda = () => {
        setDrawerFormPrePedidoVendaVisible(false)
    }

    const wTab = 1238
    const htb = `calc(100vh - 149px - 110px)`
    const hsc = `calc(100vh - 180px - 109px - 33px)` // 1 a menos que htb 33px do DatePicker
    const wDrawer = innerW > 640 ? innerW - 144 : 360 // - 144
    const wDrawerx = innerW > 720 && innerW > 360 ? 720 : 360 // - 144
    // use memo em componente <Tabela> para evitar re-render desnecessario
    const tabela = useMemo(() => {
        // console.log('useMemo tabela')
        return (
            <Tabela className="mx-5" id='prePedidosVenda' titulo={'Pre-Pedidos-Venda'} wTab={wTab} htb={htb} hsc={hsc} dataSource={qrPrePedidosVenda.data} columns={colunas} handleRow={handleRow} loading={qrPrePedidosVenda.isLoading} rowKey={'id'} />
        )
    }, [qrPrePedidosVenda.data])

    let corPicker = 'bg-blue-600'
    if (mesref !== null) {
        corPicker = dateFormat(mesref, 'yyyy-MM-dd') === dateFormat(now, 'yyyy-MM-dd') ? 'bg-blue-600' : 'bg-red-600'
    }

    return (
        <Layout titulo={"Pre-Pedido Venda"} botoes={null}>
            <div className="mx-auto">
                <DatePicker disabled={qrPrePedidosVenda.isLoading}
                    // wrapperClassName="btmonth"
                    className={`w-[88px] mb-[3px] text-center uppercase border-none rounded-md font-medium py-1 cursor-pointer text-white ${corPicker}`}
                    selected={mesref}
                    locale="pt-BR"
                    onChange={(date: any) => setMesRef(date)}
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    todayButton="Hoje"
                    popperPlacement="bottom"
                />
            </div>
            <SpinnerNew loading={qrPrePedidosVenda.isLoading} />
            <div>
                {tabela}
            </div>

            <DrawerFormPrePedidoVenda hideDrawerFormPrePedidoVenda={hideDrawerFormPrePedidoVenda} drawerFormPrePedidoVendaVisible={drawerFormPrePedidoVendaVisible} operacao={operacao} formasPagamento={qrBlingFormasPagamento.data} empresas={qrEmpresas.data}/>
        </Layout>
    )
}