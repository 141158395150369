import React, { createContext, useState, useContext } from 'react';
import { apierp } from '../services/api';
import { getStoreEncrypted, setStoreEncrypted } from '../utils/session';

// import api from '../services/api';
// import history from '../services/history';
// import { useHistory } from "react-router-dom";

interface GeralContextData {
  Load(): void;
  menuSelected: number;
  setMenuSelected(menuSelected: number): void;
  menuGrupoSelected: number;
  setMenuGrupoSelected(menuGrupoSelected: number): void;
  showMenu: boolean;
  setShowMenu(showMenu: boolean): void;
  userTenant: any;
  setUserTenant(userTenant: any, userMenu: any, permissions: any): void;
  rotaBase: string
  userMenu: any;
  setUserMenu(userMenu: any): void;
  permissions: any;
  setPermissions(permissions: any): void;
}

interface IGeralState {
  menuSelected: number;
  menuGrupoSelected: number;
  showMenu: boolean;
  userTenant: any;
  rotaBase: string;
  userMenu: any;
  permissions: any;
}
const GeralContext = createContext<GeralContextData>({} as GeralContextData);

interface Props {
  children: React.ReactNode;
}

export const GeralProvider: React.FC<Props> = ({ children }) => {
  
  const initialData = {
    menuSelected: -1,
    menuGrupoSelected:-1,
    showMenu: true,
    userTenant: null,
    rotaBase: null,
    userMenu: null,
    permissions: null,
  }
  
  const [data, setData] = useState<IGeralState>(() => {
    const zGeral = getStoreEncrypted('@zappgeral:data')
    // console.log('geral.tsx called')
    if (zGeral) {
      const geral = JSON.parse(zGeral)
      let menuSelected = geral.menuSelected
      let menuGrupoSelected = geral.menuGrupoSelected
      let showMenu = geral.showMenu
      let userTenant = geral.userTenant
      let rotaBase = geral.rotaBase
      let userMenu = geral.userMenu
      let permissions = geral.permissions
      apierp.defaults.headers.tenantid = userTenant?.tenantId;
      apierp.defaults.headers.userid = userTenant?.userId;

      return {
        menuSelected,
        menuGrupoSelected,
        showMenu,
        userTenant,
        rotaBase,
        userMenu,
        permissions,
      }
    }
    return initialData
  });

  function Load() {
    setData({} as any);
  }

  function setMenuSelected(menuSelected: number): void {
    const newData = {...data, menuSelected}
    setData(newData);
    setStoreEncrypted('@zappgeral:data', JSON.stringify(newData))
  }

  function setMenuGrupoSelected(menuGrupoSelected: number): void {
    const newData = {...data, menuGrupoSelected}
    setData(newData);
    setStoreEncrypted('@zappgeral:data', JSON.stringify(newData))
  }

  function setShowMenu(showMenu: boolean): void {
    const newData = {...data, showMenu}
    setData(newData);
    setStoreEncrypted('@zappgeral:data', JSON.stringify(newData))
  }

  function setUserMenu(userMenu: any): void {
    const newData = {...data, userMenu}
    setData(newData);
    setStoreEncrypted('@zappgeral:data', JSON.stringify(newData))
  }
  
  function setPermissions(permissions: any): void {
    const newData = {...data, permissions}
    setData(newData);
    setStoreEncrypted('@zappgeral:data', JSON.stringify(newData))
  }
  
  function setUserTenant(userTenant: any, userMenu: any, permissions:any): void {
    const rotaBase= "/zapp/" + userTenant.tenant.shortName.replaceAll(' ', '_') // + "_" + userTenant.tenant.id
    const newData = {...initialData, userTenant, userMenu, permissions, rotaBase}
    setData(newData);
    setStoreEncrypted('@zappgeral:data', JSON.stringify(newData))
    apierp.defaults.headers.tenantid = userTenant.tenantId;
    apierp.defaults.headers.userid = userTenant.userId;
    // console.log('geral-userTenant', userTenant)
  }

  return (
    <GeralContext.Provider
    value={{ 
      menuSelected: data.menuSelected,
      menuGrupoSelected: data.menuGrupoSelected,
      showMenu: data.showMenu,
      userTenant: data.userTenant,
      rotaBase: data.rotaBase,
      userMenu: data.userMenu,
      permissions: data.permissions,
      Load, 
      setMenuSelected,
      setMenuGrupoSelected,
      setShowMenu,
      setUserTenant,
      setUserMenu,
      setPermissions,
     }}
    >
      {children}
    </GeralContext.Provider>
  );
};

export function useGeral() {
  const context = useContext(GeralContext);

  return context;
}
