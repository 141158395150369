import React from "react";

// import { Nav, Button, VStack } from "../../components"

export default function Forgot() {
  return (
    <h1>Forgot</h1>
    // <VStack m="32px">
    //   <span className="c-heading v-h3">## FORGOT ##</span>
    //   <div>Função não implementada!</div>
    //   <div>
    //     <Nav to="/">
    //       <Button buttonScheme="c-button__secondary" m="16px 0">
    //         Voltar
    //       </Button>
    //     </Nav>
    //   </div>
    // </VStack>
  );
}
