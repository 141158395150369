import React, {useEffect, useState} from 'react';
import { useGeral } from "../contexts/geral";

function Permitted(permission) {

    const [permitted, setPermitted] = useState(false);
    const { permissions } = useGeral();

    useEffect(() => {

        setPermitted(permissions.findIndex((x) => x === permission)>-1)

        return () => {
            setPermitted(false)
        }
    });

    return permitted;
}

export default Permitted;
