import { Alert, Button, Drawer, Form, Input, InputNumber, Modal, Radio, Select, Space } from "antd";
import { HiRefresh } from "react-icons/hi";
import { AiOutlineSync } from "react-icons/ai";
import useWindowSize from "../../../hooks/useWindowSize";
import { useEffect, useState } from "react";
import { getEmpresaByCnpj, validateUf } from "../../../utils/functions";
import { apierp } from "../../../services/api";
import { indicadorIe } from "../../../data/dados";
import { alterarClienteBling, cadastrarClienteBling } from "./functions";
const { TextArea } = Input
const { Option } = Select;

export default function DrawerFormAlteraCliente(props: any) {
    const { hideDrawerFormAlteraCliente, drawerFormAlteraClienteVisible, cliente, ids, pesquisar } = props
    const [buttonsDisabled, setButtonsDisabled] = useState(false)

    //ESTADOS CLIENTE
    // const clientex = { ...cliente,
    //     numeroDocumento: cliente?.numeroDocumento || '',
    //     situacao: cliente?.situacao || 'A',
    // }

    const [empresaCliente, setEmpresaCliente] = useState(null as any)

    const isPF = cliente?.numeroDocumento?.length === 11 ? true : false
    const isPJ = !isPF

    const [formCliente] = Form.useForm();

    const fillFormAlteraCliente = (cliente: any) => {
        formCliente.setFieldsValue({
            id: cliente?.id,
            numeroDocumento: cliente?.numeroDocumento || '',
            situacao: cliente?.situacao || 'A',
            codigo: cliente?.codigo || '',
            nome: cliente?.nome || '',
            fantasia: cliente?.fantasia || '',
            email: cliente?.email || '',
            telefone: cliente?.telefone || '',
            celular: cliente?.celular || '',
            rg: cliente?.rg || '',
            orgaoEmissor: cliente?.orgaoEmissor || '',
            ie: cliente?.ie || '',
            indicadorIe: cliente?.indicadorIe || '',
            cep: cliente?.cep || '',
            endereco: cliente?.endereco,
            numero: cliente?.numero || '',
            complemento: cliente?.complemento || '',
            bairro: cliente?.bairro || '',
            municipio: cliente?.municipio || '',
            uf: cliente?.uf || '',
        });
        // console.log('cliente', cliente)
    };

    // setEmpresaCliente(null)
    if (empresaCliente === null) {
        formCliente.resetFields()
        fillFormAlteraCliente(cliente)
    }

    const regexCep = /^\d{8}$/
    const handleCep = async (e: any) => {
        // const cep = e.target.value.replace(/\D/g, '') //.substring(0, 8)
        const cep = e.target.value
        if (cep.length === 8 && regexCep.test(cep)) {
            const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`)
            const data = await response.json()
            formCliente.setFieldsValue({
                endereco: data.logradouro,
                bairro: data.bairro,
                municipio: data.localidade,
                uf: data.uf,
            });
        } else {
            formCliente.setFieldsValue({
                endereco: '',
                bairro: '',
                municipio: '',
                uf: '',
            });
        }
    }

    const buscarCep = async () => {
        const cep = formCliente.getFieldValue('cep')
        // console.log(cep.length, regexCep.test(cep), cep)
        const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`)
        const data = await response.json()
        formCliente.setFieldsValue({
            endereco: data.logradouro,
            bairro: data.bairro,
            municipio: data.localidade,
            uf: data.uf,
        });
    }

    useEffect(() => {
        formCliente.setFieldsValue({
            situacao: cliente?.situacao || 'A',
            nome: empresaCliente?.nome,
            fantasia: empresaCliente?.fantasia,
            email: empresaCliente?.email,
            telefone: empresaCliente?.telefone,

            celular: empresaCliente?.celular,
            rg: empresaCliente?.rg,
            orgaoEmissor: empresaCliente?.orgaoEmissor,
            ie: empresaCliente?.ie,
            indicadorIe: empresaCliente?.indicadorIe,

            cep: empresaCliente?.cep.replace(/\D/g, ''),
            endereco: empresaCliente?.logradouro,
            numero: empresaCliente?.numero,
            complemento: empresaCliente?.complemento,
            bairro: empresaCliente?.bairro,
            municipio: empresaCliente?.municipio,
            uf: empresaCliente?.uf,
            situacaoCNPJ: empresaCliente?.situacao,
        });
    }, [empresaCliente])

    const handleNumeroDocumento = async () => {
        if (isPJ) {
            // const empresaCliente = getEmpresaByCnpj(cliente?.numeroDocumento) // problema CORS
            const response1 = await apierp.get(`proxy/empresa/${cliente?.numeroDocumento}`) // na verdade é get
            const empresaCliente = await response1.data as any;
            setEmpresaCliente(empresaCliente)
        }
    }

    async function handleSubmitFormAlteraCliente(dados: any,) {
        setButtonsDisabled(true)
        const cliente = {
            nome: dados.nome?.toUpperCase().trim(),
            codigo: dados.codigo,
            situacao: dados.situacao,
            numeroDocumento: dados.numeroDocumento, // cnpj ***
            telefone: dados.telefone?.trim(),
            celular: dados.celular?.trim(),
            fantasia: dados.fantasia?.trim(),
            tipo: isPF ? 'F' : 'J',
            indicadorIe: +dados.indicadorIe,
            ie: dados.ie?.trim(),
            rg: dados.rg?.trim(),
            orgaoEmissor: dados.orgaoEmissor?.trim(), // orgaoEmissor ***
            email: dados.email?.toLowerCase(),
            endereco: {
                geral: {
                    endereco: dados.endereco?.trim(),
                    cep: dados.cep,
                    bairro: dados.bairro?.trim(),
                    municipio: dados.municipio?.trim(), // cidade ***
                    uf: dados.uf,
                    numero: dados.numero?.trim(),
                    complemento: dados.complemento?.trim(),
                },
            },
        }
        const dadosDto = {
            id: 0,
            empresaId: 0,
            nome: dados.nome?.toUpperCase().trim(),
            codigo: dados.codigo,
            situacao: dados.situacao,
            cnpj: dados.numeroDocumento, // cnpj ***
            fone: dados.telefone?.trim(),
            celular: dados.celular?.trim(),
            nomeFantasia: dados.fantasia?.trim(),
            tipoPessoa: isPF ? 'F' : 'J',
            indicadorIe: +dados.indicadorIe,
            ie: dados.ie?.trim(),
            rg: dados.rg?.trim(),
            orgaoEmissor: dados.orgaoEmissor?.trim(), // orgaoEmissor ***
            email: dados.email?.toLowerCase(),
            endereco: dados.endereco?.trim(),
            cep: dados.cep,
            bairro: dados.bairro?.trim(),
            cidade: dados.municipio?.trim(), // cidade ***
            uf: dados.uf,
            numero: dados.numero?.trim(),
            complemento: dados.complemento?.trim(),
        }

        if (dados.indicadorIe === '1' && dados.ie === '') {
            // formCliente.setFields([
            //     { name: 'ie', errors: ['Informe a Inscrição Estadual'] },
            // ])
            Modal.error({
                title: 'Informe a Inscrição Estadual',
                content: 'Quando o Indicador de IE for 1, a Inscrição Estadual é obrigatória!',
            });
            setButtonsDisabled(false)
            return
        }
        // console.log('handleSubmitFormAlteraCliente', dados, cliente, ids, ids[0], ids[1])
        if (dados.all === 'S') {
            if (ids[0]) {
                // console.log(`A-E1 id=${ids[0]}`)
                // console.log(cliente, 1, ids[0])
                await alterarClienteBling(cliente, 1, ids[0])
                const dadosDtox = { ...dadosDto, empresaId: 1, id: ids[0] }
                const responsex = await apierp.post(`clientes/createorupdateblingcliente`, dadosDtox)
            } else {
                // console.log('C-E1')
                // console.log(cliente, 1)
                const id1 = await cadastrarClienteBling(cliente, 1)
                const dadosDtox = { ...dadosDto, empresaId: 1, id: id1 }
                const responsex = await apierp.post(`clientes/createorupdateblingcliente`, dadosDtox)
            }

            if (ids[1]) {
                // console.log(`A-E2 id=${ids[1]}`)
                // console.log(cliente, 2, ids[1])
                await alterarClienteBling(cliente, 2, ids[1])
                const dadosDtox = { ...dadosDto, empresaId: 2, id: ids[1] }
                const responsex = await apierp.post(`clientes/createorupdateblingcliente`, dadosDtox)
            } else {
                // console.log('C-E2')
                // console.log(cliente, 2)
                const id2 = await cadastrarClienteBling(cliente, 2)
                const dadosDtox = { ...dadosDto, empresaId: 2, id: id2 }
                const responsex = await apierp.post(`clientes/createorupdateblingcliente`, dadosDtox)
            }
        } else {
            const empresaId = ids.findIndex((id: any) => id === dados.id) + 1 // indice + 1 do array de ids
            // console.log(`A-E${empresaId}, id=${dados.id}`)
            // console.log(cliente, empresaId, dados.id)
            await alterarClienteBling(cliente, empresaId, dados.id)
            const dadosDtox = { ...dadosDto, empresaId, id: dados.id }
            const responsex = await apierp.post(`clientes/createorupdateblingcliente`, dadosDtox)
        }

        pesquisar()

        hideDrawerFormAlteraCliente()
        setButtonsDisabled(false)
        setEmpresaCliente(null)
    }

    const handleCancel = () => {
        hideDrawerFormAlteraCliente()
        setEmpresaCliente(null)
    }

    const checkUf = (_: any, value: string) => {
        if (validateUf(value)) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('UF Inv!'));
    };

    const [innerW, innerH] = useWindowSize();
    const wDrawerx = innerW > 640 && innerW > 360 ? 640 : 360 // - 144

    const layout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 19 },
    };

    const situacaoCNPJ = empresaCliente?.situacao
    const situacaoCNPJtype = situacaoCNPJ === 'BAIXADA' ? 'error' : situacaoCNPJ === 'ATIVA' ? 'success' : 'info'
    // console.log('isPJ', isPJ  ,'situacaoCNPJ', situacaoCNPJ)
    const savebtnDisabled = (isPJ && situacaoCNPJ && situacaoCNPJ !== 'ATIVA')
    const haveCep = !!empresaCliente?.cep
    // console.log('haveCep', haveCep, empresaCliente?.cep)
    return (
        <Drawer
            title={'Alteração de Cliente'}
            width={wDrawerx}
            closable={true}
            maskClosable={false}
            onClose={hideDrawerFormAlteraCliente}
            open={drawerFormAlteraClienteVisible}
            headerStyle={{ borderRadius: "0" }}
            bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
        >
            <div className="inner-drawer-body">
                <Form {...layout} form={formCliente} onFinish={handleSubmitFormAlteraCliente}>
                    <Form.Item name="numeroDocumento" hidden noStyle></Form.Item>
                    <Form.Item name="codigo" hidden noStyle></Form.Item>
                    <Form.Item name="id" hidden noStyle></Form.Item>
                    <Form.Item label="Cnpj/Cpf">
                        <Form.Item className="inline-block my-0">
                            <div className="flex my-0">
                                <Button className="rounded-md inline-block w-[130px] mr-2 px-1" onClick={handleNumeroDocumento} >
                                    {cliente?.numeroDocumento}
                                </Button>
                                <div hidden={!situacaoCNPJ}>
                                    <div className="h-8 pt-[5px] px-0 font-bold" >{situacaoCNPJ}</div>
                                </div>
                            </div>
                        </Form.Item>

                        <Form.Item name="situacao" label="Sit. Bling" rules={[{ required: true }]} style={{ display: 'inline-block', margin: '0 0 0 8px' }}>
                            <Radio.Group >
                                <Radio value={"A"}>Ativo</Radio>
                                <Radio value={"I"}>Inativo</Radio>
                                {/* <Radio value={"E"}>Excluido</Radio> */}
                            </Radio.Group>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="Nat. Operação" name="naturezaOperacaoPadrao">
                        <Input disabled placeholder="" />
                    </Form.Item>
                    <Form.Item name="nome" label="Nome" rules={[{ required: true, message: 'Nome Obrigatório' }]}>
                        <TextArea placeholder="" rows={1} />
                    </Form.Item>
                    <Form.Item hidden={isPF} name="fantasia" label="Nome Fantasia">
                        <Input placeholder="" />
                    </Form.Item>
                    <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email', message: 'Email Inválido!' }]}>
                        <Input placeholder="" />
                    </Form.Item>
                    <Form.Item name="emailNfe" label="Email Nfe" rules={[{ type: 'email', message: 'Email Inválido!' }]}>
                        <Input disabled placeholder="" />
                    </Form.Item>
                    <Form.Item label="Telefone Fixo">
                        <Form.Item name="telefone" className="-ml-2" label="" style={{ display: 'inline-block', width: 'calc(55% - 8px)', margin: '0 0 0' }}>
                            <Input placeholder="(xx) xxxx-xxxx" />
                        </Form.Item>
                        <Form.Item name="celular" label="Celular" style={{ display: 'inline-block', width: 'calc(45% - 0px)', margin: '0 0 0 8px' }}>
                            <Input placeholder="(xx) xxxxx-xxxx" />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item hidden={isPJ} label="R.G">
                        <Form.Item name="rg" className="-ml-2" label="" style={{ display: 'inline-block', width: 'calc(55% - 8px)', margin: '0 0 0' }}>
                            <Input placeholder="" />
                        </Form.Item>
                        <Form.Item name="orgaoEmissor" label="Orgão Emissor" style={{ display: 'inline-block', width: 'calc(45% - 0px)', margin: '0 0 0 8px' }}>
                            <Input placeholder="" />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="Inscr. Estadual">
                        <Form.Item name="ie" className="-ml-2" label="" style={{ display: 'inline-block', width: 'calc(35% - 8px)', margin: '0 0 0' }}>
                            <Input placeholder="" />
                        </Form.Item>
                        <Form.Item name="indicadorIe" label="Ind.Ie" rules={[{ required: true, message: 'Obrigatório' }]} style={{ display: 'inline-block', width: 'calc(65% - 0px)', margin: '0 0 0 8px' }}>
                            <Select placeholder="Selecione ...">
                                <Option value={''}>Selecione ...</Option>
                                {indicadorIe.map((iie: any, index: any) => {
                                    return <Option value={iie.value}>{iie.value}-{iie.label}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Form.Item>
                    {/* regex para cep  pattern: /^[0-9]{8}/  */}
                    <Form.Item label="Cep">
                        <Form.Item name="cep" rules={[{ required: true, pattern: /^\d{8}$/, message: '8 digitos numéricos' }]} style={{ display: 'inline-block', width: 'calc(30% - 8px)', margin: '0 0 0' }}>
                            <Input className="w-[100px]" onChange={handleCep} />
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(45% - 8px)', margin: '0 0 0' }}>
                            <Button className="rounded-md flex items-center gap-1" hidden={!haveCep} onClick={buscarCep} icon={<HiRefresh size={20} className="text-xlxx" />}>Atualizar</Button>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="Endereço">
                        <Form.Item name="endereco" className="-ml-2" label="" rules={[{ required: true, message: 'Endereço Obrigatório' }]} style={{ display: 'inline-block', width: 'calc(75% - 8px)', margin: '0 0 0' }}>
                            <TextArea placeholder="" rows={1} />
                        </Form.Item>
                        <Form.Item name="numero" label="Nº" style={{ display: 'inline-block', width: 'calc(25% - 0px)', margin: '0 0 0 8px' }}>
                            <Input placeholder="" />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item name="complemento" label="Complemento">
                        <TextArea placeholder="" rows={1} />
                    </Form.Item>
                    <Form.Item name="bairro" label="Bairro">
                        <TextArea placeholder="" rows={1} />
                    </Form.Item>
                    <Form.Item label="Município">
                        <Form.Item name="municipio" className="-ml-2" label="" rules={[{ required: true, message: 'Município Obrigatório' }]} style={{ display: 'inline-block', width: 'calc(83% - 8px)', margin: '0 0 0' }}>
                            <Input placeholder="" />
                        </Form.Item>
                        <Form.Item name="uf" label="UF" rules={[{ validator: checkUf }]} style={{ display: 'inline-block', width: 'calc(17% - 0px)', margin: '0 0 0 8px' }}>
                            <Input maxLength={2} placeholder="" />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item name="all" label="Atualiza todos?" rules={[{ required: true, message: 'Faça uma escolha' }]} >
                        <Radio.Group >
                            <Radio value={"S"}>Sim</Radio>
                            <Radio value={"N"}>Não</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </div>
            <Space className="drawer-footer flex w-full justify-end">
                <Space>
                    <Button hidden={buttonsDisabled} onClick={handleCancel}>Cancel</Button>
                    <Button onClick={formCliente.submit} hidden={buttonsDisabled} disabled={savebtnDisabled} type="primary">Salvar</Button>
                </Space>
            </Space>
        </Drawer>
    )

}