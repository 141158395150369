import React from "react";

export default function SpinnerModal(props: any) {
  return (

    <div
      className={`spinner justify-center items-center`}
      style={{
        display: props.loading ? "flex" : "none",
        position: 'fixed',
        top: 60,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        zIndex: 9999,
      }}
    >
      <div  >
        <div className="lds-spinner">
          <div></div><div></div><div></div><div></div><div></div><div></div>
          <div></div><div></div><div></div><div></div><div></div><div></div>
        </div>
      </div>
    </div>
  )
}
