import { formatDecimal } from "../../utils/functions";
import { Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { getColumnSearchProps } from "../../components/columnSearchProps";
import { filter } from "../../zTemp/constMovimentosEstoque";

export function columnsProduto(props: any) {
  // const { setSearchText, setSearchedColumn, setSelectedKeys, selectedKeys, searchInput, searchedColumn, searchText } = props;
  const filterCategorias = props.categorias.map((item: any) => {
    return { text: item, value: item }
  })
  return [
    // {
    //   title: 'Id',
    //   dataIndex: 'id',
    //   ellipsis: true,
    //   width: '40px',
    //   className: 'text-right',
    // },
    {
      title: 'Codigo',
      dataIndex: 'codigo',
      ellipsis: true,
      width: '90px',
      className: 'text-left',
      sorter: (a: any, b: any) => a.codigo.localeCompare(b.codigo),
      ...getColumnSearchProps({
        dataIndex: 'codigo',
        ...props
      })
    },
    {
      title: 'Nome',
      dataIndex: 'nome',
      width: '445px',
      className: 'text-left',
      ellipsis: false,
      sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
      ...getColumnSearchProps({
        dataIndex: 'nome',
        ...props
      })
    },
    {
      title: 'NCM',
      dataIndex: 'ncm',
      width: '90px',
      className: 'text-left',
      ellipsis: false,
      sorter: (a: any, b: any) => a.ncm.localeCompare(b.ncm),
      ...getColumnSearchProps({
        dataIndex: 'ncm',
        ...props
      })
    },
    {
      title: 'Nome Componente',
      dataIndex: 'nomeComponente',
      width: '190px',
      className: 'text-left',
      ellipsis: true,
      sorter: (a: any, b: any) => a.nomeComponente.localeCompare(b.nomeComponente),
      ...getColumnSearchProps({
        dataIndex: 'nomeComponente',
        ...props
      })
    },
    {
      title: 'UN',
      dataIndex: 'unidade',
      width: '45px',
      className: 'text-left',
      ellipsis: true,
      render: (text: any, record: any) => `${text}`
    },
    {
      title: 'SP',
      dataIndex: 'spedTipoItem',
      width: '55px',
      className: 'text-left',
      ellipsis: true,
      // sorter: (a: any, b: any) => a.spedTipoItem.localeCompare(b.spedTipoItem),
      ...getColumnSearchProps({
        dataIndex: 'spedTipoItem',
        ...props
      })
    },
    {
      title: 'Categoria',
      dataIndex: 'categoria',
      width: '140px',
      className: 'text-left',
      ellipsis: true,
      render: (text: any, record: any) => `${text}`,
      filters: filterCategorias,
      filterMode: 'menu',
      // filterSearch: true,
      onFilter: (value: string, record: any) => record.categoria.includes(value),
    },
    {
      title: 'P.Venda',
      dataIndex: 'precoVenda',
      width: '80px',
      key: "precoVenda",
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },
    {
      title: 'P.Compra',
      dataIndex: 'precoCompra',
      width: '80px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },
    {
      title: 'P.Info',
      dataIndex: 'precoInformado',
      width: '80px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },
    {
      title: 'Est.Min',
      dataIndex: 'estoqueMinimo',
      width: '90px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      render: (text: any, record: any) => `${formatDecimal(+text, 0)} ${record.estoqueMinimo ? record.unidade : ''}`
    },
    {
      title: 'Prz.Rep',
      dataIndex: 'prazoReposicao',
      width: '75px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      render: (text: any, record: any) => `${formatDecimal(+text, 0)} ${record.prazoReposicao ? 'D' : ''}`
    },
    {
      title: 'Situação',
      dataIndex: 'situacao',
      width: '80px',
      className: 'text-left',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: record.situacao === 'Inativo' ? "text-red-400 font-semibold" : "" }),
      render: (text: any, record: any) => `${text}`,
      filters: [
        {
          text: 'Ativo',
          value: 'Ativo',
        },
        {
          text: 'Inativo',
          value: 'Inativo',
        },
      ],
      filterMode: 'menu',
      defaultFilteredValue: ['Ativo'],
      onFilter: (value: string, record: any) => record.situacao.includes(value),
    },
  ]
}