import { useCallback, useEffect, useState } from 'react'
import useProcessando from '../../../data/hooks/useProcessando'
import { apierp } from '../../../services/api'

export default function useDre(ano: number) {
    const { processando, iniciarProcessamento, finalizarProcessamento } = useProcessando()
    const [dres, setDres] = useState<any[]>([])
    const [dre, setDre] = useState<any>(null)

    const obterDres = useCallback(async function () {
        try {
            iniciarProcessamento()
            const body = {
                ano,
                empresaId: null
            }
            const response = await apierp.post('relatorios/dre', body)
            const data = await response.data as any;
            setDres(data)
            // console.log(data)
        } finally {
            finalizarProcessamento()
        }
    }, [iniciarProcessamento, finalizarProcessamento])

    function selecionarDre(dre: any) {
        setDre(dre)
    }

    function voltar() {
        setDre(null)
    }

    useEffect(() => {
        obterDres()
    }, [obterDres])

    return {
        dres,
        dre,
        ano,
        processando,
        selecionarDre,
        voltar
    }

}