import { dateMySqlToPtBr } from "../../../utils/dateFunctions";
import { formatDecimal } from "../../../utils/functions";
import { Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { getColumnSearchProps } from "../../../components/columnSearchProps";

export function columnsHistoricoCompras(props: any) {
  // const { setSearchText, setSearchedColumn, setSelectedKeys, selectedKeys, searchInput, searchedColumn, searchText } = props;
  return [
    {
      title: 'Empresa',
      dataIndex: 'nomeCurto',
      width: '100px',
      // align: 'right' as 'right',
      // className: 'text-right',
      ellipsis: true,
    },
    {
      title: 'Data',
      dataIndex: 'datacompra',
      width: '100px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      render: (text: any, record: any) => `${dateMySqlToPtBr(text)}`
    },
    {
      title: 'Pedido',
      dataIndex: 'numeropedido',
      width: '70px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
    },
    {
      title: 'Fornecedor',
      dataIndex: 'nomefornecedor',
      width: '200px',
      ellipsis: true,
    },
    {
      title: 'Quantidade',
      dataIndex: 'quantidade',
      width: '100px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },
    {
      title: 'UN',
      dataIndex: 'unidade',
      width: '50px',
      // align: 'right' as 'right',
      // className: 'text-right',
      ellipsis: true,
    },
    {
      title: 'P.Unit',
      dataIndex: 'precounitario',
      width: '100px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },
    {
      title: 'Ipi',
      dataIndex: 'ipi',
      width: '80px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },
    {
      title: 'Total',
      dataIndex: 'totalcompra',
      width: '120px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },

  ]
}