import { Button, Drawer, Form, Input, Radio, Select, Space } from "antd"
import useWindowSize from "../../hooks/useWindowSize";
import { useEffect } from "react";
import { formatDecimalZ } from "../../utils/functions";

const { TextArea } = Input
const { Option } = Select;

export default function DrawerFormPrePedidoVenda(props: any) {

    const { hideDrawerFormPrePedidoVenda, drawerFormPrePedidoVendaVisible, operacao, formasPagamento, empresas } = props
    // console.log('empresas', empresas)
    // console.log('formasPagamento', formasPagamento)
    const [formPrePedidoVenda] = Form.useForm();
    const fields = {
        id: 1,
        // tenantId: 3,
        empresaId: null,
        // createdAt: "2024-05-02T14:01:57.604Z",
        // updatedAt: "2024-05-02T14:01:57.604Z",
        // clienteId: 6699,
        // vendedorId: 10,
        data: "2024-04-08",
        totalprodutos: 1020,
        total: 1020,
        situacaoId: 1,
        // blingLojaId: null,
        blingClienteId: "16588688540",
        blingPedidoVendaId: null,
        blingPedidoNumero: "1234567",
        observacoes: "Observação exemplo",
        observacoesInternas: "Observação interna exemplo",
        desconto: 0,
        outrasDespesas: 0,
        prazoEntrega: null,
        blingCategoriaReceitaDespesaId: "2611836175",
        blingTransportadoraId: null,
        fretePorConta: '1- Do destinatário (FOB)',
        frete: 50,
        quantidadeVolumes: 2,
        pesoBruto: 34,
        blingNotaFiscalId: null,
        blingVendedorId: "15596338666",
        // cliente: "FERNANDO RODRIGUES PACHECO",
        // vendedor: "CLEBER",
        // situacao: "Em Andamento"
    }
    const fillFormPrePedidoVenda = (fields: any) => {
        formPrePedidoVenda.setFieldsValue(fields)
    }

    //provisorio

    useEffect(() => {
        fillFormPrePedidoVenda(fields)
    }, [drawerFormPrePedidoVendaVisible])

    const handleSubmitFormPrePedidoVenda = async (values: any) => {
        console.log('handleSubmitFormPrePedidoVenda', values)
    }

    const [innerW, innerH] = useWindowSize();
    const wDrawerx = innerW > 840 ? 840 : innerW > 720 ? 720 : 360
    const layoutf = {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
        // gutter: 16,
        // colon: true,
        // hideRequiredMark: false,
        // scrollToFirstError: false,
        // validateTrigger: "onChange",

    }
    const layout0 = {
        labelCol: { span: 0 },
        wrapperCol: { span: 24 }
    }

    return (
        <Drawer
            title={`${operacao} de Pre-Pedido Venda`}
            width={wDrawerx}
            closable={true}
            maskClosable={false}
            onClose={hideDrawerFormPrePedidoVenda}
            open={drawerFormPrePedidoVendaVisible}
            headerStyle={{ borderRadius: "0" }}
            bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
        >
            <div className="inner-drawer-body">
                <Form {...layoutf} form={formPrePedidoVenda} onFinish={handleSubmitFormPrePedidoVenda}>
                    <Form.Item name="id" hidden noStyle></Form.Item>
                    <Form.Item name="blingPedidoVendaId" hidden noStyle></Form.Item>
                    <Form.Item name="blingCategoriaReceitaDespesaId" hidden noStyle></Form.Item>
                        {/* <Form.Item name="blingCategoriaReceitaDespesaId" label="Categoria" className="inline-block mb-2 ml-4"><Input placeholder="" className="w-48" /></Form.Item> */}

                    <div className="font-bold mb-2">Dados do Pedido</div>
                    <Form.Item label="Empresa" className="empresa mb-0">
                        <Form.Item name="empresaId" label="" className="inline-block mb-2 bg-red-300">
                            <Select placeholder="Empresa" style={{ width: '256px' }} >
                                {/* <Option value="">Selecione ...</Option> */}
                                {empresas.map((empresa: any) => <Option key={empresa.id} value={empresa.id}>{empresa.nome}</Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item name="data" label="Data" className="inline-block mb-2 ml-4"><Input placeholder="" className="w-24" /></Form.Item>
                        <Form.Item name="situacaoId" label="Situação" className="inline-block mb-2 ml-4"><Input placeholder="" className="w-[160px]" /></Form.Item>
                    </Form.Item>

                    <Form.Item name="blingClienteId" label="Cliente" className="mb-2 xbg-violet-300"><Input placeholder="" className="" /></Form.Item>
                    {/* <Form.Item name="blingPedidoVendaId" label="Pedido Venda"><Input placeholder="" className="w-64" /></Form.Item> */}
                    <Form.Item label="Nº Pedido" className="pedido1 mb-0">
                        <Form.Item name="blingPedidoNumero" label="" className="inline-block mb-2"><Input placeholder="" className="w-20" /></Form.Item>
                        <Form.Item name="blingVendedorId" label="Vendedor" className="inline-block mb-2 ml-4"><Input placeholder="" className="w-64" /></Form.Item>
                    </Form.Item>

                    <div className="font-bold mb-2">Itens do Pedido</div>

                    <div className="font-bold mb-2">Totais</div>

                    <div className="flex gap-0">
                        <div className="flex flex-col w-1/5 text-right">
                            <div className=" py-1 px-2 mt-x bg-slate-300 font-semibold border-solid border-0 border-b border-t">Total Produtos</div>
                            <div className=" py-1 px-2 mb-2 bg-slate-50  font-normal border-solid border-0 border-b ">{formatDecimalZ(fields.totalprodutos, 2)}</div>
                        </div>
                        <div className="flex flex-col w-1/5 text-right">
                            <div className=" py-1 px-2 mt-x bg-slate-300 font-semibold border-solid border-0 border-b border-t">Desconto</div>
                            <div className=" py-1 px-2 mb-2 bg-slate-50  font-normal border-solid border-0 border-b ">{formatDecimalZ(fields.desconto, 2)}</div>
                        </div>
                        <div className="flex flex-col w-1/5 text-right">
                            <div className=" py-1 px-2 mt-x bg-slate-300 font-semibold border-solid border-0 border-b border-t">Frete</div>
                            <div className=" py-1 px-2 mb-2 bg-slate-50  font-normal border-solid border-0 border-b ">{formatDecimalZ(fields.frete, 2)}</div>
                        </div>
                        <div className="flex flex-col w-1/5 text-right">
                            <div className=" py-1 px-2 mt-x bg-slate-300 font-semibold border-solid border-0 border-b border-t">Outras Despesas</div>
                            <div className=" py-1 px-2 mb-2 bg-slate-50  font-normal border-solid border-0 border-b ">{formatDecimalZ(fields.outrasDespesas, 2)}</div>
                        </div>
                        <div className="flex flex-col w-1/5 text-right">
                            <div className=" py-1 px-2 mt-x bg-slate-300 font-semibold border-solid border-0 border-b border-t">Total do Pedido</div>
                            <div className=" py-1 px-2 mb-2 bg-slate-50  font-normal border-solid border-0 border-b ">{formatDecimalZ(fields.total, 2)}</div>
                        </div>
                    </div>
                    {/* <Form.Item name="totalprodutos" label="Total Produtos" className="mb-2"><Input placeholder="" className="w-64" /></Form.Item>
                    <Form.Item name="desconto" label="Desconto" className="mb-2"><Input placeholder="" className="w-64" /></Form.Item>
                    <Form.Item name="frete" label="Frete" className="mb-2"><Input placeholder="" className="w-64" /></Form.Item>
                    <Form.Item name="outrasDespesas" label="Outras Desp." className="mb-2"><Input placeholder="" className="w-64" /></Form.Item>
                    <Form.Item name="total" label="Total pedido" className="mb-2"><Input placeholder="" className="w-64" /></Form.Item> */}

                    <div className="font-bold mb-2">Forma de Pagamento</div>
                    <div className="flex flex-col mb-2">
                        <div className="cabecalho flex py-1 gap-2 mt-x bg-slate-100 font-semibold border-solid border-0 border-b border-t">
                            <div className={`w-5 text-center`}>it</div>
                            <div className={`w-10 text-center`}>Dias</div>
                            <div className={`w-24 text-left`}>Data</div>
                            <div className={`w-24 text-right`}>Valor</div>
                            <div className={`w-60 text-left`}>Forma</div>
                            <div className={`w-64 text-left`}>Observação</div>
                            <div className={`w-8 text-center bg-slate-200`}></div>
                            <div className={`w-8 text-center bg-slate-200`}></div>
                        </div>
                        <div className="item flex items-center h-7 py-0 gap-2 bg-slate-300  font-normal border-solid border-0 border-b ">
                            <div className={`w-5 text-center`}>1</div>
                            <div className={`w-10 text-center`}><input type="number" className="input-number w-9 border-0 border-l border-r px-1 py-[2px]" onWheel={(e) => (e.target as HTMLInputElement).blur()} /></div>
                            <div className={`w-24 text-left`}>30/05/2024</div>
                            <div className={`w-24 text-right`}>{formatDecimalZ(2500,2)}</div>
                            <div className={`w-60 text-left`}>Pix Caixa Economica Federal</div>
                            <div className={`w-64 text-left`}>item</div>
                            <div className={`w-8 text-center bg-slate-200`}>itm</div>
                            <div className={`w-8 text-center bg-slate-200`}>itm</div>
                        </div>
                    </div>

                    <div className="font-bold mb-2">Dados do Transporte</div>

                    <Form.Item name="blingTransportadoraId" label="Transportadora" className=" mb-2"><Input placeholder="" className="" /></Form.Item>
                    <Form.Item label="Frete Por Conta" className="transporte1 mb-0">
                        <Form.Item name="fretePorConta" label="" className="inline-block mb-2"><Input placeholder="" className="w-56" /></Form.Item>
                    </Form.Item>

                    <Form.Item label="Qtd. Volumes" className="volumes1 mb-0">
                        <Form.Item name="quantidadeVolumes" label="" className="inline-block mb-2"><Input placeholder="" className="w-14" /></Form.Item>
                        <Form.Item name="pesoBruto" label="Peso Bruto" className="inline-block mb-2 ml-4"><Input placeholder="" className="w-20" /></Form.Item>
                        <Form.Item name="blingNotaFiscalId" label="Nota Fiscal" className="inline-block mb-2 ml-4"><Input placeholder="" className="w-20" /></Form.Item>
                        <Form.Item name="prazoEntrega" label="Prazo Entrega" className="inline-block mb-2 ml-4"><Input placeholder="" className="w-24" /></Form.Item>
                    </Form.Item>

                    <Form.Item name="observacoes" label="Observações" className="mb-2"><TextArea placeholder="" rows={1} className="" /></Form.Item>
                    <Form.Item name="observacoesInternas" label="Obs. Internas" className="mb-2"><TextArea placeholder="" rows={1} className="" /></Form.Item>

                </Form>
            </div>
        </Drawer>
    );
}