import { useCallback, useEffect, useState } from "react";

const useTabActive = () => {
  const [visibilityState, setVisibilityState] = useState(true);

  const handleVisibilityChange = useCallback(() => {
    setVisibilityState(document.visibilityState === 'visible');
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange)
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange)
    }
  }, []);

  return visibilityState;
}

export default useTabActive;

// usage
// const isTabActive = useTabActive();

// https://stackoverflow.com/questions/49902883/check-if-the-browser-tab-is-in-focus-in-reactjs
// RESPOSTA DO: https://stackoverflow.com/users/12820200/gal-zakay