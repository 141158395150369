import { useQuery } from "@tanstack/react-query";
import { apierp } from "../../services/api";

const fetchContato = async (clienteId: number) => {
    // console.log('fetchContato', clienteId)
    const response = await apierp.post(`clientes/obterclienteporid`, { clienteId })
    const data = await response.data as any;
    // console.log('fetchContato', [data])
    return data
}

const fetchContatos = async (termo: any) => {
    // console.log('fetchContatos', clienteId)
    const response = await apierp.post(`clientes/obterclientesportermolite`, { termo })
    const data = await response.data as any;
    // console.log('fetchContatos', data)
    return data
}


const fetchBlingContato = async (clienteId: number, empresaId: number) => {
    // console.log('fetchBlingContato', clienteId, empresaId)
    const response = await apierp.post(`clientes/obterblingclienteporclienteidempresaid`, { clienteId, empresaId })
    const data = await response.data as any;
    // console.log('fetchBlingContato', [data])
    return data
}

export function useContatos(clienteId: number, transportadorId: number, termoc: any, termot: any, empresaId: number) {
    // console.log('useContatos', clienteId, transportadorId, termoc, termot, empresaId)

    const queryCliente= useQuery({
        queryKey: ['cliente' , clienteId],
        queryFn: () => fetchContato(clienteId),
        retry: false,
        enabled: !!clienteId,
    });

    const queryClientes= useQuery({
        queryKey: ['clientes' , termoc],
        queryFn: () => fetchContatos(termoc),
        retry: false,
        enabled: !!termoc,
    });

    const queryTransportador= useQuery({
        queryKey: ['transportador' , transportadorId],
        queryFn: () => fetchContato(transportadorId),
        retry: false,
        enabled: !!transportadorId,
    });

    const queryTransportadores= useQuery({
        queryKey: ['transportadores' , termot],
        queryFn: () => fetchContatos(termot),
        retry: false,
        enabled: !!termot,
    });

    //=====
    const queryBlingCliente= useQuery({
        queryKey: ['blingcliente' , clienteId, empresaId],
        queryFn: () => fetchBlingContato(clienteId, empresaId),
        retry: false,
        enabled: !!clienteId,
    });

    
    const queryBlingTransportador= useQuery({
        queryKey: ['blingtransportador' , transportadorId, empresaId],
        queryFn: () => fetchBlingContato(transportadorId, empresaId),
        retry: false,
        enabled: !!transportadorId,
    });

    return {
        qrCliente: queryCliente,
        qrTransportador: queryTransportador,
        qrClientes: queryClientes,
        qrTransportadores: queryTransportadores,
        qrBlingCliente: queryBlingCliente,
        qrBlingTransportador: queryBlingTransportador,
}
}