// import React from 'react';
import './App.css';
import Routes from './routes';
import { GeralProvider } from './contexts/geral';
import { AuthProvider } from './contexts/auth';

import { registerLocale, setDefaultLocale } from "react-datepicker";
import { ptBR } from 'date-fns/locale';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// const queryClient = new QueryClient()
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
})

function App() {
  registerLocale('pt-BR', ptBR)
  setDefaultLocale('pt-BR');
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <GeralProvider>
          <AuthProvider>
            <Routes />
          </AuthProvider>
        </GeralProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
