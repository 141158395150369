
interface Dado {
    [key: string]: string | number;
}

export function groupByTs(dados: Dado[], arrayAtributos: string[], arrayValores: string[]): Dado[] {
    const acc: Dado[] = [];

    dados.forEach(obj => {
        // Cria a chave baseada nos atributos
        const key = arrayAtributos.map(attr => obj[attr]).join('-');

        // Procura o objeto no acumulador que corresponde à chave
        let groupObj = acc.find(o => arrayAtributos.map(attr => o[attr]).join('-') === key);

        // Se o objeto não existir no acumulador, cria um novo objeto com os atributos e valores iniciais
        if (!groupObj) {
            groupObj = {};
            arrayAtributos.forEach(attr => groupObj![attr] = obj[attr]);
            arrayValores.forEach(val => groupObj![val] = 0);
            acc.push(groupObj);
        }

        // Soma os valores
        arrayValores.forEach(val => (groupObj![val] as number) += obj[val] as number);
    });

    return acc;
}