import React from "react";

export default function Spinner(props: any) {
  return (
    <div className={`spinner justify-center items-center`} style={{ textAlign: "center", display: props.loading ? "flex" : "none" }} >
      {/* <Heading className="v-h2">Gerando Relatório !!!</Heading>
      <Heading className="v-h3">Aguarde um instante !!!</Heading> */}
      <div className="flex flex-col items-center">
        <div className="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div>{props.children}</div>
      </div>
    </div>
  )
}
