import { LockFilled, UnlockFilled, CheckSquareFilled, StarFilled, DownloadOutlined, CopyOutlined, PrinterOutlined, PlusOutlined } from '@ant-design/icons';

export  const columnsFichaTecnica = [
    {
      title: 'Item',
      dataIndex: '',
      ellipsis: true,
      width: '60px',
      align: 'right' as 'right',
      className: 'text-right',
      render: (text: any, record: any, index: any) => `${index + 1}`
    },
    {
      title: 'Nome da Ficha Técnica',
      dataIndex: 'nome',
      width: '300px',
      className: 'text-left',
      ellipsis: false,
      render: (text: any, record: any) => `${text}`
    },
    {
      title: 'UN',
      dataIndex: 'unidade',
      width: '40px',
      className: 'text-left',
      ellipsis: true,
      render: (text: any, record: any) => `${text}`
    },
    {
      title: 'Produz',
      dataIndex: 'quantProduzida',
      ellipsis: true,
      width: '80px',
      align: 'right' as 'right',
      className: 'text-right',
      render: (text: any, record: any) => `${text.toString().replace(".", ",")}`
    },
    {
      title: 'Para Custo',
      dataIndex: 'isForCusto',
      width: '90px',
      className: 'text-left',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: record.isForCusto ? "text-blue-500 my-nowrap" : "my-nowrap" }),
      render(text: any, record: any, i: any) {
        return {
          // children: (<>{!record.isForCusto ? <div> {text ? <div><LockFilled /></div> : <div><CheckSquareFilled /> Sim</div>} </div> : ''}</>)
          children: (<>{!record.isForCusto ? null : <div><CheckSquareFilled /> Sim</div>}</>)
        }
      }
    },
    {
      title: 'Situação',
      dataIndex: 'situacao',
      width: '80px',
      className: 'text-left',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: record.situacao === 'Inativo' ? "text-red-500" : "text-blue-500" }),
      render: (text: any, record: any) => `${text}`
    },
  ]