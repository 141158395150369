import { useEffect, useRef, useState } from "react"

import { Button, Col, Drawer, Form, Input, InputRef, message, Popconfirm, Radio, Row, Select, Space, Typography } from "antd";
import DatePicker from "react-datepicker";
// import  "react-datepicker/dist/react-datepicker.css"
import moment from 'moment';
import Layout from "../../../components/template/Layout"
import { printDiv, copyToClipboard, downloadToCsv } from "../../../utils/functions";
import { LockFilled, UnlockFilled, CheckSquareFilled, DownloadOutlined, CopyOutlined, PrinterOutlined, PlusOutlined, FilterOutlined, FastBackwardOutlined } from '@ant-design/icons';
import { useAuth } from "../../../contexts/auth";
import useWindowSize from "../../../hooks/useWindowSize";
import { columnsOrdemProducao } from "./columnsOrdemProducaoV2";
import { useGeral } from "../../../contexts/geral";
import SpinnerNew from "../../../components/template/SpinnerNew";
import Tabela from "../../../components/template/Tabela";
import { customMessage } from "../../../components/template/CustomMessage";
import { apierp } from "../../../services/api";
import { dateFormat, dateMySqlToPtBr, dateOnly, datePtBrToMySql } from "../../../utils/dateFunctions";
import { OrdemProducaoItens } from "./OrdemProducaoItemV2";
import isPermitted from "../../../hooks/isPermitted";
import { FilterConfirmProps } from "antd/lib/table/interface";

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
const { Option } = Select;

export default function OrdemProducaoV2() {

  //ESTADOS Gerais
  const { Logout, user } = useAuth();
  const usuario: any = user
  const [innerW, innerH] = useWindowSize();

  const { userMenu, menuSelected, userTenant, permissions } = useGeral();

  const menu = userMenu.find((x: any) => x.id == menuSelected)

  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const [toggleState, setToggleState] = useState(false)

  const [mesref, setMesRef] = useState(null as any)

  //ESTADOS Produto
  const [produtos, setProdutos] = useState([])
  const [produto, setProduto] = useState(null as any)

  //ESTADOS OrdemProducao
  const [formOrdemProducao] = Form.useForm();
  const [ordensProducao, setOrdensProducao] = useState([])
  const [loadingOrdemProducao, setLoadingOrdemProducao] = useState(false)
  const [drawerOrdensProducaosVisible, setDrawerOrdensProducaosVisible] = useState(false)
  const [drawerFormOrdemProducaoVisible, setDrawerFormOrdemProducaoVisible] = useState(false)
  // const [ordemProducao, setOrdemProducao] = useState(null as any)
  const [rowOrdemProducaoSelected, setRowOrdemProducaoSelected] = useState(-1)
  const [lastOrdemProducaoSelected, setLastOrdemProducaoSelected] = useState(-1)
  const [isInclusaoOrdemProducao, setIsInclusaoOrdemProducao] = useState(false)
  const [ordemProducaoBlocked, setOrdemProducaoBlocked] = useState(false)
  const [hideButtonFinaliza, setHideButtonFinaliza] = useState(false)
  const [hideButtonReverter, setHideButtonReverter] = useState(false)
  const [isInProcessing, setIsInProcessing] = useState(false)
  const [finishDate, setFinishDate] = useState(new Date(dateFormat(new Date(), 'yyyy-MM-dd 12:00:00')));

  //ESTADOS OrdemProducaoItem
  const [formOrdemProducaoItem] = Form.useForm();
  const [loadingOrdemProducaoItem, setLoadingOrdemProducaoItem] = useState(false)
  const [drawerOrdemProducaoItensVisible, setDrawerOrdemProducaoItensVisible] = useState(false)
  const [drawerFormOrdemProducaoItemVisible, setDrawerFormOrdemProducaoItemVisible] = useState(false)
  const [ordemProducaoFull, setOrdemProducaoFull] = useState(null as any)
  const [ordemProducaoHasNegativo, setOrdemProducaoFullHasNegativo] = useState(false)
  // const [ordemProducaoItem, setOrdemProducaoItem] = useState(null as any)
  // const [ordemProducaoItemBlocked, setOrdemProducaoItemBlocked] = useState(false)
  const [rowOrdemProducaoItemSelected, setRowOrdemProducaoItemSelected] = useState(-1)
  const [lastOrdemProducaoItemSelected, setLastOrdemProducaoItemSelected] = useState(-1)
  // const [isInclusaoOrdemProducaoItem, setIsInclusaoOrdemProducaoItem] = useState(false)
  const [onlyViewOPItem, setOnlyViewOPItem] = useState(true)

  const isPermittedVisualizarItensOP = isPermitted('visualizar_itens_ordem_producao')
  const isPermittedVisualizarNomesProdutos = isPermitted('visualizar_nomes_produtos')
  const isPermittedVisualizarPrecosProdutos = isPermitted('visualizar_precos_produtos')
  const isPermittedManterOP = isPermitted('manter_ordens_producao')
  const isPermittedReverterOP = isPermitted('reverter_ordens_producao')
  const isPermittedFinalizarOP = isPermitted('finalizar_ordens_producao')

  // search filter produto
  interface DataType {
    descricao: string;
    nomeComponente: string;
  }
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [selectedKeys, setSelectedKeys] = useState('');
  const searchInput = useRef<InputRef>(null);
  type DataIndex = keyof DataType;
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };
  //end

  const colunasOrdemProducao = columnsOrdemProducao({
    setSearchText, setSearchedColumn, setSelectedKeys, selectedKeys, searchInput, searchedColumn, searchText
  })

  useEffect(() => {
    const now = new Date()
    setMesRef(now)
  }, [])

  useEffect(() => {
    // console.log('mesref:', mesref)
    setOrdensProducao([]);
    setToggleState(!toggleState)
      }, [mesref])

  useEffect(() => {
    setLoadingOrdemProducao(true)
    setTimeout(async function () {
      try {
        await loadOrdensProducao(mesref)
        await loadProdutos()
      } catch (err) {
        if (customMessage(err) === 403) Logout()
      }
      setLoadingOrdemProducao(false)
    }, 500);
  }, [toggleState])

  function enableButtons(wait: number) {
    setTimeout(function () {
      setButtonsDisabled(false)
      // setMsgError('')
    }, wait);
  }
  function disableButtons() {
    setButtonsDisabled(true)
  }

  async function loadOrdensProducao(mesref: any) {
    if (!mesref) {return}
    const ano = mesref.getFullYear()
    const mes = mesref.getMonth() + 1
    // console.log('ano,mes:',ano,mes)
    // const response = await apierp.get(`ordensproducaobt`)
    const response = await apierp.get(`ordensproducaobtam/${ano}/${mes}`)
    const data = response.data as any;
    setOrdensProducao(data);
    // console.log(data)
  }

  async function loadOrdemProducaoFull(ordemProducaoId: any) {
    const response = await apierp.get(`ordensproducaofull/${ordemProducaoId}`)
    const data = response.data as any;
    setOrdemProducaoFull(data);
    let hasNegativo = false
    for (let it of data.ordemproducaoitens) {
      if (it.estoqueDisponivel < it.quantConsumida) {
        hasNegativo = true
      }
    }
    setOrdemProducaoFullHasNegativo(hasNegativo)
    // console.log('hasNegativo', hasNegativo, data)
  }
  async function loadProdutos() {
    const response = await apierp.get(`produtosbt/X7`) // X7 - (03 E 04), 04 - Produto Acabado (speddTI)
    const data = response.data as any;
    // console.log(data)
    setProdutos(data.sort((a: any, b: any) => (a.descricao < b.descricao ? -1 : a.seq === b.seq ? 0 : 1)));
  }
  async function loadProdutoFt(produtoId: any) {
    const response = await apierp.get(`produtosft/${produtoId}`)
    const data = response.data as any;
    // console.log(data)
    setProduto(data);
  }

  async function handleSubmitOrdemProducao(dados: any) {
    setButtonsDisabled(true)
    dados.dataConclusao = dateFormat(finishDate, 'yyyy-MM-dd')
    // console.log('dados', dados)
    delete dados.produtoId // o que interessa é fichatecnicaId
    if (dados.id === '') {
      delete dados.id
      //CREATE
      try {
        const result = await apierp.post('ordensproducao', dados);
        setToggleState(!toggleState)
        message.success('Ordem Produção incluida com sucesso!');
        hideDrawerFormOrdemProducao()
      } catch (err) {
        if (customMessage(err) === 403) Logout()
        enableButtons(500)
      }
    } else {
      //UPDATE
      try {
        const result = await apierp.put(`ordensproducao/${dados.id}`, dados);
        setToggleState(!toggleState)
        message.success('Ordem Produção alterada com sucesso!');
        hideDrawerFormOrdemProducao()
      } catch (err) {
        if (customMessage(err) === 403) Logout()
        enableButtons(500)
      }
    }
  };
  async function handleDelete() {
    const dados = formOrdemProducao.getFieldsValue()
    try {
      await apierp.delete(`ordensProducao/${dados.id}`);
      setToggleState(!toggleState)
      setLastOrdemProducaoSelected(-1)
      message.success('Ordem Produção excluida com sucesso!');
      hideDrawerFormOrdemProducao()
    } catch (err) {
      if (customMessage(err) === 403) Logout()
    }

  };

  async function handleFinalizar() {
    // console.log('handleFinalizar', ordemProducaoFull.id)
    const dados = {
      "ordemproducaoId": ordemProducaoFull.id
    }
    try {
      await apierp.post(`ordensproducao/finalizar`, dados);
      setToggleState(!toggleState)
      setLastOrdemProducaoSelected(-1)
      message.success('Ordem Produção finalizada com sucesso!');
      hideDrawerOrdemProducaoItens()
      hideDrawerFormOrdemProducao()

    } catch (err) {
      if (customMessage(err) === 403) Logout()
    }
  };
  async function handleReverter() {
    // console.log('handleReverter', ordemProducaoFull.id)
    const dados = {
      "ordemproducaoId": ordemProducaoFull.id
    }
    try {
      await apierp.post(`ordensproducao/reverter`, dados);
      setToggleState(!toggleState)
      setLastOrdemProducaoSelected(-1)
      message.success('Ordem Produção revertida com sucesso!');
      hideDrawerOrdemProducaoItens()
      hideDrawerFormOrdemProducao()
    } catch (err) {
      if (customMessage(err) === 403) Logout()
    }
  };

  function confirmDelete(e: any) {
    handleDelete();
  }
  function cancelDelete(e: any) {
    message.error('Ordem Produção não foi excluída');
  }
  function confirmFinalizar(e: any) {
    setHideButtonFinaliza(true)
    setIsInProcessing(true)
    handleFinalizar();
  }
  function cancelFinalizar(e: any) {
    hideDrawerOrdemProducaoItens()
    message.error('Ordem Produção não foi Finalizada');
  }
  function confirmReverter(e: any) {
    setHideButtonReverter(true)
    setIsInProcessing(true)
    handleReverter();
  }
  function cancelReverter(e: any) {
    hideDrawerOrdemProducaoItens()
    message.error('Ordem Produção não foi Revertida');
  }

  async function handleRowOrdemProducao(record: any, index: any) {
    setLastOrdemProducaoSelected(index)
    setRowOrdemProducaoSelected(index)
    const ordemProducao: any = ordensProducao.find((x: any) => x.id === record.id)
    // console.log('opxxx',ordemProducao)
    setFinishDate(new Date(`${ordemProducao.dataConclusao} 12:00:00`))
    setHideButtonFinaliza(false)
    setHideButtonReverter(false)
    setIsInProcessing(false)
    await loadProdutoFt(ordemProducao.fichatecnica.produtoId)
    // await loadProdutoFt(ordemProducao.produtoId) //XXX
    await loadOrdemProducaoFull(ordemProducao.id)
    setIsInclusaoOrdemProducao(false)
    setOrdemProducaoBlocked(!(ordemProducao.situacao === 'aberta' || ordemProducao.situacao === 'revertida'))
    showDrawerFormOrdemProducao()
    fillFormOrdemProducao(ordemProducao)
  }

  // Drawers Form OrdemProducao
  const showDrawerFormOrdemProducao = () => {
    setButtonsDisabled(false)
    setDrawerFormOrdemProducaoVisible(true)
  };
  const hideDrawerFormOrdemProducao = async () => {
    setButtonsDisabled(false)
    // await loadFichasTecnicas(produto.id)
    setRowOrdemProducaoSelected(-1)
    setDrawerFormOrdemProducaoVisible(false)
  }
  const fillFormOrdemProducao = (ordemproducao: any) => {
    formOrdemProducao.setFieldsValue({
      // codigo: produto.codigo,
      // descricao: produto.descricao,
      id: ordemproducao.id,
      produtoId: ordemproducao.fichatecnica.produtoId,
      // produtoId: ordemproducao.produtoId, //XXX
      fichatecnicaId: ordemproducao.fichatecnicaId,
      quantProduzida: ordemproducao.quantProduzida.toString().replace(".", ","),
      depositoId: ordemproducao.depositoId,
      // dataConclusao: dateMySqlToPtBr(ordemproducao.dataConclusao),
      observacao: ordemproducao.observacao,
      situacao: ordemproducao.situacao
    });
  };

  const novaOrdemProducao = () => {
    formOrdemProducao.resetFields();
    // setFinishDate(new Date(dateFormat(new Date(), 'yyyy-MM-dd 12:00:00')))
    setProduto(null as any)
    setOrdemProducaoFull(null as any);
    setIsInclusaoOrdemProducao(true)
    setOrdemProducaoBlocked(false)
    setIsInProcessing(false)
    showDrawerFormOrdemProducao()
    setLastOrdemProducaoSelected(-1)
    setRowOrdemProducaoSelected(-1)
    setOrdemProducaoFullHasNegativo(false)
    formOrdemProducao.setFieldsValue({
      id: '',
      situacao: 'em criação',
      observacao: null
      // dataConclusao: dateFormat(new Date(),'dd/MM/yyyy')
    });
  }

  const handleProdutochange = async (produtoId: any) => {
    await loadProdutoFt(produtoId)
    const produto: any = produtos.find((x: any) => x.id === produtoId)
    formOrdemProducao.setFieldsValue({
      // id: '',
      fichatecnicaId: '',
      depositoId: produto.depositoId,
    });
  };

  // Drawers Table OrdemProducaoItem
  const showDrawerOrdemProducaoItens = async (onlyView: any) => {
    // await loadOrdemProducaoItens(produto.id)
    setOnlyViewOPItem(onlyView)
    setButtonsDisabled(false)
    setRowOrdemProducaoItemSelected(-1)
    setLastOrdemProducaoItemSelected(-1)
    setDrawerOrdemProducaoItensVisible(true)
  };
  const hideDrawerOrdemProducaoItens = () => {
    setOnlyViewOPItem(true)
    setButtonsDisabled(false)
    setDrawerOrdemProducaoItensVisible(false)
  };

  const fieldChange = () => {
    setHideButtonFinaliza(true)
  }

  const dateChange = (date: any) => {
    setFinishDate(date)
    setHideButtonFinaliza(true)
  }

  // TABELA COM FOOTER
  const dadosFlat: any = []
  ordensProducao.forEach((it: any) => {
    const item = {
      id: it.id,
      situacao: it.situacao,
      numero: it.numero,
      codigo: it.fichatecnica.produto.codigo,
      descricao: it.fichatecnica.produto.descricao,
      nomeComponente: it.fichatecnica.produto.nomeComponente, 
      // codigo: it.codigo, //XXX
      // descricao: it.descricao, //XXX
      // nomeComponente: it.nomeComponente, //XXX
      quantProduzida: it.quantProduzida,
      dataConclusao: it.dataConclusao,
      updatedBy: it.updatedBy,
      addClass:
        it.situacao === "aberta" || it.situacao === "revertida" ? "bg-orange-50 text-orange-700" : ""
    }
    dadosFlat.push(item)
  })

  const botoes = () => {
    return (
      <Space size={10}>
        <Button onClick={() => printDiv('printableDiv-ordemProducao', 'ordemProducao')} shape="round" size="small" icon={<PrinterOutlined />} title="Imprimir"></Button>
        <Button onClick={() => copyToClipboard('ordemProducao')} shape="round" size="small" icon={<CopyOutlined />} title="Copy to Clipboard"></Button>
        <Button onClick={() => downloadToCsv('ordemProducao', "ordensProducao.csv")} shape="round" size="small" icon={<DownloadOutlined />} title="Download CSV"></Button>
        {/* <Button onClick={() => {}} shape="round" size="small" icon={<FilterOutlined />} title="Filtrar data"></Button> */}
        <Button onClick={novaOrdemProducao} type="primary" shape="round" size="small" icon={<PlusOutlined />} hidden={!isPermittedManterOP} title="Nova"></Button>
      </Space>
    )
  }

  const colunas = colunasOrdemProducao.slice()
  if (!isPermittedVisualizarNomesProdutos) {
    colunas[3].dataIndex = 'nomeComponente'
  }

  const wTab = 1270 // liquido = x-8
  const htb = `calc(100vh - 149px - 110px - 26px)`
  const hsc = `calc(100vh - 180px - 109px - 26px)` // 1 a menos que htb
  const wDrawer = innerW > 640 ? innerW - 144 : 360 // - 144
  const wDrawerx = innerW > 720 && innerW > 360 ? 720 : 360 // - 144
  // console.log('op',ordemProducaoItens)
  return (
    <Layout titulo={menu.label} botoes={botoes()}>
      <div className="mx-auto">
        <DatePicker
          // wrapperClassName="btmonth"
          className="select-none w-20 text-center uppercase border-none rounded-md font-medium py-[2px] cursor-pointer text-white bg-blue-600 z-10"
          selected={mesref}
          locale="pt-BR"
          onChange={(date: any) => setMesRef(date)}
          dateFormat="MMM/yyyy"
          showMonthYearPicker
          showFullMonthYearPicker
          popperPlacement="bottom"
        />
      </div>
      <SpinnerNew loading={loadingOrdemProducao} />

      <Tabela className="mx-5" id='ordemProducao' titulo={menu.label} wTab={wTab} htb={htb} hsc={hsc} dataSource={dadosFlat} columns={colunas} handleRow={handleRowOrdemProducao} rowSelected={rowOrdemProducaoSelected} lastSelected={lastOrdemProducaoSelected} loading={loadingOrdemProducao} />

      {/* DRAWER FORM ORDEM PRODUCAO*/}
      <Drawer
        title={(isInclusaoOrdemProducao ? "Criar uma nova" : "Alterar") + " Ordem de Producao"}
        width={wDrawerx}
        closable={true}
        maskClosable={false}
        onClose={hideDrawerFormOrdemProducao}
        open={drawerFormOrdemProducaoVisible}
        headerStyle={{ borderRadius: "0" }}
        bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
      >
        <div className="inner-drawer-body">
          <Form layout="vertical" form={formOrdemProducao} onFinish={handleSubmitOrdemProducao} onFieldsChange={fieldChange} disabled={ordemProducaoFull?.situacao === "finalizada" || !isPermittedManterOP} >
            {/* <Title level={5}>{formOrdemProducao.getFieldValue('codigo')} - {formOrdemProducao.getFieldValue('descricao')}</Title> */}
            <Form.Item name="id" hidden noStyle></Form.Item>
            <Form.Item name="depositoId" hidden noStyle></Form.Item>
            <Form.Item name="situacao" hidden noStyle></Form.Item>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="produtoId"
                  label="Produto"
                  rules={[{ required: true, message: 'Selecione um produto' }]}
                >
                  <Select placeholder="Produto" showSearch optionFilterProp="children" onChange={handleProdutochange} dropdownClassName="border-solid border border-blue-500">
                    {/* <Option value={null}>É Nivel Raiz</Option> */}
                    {produtos.filter((p: any) => p.situacao === "Ativo").map((produto: any, index: any) => {
                      return <Option value={produto.id} className="text-blue-800 font-semibold">{produto.codigo} - {produto.nomeComponente}</Option>
                      // return <Option value={produto.id}>{produto.nomeComponente} - {produto.codigo}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="fichatecnicaId"
                  label="FichaTecnica"
                  rules={[{ required: true, message: 'Selecione uma ficha técnica' }]}
                >
                  <Select placeholder="FichaTecnica"  dropdownClassName="border-solid border border-blue-500">
                    {/* <Option value={null}>É Nivel Raiz</Option> */}
                    {produto?.fichastecnicas?.filter((ft: any) => ft.situacao === "Ativo").map((ft: any, index: any) => {
                      return <Option value={ft.id} className=" text-blue-800 font-semibold"> {produto.codigo} - {ft.nome}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="quantProduzida"
                  label="Quantida Produzida"
                  rules={[{ required: true, message: 'Quantidade Obrigatória' }]}
                >
                  <Input placeholder="Quantidade" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  // name="dataConclusao" // USANDO finishDate no estado
                  label="Data para Conclusão"
                  rules={[{ required: true, message: 'Data para Conclusão Obrigatória' }]}
                >
                  <DatePicker className="ant-input" selected={finishDate} locale="pt-BR" dateFormat="dd/MM/yyyy" onChange={(date: any) => dateChange(date)} disabled={ordemProducaoFull?.situacao === "finalizada" || !isPermittedManterOP} todayButton="Hoje" />
                  {/* <Input placeholder="Digite a data de conclusão"/> */}
                  {/* <DatePicker className="w-[170px]" placeholder="Selecione uma data" defaultValue={moment(dataConclusao, 'YYYY-MM-DD')} onChange={onChangeDate} format={'DD/MM/YYYY'}/> */}
                  {/* <DatePicker /> */}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="observacao"
                  label="Observação"
                >
                  <TextArea rows={2} placeholder="Observações se necessário for" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <div>Situação: </div>
                <h2 className="mb-2 px-[11px]">{formOrdemProducao.getFieldValue('situacao')}</h2>
              </Col>
            </Row>
            {ordemProducaoHasNegativo
              ? <Row gutter={16}>
                <Col span={24}>
                  <h2 className="mb-2 py-2 text-center text-red-600 bg-red-100">Materia prima insuficiente!</h2>
                </Col>
              </Row>
              : null}
            {isInProcessing
              ? <Row gutter={16}>
                <Col span={24}>
                  <h2 className="mb-2 py-2 text-center text-green-600 bg-green-100">Em Processamento - Aguarde!</h2>
                </Col>
              </Row>
              : null}

          </Form>
          <Space className="inner-drawer-buttons justify-start">
            <Button className="bg-gray-100 rounded-full" onClick={() => { showDrawerOrdemProducaoItens(true) }} hidden={!isPermittedVisualizarItensOP} disabled={isInclusaoOrdemProducao}>Itens O.P.</Button>
            {ordemProducaoFull?.situacao === "aberta" || ordemProducaoFull?.situacao === "revertida"
              ?
              <Popconfirm
                title="Deseja realmente finalizar esta OP?"
                onConfirm={confirmFinalizar}
                onCancel={cancelFinalizar}
                okText="Sim"
                cancelText="Não"
              >
                <Button onClick={() => { }} type="primary" disabled={ordemProducaoHasNegativo || isInclusaoOrdemProducao || hideButtonFinaliza || !isPermittedFinalizarOP} >Finalizar O.P.</Button>
              </Popconfirm>
              :
              null
            }
            {ordemProducaoFull?.situacao === "finalizada"
              ?
              <Popconfirm
                title="Deseja realmente reverter esta OP?"
                onConfirm={confirmReverter}
                onCancel={cancelReverter}
                okText="Sim"
                cancelText="Não"
                disabled={!isPermittedReverterOP}
              >
                <Button onClick={() => { }} type="primary" danger disabled={!isPermittedReverterOP || hideButtonReverter} >Reverter O.P.</Button>
              </Popconfirm>
              :
              null
            }
          </Space>
        </div>

        <Space className="drawer-footer flex w-full justify-end">
          <Space>
            <Button onClick={() => { hideDrawerFormOrdemProducao() }} type="primary" icon={<FastBackwardOutlined />} />
            <Button onClick={hideDrawerFormOrdemProducao}>Cancel</Button>
            {/* <Button onClick={resetFormOrdemProducao}>Reset</Button> */}
            <Popconfirm disabled={ordemProducaoBlocked || isInclusaoOrdemProducao}
              title="Deseja realmente excluir esta OP?"
              onConfirm={confirmDelete}
              onCancel={cancelDelete}
              okText="Sim"
              cancelText="Não"
            >
              {/* <Button onClick={() => {}} type="primary" danger disabled={isInclusao || ordemProducaoBlocked || buttonsDisabled} >Delete</Button> */}
              <Button onClick={() => { }} type="primary" disabled={ordemProducaoBlocked || isInclusaoOrdemProducao || !isPermittedManterOP} danger >Delete</Button>
            </Popconfirm>

            <Button onClick={formOrdemProducao.submit} disabled={ordemProducaoBlocked || buttonsDisabled || !isPermittedManterOP} type="primary">Submit</Button>
          </Space>
        </Space>
      </Drawer>

      {/* DRAWER TABELA ORDEM PRODUCAO ITENS */}
      <Drawer
        title="Itens da Ordem de Produção"
        width={wDrawer}
        closable={true}
        onClose={hideDrawerOrdemProducaoItens}
        open={drawerOrdemProducaoItensVisible}
        headerStyle={{ borderRadius: "0" }}
        bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
      >
        <div className="inner-drawer-body justify-start">
          <OrdemProducaoItens ordemProducao={ordemProducaoFull}
          //TODO - POR ENQUANTO É SÓ LISTAR
          // novaOrdemProducaoItem={novaOrdemProducaoItem}
          // rowFichaTecnicaSelected={rowFichaTecnicaSelected}
          // handleRowOrdemProducaoItem={handleRowOrdemProducaoItem}
          // rowOrdemProducaoItemSelected={rowOrdemProducaoItemSelected}
          // lastOrdemProducaoItemSelected={lastOrdemProducaoItemSelected}
          // loadingOrdemProducaoItem={loadingOrdemProducaoItem}
          />
        </div>
        <Space className="drawer-footer flex w-full justify-between">
          <Space className="flex w-full justify-start">
            <Button onClick={() => { hideDrawerOrdemProducaoItens(); hideDrawerFormOrdemProducao() }} type="primary" icon={<FastBackwardOutlined />} />
            <Button onClick={hideDrawerOrdemProducaoItens}>Cancel</Button>
          </Space>
          {ordemProducaoFull?.situacao === "aberta" || ordemProducaoFull?.situacao === "revertida"
            ?
            <Popconfirm
              title="Deseja realmente finalizar esta OP?"
              onConfirm={confirmFinalizar}
              onCancel={cancelFinalizar}
              okText="Sim"
              cancelText="Não"
            >
              <Button onClick={() => { }} type="primary" disabled={ordemProducaoHasNegativo || isInclusaoOrdemProducao || hideButtonFinaliza || !isPermittedFinalizarOP} >Finalizar O.P.</Button>
            </Popconfirm>
            :
            null
          }

          {ordemProducaoFull?.situacao === "finalizada"  //|| onlyViewOPItem --obsoleto
            ?
            <Popconfirm
              title="Deseja realmente reverter esta OP?"
              onConfirm={confirmReverter}
              onCancel={cancelReverter}
              okText="Sim"
              cancelText="Não"
            >
              <Button onClick={() => { }} type="primary" danger disabled={!isPermittedReverterOP} >Reverter O.P.</Button>
            </Popconfirm>
            :
            null
          }
        </Space>
      </Drawer>

    </Layout>
  )
}


