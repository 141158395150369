import { useQuery } from "@tanstack/react-query";
import { apierp } from "../../../services/api";
import { categoriasProdutos } from "../../../data/dados";

const fetchProdutos = async () => {
    // console.log('fetchProdutos')
    const response = await apierp.get('produtosbt')
    // console.log('fetchProdutos2')
    return response?.data
}
const fetchEmpresas = async () => {
    // console.log('fetchEmpresas')
    const response = await apierp.get('empresas')
    // console.log('fetchEmpresas2')
    return response?.data
}
const fetchProduto = async (id: number) => {
    // console.log('fetchProduto', id)
    const response = await apierp.get(`produtos/${id}`)
    // console.log('fetchProduto2', id)
    return response?.data
}

const fetchBlingProdutos = async (id: number) => {
    // console.log('fetchBlingProdutos', id)
    const response = await apierp.get(`blingprodutos/${id}`)
    // console.log('fetchBlingProdutos2', id)
    return response?.data
}

const fetchProdutosBling = async (codigo: any) => {
    // console.log('fetchProdutosBling', codigo)
    const produtosBling: any = []

    for (let empresaId = 1; empresaId < 3; empresaId++) {
        // console.log('fetchProdutosBling', empresaId, codigo)
        const dataBling: any = {
            "metodo": "obterProdutoPorCodigo",
            "param": {
                "empresaId": +empresaId,
                "codigo": codigo,
                //   "criterio": 5
            }
        }
        const response1 = await apierp.post(`bling/v3/produtos`, dataBling) // na verdade é get
        const data1 = await response1.data as any;
        const produto = await data1.data[0]
        // console.log('fetchProdutosBling2', empresaId, produto?.codigo)
        await produtosBling.push({
            empresaId: empresaId,
            empresaNome: empresaId === 1 ? 'Colorbrilho' : 'Colorbrilho2',
            codigo: produto?.codigo,
            nome: produto?.nome,
            unidade: produto?.unidade,
            id: produto?.id,
            preco: produto?.preco,
            situacao: produto?.situacao,
            pesoBruto: produto?.pesoBruto,
            pesoLiquido: produto?.pesoLiquido,
            largura: produto?.dimensoes.largura,
            altura: produto?.dimensoes.altura,
            profundidade: produto?.dimensoes.profundidade,
            categoriaId: produto?.categoria.id,
            categoria: categoriasProdutos.find(c => c.empresaId === empresaId && c.id === produto?.categoria.id)?.descricao,
            tipo: produto?.tipo,
            producao: produto?.tributacao.spedTipoItem === '04' ? 'P' : 'T',
            ncm: produto?.tributacao.ncm,
            origem: produto?.tributacao.origem,
            spedTipoItem: produto?.tributacao.spedTipoItem,
        })
    }

    return produtosBling
}

export function useDadosMapa(produtoId?: any, codigo?: any ) {
    // console.log('executou useDadosMapa', produtoId, codigo)

    const queryProdutos = useQuery({
        queryKey: ['produtos'],
        queryFn: fetchProdutos,
        retry: false,
        refetchOnWindowFocus: true
    });

    const queryEmpresas = useQuery({
        queryKey: ['empresas'],
        queryFn: fetchEmpresas,
        retry: false
        // enabled: !!params,
    });

    const queryProduto = useQuery({
        queryKey: ['produto', produtoId],
        queryFn: () => fetchProduto(produtoId),
        retry: false,
        enabled: !!produtoId,
    });

    const queryBlingProdutos = useQuery({
        queryKey: ['blingprodutos', produtoId],
        queryFn: () => fetchBlingProdutos(produtoId),
        retry: false,
        enabled: !!produtoId,
    });

    const queryProdutosBling = useQuery({
        queryKey: ['produtosbling', codigo],
        queryFn: () => fetchProdutosBling(codigo),
        retry: false,
        enabled: !!codigo,
    });

    return { qrProdutos: queryProdutos, qrEmpresas: queryEmpresas, qrProduto: queryProduto, qrBlingProdutos: queryBlingProdutos, qrProdutosBling: queryProdutosBling };
}