import { useEffect, useState } from 'react'
// import router from 'next/router'
// import Image from 'next/image'
// import Link from 'next/link'
import { AiOutlineAppstore, AiOutlineMenu, AiOutlineBarChart, AiOutlineBell, AiOutlineUser, AiOutlineBorderlessTable, AiOutlineWechat } from 'react-icons/ai'
import { MdOutlineLogout } from 'react-icons/md'
import { FiUsers } from 'react-icons/fi'
import { SiAzuredevops } from 'react-icons/si'
import { TbError404 } from 'react-icons/tb'
import { useNavigate } from "react-router-dom";

// import styles from './Layout.module.css'
import { useGeral } from '../../contexts/geral'
import useWindowSize from "../../hooks/useWindowSize";
import { useAuth } from '../../contexts/auth'
import { customMessage } from './CustomMessage'
import { Avatar, Badge, Divider, Tooltip } from 'antd'
import { apierp } from '../../services/api'
import ModalMensagens from './Partial/ModalMensagens'
// import { checkToken } from '../../utils/functions'

interface LayoutProps {
  titulo?: string
  subtitulo?: string
  children?: any
  botoes?: any
  classContent?: any
}
export default function Layout(props: LayoutProps) {
  const { user, Logout } = useAuth()
  const usuario: any = user
  // console.log(usuario)
  // const [itemsMenu] = useState(usuario?.userMenu)
  // const [itemsMenu, setItemsMenu] = useState([] as any)
  const [colors, setColors] = useState([])

  const { menuGrupoSelected, setMenuGrupoSelected, userTenant, userMenu, rotaBase } = useGeral();

  const [showModal, setShowModal] = useState(false)

  // console.log(colors)

  const home = rotaBase

  let [innerW, innerH] = useWindowSize()
  // let  [innerW, innerH] = [window.innerWidth, window.innerHeight]

  const { showMenu, setShowMenu } = useGeral()
  const [conversas, setConversas] = useState([])
  const [naoLidas, setNaoLidas] = useState([])
  const [notifys, setNotifys] = useState([] as any)

  let navigate = useNavigate();

  useEffect(() => {
    setColors(userTenant.tenant.colors.split(','))
    setShowMenu(false)
    // setMenuGrupoSelected(-1)
    if (usuario?.mustChangePassword) {
      // notifys.push('Necessário Alterar Password')
      setNotifys(['Necessário Alterar Password'])
    }
    if (!usuario?.shortName) {
      navigate(`/`);
    }
    // loadNaoLidas()

  }, [])

  // async function loadConversas() {
  //   const response = await apierp.post('chats/listarconversas')
  //   const data = response.data as any;
  //   const { conversas } = data
  //   // console.log(data)
  //   setConversas(conversas);
  // }
  // async function loadNaoLidas() {
  //   const response = await apierp.get('chats/getnaolidas')
  //   const data = response.data as any;
  //   const { mensagensNaoLidas } = data
  //   setNaoLidas(mensagensNaoLidas);
  // }

  const hideModalMensagens = () => {
    setShowModal(false)
  };

  function logout() {
    setMenuGrupoSelected(-1)
    Logout()
    navigate(`/`);
  }

  // console.log('showMenu', showMenu, innerW > 640)
  let classTemplateAreas = (innerW > 640 || innerW === 0 || showMenu) ? "grid-layout1" : "grid-layout2"
  let classMenuLateral = (innerW > 640 || innerW === 0 || showMenu) ? "class-menu-lateral1" : "class-menu-lateral2"
  let classHamb = (innerW > 640 || innerW === 0 || showMenu) ? "hidden" : ""

  const toggleMenu = () => {
    // classTemplateAreas ="grid-layout1"
    // classMenuLateral = "class-menu-lateral1"
    // classHamb = "hidden"
    setShowMenu(!showMenu)
  }

  // const clickMenu = (rota: string) => {
  //   if(innerW < 640 && innerW > 0) setShowMenu(false)
  //   navigate(rota);
  // }

  const clickMenuUser = (i: any, id: any, rota: string) => {
    // console.log('clickMenuUser', i, typeof i)
    setMenuGrupoSelected(i)
    if (innerW < 640 && innerW > 0) setShowMenu(false)
    navigate(rota);
  }

  const clickHome = () => {
    // console.log('clickMenuUser', i, typeof i)
    setMenuGrupoSelected(-1)
    if (innerW < 640 && innerW > 0) setShowMenu(false)
    navigate(home);
  }

  const clickChat = () => {
    // loadConversas()
    // setShowModal(true)
  }

  function handleProfileClick() {
    navigate('/profile')
    // setMenuSelected(0);
    // hideMenu()
    // setTimeout(() => { navigate('/profile') }, 180);
  }

  const getSelected = (i: any) => {
    // console.log('getSelected', i, menuGrupoSelected, typeof menuGrupoSelected)
    const ret = menuGrupoSelected === i ? "bg-violet-600 hover:bg-violet-600" : ""
    // const ret=""
    return ret
  }

  const GetIcon: any = (props: { icon: string }) => {
    // console.log(props.icon)
    let ret = null
    if (props.icon === "AiOutlineBarChart") ret = <AiOutlineBarChart size={30} />
    if (props.icon === "AiOutlineAppstore") ret = <AiOutlineAppstore size={30} />
    if (props.icon === "FiUsers") ret = <FiUsers size={30} />
    if (props.icon === "SiAzuredevops") ret = <SiAzuredevops size={30} />
    if (props.icon === "AiOutlineBorderlessTable") ret = <AiOutlineBorderlessTable size={30} />
    return ret || <AiOutlineAppstore size={30} />
  }

  // if (innerW === 0) { return }
  // console.log("render menu", innerW, showMenu, classTemplateAreas, classMenuLateral, menuGrupoSelected)
  // console.log(colors[7])
  return (
    <div id="mainmain" className={classTemplateAreas}>
      <div className={"pageLogo " + " z-30"} style={{ backgroundColor: colors[4] }}>
        <div onClick={() => clickHome()} className={"h-[60px] pt-1 bg-black/20 flex justify-center mb-0"}>
          {/* <a href={home}> */}
          <img src={colors[7]} alt="Logo" height={52} />
          {/* </a> */}
        </div>
      </div>
      <div className={"pageHeader" + " z-30"} style={{ backgroundColor: colors[1] }}>
        <AiOutlineMenu onClick={() => toggleMenu()} className={classHamb + ' pointer text-white mr-4 mb-[2px]'} size={24} />
        <div className='flex justify-between items-center w-full'>
          <div>
            <div className={'leading-6 text-xl mb-1 font-medium'}>
              {/* {usuario?.tenantName.match(/[A-Z]/g)} */}
              {innerW < 640 && innerW > 0 ? userTenant?.tenant.name.match(/[A-Z]/g) : userTenant?.tenant.name}
            </div>
          </div>
          <div className="flex items-center">
            <Tooltip className={innerW < 420 && innerW > 0 ? '!hidden' : ''} placement="bottomRight" title="Notificações">
              <Badge count={notifys.length} overflowCount={9} size="small" ><AiOutlineBell onClick={() => { }} className='pointer text-[#293C61] bg-white p-1 rounded-full ml-3' size={34} /></Badge>
            </Tooltip>
            <Tooltip className={''} placement="bottomRight" title="Mensagens">
              <Badge count={naoLidas.length} overflowCount={9} size="small" ><AiOutlineWechat onClick={clickChat} className='pointer text-[#293C61] bg-white p-1 rounded-full ml-3' size={34} /></Badge>
            </Tooltip>
            <div className="flex ml-5 items-center">
              <div className="text-right ">
                {innerW < 640 && innerW > 0 ? '' : <div className="leading-4 font-medium flex">{usuario?.shortName}</div>}
              </div>
              <Tooltip placement="bottomRight" title="Profile">
                <AiOutlineUser onClick={() => { handleProfileClick() }} className='pointer text-[#293C61] bg-white p-1 rounded-full ml-3' size={34} />
              </Tooltip>
            </div>
            <Tooltip placement="bottomRight" title="Sair">
              <MdOutlineLogout onClick={() => logout()} className='pointer text-[#293C61] bg-white p-1 rounded-full ml-3' size={34} />
            </Tooltip>
          </div>
        </div>
      </div>
      <div className={"pageTitulo bg-blue-3000"}>
        {/* <div className="mt-1 overflow-hidden">Principal / Nivel-1 / Nivel-2 / Nivel-3</div> */}
        <h1 className='mb-1 text-2xl font-bold'>{props.titulo}</h1>
        {props.subtitulo && innerW > 640 && <div className="h-6 mb-1 text-base font-medium">{props.subtitulo}</div> }
      </div>
      <div className={`pageContent ${props.classContent}`}>
        <div className="overflow-x-hidden">
          <div className="content flex flex-col">
            {/* Content */}
            {props.children}
          </div>
        </div>
      </div>
      <div className={"pageButtons"} style={{ backgroundColor: colors[3] }}>
        {/* Botões do contexto da página */}
        {props.botoes}
      </div>
      <div className={"pageFooter"} style={{ backgroundColor: colors[4] }} >
        <div className="h-5 mb-1 font-medium">Copyright &copy; 2022</div>
        <div className="h-5 mb-1 ml-2 text-white font-medium ">
          Apphost
        </div>
      </div>

      <div id="menulateral" className={classMenuLateral} style={{ backgroundColor: colors[1] }}>
        <div className="z-30 p-4 overflow-y-auto overflow-x-hidden">
          <div className="flex flex-col">
            {userMenu.filter((x: any) => x.level === 0).map((it0: any, i: any) => {
              const rota = it0.url ?? `${rotaBase}/${it0.id}`
              // const rota = it0.url ?? `${rotaBase}`
              return (
                <div key={it0.id} id={it0.id}
                  onClick={() => clickMenuUser(i, it0.id, rota)}
                  className={`flex flex-col justify-center w-28 shrink-0 h-16 my-1 cursor-pointer rounded-md ${getSelected(i)}`}>
                  {/* <a href={rota}> */}
                  <div className="flex flex-col items-center text-white">
                    <GetIcon icon={it0.icon} />
                    <span>{it0.label}</span>
                  </div>
                  {/* </a> */}
                </div>
              )
            })
            }
            {/* <Divider className='bg-white' />
            <div key="91" onClick={() => clickMenu(4)} className={`flex flex-col w-28 shrink-0 h-16 my-1 hover:bg-black/25    rounded-md ${getSelected(4)}`}><Link href="/dashboard"><a className="flex flex-col w-full h-full justify-center text-white"><div className="flex flex-col items-center"><AiOutlineBarChart size={30} /><span>Dashboard </span></div></a></Link></div>
            <div key="92" onClick={() => clickMenu(5)} className={`flex flex-col w-28 shrink-0 h-16 my-1 hover:bg-black/25    rounded-md ${getSelected(5)}`}><Link href="/cadastro "><a className="flex flex-col w-full h-full justify-center text-white"><div className="flex flex-col items-center"><AiOutlineAppstore size={30} /><span>Cadastro  </span></div></a></Link></div>
            <div key="93" onClick={() => clickMenu(6)} className={`flex flex-col w-28 shrink-0 h-16 my-1 hover:bg-black/25 rounded-md ${getSelected(6)}`}><Link href="/notfound"><a className="flex flex-col w-full h-full justify-center text-white"><div className="flex flex-col items-center"><TbError404 size={30} /><span>Not Found</span></div></a></Link></div> */}
          </div>
        </div>
      </div>
      {/* MASK protege area fora da sidebar logo e header contra click */}
      <div
        className="z-20"
        style={{
          backgroundColor: "rgba(0, 39, 102,0.3)", position: "absolute", left: 0, top: 0,
          height: "calc(100vh - 32px)", width: "100%",
          display: showMenu && innerW < 640 ? "block" : "none",
        }}
        onClick={() => { setShowMenu(false) }}
      />

      <ModalMensagens showModal={showModal} hideModalMensagens={hideModalMensagens} conversas={conversas} userId={usuario.id} />

    </div>
  )
}