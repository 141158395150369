import { Table } from "antd";

export default function Tabela(props: any) {
  const {id, rowKey, titulo, subtitulo, wTab, htb, hsc, dataSource, columns, handleRow, rowSelected, lastSelected, loading, className} = props
  return (
    <div className={`bg-blue-30 overflow-x-auto ${className} h-full` }>
        <div className="flex flex-row justify-center items-center mx-auto " style={{ maxWidth: `${wTab}px`, minWidth: `${wTab}px` }}>
          <div id={`printableDiv-${id}`} className="pt-6  pb-[1px] fadein" style={{ display: loading ? "none" : "" }}>
            <h1 className='hidden mb-1 text-2xl font-bold'>{titulo}</h1>
            <h1 className={`subtitulo text-base font-bold ${subtitulo ? 'mb-1' : 'mb-0'}`}>{subtitulo}</h1>
            {/* TABLE */}
            <div className="flex flex-col justify-between bg-[#f8f9fb]" style={{ maxHeight: htb }}> {/** ou heigth */}
              <div className="envelopetable ">
                <Table id={id} dataSource={dataSource} columns={columns} size="small" rowKey={rowKey ?? "id"}
                  rowClassName={(record, index) => { 
                    let addClass=" font-medium"
                    addClass+= index === rowSelected ? " rowSelected" : ""
                    addClass+= record.footer ? " font-semibold bg-[#EEEEEE]" : ""
                    addClass+= record.addClass ? ` ${record.addClass}` : ""
                    return addClass
                  }}
                  pagination={false}
                  scroll={{ y: hsc }}
                  onRow={(record: any, rowIndex: any) => {
                    return {
                      onClick: event => { handleRow(record, rowIndex) }, // click row
                      className: lastSelected === rowIndex ? "lastSelected" : ""
                    };
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}
