import { apierp } from "../../../services/api";

export async function cadastrarProdutoBLING(produto: any, empresaId: any) {
    const dataBling: any = {
        "metodo": "cadastrarProduto",
        "param": {
            "empresaId": empresaId,
            "dados": produto
        },
    }
    // console.log('inclusao', produto.numeroDocumento)
    const response1 = await apierp.post(`bling/v3/produtos`, dataBling) 
    const data1 = await response1.data as any;
    // console.log(data1.data.id)
    return data1.data.id
}

export async function alterarProdutoBLING(produto: any, empresaId: any, produtoId: any) {
    const dataBling: any = {
        "metodo": "alterarProduto",
        "param": {
            "empresaId": empresaId,
            "id": produtoId, // id do produto no bling 
            "dados": produto
        },
    }
    // console.log('atualizacao', produtoId)
    const response1 = await apierp.post(`bling/v3/produtos`, dataBling) // na verdade é put
    const data1 = await response1.data as any;
    return data1.data.id
}