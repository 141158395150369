import Tabela from "../../../components/template/Tabela"
import { columnsProdutoBling } from "./columnsProdutoBling"

export function Produtosbling(props: any) {

    const {produto, handleRowProdutoBling, rowProdutoBlingSelected, lastProdutoBlingSelected, loadingProdutoBling} = props
    if (!produto) { return <div>Sem produto ???</div> }
    
    const wTab=878
    const htb = `calc(100vh - 149px - 110px)`
    const hsc = `calc(100vh - 180px - 109px)` 

    return (
        <Tabela className="mx-0" id='produto' titulo='Produtos Bling' subtitulo={produto.nome} wTab={wTab} htb={htb} hsc={hsc} dataSource={produto.blingprodutos} columns={columnsProdutoBling} handleRow={handleRowProdutoBling} rowSelected={rowProdutoBlingSelected} lastSelected={lastProdutoBlingSelected} loading={loadingProdutoBling} />
    )
    
  }