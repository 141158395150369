import Layout from "../../../../components/template/Layout";
// import CardPedidosPendentes from "./CardPedidosPendentes";
import { Button, Col, Divider, Drawer, Form, Input, InputNumber, InputRef, Row, Space, Typography, message } from "antd";
import { DownloadOutlined, PrinterOutlined, CopyOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import useWindowSize from "../../../../hooks/useWindowSize";
import { useAuth } from "../../../../contexts/auth";
import { dateFormat, dateMySqlToPtBr } from "../../../../utils/dateFunctions";
import SpinnerNew from "../../../../components/template/SpinnerNew"
import { useNavigate } from "react-router-dom";
import { copyToClipboard, downloadToCsv, printDiv } from "../../../../utils/functions";
import Tabela from "../../../../components/template/Tabela";
import { FilterConfirmProps } from "antd/lib/table/interface";
import useEstoqueDivididoByDateSped from "./useEstoqueDivididoByDateSped";
import { columnsEstoqueDivididoSped } from "./columnsEstoqueDivididoSped";
import {  tipositem } from "../../../../data/dados"

const { Title, Text, Paragraph } = Typography;

export default function EstoqueDivididoByDate() {
    const [mesref, setMesRef] = useState(null as any)

    const { estoqueDividido, empresas, processando, reload } = useEstoqueDivididoByDateSped(mesref)

    // const now = new Date('2024-01-01')
    const now = new Date()
    useEffect(() => {
        // console.log('useEffect unico', now)
        setMesRef(now)
    }, [])

    // console.log(pedidosPendentesSaida)
    // console.log(caixas)
    const { Logout, user } = useAuth();
    const usuario: any = user
    const [innerW, innerH] = useWindowSize();

    const [deliveryDate, setDeliveryDate] = useState(new Date(dateFormat(new Date(), 'yyyy-MM-dd 12:00:00')));

    const [buttonsDisabled, setButtonsDisabled] = useState(false)

    const [toggleState, setToggleState] = useState(false)

    let navigate = useNavigate();

    async function handleRow(id: any, index: any) {
    }

    
    // search filter produto
    interface DataType {
        nome: string;
        nomeComponente: string;
      }
      const [searchText, setSearchText] = useState('');
      const [searchedColumn, setSearchedColumn] = useState('');
      const [selectedKeys, setSelectedKeys] = useState('');
      const searchInput = useRef<InputRef>(null);
      type DataIndex = keyof DataType;
      const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
      ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
      };
      const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
      };
      //end

      const colunas = columnsEstoqueDivididoSped({
        setSearchText, setSearchedColumn, setSelectedKeys, selectedKeys, searchInput, searchedColumn, searchText
      })

    const handleChangeDate = (newDate: any) => {
        // console.log(newDate, typeof newDate)
        setToggleState(!toggleState)
    }
    const dateChange = (date: any) => {
        setDeliveryDate(date)
        // setHideButtonFinaliza(true)
    }

    function enableButtons(wait: number) {
        setTimeout(function () {
            setButtonsDisabled(false)
            // setMsgError('')
        }, wait);
    }
    function disableButtons() {
        setButtonsDisabled(true)
    }

    //     vlrmovet: totalVlrMovE23   = it.vlrmovet
    //     vlrmovst: totalVlrMovS23   = it.vlrmovst
    //     vlrcompt: totalVlrComp23   = it.vlrcompt
    //     vlrcomp1: totalVlrComp23PA = it.vlrcomp1
    //     vlrcomp2: totalVlrComp23SQ = it.vlrcomp2
    //     vlreprdt: totalVlrPrdz23   = it.vlreprdt

    // TABELA COM FOOTER
    const dadosFlat: any = []
    let totalSaldoBalanco = 0.0
    let totalInventario = 0.0
    let totalInventarioPA = 0.0
    let totalInventarioSQ = 0.0
    let totalVlrMovE23 = 0.0
    let totalVlrMovS23 = 0.0
    let totalVlrComp23 = 0.0
    let totalVlrComp23PA = 0.0
    let totalVlrComp23SQ = 0.0
    let totalVlrPrdz23 = 0.0

    estoqueDividido.filter(x => x.sldestv2ft > 0).forEach((it: any) => {
        totalSaldoBalanco += it.vlrsldbt
        totalInventario += it.vlrestv2ft
        totalInventarioPA += it.vlrestv2f1
        totalInventarioSQ += it.vlrestv2f2
        totalVlrMovE23   += it.vlrmovet
        totalVlrMovS23   += it.vlrmovst
        totalVlrComp23   += it.vlrcompt
        totalVlrComp23PA += it.vlrcomp1
        totalVlrComp23SQ += it.vlrcomp2
        totalVlrPrdz23   += it.vlreprdt 
        const xxx = tipositem.find(ti => ti.value ===it.spedTipoItem)
        // console.log(it.spedTipoItem, xxx!.label)
        // it.spedTipoItem = xxx?.label
        const item = {
            ...it, spedTipoItem: `${it.spedTipoItem}-${xxx?.label}`,
            footer: false,
            //   addClass: it.situacao === "Ativo" ? "" : "text-gray-400" 
        }
        dadosFlat.push(item)
    })
      const total = {
        id: '#',
        spedTipoItem: '',
        codigo: 'Total',
        nome: '',
        unidade: '',
        ncm: '',
        // sldestft: NaN,
        vlrmovet: totalVlrMovE23,
        vlrmovst: totalVlrMovS23,
        vlrcompt: totalVlrComp23,
        vlrcomp1: totalVlrComp23PA,
        vlrcomp2: totalVlrComp23SQ,
        vlreprdt: totalVlrPrdz23,

        vlrsldbt: totalSaldoBalanco,
        vlrestv2ft: totalInventario,
        // sldestf1: NaN,
        // pctcons1: totalInventarioPA/totalInventario,
        vlrestv2f1: totalInventarioPA,
        // sldestf2: NaN,
        // pctcons2: totalInventarioSQ/totalInventario,
        vlrestv2f2: totalInventarioSQ,
        footer: true,
      }
      dadosFlat.push(total)

    const botoes = () => {
        return (
            <Space size={10}>
                <Button onClick={() => printDiv('printableDiv-estoque', 'estoque')} shape="round" size="small" icon={<PrinterOutlined />} title="Imprimir"></Button>
                <Button onClick={() => copyToClipboard('estoque')} shape="round" size="small" icon={<CopyOutlined />} title="Copy to Clipboard"></Button>
                {/* <Button onClick={() => downloadToCsv('estoque', "Estoques.csv")} shape="round" size="small" icon={<DownloadOutlined />} title="Download CSV"></Button> */}
            </Space>
        )
    }

    const wTab = 1800 // 3365 // + 8 // liquido = x-8
    const htb = `calc(100vh - 149px - 110px - 26px)`
    const hsc = `calc(100vh - 180px - 109px - 57px)` // 1 a menos que       

    let corPicker = 'bg-blue-600'
    if (mesref !== null) {
        corPicker = dateFormat(mesref, 'yyyy-MM-dd') === dateFormat(now, 'yyyy-MM-dd') ? 'bg-blue-600' : 'bg-red-600'
    }
    // console.log('empresas', empresas)

    return (
        <Layout titulo='Mapa do Estoque' botoes={botoes()}>
            <div className="mx-auto">
                <DatePicker
                    // wrapperClassName="btmonth"
                    className={`w-[88px] mb-[3px] text-center uppercase border-none rounded-md font-medium py-1 cursor-pointer text-white ${corPicker}`}
                    selected={mesref}
                    locale="pt-BR"
                    onChange={(date: any) => setMesRef(date)}
                    dateFormat="dd/MM/yyyy"
                    // maxDate={new Date('2024-01-01')}
                    todayButton="Hoje"
                    popperPlacement="bottom"
                />
            </div>
            <SpinnerNew loading={processando} htb={htb} />
            {processando ? (
                null
            ) : estoqueDividido.length > 0 ? (
                // <div>TABELA ESTOQUE DIVIDIDO</div>
                <>
                    <Tabela className="mx-5" id='estoque' titulo={`Estoque dividido em ${dateFormat(mesref, "dd/MM/yyyy")}`} wTab={wTab} htb={htb} hsc={hsc} dataSource={dadosFlat} columns={colunas} handleRow={() => {}} rowSelected={() => {}} lastSelected={-1} loading={processando} />
                </>

            ) : (
                <div>Dados não encontrados</div>
            )}
        </Layout>

    );
}