import { Button } from 'antd';
import { useEffect, useState } from 'react';
import CardMenu from '../../../components/template/CardMenu';
import { useAuth } from '../../../contexts/auth';
import { useGeral } from '../../../contexts/geral';
import { useNavigate } from "react-router-dom";
import { removeStoreEncrypted } from '../../../utils/session';
import { apierp } from '../../../services/api';

export default function Home() {
  const { user, Logout } = useAuth();
  const usuario: any = user
  const { setMenuGrupoSelected,setUserTenant, rotaBase } = useGeral();
  let navigate = useNavigate();

  const [qtTenants, setQtTenants] = useState(0)
  useEffect(() => {
    // setShowMenu(false)
    setMenuGrupoSelected(-1)
    removeStoreEncrypted('@zappgeral:data');
    apierp.defaults.headers.Authorization = '';
    delete apierp.defaults.headers.tenantid;
    delete apierp.defaults.headers.userid;
    if (!usuario?.shortName) {
      navigate(`/login`);
    }
    // console.log('home', usuario)
    const userTenants = usuario?.usersTenants.filter((usertenant: any) => usertenant.isActiveInTenant === true)
    // console.log('userTenants', userTenants)
    if (userTenants.length === 1) {
        tenantSelected(userTenants[0])
    } else {
      setQtTenants(userTenants.length)
    }
  }, [])

  async function getPermissions(id: any, tenantId: any) {
    apierp.defaults.headers.tenantid = tenantId
    const response = await apierp.get(`userpermissions/${id}`)
    const data = response.data as any;
    // console.log('userpermissions/id', id, data)
    return data
  }

  //  async function loadUserMenu() {
  //     const response = await apierp.post('menusflat', userTenant)
  //     setItemsMenu(response.data)
  //   }
  //   loadMenus()

  async function tenantSelected(userTenant: any) {
    // console.log('quendo setado', userTenant)
    // setMenuSelected(-15)
    const response = await apierp.post('menusflat', userTenant)
    const permissions: any = await getPermissions(userTenant.userId, userTenant.tenantId)

    // console.log('permissions', permissions)
    setUserTenant(userTenant, response.data, permissions)
    // const rota = rotaBase 
    const rota = "/zapp/" + userTenant.tenant.shortName.replaceAll(' ', '_') // + "_" + userTenant.tenant.id
    // console.log('rota', rota)
    navigate(rota);
  };

  return qtTenants > 1 ? (
    <div className='flex flex-col h-full items-center justify-center w-250 min-w-[360px] overflow-x-hidden fadein'>
      <h1 className='text-2xl mt-10'>Olá {usuario?.shortName}</h1>
      <h1 className='text-2xl text-red-700'>Selecione um App</h1>
      <div className='flex flex-wrap items-center justify-center w-250 min-w-[360px] overflow-x-hidden fadein'>
        {
          usuario?.usersTenants.map((usertenant: any) => {
            // console.log(usertenant)
            const color = usertenant.tenant.colors.split(',')
            const bgcard = color[0]
            const txtcard = 'text-white'
            if (usertenant.isActiveInTenant) {
              return (
                <div key={usertenant.id} onClick={() => tenantSelected(usertenant)}><a><CardMenu bg={bgcard} txt={txtcard} title={usertenant.tenant.name} description="" imagem="images/gotabranca.png" /></a></div>
              )
            } else {
              return null
            }
          })
        }
      </div>
      <Button className='mt-4 w-20' onClick={() => { Logout() }} type="primary">Logout</Button>
    </div>
  ) : (null)
}
