
import * as React from "react"
import { useEffect, useState } from "react"
import { Divider, Typography } from "antd";
import DatePicker from "react-datepicker";

import Layout from "../../../components/template/Layout"
import { useAuth } from "../../../contexts/auth";
import useWindowSize from "../../../hooks/useWindowSize";
import { useGeral } from "../../../contexts/geral";
import { customMessage } from "../../../components/template/CustomMessage";
import { apierp } from "../../../services/api";
import SpinnerNew from "../../../components/template/SpinnerNew";
import { formatDecimal, groupBy } from "../../../utils/functions";

const { Title, Text, Paragraph } = Typography;

export default function RelVendas() {
  const { Logout, user } = useAuth();
  const usuario: any = user
  const [innerW, innerH] = useWindowSize();

  const { userMenu, menuSelected, userTenant, } = useGeral();
  const menu = userMenu.find((x: any) => x.id == menuSelected)

  const [toggleState, setToggleState] = useState(false)
  const [loadingRelVendas, setLoadingRelVendas] = useState(false)
  const [relvendas, setRelVendas] = useState([])
  const [relvendasgroup, setRelVendasGroup] = useState([])
  const [empresas, setEmpresas] = useState([])
  const [anoref, setAnoRef] = useState(new Date())
  const [startDate, setStartDate] = useState(new Date("2023-01-01"));
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    setLoadingRelVendas(true)
    setTimeout(async function () {
      try {
        await loadEmpresas()
        await loadRelVendas()
      } catch (err) {
        if (customMessage(err) === 403) Logout()
      }
      setLoadingRelVendas(false)
    }, 500);
  }, [anoref])

  async function loadRelVendas() {
    
    const ano = anoref.getFullYear()
    const response = await apierp.get(`relatorios/vendas/${ano}`)
    const data = response.data as any;
    const datag = groupBy(data, [ 'ano', 'mes' ], ['desconto','valorfrete','totalprodutos','totalvenda', 'totalnota'])
    setRelVendas(data);
    setRelVendasGroup(datag);
  }
  async function loadEmpresas() {
    const dados = {
    }
    const response = await apierp.get('empresas', dados)
    const data = response.data as any;
    // console.log(data)
    setEmpresas(data);
  }

  const wTab = 684+120

  let gTotProdutos = 0
  let gTotDescontos = 0
  let gTotFretes = 0
  let gTotVendas = 0
  let gTotSemNotas = 0
  let gTotNotas = 0

  return (
    <Layout titulo={"Relatório de Vendas"} botoes={null}>
      <div className="mx-auto">
        <DatePicker
          // wrapperClassName="btmonth"
          className="select-none w-20 text-center uppercase border-none rounded-md font-medium py-[2px] cursor-pointer text-white bg-blue-600 z-10"
          selected={anoref}
          selectsStart
          locale="pt-BR"
          onChange={(date: any) => setAnoRef(date)}
          startDate={startDate}
          endDate={endDate}
          // dateFormat="MMM/yyyy"
          dateFormat="yyyy"
          showYearPicker
          // showMonthYearPicker
          // showFullMonthYearPicker
          popperPlacement="bottom"
        />
      </div>
      <SpinnerNew loading={loadingRelVendas} />
      <div hidden={loadingRelVendas} className="overflow-x-auto mb-5">

        <div className="flex flex-col ">
          {empresas.map((e: any) => {
            let empTotProdutos = 0
            let empTotDescontos = 0
            let empTotFretes = 0
            let empTotVendas = 0
            let empTotSemNotas = 0
            let empTotNotas = 0
            return (
              <div className="flex flex-col mx-auto " style={{ maxWidth: `${wTab}px`, minWidth: `${wTab}px` }}>
                <div className="mx-4">
                <h2 className="mt-6 mb-1">{e.nome}</h2>
                <div className="flex bg-[#0f172a] px-3 pt-1 mb-2">
                  <h3 className="w-[60px]  text-white text-left ">Mês</h3>
                  <h3 className="w-[120px] text-white text-right ">Total Produtos</h3>
                  <h3 className="w-[120px] text-white text-right ">Descontos</h3>
                  <h3 className="w-[120px] text-white text-right ">Fretes</h3>
                  <h3 className="w-[120px] text-white text-right ">Total Venda</h3>
                  <h3 className="w-[120px] text-white text-right ">Total S/Nota</h3>
                  <h3 className="w-[120px] text-white text-right ">Total C/Nota</h3>
                </div>
                {relvendas.filter((dv: any) => dv.empresaId === e.id).map((x: any) => {
                  empTotProdutos += x.totalprodutos
                  empTotDescontos += x.desconto
                  empTotFretes += x.valorfrete
                  empTotVendas += x.totalvenda
                  empTotSemNotas += (x.totalvenda - x.totalnota)
                  empTotNotas += x.totalnota
                  return (
                    <div className="flex px-3 pt-1 border-solid border-0 border-b border-[#b0d2ff]">
                      <h4 className="w-[60px]  text-left ">{x.mes.toString().padStart(2, '0')}/{x.ano}</h4>
                      <h4 className="w-[120px] text-right ">{formatDecimal(x.totalprodutos, 2)}</h4>
                      <h4 className="w-[120px] text-right ">{formatDecimal(x.desconto, 2)}</h4>
                      <h4 className="w-[120px] text-right ">{formatDecimal(x.valorfrete, 2)}</h4>
                      <h4 className="w-[120px] text-right ">{formatDecimal(x.totalvenda, 2)}</h4>
                      <h4 className="w-[120px] text-right ">{formatDecimal(x.totalvenda-x.totalnota, 2)}</h4>
                      <h4 className="w-[120px] text-right ">{formatDecimal(x.totalnota, 2)}</h4>
                    </div>)
                })}
                <div className="flex px-3 pt-1 bg-slate-300 font-bold">
                  <h4 className="w-[60px] text-left ">Total...</h4>
                  <h4 className="w-[120px] text-right ">{formatDecimal(empTotProdutos, 2)}</h4>
                  <h4 className="w-[120px] text-right ">{formatDecimal(empTotDescontos, 2)}</h4>
                  <h4 className="w-[120px] text-right ">{formatDecimal(empTotFretes, 2)}</h4>
                  <h4 className="w-[120px] text-right ">{formatDecimal(empTotVendas, 2)}</h4>
                  <h4 className="w-[120px] text-right ">{formatDecimal(empTotSemNotas, 2)}</h4>
                  <h4 className="w-[120px] text-right ">{formatDecimal(empTotNotas, 2)}</h4>
                </div>
                </div>
              </div>
            )
          }
          )}
        </div>

        <div className="flex flex-col ">
              <div className="flex flex-col mx-auto " style={{ maxWidth: `${wTab}px`, minWidth: `${wTab}px` }}>
                <div className="mx-4">
                <h2 className="mt-6 mb-1">COLORBRILHO (AGRUPADA)</h2>
                <div className="flex bg-[#0f172a] px-3 pt-1 mb-2">
                  <h3 className="w-[60px]  text-white text-left ">Mês</h3>
                  <h3 className="w-[120px] text-white text-right ">Total Produtos</h3>
                  <h3 className="w-[120px] text-white text-right ">Descontos</h3>
                  <h3 className="w-[120px] text-white text-right ">Fretes</h3>
                  <h3 className="w-[120px] text-white text-right ">Total Venda</h3>
                  <h3 className="w-[120px] text-white text-right ">Total S/Nota</h3>
                  <h3 className="w-[120px] text-white text-right ">Total C/Nota</h3>
                </div>
                {relvendasgroup.map((x: any) => {
                  gTotProdutos += x.totalprodutos
                  gTotDescontos += x.desconto
                  gTotFretes += x.valorfrete
                  gTotVendas += x.totalvenda
                  gTotSemNotas += (x.totalvenda - x.totalnota)
                  gTotNotas += x.totalnota
                  return (
                    <div className="flex px-3 pt-1 border-solid border-0 border-b border-[#b0d2ff]">
                      <h4 className="w-[60px]  text-left ">{x.mes.toString().padStart(2, '0')}/{x.ano}</h4>
                      <h4 className="w-[120px] text-right ">{formatDecimal(x.totalprodutos, 2)}</h4>
                      <h4 className="w-[120px] text-right ">{formatDecimal(x.desconto, 2)}</h4>
                      <h4 className="w-[120px] text-right ">{formatDecimal(x.valorfrete, 2)}</h4>
                      <h4 className="w-[120px] text-right ">{formatDecimal(x.totalvenda, 2)}</h4>
                      <h4 className="w-[120px] text-right ">{formatDecimal(x.totalvenda-x.totalnota, 2)}</h4>
                      <h4 className="w-[120px] text-right ">{formatDecimal(x.totalnota, 2)}</h4>
                    </div>)
                })}
                <div className="flex px-3 pt-1 bg-slate-300 font-bold">
                  <h4 className="w-[60px] text-left ">Total...</h4>
                  <h4 className="w-[120px] text-right ">{formatDecimal(gTotProdutos, 2)}</h4>
                  <h4 className="w-[120px] text-right ">{formatDecimal(gTotDescontos, 2)}</h4>
                  <h4 className="w-[120px] text-right ">{formatDecimal(gTotFretes, 2)}</h4>
                  <h4 className="w-[120px] text-right ">{formatDecimal(gTotVendas, 2)}</h4>
                  <h4 className="w-[120px] text-right ">{formatDecimal(gTotSemNotas, 2)}</h4>
                  <h4 className="w-[120px] text-right ">{formatDecimal(gTotNotas, 2)}</h4>
                </div>
                </div>
              </div>
            

        </div>

      </div>

    </Layout>
  )
}

