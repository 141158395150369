import Layout from "../../../components/template/Layout";
import usePedidosPendentesSaidaByDate from "./usePedidosPendentesSaidaByDate";
import CardPedidosPendentes from "./CardPedidosPendentes";
import { Button, Col, Divider, Drawer, Form, Input, InputNumber, Modal, Row, Space, Typography, message } from "antd";
import { DownloadOutlined, FastBackwardOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import useWindowSize from "../../../hooks/useWindowSize";
import { useAuth } from "../../../contexts/auth";
import { dateFormat, dateFormatBr, dateMySqlToPtBr } from "../../../utils/dateFunctions";
import useProcessando from "../../../data/hooks/useProcessando";
import { apierp } from "../../../services/api";
import { customMessage } from "../../../components/template/CustomMessage";
import { useNavigate } from "react-router-dom";
import useImportPedidosFromBlingV3 from "./useImportPedidosFromBlingV3";
import SpinnerNew from "../../../components/template/SpinnerNew";
import Spinner from "../../../components/template/Spinner";
import { formatDecimalZ } from "../../../utils/functions";
import isPermitted from "../../../hooks/isPermitted";

const { Title, Text, Paragraph } = Typography;

// import { useAuth } from '../../contexts/auth';
// import history from '../../services/history'
// import { useHistory } from "react-router-dom";

// import { Center, Heading, SideMenu, VStack } from "../../components"


export default function SaidaMercadoriasByDate() {

  const permiteImportarPedidosVendaV2 = isPermitted('importar_pedidos_venda_v2')
  const permiteImportarSelecionados = isPermitted('importar_pedidos_venda_v3')

  const [mesref, setMesRef] = useState(null as any)
  const [mesImport, setMesImport] = useState(null as any)

  const { pedidosPendentesSaida, empresas, caixas, processando, reload } = usePedidosPendentesSaidaByDate(mesref)
  const { pedidosVendaV3, processandoV3, reloadV3 } = useImportPedidosFromBlingV3(mesImport)

  const [pedidosToImport, setPedidosToImport] = useState([] as any)
  const [importandoPedidos, setImportandoPedidos] = useState(false)

  const [toggleState, setToggleState] = useState(false)

  useEffect(() => {
    const pedidosToImport = []
    for (let pedido of pedidosVendaV3) {
      pedidosToImport.push({
        id: pedido.id,
        empresaId: pedido.empresaId,
        importar: (pedido.situacaoNome === 'Cancelado' ||  pedido.situacaoNome === 'Atendido') && !pedido.existeNoZapp, // TODO SE JA EXISTE NO ZAPP E SITUACAO NO ZAPP E DIFERENTE DA SITUACAO NO BLING, DEVE SER IMPORTADO
        reimportar: (pedido.situacaoNome === 'Cancelado' ||  pedido.situacaoNome === 'Atendido') && pedido.existeNoZapp,
        marcado: false,
        remarcado: false
      })
    }
    setPedidosToImport(pedidosToImport)
    // console.log('useEffect pedidosToImport', pedidosToImport)
  }, [pedidosVendaV3])

  const now = new Date()
  // const now = new Date("2024-04-16 12:00:00")
  useEffect(() => {
    setMesRef(now)
    // console.log('useEffect unico', now)
  }, [])
 
  const { Logout, user } = useAuth();
  const usuario: any = user
  const [innerW, innerH] = useWindowSize();

  const [drawerFormPrepararEntregaVisible, setDrawerFormPrepararEntregaVisible] = useState(false)
  const [rowPedidoSelected, setRowPedidoSelected] = useState(-1)

  const [formSaidaMercadoria] = Form.useForm();
  const [formImportarPedido] = Form.useForm();
  const [deliveryDate, setDeliveryDate] = useState(new Date(dateFormat(new Date(), 'yyyy-MM-dd 12:00:00')));

  const [buttonsDisabled, setButtonsDisabled] = useState(false)


  let navigate = useNavigate();

  // Drawers Form prepararEntrega
  const showDrawerFormPrepararEntrega = () => {
    setButtonsDisabled(false)
    setDrawerFormPrepararEntregaVisible(true)
  };
  const hideDrawerFormPrepararEntrega = async () => {
    setButtonsDisabled(false)
    setDrawerFormPrepararEntregaVisible(false)
  };

  // Drawers Form importarPedidos
  const [drawerFormImportarPedidosVisible, setDrawerFormImportarPedidosVisible] = useState(false)
  const showDrawerFormImportarPedidos = () => {
    setButtonsDisabled(false)
    setDrawerFormImportarPedidosVisible(true)
  };
  const hideDrawerFormImportarPedidos = () => {
    setImportandoPedidos(false)
    setMesImport(null)
    const newMesref = new Date(mesref.setSeconds(mesref.getSeconds() + 1)) // força o reload
    // console.log('newMesref', newMesref)
    setMesRef(newMesref) ; // força o reload
    setButtonsDisabled(false)
    setDrawerFormImportarPedidosVisible(false)
  };


  async function handleRow(id: any, index: any) {
    formSaidaMercadoria.resetFields()
    // formSaidaMercadoria.setFieldsValue({'dataSaidaEstoque': ''})
    const pedidoPendenteSaida = pedidosPendentesSaida[index]
    setRowPedidoSelected(index)
    const pedidoPendente = {
      id,
      numero: pedidoPendenteSaida.numero,
      data: pedidoPendenteSaida.data,
      // dataSaidaEstoque: new Date(dateFormat(Date.now(), 'yyyy-MM-dd')),
      dataSaidaEstoque: new Date(),
      cliente: pedidoPendenteSaida.cliente.nome,
      itens: pedidoPendenteSaida.itens,
      caixas: caixas.map((it: any) => {
        return ({ id: it.id, descricao: it.descricao, nomeComponente: it.nomeComponente })
      })
    }

    formSaidaMercadoria.setFieldsValue(pedidoPendente)

    showDrawerFormPrepararEntrega()
  }
  const handleChangeDate = (newDate: any) => {
    setToggleState(!toggleState)
  }
  const dateChange = (date: any) => {
    setDeliveryDate(date)
    // setHideButtonFinaliza(true)
  }

  function enableButtons(wait: number) {
    setTimeout(function () {
      setButtonsDisabled(false)
      // setMsgError('')
    }, wait);
  }
  function disableButtons() {
    setButtonsDisabled(true)
  }

  async function handleSubmitSaidaMercadoria(dadosForm: any) {
    disableButtons()
    const dados: any = {
      pedidoVendaId: dadosForm.id,
      // dataSaidaEstoque: dateFormat(dadosForm.dataSaidaEstoque,'yyyy-MM-dd'),
      dataSaidaEstoque: dateFormat(deliveryDate, 'yyyy-MM-dd'),
      caixas: []
    }
    delete dadosForm.id
    delete dadosForm.dataSaidaEstoque
    const entries = Object.entries(dadosForm);
    const caixas = []
    for (let it of entries) {
      if (it[1]) {
        caixas.push({
          produtoId: +it[0],
          quantidade: +it[1]
        })
      }
    }
    dados.caixas = caixas
    //UPDATE
    try {
      const result = await apierp.put(`blingpedidosvendav2/${dados.pedidoVendaId}`, dados);
      setToggleState(!toggleState)
      message.success('Saida de Mercadoria registrada com sucesso!');
      hideDrawerFormPrepararEntrega()
    } catch (err) {
      if (customMessage(err) === 403) Logout()
      enableButtons(500)

    }
    setRowPedidoSelected(-1)
    reload()
    hideDrawerFormPrepararEntrega()
  }

  async function importarPedidosVenda() {
    const rota = "/zapp/" + usuario.shortName.replaceAll(' ', '_') // + "_" + userTenant.tenant.id
    navigate(`${rota}/blingpedidosvendav2`)

    // apierp.get(`blingpedidosvendav2`);
    // Modal.success({
    //   title: 'Sucesso', content: (
    //     <>
    //       <p>Sua solicitação foi enviada ao servidor!</p>
    //       <p>Se existirem novos pedidos, eles estarão disponiveis dentro de alguns minutos</p>
    //       <p className="text-red-500 font-semibold">Por favor, AGUARDE alguns minutos e clique na tecla F5 para atualizar esta tela!</p>
    //     </>
    //   )
    // });
  }

  async function importarPedidosVendaV3() {
    // setPedidosToImport([])
    setMesImport(mesref)
    showDrawerFormImportarPedidos()
    // reloadV3()
    // apierp.get(`blingpedidosvendav2`);
    // Modal.success({
    //   title: 'Sucesso', content: (
    //     <>
    //       <p>Sua solicitação foi enviada ao servidor!</p>
    //       <p>Se existirem novos pedidos, eles estarão disponiveis dentro de alguns minutos</p>
    //       <p className="text-red-500 font-semibold">Por favor, AGUARDE alguns minutos e clique na tecla F5 para atualizar esta tela!</p>
    //     </>
    //   )
    // });
  }

  const marcaPedido = async (empresaId: number, pedidoId: number) => {
    const pedido = pedidosToImport.find((p: any) => p.id === pedidoId && p.empresaId === empresaId)
    // pedidosToImport.push(pedido)
    pedido.marcado = true
    setToggleState(!toggleState)

  }
  const remarcaPedido = async (empresaId: number, pedidoId: number) => {
    const pedido = pedidosToImport.find((p: any) => p.id === pedidoId && p.empresaId === empresaId)
    // pedidosToImport.push(pedido)
    pedido.remarcado = true
    setToggleState(!toggleState)

  }

  const desmarcaPedido = async (empresaId: number, pedidoId: number) => {
    const pedido = await pedidosToImport.find((p: any) => p.id === pedidoId && p.empresaId === empresaId)
    // pedidosToImport.push(pedido)
    pedido.marcado = false
    pedido.remarcado = false
    pedido.importar = false
    pedido.reimportar = false
    // setPedidosToImport(pedidosToImport)
    setToggleState(!toggleState)

  }

  const importarPedidosFromBling = async () => {
    setImportandoPedidos(true)
    const pedidos = pedidosToImport.filter((p: any) => p.marcado || p.remarcado)
    for (let pedido of pedidos) {
      const ped = await pedidosVendaV3.find((p: any) => p.empresaId === pedido.empresaId && p.id === pedido.id)
      const dataBling = {
        "metodo": "processaUmBlingPedidoVenda",
        "param": ped
      }
      await apierp.post(`bling/v3/pedidosvenda`, dataBling) // na verdade é get
      // await desmarcaPedido(pedido.empresaId, pedido.id) // nao esta funcionando direito
    }
    setImportandoPedidos(false)
    hideDrawerFormImportarPedidos()
  }

  const temMarcado = pedidosToImport.find((p: any) => p.marcado)
  const temRemarcado = pedidosToImport.find((p: any) => p.remarcado)

  const botoes = () => {
    return (
      <Space size={10}>
        {/* <Button disabled={importandoPedidos || processandoV3 || processando} onClick={() => { importarPedidosVenda() }} type="primary" shape="round" size="small" icon={<DownloadOutlined />} hidden={!permiteImportarPedidosVendaV2} title="Buscar pedidos">Buscar Pedidos</Button> */}
        <Button disabled={importandoPedidos || processandoV3 || processando} onClick={() => { importarPedidosVendaV3() }} type="primary" shape="round" size="small" icon={<DownloadOutlined />} hidden={(usuario.id > 999)} title="Buscar pedidos">Buscar Pedidos de Venda</Button>
      </Space>
    )
  }
  
  const wTab = 740 // liquido = x-8
  const htb = `calc(100vh - 149px - 110px)`
  const wDrawer = innerW > 640 ? innerW - 144 : 360 // - 144
  const wDrawerx = innerW > 720 && innerW > 360 ? 720 : 360 // - 144
  const wDrawery = innerW > 1080 && innerW > 720 ? 1080 : 720
  const divTotalLeft = innerW > 640 ? 'left-[144px]' : ''

  let corPicker = 'bg-blue-600'
  if (mesref !== null) {
    corPicker = dateFormat(mesref, 'yyyy-MM-dd') === dateFormat(now, 'yyyy-MM-dd') ? 'bg-blue-600' : 'bg-red-600'
  }

  // console.log(importandoPedidos, processandoV3)
  return (
    <Layout titulo={'Saída de Mercadorias'} botoes={botoes()}>
      <div className="mx-auto">
        <DatePicker disabled={importandoPedidos || processandoV3 || processando}
          // wrapperClassName="btmonth"
          className={`w-[88px] mb-[3px] text-center uppercase border-none rounded-md font-medium py-1 cursor-pointer text-white ${corPicker}`}
          selected={mesref}
          locale="pt-BR"
          onChange={(date: any) => setMesRef(date)}
          dateFormat="dd/MM/yyyy"
          maxDate={new Date()}
          todayButton="Hoje"
          popperPlacement="bottom"
        />
        <SpinnerNew loading={processando} />
      </div>
      <div className="overflow-x-auto">
        <div className="mx-auto" style={{ minWidth: wTab, maxWidth: wTab }}>
          <div className="flex flex-col items-center mb-4" style={{ minHeight: htb, maxHeight: htb }}>
            {pedidosPendentesSaida.map((pedido: any, index) =>
              <CardPedidosPendentes className="" key={index} pedido={pedido} empresas={empresas} rowIndex={index} rowSelected={rowPedidoSelected} handleRow={handleRow} />
            )}
          </div>
        </div>
      </div>

      {/* DRAWER FORM PREPARAR ENTREGA */}
      <Drawer
        title={'Preparar Entrega'}
        width={wDrawerx}
        closable={true}
        maskClosable={false}
        onClose={hideDrawerFormPrepararEntrega}
        open={drawerFormPrepararEntregaVisible}
        headerStyle={{ borderRadius: "0" }}
        bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
      >
        <div className="inner-drawer-body">
          <Form layout="horizontal" form={formSaidaMercadoria} onFinish={handleSubmitSaidaMercadoria} >
            <Title level={5}>Pedido {formSaidaMercadoria.getFieldValue('numero')} data: {formSaidaMercadoria.getFieldValue('itens') && dateMySqlToPtBr(formSaidaMercadoria.getFieldValue('data'))} - {formSaidaMercadoria.getFieldValue('cliente')}</Title>
            {/* <Title level={5}>Pedido {formSaidaMercadoria.getFieldValue('numero')} data: {formSaidaMercadoria.getFieldValue('data')} - {formSaidaMercadoria.getFieldValue('cliente')}</Title> */}
            <div className="my-2 font-semibold">Itens:</div>
            {formSaidaMercadoria.getFieldValue('itens') && formSaidaMercadoria.getFieldValue('itens').map((item: any, index: number) => {
              return (<div key={index} className="flex justify-between font-medium mr-4"><div>{item.codigo} - {item.descricao}</div> <div>{item.quantidade} {item.unidade}</div> </div>)
            })}
            {/* <div className="my-2 font-semibold">Data de preparação da entrega:</div> */}
            <Form.Item name="id" hidden noStyle></Form.Item>
            {/* <Row className="mt-4" gutter={16}>
              <Col span={12}> */}
            <div className="font-medium">
              <Form.Item className="mt-4 w-[350px]"
                name="dataSaidaEstoque"
                label="Data de preparação da entrega"
                labelCol={{ span: 18 }}
                rules={[{ required: true, message: 'Data Obrigatória' }]}
              >
                {/* <DatePicker className="ant-input" selected={formSaidaMercadoria.getFieldValue('dataSaidaEstoque')} locale="pt-BR" dateFormat="dd/MM/yyyy" onChange={(date: any) => handleChangeDate(date)} todayButton="Hoje" /> */}
                <DatePicker
                  className="ant-input w-[124px] text-lg text-red-500 font-bold "
                  selected={deliveryDate}
                  locale="pt-BR"
                  dateFormat="dd/MM/yyyy"
                  onChange={(date: any) => dateChange(date)}
                  todayButton="Hoje"
                  popperPlacement="bottom"

                />
              </Form.Item>
            </div>
            {/* </Col>
            </Row> */}

            <div className="mb-4 font-semibold">Caixas para embalagem:</div>
            {formSaidaMercadoria.getFieldValue('itens') && formSaidaMercadoria.getFieldValue('caixas').map((caixa: any, index: number) => {
              return (
                <div key={index} className="flex justify-start font-medium mr-4">
                  {/* <Row className="linha" gutter={16}>
                  <Col className="coluna" span={18} > */}
                  <Form.Item className="my-0 w-[350px]"
                    name={caixa.id}
                    label={`${index + 1} - ${caixa.nomeComponente}`}
                    labelCol={{ span: 18 }}
                  >
                    <InputNumber className="myinputnumber text-lg text-red-500 font-bold" min={1} max={100} />
                  </Form.Item>
                  {/* </Col>
                  </Row> */}
                </div>
              )
            })}
          </Form>
        </div>
        <Space className="drawer-footer flex w-full justify-between">
          <Space className="flex w-full justify-start">
            <Button onClick={() => { hideDrawerFormPrepararEntrega() }} type="primary" icon={<FastBackwardOutlined />} />
            <Button onClick={hideDrawerFormPrepararEntrega}>Cancel</Button>
          </Space>
          <Button onClick={formSaidaMercadoria.submit} disabled={buttonsDisabled} type="primary">Submit</Button>
        </Space>
      </Drawer>

      {/* DRAWER FORM IMPORTAR PEDIDOS */}
      <Drawer
        title={'Importar Pedidos do Bling V3'}
        width={wDrawery}
        closable={true}
        maskClosable={false}
        onClose={hideDrawerFormImportarPedidos}
        open={drawerFormImportarPedidosVisible}
        headerStyle={{ borderRadius: "0" }}
        bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
      >
        <div className="inner-drawer-body">
          <div className="mb-20">
            {/* <Form layout="horizontal" form={formImportarPedido} onFinish={handleSubmitSaidaMercadoria} > */}
            {/* <Title level={5}>Importar Pedidos</Title>
            <div className="my-2 font-semibold">Pedidos:</div> */}

            <div key={'cab'} className={`flex items-center px-2 h-[33px] gap-2 font-medium mb-1 bg-slate-300 `}>
              <div className={`w-10 text-left `}>N°</div>
              <div className={`w-20 text-left `}>Data</div>
              <div className={`w-28 text-left `}>Situacao</div>
              <div className={`w-[484px] text-left `}>Cliente</div>
              <div className={`w-[72px] text-right`}>Total</div>
              <div className={`w-24 text-left `}>Saida Estoque</div>
              {/* <div className={`w-24 text-left `}></div> */}
            </div>

            {pedidosVendaV3.map((pedido: any, index) => {
              const corTexto = pedido.situacaoNome === "Cancelado" ? 'text-red-500' : ''
              let corFundo = !pedido.existeNoZapp ? 'bg-yellow-200' : pedido.dataSaidaEstoque ? 'bg-green-200' : 'bg-slate-200'
              corFundo = pedido.situacaoNome === "Em Aberto" || pedido.situacaoNome === "Em Andamento" ? 'bg-blue-200' : corFundo
              const xx = pedidosToImport.find((p: any) => p.id === pedido.id && p.empresaId === pedido.empresaId)
              const selecionar = xx?.importar && !xx?.marcado
              const reselecionar = xx?.reimportar && !xx?.remarcado
              const selecionado = xx?.marcado
              const reselecionado = xx?.remarcado
              return (
                <div key={index} className={`flex items-center px-2 h-[33px] gap-2 font-medium border border-solid border-white ${corTexto} ${corFundo}`}>
                  <div className={`w-10 text-left `}>{pedido.numero}</div>
                  <div className={`w-20 text-left `}>{dateFormatBr(pedido.data)}</div>
                  <div className={`w-28 text-left `}>{pedido.situacaoNome}</div>
                  <div className={`w-[484px] text-left `}>{pedido.contato.nome}</div>
                  <div className={`w-[72px] text-right`}>{formatDecimalZ(pedido.total, 2)}</div>
                  <div className={`w-20 text-left `}>{dateFormatBr(pedido.dataSaidaEstoque)}</div>
                  <div className={`w-24 text-left `}>
                    {/* {(xx?.importar && !xx?.marcado)
                      ? <div onClick={() => { marcaPedido(pedido.empresaId, pedido.id) }} className="bg-white text-center py-[2px] mt-[3px] cursor-pointer rounded-md border border-solid">Selecionar</div>
                      : ''
                    } */}
                    {selecionar && <div onClick={() => { marcaPedido(pedido.empresaId, pedido.id) }} className="bg-white text-center py-[2px] cursor-pointer rounded-sm border border-solid" title="Selecionar para importação">Selecionar</div>}
                    {reselecionar && <div onClick={() => { remarcaPedido(pedido.empresaId, pedido.id) }} className="bg-white text-center py-[2px] cursor-pointer rounded-sm border border-solid w-10" title="Selecionar para importação">Sel..</div>}
                    {selecionado && <div className="bg-violet-300 text-center text-black py-[2px] rounded-sm border border-solid" title="Selecionado para importação">Selecionado</div>}
                    {reselecionado && <div className="bg-violet-300 text-center text-black py-[2px] rounded-sm border border-solid" title="Selecionado para importação">Selecionado</div>}
                  </div>
                </div>)
            }
            )}
            <Spinner loading={processandoV3} />
            {/* </Form> */}
          </div>
        </div>
        <Space className="drawer-footer flex w-full justify-between">
          <Space className="flex w-full justify-start">
            {/* <Button onClick={() => { hideDrawerFormImportarPedidos() }} type="primary" icon={<FastBackwardOutlined />} /> */}
            <Button disabled={importandoPedidos || processandoV3 } onClick={hideDrawerFormImportarPedidos}>Cancel</Button>
            <div>{pedidosVendaV3.length} pedidos encontrados</div>
          </Space>
          {/* <Button loading={importandoPedidos} disabled={buttonsDisabled || processandoV3 || !temMarcado} onClick={importarPedidosFromBling} type="primary">Importar Selecionados</Button> */}
          <Button loading={importandoPedidos} disabled={buttonsDisabled || processandoV3 || (!temMarcado && !temRemarcado) || !permiteImportarSelecionados} onClick={importarPedidosFromBling} type="primary">Importar Selecionados</Button>
        </Space>

      </Drawer>
      <div className={`divtotal absolute flex items-center h-10 px-4 bottom-8 ${divTotalLeft} text-white`}>{pedidosPendentesSaida?.length} Pedidos</div>
    </Layout>

  );
}