
import * as React from "react"
import Layout from "../../../components/template/Layout";
// import { useAuth } from '../../contexts/auth';
// import history from '../../services/history'
// import { useHistory } from "react-router-dom";

// import { Center, Heading, SideMenu, VStack } from "../../components"


export default function NotFound() {
  // history.push('/'); //quebra galho para forçar a navegação para a raiz e eliminar um bug.
  return (
    <Layout titulo={'404'}>
    <div className="flex h-screen justify-center">
      <h1 className="my-auto">NotFound</h1>
    </div>

    </Layout>
    
  );
}
