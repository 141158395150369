import { Button, InputRef, Space } from "antd"
import { LockFilled, UnlockFilled, CheckSquareFilled, DownloadOutlined, CopyOutlined, PrinterOutlined, PlusOutlined } from '@ant-design/icons';
import Tabela from "../../../components/template/Tabela"
import { copyToClipboard, downloadToCsv, printDiv } from "../../../utils/functions"
import { useGeral } from "../../../contexts/geral";
import { columnsHistoricoCompras } from "./columnsHistoricoCompras";
import isPermitted from "../../../hooks/isPermitted";
import { useRef, useState } from "react";
import { FilterConfirmProps } from "antd/lib/table/interface";

export function HistoricoCompras(props: any) {
    const { userTenant } = useGeral();
    const colors = userTenant.tenant.colors.split(',')
    const isPermittedManterFT = isPermitted('manter_fichas_tecnicas')

    // search filter produto
    interface DataType {
        nome: string;
        nomeComponente: string;
    }
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [selectedKeys, setSelectedKeys] = useState('');
    const searchInput = useRef<InputRef>(null);
    type DataIndex = keyof DataType;
    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };
    //end

    //   const { produto, novaHistoricoCompras,
    //     handleRowHistoricoCompras, rowHistoricoComprasSelected, lastHistoricoComprasSelected, loadingHistoricoCompras,
    //   } = props
    const { produto, compras } = props
    if (!compras) { return <div>Sem Historico de compras</div> }
    // console.log('historicoCompras',produto.historicocompras)
    const historicoCompras = compras
    // console.log(historicoCompras)
    const wTab = 928
    const htb = `calc(100vh - 149px - 110px)`
    const hsc = `calc(100vh - 180px - 109px)`

    const colunas = columnsHistoricoCompras({
        setSearchText, setSearchedColumn, setSelectedKeys, selectedKeys, searchInput, searchedColumn, searchText
    })
    // console.log(colunas)

    return (
        <div className="flex flex-col justify-between" style={{ height: "calc(100vh - 137px)" }}>
            <Tabela className="mx-0" id='historicocompra' titulo='Historico de Compras' subtitulo={produto.codigo + " - " + produto.nome} wTab={wTab} htb={htb} hsc={hsc} dataSource={historicoCompras} columns={colunas} handleRow={() => { }} rowSelected={() => { }} lastSelected={() => { }} loading={false} />
            <div className={"pageButtons"} style={{ backgroundColor: colors[3] }}>
                <Space size={10}>
                    <Button onClick={() => printDiv('printableDiv-historicocompra', 'historicocompra')} shape="round" size="small" icon={<PrinterOutlined />} title="Imprimir"></Button>
                    <Button onClick={() => copyToClipboard('historicocompra')} shape="round" size="small" icon={<CopyOutlined />} title="Copy to Clipboard"></Button>
                    <Button onClick={() => downloadToCsv('historicocompra', "FichasTecnicas.csv")} shape="round" size="small" icon={<DownloadOutlined />} title="Download CSV"></Button>
                    {/* <Button onClick={novaHistoricoCompras} type="primary" shape="round" size="small" icon={<PlusOutlined />} hidden={!isPermittedManterFT} title="Novo"></Button> */}
                </Space>
            </div>
        </div>
    )
}
