import { useEffect, useMemo, useRef, useState } from "react";
import { useDebounce } from "use-debounce";
import useClientesRanking from "./useClientesRanking";
import Layout from "../../../components/template/Layout";
import SpinnerNew from "../../../components/template/SpinnerNew";
import Tabela from "../../../components/template/Tabela";
import { Button, Checkbox, Col, Drawer, Form, Input, InputNumber, InputRef, Modal, Pagination, Row, Select, Space, Switch, Typography } from "antd";
import { FilterConfirmProps } from "antd/lib/table/interface";
import { columnsClienteRanking } from "./columnsClienteRanking";
import { apierp } from "../../../services/api";
import TextArea from "antd/lib/input/TextArea";
import { groupByTs } from "../../../utils/tsFunctions";
import { groupBy } from "../../../utils/functions";
import useWindowSize from "../../../hooks/useWindowSize";
import { CardCliente } from "../../__Shared/Clientes/CardCliente";
import { useAuth } from "../../../contexts/auth";
import { FilterOutlined } from '@ant-design/icons';
import { RiFilterLine, RiFilterOffLine } from "react-icons/ri";


export default function ClienteRanking() {
  const { Logout, user } = useAuth();
  const usuario: any = user
  // console.log(usuario)
  const todos = usuario.topSecurityLevel < 5 ? true : false;
  // const pagina = 1
  // const pageSize = 500
  const [dias, setDias] = useState(0)
  const [text, setText] = useState('')
  const [nomeFilter] = useDebounce(text, 500);
  const [pagina, setPagina] = useState(1)
  const [pageSize, setPageSize] = useState(100)

  const [param, setParam] = useState(null as any)
  const [gcIdFilter, setGcIdFilter] = useState(null as any)
  const [statusCrmFilter, setStatusCrmFilter] = useState(null as any)
  const [obsFilter, setObsFilter] = useState(false)

  const { clientesRanking, totalReg, processando, reload } = useClientesRanking(param)

  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const [innerW, innerH] = useWindowSize();

  //ESTADOS CLIENTE
  const [formCliente] = Form.useForm();
  const [loadingCliente, setLoadingCliente] = useState(false)
  const [drawerFormClienteVisible, setDrawerFormClienteVisible] = useState(false)
  const [clientes, setClientes] = useState([])
  const [users, setUsers] = useState([])
  const [statusCrm, setStatusCrm] = useState([])
  const [componentes, setComponentes] = useState([])
  const [cliente, setCliente] = useState(null as any)
  // const [rowClienteSelected, setRowClienteSelected] = useState(-1)
  // const [lastClienteSelected, setLastClienteSelected] = useState(-1)
  const [isInclusaoCliente, setIsInclusaoCliente] = useState(false)
  const [clienteBlocked, setClienteBlocked] = useState(false)

  const { Title, Text, Paragraph } = Typography;
  const { Option } = Select;

  useEffect(() => {
    // console.log('useEffect unico', param)
    loadUsers()
    loadStatusCrm()
    setParam({ todos, pagina, pageSize, dias, gcIdFilter: null, statusCrmFilter: null, obsFilter: false, nomeFilter })
  }, [])

  useEffect(() => {
    if (nomeFilter.length < 3 && nomeFilter.length > 0) {
      return
    }
    setParam({ ...param, todos, pagina, pageSize, dias, gcIdFilter, statusCrmFilter, obsFilter, nomeFilter })
    // setParam({ ...param, nomeFilter })
  }, [nomeFilter])

  async function loadUsers() {
    const response = await apierp.get(`usersbt`)
    const data = response.data as any;
    const users = data.filter((user: any) => user.topSecurityLevel <= 5 && !user.isInactive && user.isActiveInTenant)
      .map((user: any) => { return ({ id: user.id, nome: user.name, shortName: user.shortName }) })
    setUsers(users);
  }
  async function loadStatusCrm() {
    const response = await apierp.get(`clientes/obterstatuscrm`)
    const data = response.data as any;
    // console.log('statusCrm', data)
    setStatusCrm(data);
  }

  //inicio search 
  interface DataType {
    nome: string;
    cnpj: string;
  }
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [selectedKeys, setSelectedKeys] = useState('');
  const searchInput = useRef<InputRef>(null);
  type DataIndex = keyof DataType;
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };
  //fim search filter cliente

  const gerAtribuidos = groupBy(clientesRanking, 'gcsName', '')
    .filter((item: any) => item.gcsName).map((item: any) => ({ text: item.gcsName, value: item.gcsName }))
  // console.log('gerAtribuidos', gerAtribuidos)
  const colunas = columnsClienteRanking({
    setSearchText, setSearchedColumn, setSelectedKeys, selectedKeys, searchInput, searchedColumn, searchText, gerAtribuidos
  })

  const handleRowCliente = async (record: any, index: any) => {
    // console.log('handleRowCliente', record, index)
    const dados = { clienteId: record.id }
    // const response1 = await apierp.post(`clientes/obterrankingcliente`, dados)
    // const data1 = await response1.data as any;
    // console.log('data1', data1)
    const response2 = await apierp.post(`clientes/obterclienteandvinculados`, dados)
    const data2 = await response2.data as any;
    // console.log('data2', data2)
    // setLastClienteSelected(index)
    // setRowClienteSelected(index)
    setClientes(data2)
    // console.log('data2', data2 )
    showDrawerFormCliente()
    fillFormCliente(
      {
        id: data2[0].id,
        nome: data2[0].nome,
        gcId: data2[0].gcId,
        statusCrmId: data2[0].statusCrmId,
        obsInterna: data2[0].obsInterna,
      }
    )
    // ======
  };
  const fillFormCliente = (cliente: any) => {
    formCliente.setFieldsValue({
      id: cliente.id,
      nome: cliente.nome,
      gcId: cliente.gcId,
      statusCrmId: cliente.statusCrmId,
      obsInterna: cliente.obsInterna,
    });
  };
  const showDrawerFormCliente = () => {
    setButtonsDisabled(false)
    setDrawerFormClienteVisible(true)
  };
  const hideDrawerFormCliente = () => {
    setButtonsDisabled(false)
    // setRowClienteSelected(-1)
    // setCliente(null)
    setDrawerFormClienteVisible(false)
  };

  async function handleSubmitFormCliente(dados: any,) {
    // console.log('handleSubmitFormCliente', dados)
    setButtonsDisabled(true)
    try {
      const response = await apierp.post(`clientes/updateobsinternaandgc`, dados)
      const data = response.data as any;
      // console.log('data', response)
      if (data.success) {
        Modal.success({ title: 'Sucesso', content: data.message });
        hideDrawerFormCliente()
        // console.log('reload')
        reload()
      } else {
        Modal.info({ content: data.message, });
        hideDrawerFormCliente()
        // reload()
      }
    } catch (err: any) {
      console.log('err', err.substring(0, 100))
      Modal.error({ title: 'Erro', content: err.message, });
      hideDrawerFormCliente()
      // reload()
    }

  }

  const onChangeDias = (value: any) => {
    setDias(value)
  };

  function onChangeNome(value: any) {
    //verificar se o value tem apostrofo e substituir por % antes de setar o texto
    // if (value.includes("'")) {
    //   value = value.replaceAll("'", "%")
    // } resolvido no backend

    setText(value)
    if (value.length === 0) {
      // setParam({ ...param, nomeFilter: '' })
    }
    if (value.length < 3) {
      return
    }
    // setText(value)
    // console.log('onChangeNome', value, nomeFilter)
    // setParam({ ...param, nomeFilter: value })
    // filtrar()
  };

  const filtrar = () => {
    // console.log(dias, text)
    setParam({ ...param, dias })
  }

  const limparFiltros = () => {
    setText('')
    setDias(0)
    setGcIdFilter(null)
    setObsFilter(false)
    setStatusCrmFilter(null)
    setParam({ ...param, dias: 0, gcIdFilter: null, statusCrmFilter: null, obsFilter: false, nomeFilter: '' })
  }

  function onChange(page: any, pageSize: any) {
    // console.log('page', page, 'pageSize', pageSize)
    setPagina(page)
    setPageSize(pageSize)
    setParam({ ...param, pagina: page, pageSize })
  }

  const filterGc = (value: any) => {
    const gcIdFilter = value === '' ? null : value
    setGcIdFilter(gcIdFilter)
    setParam({ ...param, gcIdFilter })
  }
  const filterStatus = (value: any) => {
    const statusCrmFilter = value === '' ? null : value
    setStatusCrmFilter(statusCrmFilter)
    setParam({ ...param, statusCrmFilter })
  }
  const filterObs = (checked: any) => {
    const obsFilter = checked
    // console.log('filterObs', obsFilter)
    setObsFilter(obsFilter)
    setParam({ ...param, obsFilter: obsFilter })
  }

  const wTab = 1640
  const htb = `calc(100vh - 149px - 110px)`
  const hsc = `calc(100vh - 180px - 109px)` // 1 a menos que htb
  // const hsc = `calc(100vh - 180px - 109px - 128px - 10px)` // 1 a menos que htb
  const wDrawer = innerW > 640 ? innerW - 144 : 360 // - 144
  const wDrawerx = innerW > 720 && innerW > 360 ? 720 : 360 // - 144

  // use memo em componente <Tabela> para evitar re-render desnecessario
  const tabela = useMemo(() => {
    // console.log('useMemo tabela')
    return (
      <Tabela className="mx-5" id='rankClientes' titulo={'Ranking Clientes'} wTab={wTab} htb={htb} hsc={hsc} dataSource={clientesRanking} columns={colunas} handleRow={handleRowCliente} loading={processando} rowKey={'id'} />
    )
  }, [clientesRanking])

  const botoes = () => {
    return (
      <Space className="" size={5}>
        <Button onClick={limparFiltros} shape="round" size="small" icon={<RiFilterOffLine className="mt-[2px]" size="18px" />} hidden={!true} title="Limpar Todos Filtros"></Button>
        <div className=" ml-1 text-white">{'Nome/Cnpj'}</div>
        <Input size="small" className='w-[100px]' value={text} onChange={(e) => onChangeNome(e.target.value)} />

        <Pagination className="text-white"
          onChange={onChange}
          size="small"
          total={totalReg}
          current={pagina}
          pageSizeOptions={['25', '50', '100', '250', '500', '1000', '2000', '5000', '10000']}
          defaultPageSize={100}
          showSizeChanger
          showQuickJumper
          showTotal={total => `Total ${total} itens`}
        />

        <div className=" ml-3 text-white">{'G.C.:'}</div>
        <Select size="small" className='min-w-[115px] max-w-[115px]' placeholder="Todos" value={gcIdFilter} onChange={filterGc} popupClassName="border-solid border border-blue-500">
          <Option value={''}>Todos</Option>
          {users.map((user: any, index: any) => {
            return <Option value={user.id} className="text-blue-800 font-semibold"> {user.shortName}</Option>
          })}
        </Select>
          
        <div className=" ml-1 text-white">{'Status:'}</div>
        <Select size="small" className='min-w-[185px] max-w-[185px]' placeholder="Todos" value={statusCrmFilter} onChange={filterStatus} popupClassName="border-solid border border-blue-500">
          <Option value={''}>Todos</Option>
          {statusCrm.map((status: any, index: any) => {
            return <Option value={status.id} className="text-blue-800 font-semibold"> {status.nome}</Option>
          })}
        </Select>

        <div className=" ml-1 text-white">{'Obs:'}</div>
        <Switch className="min-w-[100px] text-left" checkedChildren="Só Com Obs" unCheckedChildren="Todas" checked={obsFilter} onChange={filterObs} />
        {/* <Checkbox className="ml-3 text-white" onChange={(e) => setParam({ ...param, obsFilter: e.target.checked })}>Só Com Obs</Checkbox> */}

        <div className=" ml-1 text-white">{'Dias >='}</div>
        <InputNumber size="small" controls={true} value={dias} className='w-[72px]' min={0} max={99999} defaultValue={0} onChange={onChangeDias} />
        <Button onClick={filtrar} type="primary" shape="round" size="small" icon={<RiFilterLine className="mt-[2px]" size="18px" />} hidden={!true} title="Filtrar Dias"></Button>
      </Space>
    )
  }
  return (
    <Layout titulo={'Ranking Clientes'} botoes={botoes()}>
      <SpinnerNew loading={processando} />
      {/* <Tabela className="mx-5" id='rankClientes' titulo={'Ranking Clientes'} wTab={wTab} htb={htb} hsc={hsc} dataSource={clientesRanking} columns={colunas} handleRow={handleRowCliente} lastSelected={lastRowClienteSelected} lastSelected={lastClienteSelected} loading={processando} rowKey={'id'} /> */}
      <div hidden={processando}>{tabela}</div>
      {/* DRAWER FORM CLIENTE */}
      <Drawer
        title={"Cliente"}
        width={wDrawerx}
        closable={true}
        maskClosable={false}
        onClose={hideDrawerFormCliente}
        open={drawerFormClienteVisible}
        headerStyle={{ borderRadius: "0" }}
        bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
      >
        <div className="inner-drawer-body">
          <Form layout="vertical" form={formCliente} onFinish={handleSubmitFormCliente}>
            {clientes.map((cliente: any, index: any) => {
              return <CardCliente cliente={cliente} wCard="w-full" />;
            })}
            <hr className="my-2 border-2" />
            <Form.Item name="id" hidden noStyle></Form.Item>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="gcId"
                  label="Gerente de Conta"
                // rules={[{ required: true, message: 'Selecione Um Pai' }]}
                >
                  <Select placeholder="Gerente de Conta" showSearch optionFilterProp="children" popupClassName="border-solid border border-blue-500">
                    <Option value={''}>Sem Gerente de Conta</Option>
                    {users.map((user: any, index: any) => {
                      return <Option value={user.id} className="text-blue-800 font-semibold"> {user.nome}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="statusCrmId"
                  label="Status"
                // rules={[{ required: true, message: 'Selecione Um Pai' }]}
                >
                  <Select placeholder="Status do Contato" showSearch optionFilterProp="children" popupClassName="border-solid border border-blue-500">
                    <Option value={''}>Nenhum contato</Option>
                    {statusCrm.map((status: any, index: any) => {
                      return <Option value={status.id} className="text-blue-800 font-semibold"> {status.nome}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="obsInterna"
                  label="Observações Internas"
                // rules={[{ required: true, message: 'Quantidade Obrigatória' }]}
                >
                  <TextArea placeholder="Observações" rows={6} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <Space className="drawer-footer flex w-full justify-end">
          <Space>
            <Button hidden={buttonsDisabled} onClick={hideDrawerFormCliente}>Cancel</Button>
            {/* <Button onClick={resetFormFichaTecnicaItem}>Reset</Button> */}
            {/* <Space className="w-[500px]">x</Space> */}

            <Button onClick={formCliente.submit} hidden={buttonsDisabled} type="primary">Submit</Button>
          </Space>
        </Space>
      </Drawer>
    </Layout>

  )

}