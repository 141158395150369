import { Button, Modal } from "antd"
import { CardCliente } from "../../__Shared/Clientes/CardCliente";
import { apierp } from "../../../services/api";
import { alterarClienteBling, cadastrarClienteBling } from "./functions";
import { useState } from "react";

export default function ModalSincronizarBling(props: any) {
    const { clientesToSync, showModalSincronizarBling, hideModalSincronizarBling, pesquisar } = props
    const clienteFrom: any = clientesToSync?.clienteFrom
    const clienteTo: any = clientesToSync?.clienteTo
    const blingTo: any = clientesToSync?.blingTo
    const [btnEnabled, setBtnEnabled] = useState(true)
    const isInclusao = clienteTo?.id === undefined // pode ser undefined -> não existe cliente no bling -> fazer inclusão no blingTo

    const atualizar = async () => {
        setBtnEnabled(false)
        const cliente = {
            nome: clienteFrom.nome,
            codigo: clienteFrom.codigo,
            situacao: clienteFrom.situacao,
            numeroDocumento: clienteFrom.numeroDocumento, // cnpj ***
            telefone: clienteFrom.fone,
            celular: clienteFrom.celular,
            fantasia: clienteFrom.nomeFantasia,
            tipo: clienteFrom.tipoPessoa,
            indicadorIe: clienteFrom.indicadorIe,
            ie: clienteFrom.ie,
            rg: clienteFrom.rg,
            orgaoEmissor: clienteFrom.orgaoEmissor, // orgaoEmissor ***
            email: clienteFrom.email,
            endereco: {
                geral: {
                    endereco: clienteFrom.endereco,
                    cep: clienteFrom.cep,
                    bairro: clienteFrom.bairro,
                    municipio: clienteFrom.municipio, // cidade ***
                    uf: clienteFrom.uf,
                    numero: clienteFrom.numero,
                    complemento: clienteFrom.complemento
                },
            },
        }

        if (isInclusao) {
            await cadastrarClienteBling(cliente, +blingTo?.codigo)
            pesquisar()

        } else {
            await alterarClienteBling(cliente, +blingTo?.codigo, clienteTo?.id)
            pesquisar()
        }
        hideModalSincronizarBling()
        setBtnEnabled(true)
    }

    return (
        <Modal title="Sincronizar Bling" open={showModalSincronizarBling} onOk={hideModalSincronizarBling} onCancel={hideModalSincronizarBling} footer={[]} centered width={800}>
            <div id="printableModal" className="">
                <div className="flex ml-2">
                    <div>Origem:</div>
                    <div className="ml-2 font-bold">{clienteFrom?.empresaNome.toUpperCase()}</div>
                    <div className="ml-4">Destino:</div>
                    <div className="ml-2 font-bold">{blingTo?.nome.toUpperCase()}</div>
                </div>
                <div className="">
                    {/* The Modal Content */}
                    <CardCliente cliente={clienteFrom} wCard="w-full" />
                </div>

                <div className={`botoes flex`}>
                    <Button className="mr-2" onClick={hideModalSincronizarBling}>Cancelar</Button>
                    <Button disabled={!btnEnabled} type="primary" className="mr-2" onClick={atualizar}>Atualizar</Button>
                </div>
            </div>
        </Modal>
    )
}