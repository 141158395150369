import { dateMySqlToPtBr } from "../../../utils/dateFunctions";
import { formatDecimal, formatLength } from "../../../utils/functions";
import { getColumnSearchProps } from "../../../components/columnSearchProps";

export function columnsOrdemProducao(props: any) {
  // export const columnsOrdemProducao = 
  return [
    // {
    //   title: 'Id',
    //   dataIndex: 'id',
    //   ellipsis: true,
    //   width: '40px',
    //   className: 'text-right',
    // },
    {
      title: 'Situação',
      dataIndex: 'situacao',
      ellipsis: true,
      width: '120px',
      className: 'text-left',
      filters: [
        {
          text: 'aberta',
          value: 'aberta',
        },
        {
          text: 'finalizada',
          value: 'finalizada',
        },
        {
          text: 'revertida',
          value: 'revertida',
        },
      ],
      filterMode: 'menu',
      defaultFilteredValue: ['aberta', 'revertida'],
      onFilter: (value: string, record: any) => record.situacao.includes(value),
    },
    {
      title: 'Numero',
      dataIndex: 'numero',
      ellipsis: true,
      width: '100px',
      className: 'text-left',
      sorter: (a: any, b: any) => a.numero.localeCompare(b.numero),
    },
    {
      title: 'Codigo',
      dataIndex: 'codigo',
      ellipsis: true,
      width: '87px',
      className: 'text-left',
      sorter: (a: any, b: any) => a.codigo.localeCompare(b.codigo),
    },
    {
      title: 'Produto',
      dataIndex: 'descricao',
      width: '600px',
      className: 'text-left',
      ellipsis: false,
      ...getColumnSearchProps({
        dataIndex: 'descricao',
        ...props
      })
    },
    {
      title: 'Qtd Produzida',
      dataIndex: 'quantProduzida',
      width: '120px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      // render: (text: any, record: any) => `${formatLength(+text, 8)}`
      render: (text: any, record: any) => `${(+(text.toFixed(6))).toString().replace('.', ',')}`
    },
    {
      title: 'Data',
      dataIndex: 'dataConclusao',
      width: '100px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      render: (text: any, record: any) => `${dateMySqlToPtBr(text)}`
    },
    {
      title: 'Operador',
      dataIndex: 'updatedBy',
      width: '120px',
      align: 'left' as 'left',
      className: 'text-left',
      ellipsis: true,
      // render: (text: any, record: any) => `${text}`
    },

  ]
}