import Layout from "../../../../components/template/Layout";
import { Button, Col, Divider, Drawer, Form, Input, InputNumber, InputRef, Row, Space, Typography, message } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import useWindowSize from "../../../../hooks/useWindowSize";
import { useAuth } from "../../../../contexts/auth";
import { dateFormat, dateMySqlToPtBr } from "../../../../utils/dateFunctions";
import SpinnerNew from "../../../../components/template/SpinnerNew"
import { useNavigate } from "react-router-dom";
import { copyToClipboard, downloadToCsv, formatDecimal, groupBy, printDiv } from "../../../../utils/functions";
import Tabela from "../../../../components/template/Tabela";
import { FilterConfirmProps } from "antd/lib/table/interface";
import useEstoqueDivididoByDateSpedGrouped from "./useEstoqueDivididoByDateSpedGrouped";
import { tipositem } from "../../../../data/dados"
import { groupByTs } from "../../../../utils/tsFunctions";

const { Title, Text, Paragraph } = Typography;

export default function EstoqueDivididoByDate() {
  const [mesref, setMesRef] = useState(null as any)

  const { estoqueDividido, empresas, processando, reload } = useEstoqueDivididoByDateSpedGrouped(mesref)

  // const now = new Date('2024-01-01')
  const now = new Date()
  useEffect(() => {
    // console.log('useEffect unico')
    setMesRef(now)
  }, [])

  // console.log(pedidosPendentesSaida)
  // console.log(caixas)
  const { Logout, user } = useAuth();
  const usuario: any = user
  const [innerW, innerH] = useWindowSize();

  const [deliveryDate, setDeliveryDate] = useState(new Date(dateFormat(new Date(), 'yyyy-MM-dd 12:00:00')));

  const [buttonsDisabled, setButtonsDisabled] = useState(false)

  const [toggleState, setToggleState] = useState(false)

  let navigate = useNavigate();

  async function handleRow(id: any, index: any) {
  }


  // search filter produto
  interface DataType {
    descricao: string;
    nomeComponente: string;
  }
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [selectedKeys, setSelectedKeys] = useState('');
  const searchInput = useRef<InputRef>(null);
  type DataIndex = keyof DataType;
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };
  //end

  const handleChangeDate = (newDate: any) => {
    // console.log(newDate, typeof newDate)
    setToggleState(!toggleState)
  }
  const dateChange = (date: any) => {
    setDeliveryDate(date)
    // setHideButtonFinaliza(true)
  }

  function enableButtons(wait: number) {
    setTimeout(function () {
      setButtonsDisabled(false)
      // setMsgError('')
    }, wait);
  }
  function disableButtons() {
    setButtonsDisabled(true)
  }

  const dadosGroup = groupByTs(estoqueDividido, ['spedTipoItem'], ['vlrestf1', 'vlrestf2', 'vlrestft','vlrestv2f1', 'vlrestv2f2', 'vlrestv2ft'])
  dadosGroup.forEach((it: any) => {
    const xxx = tipositem.find(ti => ti.value === it.spedTipoItem)
    it.spedTipoItem = xxx?.label
    // console.log(xxx?.label)
  })
  dadosGroup.sort((a: any, b: any) => (a.spedTipoItem < b.spedTipoItem ? -1 : a.spedTipoItem === b.spedTipoItem ? 0 : 1))

  const wTab = 370 // + 8 // liquido = x-8
  const htb = `calc(100vh - 149px - 110px - 26px)`
  const hsc = `calc(100vh - 180px - 109px - 26px)` // 1 a menos que       

  let corPicker = 'bg-blue-600'
  if (mesref !== null) {
    corPicker = dateFormat(mesref, 'yyyy-MM-dd') === dateFormat(now, 'yyyy-MM-dd') ? 'bg-blue-600' : 'bg-red-600'
  }

  let totalGeral = 0
  let totalGeralV2 = 0
  return (
    <Layout titulo={'Estoque por Empresa'} botoes={null} >
      <div className="mx-auto">
        <DatePicker
          // wrapperClassName="btmonth"
          className={`w-[88px] mb-[3px] text-center uppercase border-none rounded-md font-medium py-1 cursor-pointer text-white ${corPicker}`}
          selected={mesref}
          locale="pt-BR"
          onChange={(date: any) => setMesRef(date)}
          dateFormat="dd/MM/yyyy"
          // maxDate={new Date('2024-01-01')}
          todayButton="Hoje"
          popperPlacement="bottom"
        />
      </div>
      <SpinnerNew loading={processando} htb={htb} />
      {processando ? (
        null
      ) : dadosGroup.length > 0 ? (

        <div className="overflow-x-auto mb-5">
          <div className="flex flex-col ">
            {empresas.map((e: any, i) => {
              let total = 0
              let totalV2 = 0
              return (
                <div className="flex flex-col mx-auto " style={{ maxWidth: `${wTab}px`, minWidth: `${wTab}px` }}>
                  <div className="mx-4">
                    <h2 className="mt-6 mb-1 text-xl">{e}</h2>
                    <h4 className="mt-0 mb-1">Estoque em: {dateFormat(mesref, 'dd/MM/yyyy')}</h4>
                    <div className="flex bg-[#0f172a] px-3 pt-1 mb-0 justify-between">
                      <h4 className="w-[180px] text-white text-left ">Tipo de Produto</h4>
                      {/* <h4 className="w-[120px] text-white text-right ">Estoque</h4> */}
                      <h4 className="w-[120px] text-white text-right ">Estoque(~C)</h4>
                    </div>
                    {
                      dadosGroup.map((it: any) => {
                        const vlrEstoqueEmp = i === 1 ? it.vlrestf1 : it.vlrestf2
                        total += vlrEstoqueEmp
                        totalGeral += vlrEstoqueEmp
                        
                        const vlrEstoqueV2Emp = i === 1 ? it.vlrestv2f1 : it.vlrestv2f2
                        totalV2 += vlrEstoqueV2Emp
                        totalGeralV2 += vlrEstoqueV2Emp
                        return (
                          <div className="flex px-3 pt-1 border-solid border-0 border-b justify-between border-[#b0d2ff]">
                            <h4 className="w-[180px] text-left ">{it.spedTipoItem}</h4>
                            {/* <h4 className="w-[120px] text-right ">{formatDecimal(vlrEstoqueEmp, 2)}</h4> */}
                            <h4 className="w-[120px] text-right ">{formatDecimal(vlrEstoqueV2Emp, 2)}</h4>
                          </div>
                        )
                      })
                    }
                    <div className="flex bg-[#bcd1ff] px-3 pt-1 mb-2 justify-between">
                      <h4 className="w-[180px] text-left ">Total</h4>
                      {/* <h4 className="w-[120px] text-right ">{formatDecimal(total, 2)}</h4> */}
                      <h4 className="w-[120px] text-right ">{formatDecimal(totalV2, 2)}</h4>
                    </div>
                  </div>
                </div>
              )
            })
            }
          </div>
          <div className="flex flex-col mx-auto " style={{ maxWidth: `${wTab}px`, minWidth: `${wTab}px` }}>
            <div className="flex bg-[#03349e] mx-4 px-3 pt-1 mt-6 mb-2 justify-between">
              <h4 className="w-[180px] text-left  text-white">Total Geral em: {dateFormat(mesref, 'dd/MM/yyyy')}</h4>
              {/* <h4 className="w-[120px] text-right  text-white">{formatDecimal(totalGeral, 2)}</h4> */}
              <h4 className="w-[120px] text-right  text-white">{formatDecimal(totalGeralV2, 2)}</h4>
            </div>
          </div>

        </div>

      ) : (
        <div>Dados não encontrados</div>
      )}
    </Layout>
  );
}