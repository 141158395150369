import { useCallback, useEffect, useState } from 'react'
import useProcessando from '../../../data/hooks/useProcessando'
import { apierp } from '../../../services/api'

export default function useCatMes(ano: number, mes: number, categoria: string) {
    const { processando, iniciarProcessamento, finalizarProcessamento } = useProcessando()
    const [catMes, setCatMes] = useState<any[]>([])
    // console.log('entrou useCatMes', ano, mes, categoria)
    // setCatMes([])

    const obterCatMes = useCallback(async function () {
        if (!mes || !categoria) {
            setCatMes([])
            return
        }
        // console.log('entrou useCallback', ano, mes, categoria)
        try {
            iniciarProcessamento()
            const body = {
                ano,
                mes,
                categoria
            }
            const response = await apierp.post('relatorios/catmes', body)
            const data = await response.data as any;
            setCatMes(data)
            // console.log(data)
        } finally {
            finalizarProcessamento()
        }
    }, [ano, mes, categoria, iniciarProcessamento, finalizarProcessamento])

    function voltar() {
        setCatMes([])
    }

    // useEffect(() => {
    //     obterCatMes()
    // }, [ano, mes, categoria])
    useEffect(() => {
        // console.log('useEffect')
        setCatMes([])

        obterCatMes()
    }, [obterCatMes])

    return {
        catMes,
        processando,
        voltar
    }

}