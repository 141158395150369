import { useMutation, useQueryClient } from "@tanstack/react-query"
import { apierp } from "../../services/api"


const submit = async (dados: any ) => {
    const dataPagamento = new Date().toISOString()
    // console.log('dataPagamento======', dataPagamento, dados)
    const dadosx = {
        "metodo": "confirmarPagamentoParcela",
        "param": {
            id: dados.id,
            parcelaId: dados.parcelaId,
            dataPagamento: dataPagamento,
            valorPago: dados.valorPago,
        }
    }
    const response = await apierp.post('pedidosvenda', dadosx)
    // console.log('response', response)
    return response?.data
}

export function useMutateConfirmarPagamentoParcela() {
    const queryClient = useQueryClient()
    const mutate = useMutation({
        mutationFn: submit,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['pedidovenda']})
            queryClient.invalidateQueries({ queryKey: ['pedidosvenda']})
        }
    })
    

    return mutate
}
