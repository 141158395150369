import { useCallback, useEffect, useState } from 'react'
import useProcessando from '../../../data/hooks/useProcessando'
import { apierp } from '../../../services/api'
import { groupBy } from '../../../utils/functions'

export default function useVendasVendedor(mesref: any, vendedor: string) {
  const { processando, iniciarProcessamento, finalizarProcessamento } = useProcessando()
  // const [pedidos, setPedidos] = useState<any[]>([])
  // const [empresas, setEmpresas] = useState<any[]>([])
  // const [pedidosItens, setPedidosItens] = useState<any[]>([])

  // const [totaisProdutosMes, setTotaisProdutosMes] = useState([])
  // const [totaisVendedor, setTotaisVendedor] = useState([])

  const [dados, setDados] = useState({} as any)

  // console.log('entrou em useVendasVendedor')

  const buscarDados = useCallback(async function () {
    // console.log('entrou em buscar dados')
    if (mesref === null) return
    const ano = mesref.getFullYear()
    const mes = mesref.getMonth() + 1
    try {
      iniciarProcessamento()
      // console.log('Buscar dados:', ano, mes)
      const response1 = await apierp.get(`blingpedidosvendav2produtosvendedores/${ano}/${mes}?vendedor=${vendedor}`)
      const data1 = await response1.data as any;

      const response2 = await apierp.get('empresas')
      const data2 = await response2.data as any;
      const arr = []
      for (let emp of data2) {
        arr[emp.id] = emp.nome
      }

      // const totaisProdutosMes = await groupBy(data1.pedidosItens, ['vendedor', 'mes', 'codigo', 'produto'], ['quantidade', 'totalProd'])
      //   .sort((a: any, b: any) => (a.produto < b.produto ? -1 : a.produto === b.produto ? 0 : 1));

      const totaisProdutos = await groupBy(data1.pedidosItens, ['vendedor', 'codigo', 'produto'], ['quantidade', 'totalProd'])
        .sort((a: any, b: any) => (a.produto < b.produto ? -1 : a.produto === b.produto ? 0 : 1));

      const totaisVendedor = await groupBy(totaisProdutos, ['vendedor',], ['quantidade', 'totalProd'])
        .sort((a: any, b: any) => (a.vendedor < b.vendedor ? -1 : a.vendedor === b.vendedor ? 0 : 1));

      const totaisProdutoCidade = await groupBy(data1.pedidosItens, ['vendedor', 'cidade', 'uf', 'codigo', 'produto'], ['quantidade', 'totalProd'])
        .sort((a: any, b: any) => (a.cidade < b.cidade ? -1 : a.cidade === b.cidade ? 0 : 1))
        .sort((a: any, b: any) => (a.uf < b.uf ? -1 : a.uf === b.uf ? 0 : 1))

      const totaisCidadeProduto = await groupBy(data1.pedidosItens, ['vendedor', 'cidade', 'uf', 'codigo', 'produto'], ['quantidade', 'totalProd'])
        .sort((a: any, b: any) => (a.produto < b.produto ? -1 : a.produto === b.produto ? 0 : 1));

      const totaisCidade = await groupBy(totaisCidadeProduto, ['vendedor', 'cidade', 'uf'], ['totalProd'])
        .sort((a: any, b: any) => (a.cidade < b.cidade ? -1 : a.cidade === b.cidade ? 0 : 1))
        .sort((a: any, b: any) => (a.uf < b.uf ? -1 : a.uf === b.uf ? 0 : 1))

      setDados({
        pedidos: data1.pedidos,
        pedidosItens: data1.pedidosItens,
        empresas: arr,
        totaisProdutos,
        totaisVendedor,
        totaisProdutoCidade,
        totaisCidadeProduto,
        totaisCidade
      })

    } catch (err) {
      console.log(err)
    } finally {
      finalizarProcessamento()
    }

  }, [mesref])

  // function voltar() {
  //   setPedidos([])
  //   setEmpresas([])
  //   setPedidosItens([])
  // }

  function reload() {
    // setPedidos([])
    // setEmpresas([])
    // setPedidosItens([])
    setDados({
      // pedidos: [],
      // pedidosItens: [],
      // empresas: [],
      // totaisVendedor: [],
      // totaisProdutosMes: [],
    })
    buscarDados()
  }

  // useEffect(() => {
  //   setPedidos([])
  //   setEmpresas([])
  //   setPedidosItens([])
  //   buscarDados()
  // }, [])

  return {
    dados,
    // pedidos,
    // pedidosItens,
    // empresas,
    // totaisVendedor,
    // totaisProdutosMes,
    processando,
    iniciarProcessamento,
    finalizarProcessamento,
    // voltar,
    reload
  }

}