import { ColumnsType } from "antd/lib/table";
import { formatDecimal } from "../../../utils/functions";
import { Input } from "antd";
import { getColumnSearchProps } from "../../../components/columnSearchProps";
import { WarningOutlined } from '@ant-design/icons';

// interface DataType {
//   // key: React.Key;
//   categoria: string;
//   codigo: string;
//   descricao: string;
//   unidade: string;
//   saldoAcumulado: number;
//   cmcUnitario: number;
//   cmcTotal: number;
// }

export function columnsProduto(props: any) {
  const filterCategorias = props.categorias.map((item: any) => {
    return { text: item, value: item }
  })
  return [
    {
      title: 'Categoria',
      dataIndex: 'categoria',
      ellipsis: true,
      width: '140px',
      className: 'text-left',
      filters: filterCategorias,
      filterMode: 'menu',
      // filterSearch: true,
      onFilter: (value: string, record: any) => record.categoria.includes(value),
    },
    {
      title: 'Codigo',
      dataIndex: 'codigo',
      ellipsis: true,
      width: '97px',
      className: 'text-left',
      sorter: (a: any, b: any) => a.codigo.localeCompare(b.codigo),
      ...getColumnSearchProps({
        dataIndex: 'codigo',
        ...props
      })
    },
    {
      title: 'Produto',
      dataIndex: 'descricao',
      width: '500px',
      className: 'text-left',
      ellipsis: false,
      sorter: (a: any, b: any) => a.descricao.localeCompare(b.descricao),
      ...getColumnSearchProps({
        dataIndex: 'descricao',
        ...props
      }),
      render: (text: any, record: any) => `${text}`
    },
    {
      title: 'SP',
      dataIndex: 'spedTipoItem',
      width: '55px',
      className: 'text-left',
      ellipsis: true,
      // sorter: (a: any, b: any) => a.spedTipoItem.localeCompare(b.spedTipoItem),
      ...getColumnSearchProps({
        dataIndex: 'spedTipoItem',
        ...props
      }),
      render: (text: any, record: any) => `${text}`
    },
    {
      title: 'UN',
      dataIndex: 'unidade',
      width: '40px',
      className: 'text-left',
      ellipsis: true,
      render: (text: any, record: any) => `${text}`
    },
    {
      title: 'Quant',
      dataIndex: 'saldoAcumulado',
      width: '100px',
      key: "saldoAcumulado",
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      sorter: (a: any, b: any) => a.saldoAcumulado - b.saldoAcumulado,
      onCell: (record: any, i: any) => ({ className: record.saldoAcumulado < 0 ? "text-red-600 font-semibold" : record.alertEstMin ? "xtext-yellow-400 font-semibold" : "text-blue-500" }),
      render: (text: any, record: any) => `${record.unidade === 'UN' ? +text : formatDecimal(+text, 3)}` // formatDecimal(+text, 0)
    },
    {
      title: '<',
      dataIndex: 'alertEstMin',
      width: '35px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      // sorter: (a: any, b: any) => a.alertEstMin.localeCompare(b.alertEstMin),
      onCell: (record: any, i: any) => ({ className: record.alertEstMin ? "text-yellow-500 font-extrabold" : " " }),
      render: (text: any, record: any) => `${record.alertEstMin ? "▲" : ""}`,
      // render: (text: any, record: any) => <WarningOutlined /> ,
      filters: [
        {
          text: '▲',
          value: '▲',
        },
      ],
      filterMode: 'menu',
      onFilter: (value: string, record: any) => record.alertEstMin === "*",
    },
    {
      title: 'CmcUnit.',
      dataIndex: 'cmcUnitario',
      width: '80px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },
    {
      title: 'CmcTotal',
      dataIndex: 'cmcTotal',
      width: '110px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: record.saldoAcumulado < 0 ? "text-red-600 font-semibold" : " " }),
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },
    {
      title: 'Est.Min.',
      dataIndex: 'estoqueMinimo',
      width: '90px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: record.alertEstMin ? "xbg-red-200 font-semibold" : " " }),
      render: (text: any, record: any) => `${formatDecimal(+text, 0)} ${record.unidade}`
    },
    {
      title: 'Prz.Rep.',
      dataIndex: 'prazoReposicao',
      width: '70px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      render: (text: any, record: any) => `${formatDecimal(+text, 0)} D`
    },

    {
      title: 'Situação',
      dataIndex: 'situacao',
      width: '80px',
      className: 'text-left',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: record.situacao === 'Inativo' ? "text-red-400 font-semibold" : "" }),
      render: (text: any, record: any) => `${text}`,
      filters: [
        {
          text: 'Ativo',
          value: 'Ativo',
        },
        {
          text: 'Inativo',
          value: 'Inativo',
        },
      ],
      filterMode: 'menu',
      defaultFilteredValue: ['Ativo'],
      onFilter: (value: string, record: any) => record.situacao.includes(value) || record.situacao === '',
    },
  ]
}