import { useEffect, useState } from "react"
import { LockFilled, UnlockFilled, CheckSquareFilled, DownloadOutlined, CopyOutlined, PrinterOutlined, PlusOutlined } from '@ant-design/icons';

import { useAuth } from "../../../contexts/auth";
import { apierp } from '../../../services/api';
import { customMessage } from "../../../components/template/CustomMessage";

import { Button, Col, Drawer, Form, Input, message, Popconfirm, Radio, Row, Select, Space, Table, Typography } from 'antd';
import Spinner from "../../../components/template/Spinner";
import useWindowSize from "../../../hooks/useWindowSize";
import { NoStyleItemContext } from "antd/lib/form/context";
import { copyToClipboard, downloadToCsv, printDiv } from "../../../utils/functions";
import Layout from "../../../components/template/Layout";
import { useGeral } from "../../../contexts/geral";
const { Title, Text, Paragraph } = Typography;

export default function StatusCrm() {
  const { Logout, user } = useAuth();
  const usuario: any = user
  const { userMenu, menuSelected, userTenant, } = useGeral();
  const menu = userMenu.find((x: any) => x.id == menuSelected)
  
  const { Option } = Select;
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [toggleState, setToggleState] = useState(false)
  
  const [rowSelected, setRowSelected] = useState(-1)
  const [lastSelected, setLastSelected] = useState(-1)
  const [isInclusao, setIsInclusao] = useState(false)

  const [statusCrms, setStatusCrms] = useState([])
  const [statusCrm, setStatusCrm] = useState(null as any)
  // const [statusCrmBlocked, setStatusCrmBlocked] = useState(true)
  const [buttonsDisabled, setButtonsDisabled] = useState(false)

  useEffect(() => {
    setLoading(true)
    setTimeout(async function(){ 
      try{
        await loadStatusCrms() 
      } catch(err) {
        if (customMessage(err)===403) Logout()
      }
      setLoading(false) 
    }, 500);
  }, [toggleState])

  async function loadStatusCrms() {
    const response = await apierp.get('statuscrm')
    const data = response.data as any;
    setStatusCrms(data);
  }

  function handleRow(record: any, index: any) {
    setLastSelected(index)
    if (rowSelected === index) {
      setRowSelected(-1)
      // setStatusCrmBlocked(true)
    } else {
      setRowSelected(index)
      setIsInclusao(false)
      showDrawer()
      const statusCrmy: any = {
        id: record.id,
        nome: record.nome,
      }
      fillForm(statusCrmy)
    }
  }

  const showDrawer = () => {
    setButtonsDisabled(false)
    setDrawerVisible(true)
  };

  const hideDrawer = () => {
    setButtonsDisabled(false)
    setRowSelected(-1)
    setStatusCrm(null)
    setDrawerVisible(false)
  };

  async function handleSubmit(dados: any){
    setButtonsDisabled(true)
    dados.nome = dados.nome.toUpperCase().trim().replaceAll('  ', ' ')
    if (dados.id ==='') {
      //CREATE
      try {
        const result = await apierp.post('statuscrm', dados);
        setToggleState(!toggleState)
        message.success('Status incluido com sucesso!');

        hideDrawer()
      } catch (err) {
        if (customMessage(err)===403) Logout()
      }
    } else {
      //UPDATE
      try {
        const result = await apierp.put(`statuscrm/${dados.id}`, dados);
        setToggleState(!toggleState)
        message.success('Status alterado com sucesso!');
        hideDrawer()
      } catch (err) {
        if (customMessage(err)===403) Logout()
      }
    }

  };

  async function handleDelete(){
    const dados = form.getFieldsValue()
    try {
      await apierp.delete(`statuscrm/${dados.id}`);
      setToggleState(!toggleState)
      setLastSelected(-1)
      message.success('Status excluido com sucesso!');
      hideDrawer()
    } catch (err) {
      if (customMessage(err)===403) Logout()
    }

  };

  function confirmDelete(e: any) {
    handleDelete();
  }
  
  function cancelDelete(e: any) {
    message.error('Status não foi excluído');
  }

  const novaStatusCrm= () => {
    setIsInclusao(true)
    // setStatusCrmBlocked(false)
    showDrawer()
    const statusCrmy: any = {
      id: '',
      nome: '',
    }
    fillForm(statusCrmy)
  }

  const fillForm = (statusCrmy: any) => {
    form.setFieldsValue({
      id: statusCrmy.id,
      nome: statusCrmy.nome,
    });
  };

  const resetForm = () => {
    setRowSelected(-1)
    novaStatusCrm() // form.resetFields();
  };

  const columns = [
    // {
    //   width: '10px',
    //   onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" })
    // },
    {
      title: 'Id',
      dataIndex: 'id',
      ellipsis: true,
      width: '20px',
      className: 'text-left',
      // onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" })
    },
       {
      title: 'Nome',
      dataIndex: 'nome',
      ellipsis: true,
      width: '240px',
      className: 'text-left',
      // onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" })
    },
  ]

  const botoes = () => {
    return (
      <Space size={10}>
        <Button onClick={() => printDiv('printableDiv-status', 'status')} shape="round" size="small" icon={<PrinterOutlined />} title="Imprimir"></Button>
        <Button onClick={() => copyToClipboard('status')} shape="round" size="small" icon={<CopyOutlined />} title="Copy to Clipboard"></Button>
        <Button onClick={() => downloadToCsv('status', "Status.csv")} shape="round" size="small" icon={<DownloadOutlined />} title="Download CSV"></Button>
        <Button onClick={novaStatusCrm} type="primary" shape="round" size="small" icon={<PlusOutlined />} title="Novo"></Button>
      </Space>
    )
  }

  const wTab = 420 // liquido = x-17
  const htb = `calc(100vh - 149px - 110px)`
  const hsc = `calc(100vh - 180px - 109px)` // 1 a menos que htb

  return (
    <Layout titulo={menu.label} botoes={botoes()}>
      <div className="spinner mt-5 justify-center items-center " style={{ display: loading ? "flex" : "none" }} >
        <Spinner />
      </div>
      <div className="bg-blue-30 mx-5 overflow-x-auto">
        <div className="flex flex-row justify-center items-center mx-auto " style={{ maxWidth: `${wTab}px`, minWidth: `${wTab}px` }}>
          <div id="printableDiv-status" className="pt-6  pb-[1px] fadein" style={{ display: loading ? "none" : "" }}>
            <h1 className='hidden mb-1 text-2xl font-bold'>Status CRM</h1>
            {/* TABLE */}
            <div className="flex flex-col justify-between bg-[#f8f9fb]" style={{ maxHeight: htb }}> {/** ou heigth */}
              <div className="envelopetable ">
                <Table id='status' dataSource={statusCrms} columns={columns} size="small" rowKey="id"
                  rowClassName={(record, index) => (index === rowSelected ? "rowSelected" : "")}
                  pagination={false}
                  scroll={{ y: hsc }}
                  onRow={(record: any, rowIndex: any) => {
                    return {
                      onClick: event => { handleRow(record, rowIndex) }, // click row
                      className: lastSelected === rowIndex ? "lastSelected" : ""
                    };
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* DRAWER FORM */}
      <Drawer
        title={ (isInclusao ? "Criar um novo" : "Alterar") + " status"}
        width={360}
        closable={false}
        maskClosable={false}
        onClose={hideDrawer}
        visible={drawerVisible}
        headerStyle={{ borderRadius: "0" }}
        bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
      >
        <div className="inner-drawer-body">
          <Form layout="vertical" form={form} onFinish={handleSubmit} >
            <Title level={5}>{form.getFieldValue('nome')}</Title>
            <Form.Item name="id" hidden noStyle></Form.Item>
           
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="nome"
                  label="Nome"
                  rules={[{ required: true, message: 'Entre com Nome do status' }]}
                >
                  <Input placeholder="Nome do status" />
                </Form.Item>
              </Col>
            </Row>

          </Form>
        </div>
        <Space className="drawer-footer">
          <Space>
            <Button onClick={hideDrawer}>Cancel</Button>
            {/* <Button onClick={resetForm}>Reset</Button> */}
            <Popconfirm 
              title="Deseja realmente excluir este status?"
              onConfirm={confirmDelete}
              onCancel={cancelDelete}
              okText="Sim"
              cancelText="Não"
            >
              <Button onClick={() => {}} type="primary" danger disabled={isInclusao || buttonsDisabled} >Delete</Button>
            </Popconfirm>

            <Button onClick={form.submit} disabled={ buttonsDisabled} type="primary">Submit</Button>
          </Space>
        </Space>
      </Drawer>
    </Layout>
  )
}
