import { Modal } from "antd"
import { CardCliente } from "./CardCliente";
import { CardClienteResumo } from "./CardClienteResumo";

export default function ModalClienteDetalhe(props: any) {
    const { cliente, resumo, showModalCliente, hideModalCliente, width = 1040 } = props

    return (
        <Modal title={props.title} open={showModalCliente} onOk={hideModalCliente} onCancel={hideModalCliente} footer={[]} centered width={width}>
            <div id="printableModal" className="">
                <div className="text-center">
                    {/* The Modal Content */}
                    {/* <CardCliente cliente={cliente} wCard="w-full" />
                    <CardClienteResumo cliente={cliente}  resumo={resumo} /> */}
                    <div className="flex gap-2 min-h-[192px] bg-slate-50" >
                        <CardCliente cliente={cliente} wCard={'w-[680px]'} />
                        <CardClienteResumo cliente={cliente} resumo={resumo} />
                    </div>
                </div>
            </div>
        </Modal>
    )
}