import { Button, Drawer, Form, Input, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { formatDecimalZ, limpaInteiro, limpaNumeroBr } from "../../utils/functions";
import { columnsEstoqueDividido } from "../Suprimentos/EstoqueDividido/columnsEstoqueDividido";

const { Option } = Select;

export default function DrawerFormItemPedidoVenda(props: any) {

    const [buttonsDisabled, setButtonsDisabled] = useState(false)
    const [savebtnDisabled, setSavebtnDisabled] = useState(false)
    const [formItemPedidoVenda] = Form.useForm();

    const { drawerFormItemPedidoVendaVisible, hideDrawerFormItemPedidoVenda, operacao,
        index, itensPedidoVenda, setItensPedidoVenda, produtos, calculaTotalPedido
    } = props

    // console.log('==itemPedidoVenda',index, itensPedidoVenda[index])
    // console.log('itensPedidoVenda', itensPedidoVenda)
    const item = itensPedidoVenda[index]
    const fieldsItem = {
        id: item?.id || '',
        index: index,
        produtoId: item?.produtoId || '',
        // descricao: item?.descricao || '',
        // codigo: item?.codigo || '',
        // unidade: item?.unidade || '',
        quantidade: item?.quantidade || '',
        precoLista: item?.precoLista.toFixed(2).toString().replace('.', ',') || '' || '',
        precoUnitario: item?.precoUnitario.toFixed(2).toString().replace('.', ',') || '',
        // precoTotal: item?.precoTotal || '',
    }
    // console.log('fieldsItem', fieldsItem)

    
    const fillFormItemPedidoVenda = (fields: any) => {
        formItemPedidoVenda.setFieldsValue(fields)
    }

    useEffect(() => {
        fillFormItemPedidoVenda(fieldsItem)
    }, [drawerFormItemPedidoVendaVisible])

    const handleCancel = () => {
        // formPedidoVenda.resetFields()
        hideDrawerFormItemPedidoVenda()
    }

    const handleSubmitFormItemPedidoVenda = async (values: any) => {
        const index = values.index
        const produto = await produtos.find((x: any) => x.id === values.produtoId)
        // console.log('======produto', produto)
        const item = {
            id: values.id,
            produtoId: values.produtoId,
            codigo: produto.codigo, // '', // 'codigo do produto'
            descricao: produto.descricao, // '', // 'descricao do produto'
            unidade: produto.unidade, // '', // 'unidade do produto'
            precoLista: produto.precoVenda, // 0, // 'preco de lista do produto'
            precoTotal: 0, // 'preco total do item'
            precoUnitario: +values.precoUnitario.replaceAll('.', '').replace(',', '.'),
            quantidade: values.quantidade,
        }
        item.precoTotal = item.quantidade * item.precoUnitario

        if (index > -1 )  {
            itensPedidoVenda[index] = item
        } else {
            itensPedidoVenda.push(item)
        }
        // console.log('handleSubmitFormItemPedidoVenda',index, item)
        calculaTotalPedido(itensPedidoVenda)
        hideDrawerFormItemPedidoVenda()

    }

    const changeFilelds = (changedFields: any, allFields: any) => {
        // console.log('changedFields', changedFields)
    }

    function onBlur(e: any, name: string) {
        // console.log('onBlur', e.target.value, name)
        formItemPedidoVenda.setFieldValue(name, formatDecimalZ(e.target.value.replaceAll('.', '').replace(',', '.'), 2))
    }

    function handleChangeInputNumber(e: any, name: string) {
        // console.log('handleChangeInputNumber', e.target.value, name)
        formItemPedidoVenda.setFieldValue(name, limpaNumeroBr(e.target.value))
    }
    function handleChangeInputInt(e: any, name: string) {
        // console.log('handleChangeInputNumber', e.target.value, name)
        formItemPedidoVenda.setFieldValue(name, limpaInteiro(e.target.value))
    }

    const handleSelectProduto = (value: any) => {
        const produto = produtos.find((x: any) => x.id === value)
        formItemPedidoVenda.setFieldValue('precoLista', formatDecimalZ(produto.precoVenda, 2))
      }

    const layoutf = {
        labelCol: { span: 3 },
        wrapperCol: { span: 21 },
    }

    return (
        <Drawer
            title={`${operacao} de Item do Pedido Venda`}
            width={720}
            closable={true}
            maskClosable={false}
            onClose={hideDrawerFormItemPedidoVenda}
            open={drawerFormItemPedidoVendaVisible}
            headerStyle={{ borderRadius: "0" }}
            bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
            className=""
        >
            <div className="inner-drawer-body">
                <Form {...layoutf} form={formItemPedidoVenda} onFinish={handleSubmitFormItemPedidoVenda} onFieldsChange={changeFilelds}>
                    <div className="flex"><h2 className="mx-auto text-red-500">Funcionalidade em Desenvolvimento</h2></div>
                    <Form.Item name="id" hidden noStyle></Form.Item>
                    <Form.Item name="index" hidden noStyle></Form.Item>
                    {/* <Form.Item name="precoLista" hidden noStyle></Form.Item> */}
                    <Form.Item
                        name="produtoId"
                        label="Produto"
                    // rules={[{ required: true, message: 'Selecione Um Pai' }]}
                    >
                        <Select placeholder="Selecione um Produto" className="w-full"
                            showSearch
                            optionFilterProp="children"
                            onChange={handleSelectProduto}
                            popupClassName="border-solid border border-blue-500">
                            {produtos.filter((x: any) => x.situacao==='Ativo').map((produto: any, index: any) => {
                                return <Option value={produto.id} className="text-blue-800 font-semibold"> {produto.codigo} - {produto.descricao}</Option>
                            })}
                        </Select>
                    </Form.Item>
                    
                    <Form.Item label="Quantidade" className="pedido2 mb-0">
                        <Form.Item name="quantidade" label="" className="inline-block mb-2"><Input onChange={(e) => handleChangeInputInt(e, 'desconto')} className="w-20" /></Form.Item>
                        <Form.Item name="precoUnitario" label="Preco Unitario" className="inline-block mb-2 ml-4"><Input onBlur={(e) => onBlur(e, 'precoUnitario')} onChange={(e) => handleChangeInputNumber(e, 'precoUnitario')} placeholder="" className="w-20" /></Form.Item>
                        <Form.Item label="Preço Lista" name="precoLista" className="inline-block mb-2 ml-4"><Input disabled onBlur={(e) => onBlur(e, 'precoLista')} onChange={(e) => handleChangeInputNumber(e, 'precoLista')} className="w-20" /></Form.Item>
                    </Form.Item>
                </Form>

            </div>
            <Space className="drawer-footer flex w-full justify-end">
                <Space>
                    <Button hidden={buttonsDisabled} onClick={handleCancel}>Cancel</Button>
                    {/* <Button onClick={formPedidoVenda.submit} hidden={buttonsDisabled} disabled={savebtnDisabled} type="primary">Salvar PV</Button> */}
                    <Button onClick={formItemPedidoVenda.submit} hidden={buttonsDisabled} disabled={savebtnDisabled} type="primary">Salvar Item</Button>
                </Space>
            </Space>
        </Drawer>
    )
}