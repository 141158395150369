import React from 'react';
import { useAuth } from '../contexts/auth';

import SignRoutes from './SignRoutes';
import OtherRoutes from './OtherRoutes';

const Routes: React.FC = () => {
  let { signed } = useAuth();
  let auth = useAuth();

  // console.log('indexxxx', window.sessionStorage.getItem('appauth'))
  // if (!signed) {
  //   signed = Boolean(window.sessionStorage.getItem('appauth'))
  // }
  
  return signed ? <OtherRoutes /> : <SignRoutes />;
};

export default Routes;
