import { useEffect, useState } from "react"
import { LockFilled, UnlockFilled, CheckSquareFilled, DownloadOutlined, CopyOutlined, PrinterOutlined, PlusOutlined } from '@ant-design/icons';

import { useAuth } from "../../../contexts/auth";
import { apierp } from '../../../services/api';
import { customMessage } from "../../../components/template/CustomMessage";

import { Button, Col, Drawer, Form, Input, message, Popconfirm, Radio, Row, Select, Space, Table, Typography } from 'antd';
import Spinner from "../../../components/template/Spinner";
import useWindowSize from "../../../hooks/useWindowSize";
import { NoStyleItemContext } from "antd/lib/form/context";
import { copyToClipboard, downloadToCsv, printDiv } from "../../../utils/functions";
import Layout from "../../../components/template/Layout";
import { useGeral } from "../../../contexts/geral";
const { Title, Text, Paragraph } = Typography;

export default function Permission() {
  const { Logout, user } = useAuth();
  const usuario: any = user
  const { userMenu, menuSelected, userTenant, } = useGeral();
  const menu = userMenu.find((x: any) => x.id == menuSelected)
  
  const { Option } = Select;
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [toggleState, setToggleState] = useState(false)
  
  const [rowSelected, setRowSelected] = useState(-1)
  const [lastSelected, setLastSelected] = useState(-1)
  const [isInclusao, setIsInclusao] = useState(false)

  const [permissions, setPermissions] = useState([])
  const [permission, setPermission] = useState(null as any)
  const [permissionBlocked, setPermissionBlocked] = useState(true)
  const [buttonsDisabled, setButtonsDisabled] = useState(false)

  const descSecurityLevel = ['root', 'master', 'admin', 'manager-0', 'manager-1', 'manager-2', 'user-nivel-6', 'user-nivel-7', 'user-nivel-8', 'user-nivel-9' ];

  useEffect(() => {
    setLoading(true)
    setTimeout(async function(){ 
      try{
        await loadPermissions() 
      } catch(err) {
        if (customMessage(err)===403) Logout()
      }
      setLoading(false) 
    }, 500);
  }, [toggleState])

  async function loadPermissions() {
    const response = await apierp.get('permissions')
    const data = response.data as any;
    setPermissions(data);
  }

  function handleRow(record: any, index: any) {
    setLastSelected(index)
    const block = ['root','master','admin']
    const isBlocked = block.findIndex((x) => x === record.slug) >= 0
    if (rowSelected === index) {
      setRowSelected(-1)
      setPermissionBlocked(true)
    } else {
      setRowSelected(index)
      setPermissionBlocked(isBlocked)
      setIsInclusao(false)
      showDrawer()
      const permissiony: any = {
        id: record.id,
        group: record.group,
        slug: record.slug,
        description: record.description,
        securityLevel: record.securityLevel,
      }
      fillForm(permissiony)
    }
  }

  const showDrawer = () => {
    setButtonsDisabled(false)
    setDrawerVisible(true)
  };

  const hideDrawer = () => {
    setButtonsDisabled(false)
    setRowSelected(-1)
    setPermission(null)
    setDrawerVisible(false)
  };

  async function handleSubmit(dados: any){
    setButtonsDisabled(true)
    dados.group = dados.group.trim().replaceAll('  ', ' ')
    dados.slug = dados.slug.toLowerCase().trim().replaceAll(' ', '')
    if (dados.id ==='') {
      //CREATE
      try {
        const result = await apierp.post('permissions', dados);
        setToggleState(!toggleState)
        message.success('Permissão incluida com sucesso!');

        hideDrawer()
      } catch (err) {
        if (customMessage(err)===403) Logout()
      }
    } else {
      //UPDATE
      try {
        const result = await apierp.put(`permissions/${dados.id}`, dados);
        setToggleState(!toggleState)
        message.success('Permissão alterada com sucesso!');
        hideDrawer()
      } catch (err) {
        if (customMessage(err)===403) Logout()
      }
    }

  };

  async function handleDelete(){
    const dados = form.getFieldsValue()
    try {
      await apierp.delete(`permissions/${dados.id}`);
      setToggleState(!toggleState)
      setLastSelected(-1)
      message.success('Permissão excluida com sucesso!');
      hideDrawer()
    } catch (err) {
      if (customMessage(err)===403) Logout()
    }

  };

  function confirmDelete(e: any) {
    handleDelete();
  }
  
  function cancelDelete(e: any) {
    message.error('Permissão não foi excluída');
  }

  const novaPermission= () => {
    setIsInclusao(true)
    setPermissionBlocked(false)
    showDrawer()
    const permissiony: any = {
      id: '',
      group: '',
      slug: '',
      description: '',
      securityLevel: '',
    }
    fillForm(permissiony)
  }

  const fillForm = (permissiony: any) => {
    form.setFieldsValue({
      id: permissiony.id,
      group: permissiony.group,
      slug: permissiony.slug,
      description: permissiony.description,
      securityLevel: permissiony.securityLevel,
    });
  };

  const resetForm = () => {
    setRowSelected(-1)
    novaPermission() // form.resetFields();
  };

  const columns = [
    // {
    //   width: '10px',
    //   onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" })
    // },
    {
      title: 'Id',
      dataIndex: 'id',
      ellipsis: true,
      width: '50px',
      className: 'text-right',
      // onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" })
    },
    {
      title: 'Grupo',
      dataIndex: 'group',
      ellipsis: true,
      width: '150px',
      className: 'text-left',
      // onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" })
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      ellipsis: true,
      width: '240px',
      className: 'text-left',
      // onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" })
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      width: '330px',
      className: 'text-left',
      ellipsis: true,
      render : (text: any, record:any ) => `${text}`
    },
    {
      title: 'Sec Level',
      dataIndex: 'securityLevel',
      width: '120px',
      className: 'text-left',
      ellipsis: true,
      render : (text: any, record:any ) => `(${text}) ${descSecurityLevel[text]}`
    },
  ]

  const botoes = () => {
    return (
      <Space size={10}>
        <Button onClick={() => printDiv('printableDiv-permissao', 'permissao')} shape="round" size="small" icon={<PrinterOutlined />} title="Imprimir"></Button>
        <Button onClick={() => copyToClipboard('permissao')} shape="round" size="small" icon={<CopyOutlined />} title="Copy to Clipboard"></Button>
        <Button onClick={() => downloadToCsv('permissao', "Inquilinos.csv")} shape="round" size="small" icon={<DownloadOutlined />} title="Download CSV"></Button>
        <Button onClick={novaPermission} type="primary" shape="round" size="small" icon={<PlusOutlined />} title="Novo"></Button>
      </Space>
    )
  }

  const wTab = 957 // liquido = x-17
  const htb = `calc(100vh - 149px - 110px)`
  const hsc = `calc(100vh - 180px - 109px)` // 1 a menos que htb

  return (
    <Layout titulo={menu.label} botoes={botoes()}>
      <div className="spinner mt-5 justify-center items-center " style={{ display: loading ? "flex" : "none" }} >
        <Spinner />
      </div>
      <div className="bg-blue-30 mx-5 overflow-x-auto">
        <div className="flex flex-row justify-center items-center mx-auto " style={{ maxWidth: `${wTab}px`, minWidth: `${wTab}px` }}>
          <div id="printableDiv-permissao" className="pt-6  pb-[1px] fadein" style={{ display: loading ? "none" : "" }}>
            <h1 className='hidden mb-1 text-2xl font-bold'>Permissões</h1>
            {/* TABLE */}
            <div className="flex flex-col justify-between bg-[#f8f9fb]" style={{ maxHeight: htb }}> {/** ou heigth */}
              <div className="envelopetable ">
                <Table id='permissao' dataSource={permissions} columns={columns} size="small" rowKey="id"
                  rowClassName={(record, index) => (index === rowSelected ? "rowSelected" : "")}
                  pagination={false}
                  scroll={{ y: hsc }}
                  onRow={(record: any, rowIndex: any) => {
                    return {
                      onClick: event => { handleRow(record, rowIndex) }, // click row
                      className: lastSelected === rowIndex ? "lastSelected" : ""
                    };
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* DRAWER FORM */}
      <Drawer
        title={ (isInclusao ? "Criar um novo" : "Alterar") + " permissão"}
        width={360}
        closable={false}
        maskClosable={false}
        onClose={hideDrawer}
        visible={drawerVisible}
        headerStyle={{ borderRadius: "0" }}
        bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
      >
        <div className="inner-drawer-body">
          <Form layout="vertical" form={form} onFinish={handleSubmit} >
            <Title level={5}>{form.getFieldValue('slug')}</Title>
            <Form.Item name="id" hidden noStyle></Form.Item>
            
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="group"
                  label="Grupo"
                  rules={[{ required: true, message: 'Entre com Grupo da permissão' }]}
                >
                  <Input placeholder="Grupo da permissão" />
                </Form.Item>
              </Col>
            </Row>
            
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="slug"
                  label="Slug (nome interno)"
                  rules={[{ required: true, message: 'Entre com Slug da permissão' }]}
                >
                  <Input placeholder="Slug da permissão" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label="Descrição"
                  rules={[{ required: true, message: 'Entre com uma Descrição do permissão' }]}
                >
                  <Input placeholder="Descrição da permissão" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="securityLevel"
                  label="Nível de Segurança"
                  rules={[{ required: true, message: 'Selecione Nível de segurança' }]}
                >
                  <Select placeholder="Selecione um Nível de segurança">
                    <Option value={''}>Selecione um Nível de segurança</Option>
                    {descSecurityLevel.map((sl: any, index: any) => {
                      return <Option value={index}>({index}) {sl}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

          </Form>
        </div>
        <Space className="drawer-footer">
          <Space>
            <Button onClick={hideDrawer}>Cancel</Button>
            {/* <Button onClick={resetForm}>Reset</Button> */}
            <Popconfirm disabled={ permissionBlocked }
              title="Deseja realmente excluir esta permissão?"
              onConfirm={confirmDelete}
              onCancel={cancelDelete}
              okText="Sim"
              cancelText="Não"
            >
              <Button onClick={() => {}} type="primary" danger disabled={isInclusao || permissionBlocked || buttonsDisabled} >Delete</Button>
            </Popconfirm>

            <Button onClick={form.submit} disabled={permissionBlocked || buttonsDisabled} type="primary">Submit</Button>
          </Space>
        </Space>
      </Drawer>
    </Layout>
  )
}
