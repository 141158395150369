export default function CardMenuNarrow(props: any) {

  return (
    <div  style={{ backgroundColor: props.bg}} className={props.bg + " m-4 rounded-lg shadow-gray-400 shadow-md  hover:shadow-gray-600 hover:cursor-pointer"}>
      <div className="flex w-[316px] items-center rounded-lg" >
        {/* <img src={"/" + props.imagem} alt="img" width={296} height={75} className="rounded-t-md mx-auto mt-[2px]"/> */}
        <img src={"/" + props.imagem} alt="img" height={65} className="rounded-l-md m-2 "/>
        <div className="py-1 px-3 w-full">
          <div className={props.txt + " text-lg font-semibold tracking-wider"}>{props.title}</div>
        </div>
      </div>
    </div>
  )
}