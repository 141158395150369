import { Button, Space } from "antd"
import { LockFilled, UnlockFilled, CheckSquareFilled, DownloadOutlined, CopyOutlined, PrinterOutlined, PlusOutlined } from '@ant-design/icons';
import Tabela from "../../../components/template/Tabela"
import { useGeral } from "../../../contexts/geral";
import { copyToClipboard, downloadToCsv, formatDecimal, printDiv } from "../../../utils/functions"
import { columnsFichaTecnicaItem } from "./columnsFichaTecnicaItem";
import isPermitted from "../../../hooks/isPermitted";

export function FichaTecnicaItens(props: any) {
  const { userTenant } = useGeral();
  const colors = userTenant.tenant.colors.split(',')
  const isPermittedManterFT = isPermitted('manter_fichas_tecnicas')
        
    const {produto, rowFichaTecnicaSelected,novaFichaTecnicaItem,
        handleRowFichaTecnicaItem, rowFichaTecnicaItemSelected, lastFichaTecnicaItemSelected, loadingFichaTecnicaItem,} = props

      if (!produto) { return <div>Sem fichas tecnicas indexFichaTecnicaItemSelected</div> }
      if (rowFichaTecnicaSelected < 0) { return <div>Sem fichas tecnicas indexFichaTecnicaItemSelected</div> }
      const fichaTecnica=produto.fichastecnicas[rowFichaTecnicaSelected]
      if (!fichaTecnica) { return <div>Sem fichas tecnicas indexFichaTecnicaItemSelected</div> }
      // console.log('fichaTecnica',fichaTecnica)
      const dadosFlat=[]
      let totalFichaCompra=0.0
      let totalFichaCmc=0.0
      let totalFichaInformado=0.0
      fichaTecnica.fichatecnicaitens.forEach((it: any) => {
        totalFichaCompra+=it.produto.precoCompra * it.quantConsumida
        totalFichaCmc+=it.produto.precoCmc * it.quantConsumida
        totalFichaInformado+=it.produto.precoInformado * it.quantConsumida
        // console.log('precoInformado', it.produto.precoInformado)
        const precoCompra = it.produto.precoInformado ? it.produto.precoInformado : it.produto.precoCompra
        const item = {
          id: it.id,
          fichatecnicaId: it.fichatecnicaId,
          produtoId: it.produto.id,
          codigo: it.produto.codigo,
          nomeComponente: it.produto.nomeComponente,
          unidade: it.produto.unidade,
          quantConsumida: it.quantConsumida,

          precoCompra: precoCompra,
          totalCompra: precoCompra * it.quantConsumida,

          precoCmc: it.produto.precoCmc,
          totalCmc: it.produto.precoCmc * it.quantConsumida,

          precoInformado: it.produto.precoInformado,
          totalInformado: it.produto.precoInformado * it.quantConsumida,

          situacao: 'Ativo*',
          temPrecoInformado: Boolean(it.produto.precoInformado),
          footer: false,
        }
        dadosFlat.push(item)
      })
      const total = {
        id: '#',
        produtoId: null,
        codigo: 'Total',
        nomeComponente: '',
        unidade: '',
        quantConsumida: '',

        precoCompra: '',
        totalCompra: totalFichaCompra,

        precoCmc: '',
        totalCmc: totalFichaCmc,

        precoInformado: '',
        totalInformado: totalFichaInformado,

        situacao: '',
        footer: true,
      }
      dadosFlat.push(total)

      const wTab=1148
      const htb = `calc(100vh - 149px - 110px)`
      const hsc = `calc(100vh - 180px - 109px)` 

  return (
    (fichaTecnica) ? (
      <>
        <h1 className={`subtitulo text-base font-bold `}>{produto.codigo} - {produto.nome}</h1>
        <div className="flex flex-col justify-between" style={{ height: "calc(100vh - 137px)" }}>
          <Tabela className="mx-0" id='fichatecnicaitem' titulo={produto.codigo + " - " + produto.nome} subtitulo={fichaTecnica.nome} wTab={wTab} htb={htb} hsc={hsc} dataSource={dadosFlat} columns={columnsFichaTecnicaItem} handleRow={handleRowFichaTecnicaItem} rowSelected={rowFichaTecnicaItemSelected} lastSelected={lastFichaTecnicaItemSelected} loading={loadingFichaTecnicaItem} />
          <div className={"pageButtons"} style={{ backgroundColor: colors[3] }}>
            <Space size={10}>
              <Button onClick={() => printDiv('printableDiv-fichatecnicaitem', 'fichatecnicaitem')} shape="round" size="small" icon={<PrinterOutlined />} title="Imprimir"></Button>
              <Button onClick={() => copyToClipboard('fichatecnicaitem')} shape="round" size="small" icon={<CopyOutlined />} title="Copy to Clipboard"></Button>
              <Button onClick={() => downloadToCsv('fichatecnicaitem', "Inquilinos.csv")} shape="round" size="small" icon={<DownloadOutlined />} title="Download CSV"></Button>
              <Button onClick={novaFichaTecnicaItem} type="primary" shape="round" size="small" icon={<PlusOutlined />} hidden={!isPermittedManterFT} title="Novo"></Button>
            </Space>
          </div>
        </div>
      </>
    ) : (
      null
    )
  )
    // <h3>{produto?.codigo} - {produto?.nome}</h3>
    // <div className="overflow-auto">
    // TABELA FICHA TECNICA 770+8
    
    // FIM
    // {(produto.fichastecnicas.length > 0 && rowFichaTecnicaSelected > -1)
    //   ? (
    //     <>
    //       <h3 className="mt-5">{produto?.fichastecnicas[rowFichaTecnicaSelected]?.nome}</h3>
          
    //       <div className="flex flex-col min-w-[828px] max-w-[828px] bg-gray-400" >
    //         <div className="flex space-between mb-[1px] p-1 bg-gray-300 font-bold" >
    //           <div className="w-[40px] px-1 text-right" >Item</div>
    //           <div className="w-[80px] px-1 text-left" >Código</div>
    //           <div className="w-[500px] px-1 text-left" >Componente</div>
    //           <div className="w-[30px] px-1 text-left" >UN</div>
    //           <div className="w-[100px] px-1 text-right" >Consumo</div>
    //           <div className="w-[70px] px-1 text-left" >Situação</div>
    //         </div>
    //         <div>
    //           {produto?.fichastecnicas[rowFichaTecnicaSelected].fichatecnicaitens.map((fti: any, index: any) => {
    //             return (
    //               <div className={"flex space-between mb-[1px] px-1 py-1 bg-gray-100 pointer" + (index === indexFichaTecnicaItemSelected ? " lastSelected" : "")}
    //                 onClick={() => handleIndexFichaTecnicaItemSelected(index)}
    //               >
    //                 <div className="w-[40px] px-1 text-right" >{index + 1}</div>
    //                 <div className="w-[80px] px-1 text-left" >{fti.produto.codigo}</div>
    //                 <div className="w-[500px] px-1 text-left" >{fti.produto.nomeComponente}</div>
    //                 <div className="w-[30px] px-1 text-left" >{fti.produto.unidade}</div>
    //                 <div className="w-[100px] px-1 text-right" >{formatDecimal(fti.quantConsumida, 6)}</div>
    //                 <div className="w-[70px] px-1 text-left" >Ativo</div>
    //               </div>
    //             )
    //           })
    //           }
    //         </div>
    //       </div>
    //     </>
    //   )
    //   : (null)
    // }

//   </div>
}