import { situacaoCliente } from "../../../data/dados"
import { dateFormatBr } from "../../../utils/dateFunctions"
import { formatarCep, formatarCpfCnpj } from "../../../utils/functions"

export function CardCliente(props: any) {
    const { cliente, wCard, key, className } = props
    // console.log('props.cliente',props.cliente)
    const classLabel = "min-w-[72px] text-left font-medium"
    // const email = cliente?.email ? cliente?.email.toLowerCase() : ''
    const situacao = situacaoCliente.find(s => s.value === cliente?.situacao)
    return (
        <div key={key} className={`card-cliente p-2 ${wCard} ${className}`} hidden={!cliente}>
            <div className="flex"><div className={classLabel}>Nome:</div><div className="flex flex-1 justify-between"><div className="font-bold pl-2">{cliente?.nome}</div><div>#{cliente?.id}{cliente?.idbling}</div></div></div>
            <div className="flex">
                <div className="flex"><div className={classLabel}>Cnpj/Cpf:</div><div className="pl-2">{formatarCpfCnpj(cliente?.cnpj)}</div></div>
                <div className="flex ml-4"><div className="">Situacao:</div><div className="pl-2">{situacao?.label}</div></div>
                <div className="flex" hidden={cliente?.tipoPessoa === 'F'}>
                    <div className="ml-2 font-medium">Ie: </div>
                    <div className="ml-2" w-20>{cliente?.ie}</div>
                    <div className="ml-2 font-medium">IndIe: </div>
                    <div className="ml-2">{cliente?.indicadorIe}</div>
                </div>
                <div className="flex" hidden={cliente?.tipoPessoa === 'J'}>
                    <div className="ml-2 font-medium">Rg:</div>
                    <div className="ml-2 w-20">{cliente?.rg}</div>
                    <div className="ml-2 font-medium">IndIe: </div>
                    <div className="ml-2">{cliente?.indicadorIe}</div>
                </div>
            </div>
            <div className="flex"><div className={classLabel}>Endereço:</div><div className="pl-2">{cliente?.endereco}, nº {cliente?.numero} - {cliente?.complemento}</div></div>
            <div className="flex"><div className={classLabel}>Bairro:</div><div className="pl-2">{cliente?.bairro} </div></div>
            <div className="flex"><div className={classLabel}>Cidade/UF:</div><div className="pl-2">{cliente?.cidade} - {cliente?.uf}</div></div>
            <div className="flex"><div className={classLabel}>CEP:</div><div className="pl-2">{formatarCep(cliente?.cep)}</div></div>
            <div className="flex"><div className={classLabel}>Telefones:</div><div className="pl-2">{cliente?.fone} {cliente?.celular}</div></div>
            <div className="flex"><div className={classLabel}>Email:</div><div className="pl-2">{cliente?.email}</div></div>
            <div className="flex" hidden={!cliente?.gcNome && !cliente?.statusCrm && !cliente?.dataStatusCrm}>
                <div className="min-w-[72px] text-left font-medium">G. Conta:</div><div className="font-bold pl-2 w-full">{cliente?.gcNome}</div>
                <div className="pl-2 text-left font-medium">Status:</div><div className="font-bold min-w-[200px] pl-2">{cliente?.statusCrm}</div>
                <div className="pl-2 text-left font-medium">Data:</div><div className="font-bold w-[90px] pl-2">{dateFormatBr(cliente?.dataStatusCrm)}</div>
            </div>
        </div>
    )

}