import { useEffect, useMemo, useRef, useState } from "react"
import Highlighter from 'react-highlight-words';
import { LockFilled, UnlockFilled, CheckSquareFilled, DownloadOutlined, CopyOutlined, PrinterOutlined, PlusOutlined, FastBackwardOutlined } from '@ant-design/icons';
import { InputRef, Modal } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useAuth } from "../../contexts/auth";
import { apierp } from '../../services/api';
import { customMessage } from "../../components/template/CustomMessage";
import DatePicker from "react-datepicker";


import useWindowSize from "../../hooks/useWindowSize";

import { Button, Col, Drawer, Form, Input, message, Popconfirm, Radio, Row, Select, Space, Table, Typography } from 'antd';
import SpinnerNew from "../../components/template/SpinnerNew";
import { copyToClipboard, downloadToCsv, formatDecimal, printDiv } from "../../utils/functions";
import Layout from "../../components/template/Layout";
import { useGeral } from "../../contexts/geral";
import Tabela from "../../components/template/Tabela";

import isPermitted from "../../hooks/isPermitted";
import { ColumnType, FilterConfirmProps } from "antd/lib/table/interface";
import { useDadosPedidosVenda } from "./useDadosPedidosVenda";
import { columnsPedidoVenda } from "./columnsPedidoVenda";
import { dateFormat } from "../../utils/dateFunctions";
import DrawerFormPedidoVenda from "./DrawerFormPedidoVenda";
import { useContatos } from "./useContatos";
import { useDebounce } from "use-debounce";

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
const { Option } = Select;

export default function PedidoVenda() {

    const [innerW, innerH] = useWindowSize();
    // const now = new Date()
    // console.log(now)
    // const [mesref, setMesRef] = useState(new Date())
    const [pagina, setPagina] = useState(1)
    const [limite, setLimite] = useState(200)
    const [pedidoVendaId, setPedidoVendaId] = useState(null as any)
    const [txId, setTxId] = useState(null as any)
    const [empresaId, setEmpresaId] = useState(null as any)
    const [clienteId, setClienteId] = useState(null as any)
    const [transportadorId, setTransportadorId] = useState(null as any)
    const [vendedorId, setVendedorId] = useState(null as any)

    // useEffect(() => {
    //   setMesRef(now)
    // }, [])

    // const data = mesref.toISOString().slice(0, 10)
    // console.log('mesref', mesref, dateFormat(mesref, 'yyyy-MM-dd HH:mm:ss'))
    // const data = dateFormat(mesref, 'yyyy-MM-dd')
    // const param = { data: data }
    const param = { pagina, limite}

    // const { qrEmpresas, qrSituacoesPedidoVenda, qrVendedores, qrFormasPagamento, qrProdutos, qrPedidosVenda, qrPedidoVenda } = useDadosPedidosVenda(data, pedidoVendaId, empresaId)
    const { qrEmpresas, qrSituacoesPedidoVenda, qrVendedores, qrFormasPagamento, qrProdutos, qrPedidosVenda, qrPedidoVenda, qrPixPedidoVenda, qrBlingVendedores, qrBlingCategoriasReceitaDespesa, qrBlingFormasPagamento, qrBlingProdutos, qrPixWebhook} = useDadosPedidosVenda(param, pedidoVendaId, empresaId, txId)

    const [drawerFormPedidoVendaVisible, setDrawerFormPedidoVendaVisible] = useState(false)
    const [operacao, setOperacao] = useState(null as string | null)
    const [pedidoVenda, setPedidoVenda] = useState(null as any)
    const [termoCli, setTermoCli] = useState(null as any);
    const [valueDebounceCli] = useDebounce(termoCli, 500);
    const [termoTra, setTermoTra] = useState(null as any);
    const [valueDebounceTra] = useDebounce(termoTra, 500);
    const [clientes, setClientes] = useState([] as any[])
    const [transportadores, setTransportadores] = useState([] as any[])


    const { qrCliente, qrTransportador, qrClientes, qrTransportadores, qrBlingCliente, qrBlingTransportador } = useContatos(clienteId, transportadorId, valueDebounceCli, valueDebounceTra, empresaId)

    useEffect(() => {
        // console.log('useEffect-qrPedidoVenda.data', qrPedidoVenda.data)
        if (qrPedidoVenda.data) {
            // setPedidoVenda(qrPedidoVenda.data)
            setEmpresaId(qrPedidoVenda.data.empresaId)
            setClienteId(qrPedidoVenda.data.clienteId)
            setTransportadorId(qrPedidoVenda.data.transportadorId)
            setVendedorId(qrPedidoVenda.data.vendedorId)
        } else {
            // setPedidoVenda(null)
        }

    }, [qrPedidoVenda.data])

    useEffect(() => {
        if (qrCliente.data) {
            setClientes([qrCliente.data])
        } else {
            setClientes([])
        }
    }, [qrCliente.data])

    useEffect(() => {
        if (qrTransportador.data) {
            setTransportadores([qrTransportador.data])
            // setTransportadorId(qrTransportador.data.id)
        } else {
            setTransportadores([])
        }
    }, [qrTransportador.data])

    useEffect(() => {
        if (qrClientes.data) {
            setClientes(qrClientes.data)
        } else {
            // setClientes([])
        }
    }, [qrClientes.data])

    // PARA DEBUG
    // useEffect(() => {
    //     // console.log('qrBlingProdutos.data', qrBlingProdutos.data)
    //     // console.log('qrBlingFormasPagamento.data', qrBlingFormasPagamento.data)
    //     // console.log('qrBlingCategoriasReceitaDespesa.data', qrBlingCategoriasReceitaDespesa.data)
    // }, [qrBlingProdutos.data, qrBlingFormasPagamento.data, qrBlingCategoriasReceitaDespesa.data])
    // useEffect(() => {
    //     // console.log('qrBlingCliente.data', qrBlingCliente.data)
    //     // console.log('qrBlingTransportador.data', qrBlingTransportador.data)
    // }, [qrBlingCliente.data, qrBlingTransportador.data])
    // useEffect(() => {
    //     // console.log('qrBlingVendedores.data', qrBlingVendedores.data)
    // }, [qrBlingVendedores.data])
    // END DEBUG

    useEffect(() => {
        if (qrTransportadores.data) {
            setTransportadores(qrTransportadores.data)
        } else {
            // setTransportadores([])
        }
    }, [qrTransportadores.data])

    // console.log('clientes', clientes)
    // console.log('transportadores', transportadores)

    const colunas = columnsPedidoVenda({})

    const handleRow = async (record: any) => {
        // console.log('handleRow', record)
        setPedidoVendaId(record.id)
        // setTimeout(() => {
        setDrawerFormPedidoVendaVisible(true)
        setOperacao('Alteração')
        // }, 200)
    }
    const hideDrawerFormPedidoVenda = () => {
        setPedidoVendaId(0)
        setDrawerFormPedidoVendaVisible(false)
    }
    const newPedido= () => {
        setPedidoVendaId(0)
        setEmpresaId(null)
        setClienteId(null)
        setTransportadorId(null)
        setVendedorId(null)
        setDrawerFormPedidoVendaVisible(true)
        setOperacao('Inclusão')
    }

    const wTab = 1528 
    const htb = `calc(100vh - 149px - 110px)`
    const hsc = `calc(100vh - 180px - 109px - 33px)` // 1 a menos que htb 33px do DatePicker
    const divTotalLeft = innerW > 640 ? 'left-[144px]' : ''
    // use memo em componente <Tabela> para evitar re-render desnecessario
    const tabela = useMemo(() => {
        // console.log('useMemo tabela')
        return (
            <Tabela className="mx-5" id='pedidosVenda' titulo={'Pedidos-Venda'} wTab={wTab} htb={htb} hsc={hsc} dataSource={qrPedidosVenda.data} columns={colunas} handleRow={handleRow} loading={qrPedidosVenda.isLoading} rowKey={'id'} />
        )
    }, [qrPedidosVenda.data])

    // let corPicker = 'bg-blue-600'
    // if (mesref !== null) {
    //     corPicker = dateFormat(mesref, 'yyyy-MM-dd') === dateFormat(now, 'yyyy-MM-dd') ? 'bg-blue-600' : 'bg-red-600'
    // }

    const botoes = () => {
        return (
          <Space size={10}>
            {/* <Button onClick={() => printDiv('printableDiv-pedidos', 'pedidos')} shape="round" size="small" icon={<PrinterOutlined />} title="Imprimir"></Button>
            <Button onClick={() => copyToClipboard('pedidos')} shape="round" size="small" icon={<CopyOutlined />} title="Copy to Clipboard"></Button>
            <Button onClick={() => downloadToCsv('pedidos', "Menu.csv")} shape="round" size="small" icon={<DownloadOutlined />} title="Download CSV"></Button> */}
            <Button onClick={() => newPedido()} type="primary" shape="round" size="small" icon={<PlusOutlined />} title="Novo"></Button>
          </Space>
        )
      }

    return (
        <Layout titulo={"Pedido Venda"} botoes={botoes()}>
            <h2 className="mx-auto text-red-500">Funcionalidade em Desenvolvimento</h2>
            {/*
            <div className="mx-auto">
                <DatePicker disabled={qrPedidosVenda.isLoading}
                    // wrapperClassName="btmonth"
                    className={`w-[88px] mb-[3px] text-center uppercase border-none rounded-md font-medium py-1 cursor-pointer text-white ${corPicker}`}
                    selected={mesref}
                    locale="pt-BR"
                    onChange={(date: any) => setMesRef(date)}
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    todayButton="Hoje"
                    popperPlacement="bottom"
                />
            </div>
            */}
            <SpinnerNew loading={qrPedidosVenda.isLoading} />
            <div>
                {tabela}
            </div>

            {qrPedidoVenda.status === "success" || operacao === "Inclusão" ?
                <DrawerFormPedidoVenda hideDrawerFormPedidoVenda={hideDrawerFormPedidoVenda} drawerFormPedidoVendaVisible={drawerFormPedidoVendaVisible}
                    operacao={operacao}
                    formasPagamento={qrFormasPagamento?.data?.filter((fp: any) => !!fp.tipoForma)}
                    // formasPagamento={qrFormasPagamento.data}
                    situacoesPedidoVenda={qrSituacoesPedidoVenda.data}
                    vendedores={qrVendedores.data.sort((a: any, b: any) => a.nome.localeCompare(b.nome))}
                    empresas={qrEmpresas.data}
                    pedidoVenda={qrPedidoVenda.data}
                    pixPedidoVenda={qrPixPedidoVenda.data}
                    clientes={clientes} termoCli={termoCli}
                    produtos={qrProdutos.data}
                    blingCliente={qrBlingCliente.data}
                    blingTransportador={qrBlingTransportador.data}
                    blingProdutos={qrBlingProdutos.data}
                    // blingFormasPagamento={qrBlingFormasPagamento?.data?.filter((fp: any) => fp.tipoPagamento === 17 && fp.situacao === 1)}
                    blingFormasPagamento={qrBlingFormasPagamento?.data?.filter((fp: any) => fp.situacao === 1)}
                    blingCategoriasReceitaDespesa={qrBlingCategoriasReceitaDespesa.data}
                    blingVendedores={qrBlingVendedores.data}
                    pixWebhook = {qrPixWebhook.data}
                    vendedorId={vendedorId} setVendedorId={setVendedorId}

                    // handleSearchCli={handleSearchCli} handleChangeCli={handleChangeCli}
                    transportadores={transportadores} termoTra={termoTra}
                    // handleSearchTra={handleSearchTra} handleChangeTra={handleChangeTra}
                    setTransportadores={setTransportadores} setClientes={setClientes} setItemPedidoVendaId={setPedidoVendaId}
                    setTermoCli={setTermoCli} setTermoTra={setTermoTra} setEmpresaId={setEmpresaId} setClienteId={setClienteId} setTransportadorId={setTransportadorId} setTxId={setTxId}
                />
                : null
            }
            <div className={`divtotal absolute flex items-center h-10 px-4 bottom-8 ${divTotalLeft} text-white`}>{qrPedidosVenda.data?.length} Pedidos</div>
        </Layout>
    )
}