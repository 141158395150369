import { useEffect, useState } from "react"
import { LockFilled, UnlockFilled, CheckSquareFilled, DownloadOutlined, CopyOutlined, PrinterOutlined, PlusOutlined } from '@ant-design/icons';

import { useAuth } from "../../../contexts/auth";
import { apierp } from '../../../services/api';
import { customMessage } from "../../../components/template/CustomMessage";

import { Button, Col, Drawer, Form, Input, message, Popconfirm, Radio, Row, Select, Space, Table, Typography } from 'antd';
import Spinner from "../../../components/template/Spinner";
import useWindowSize from "../../../hooks/useWindowSize";
import { NoStyleItemContext } from "antd/lib/form/context";
import { copyToClipboard, downloadToCsv, printDiv } from "../../../utils/functions";
import Layout from "../../../components/template/Layout";
import { useGeral } from "../../../contexts/geral";
const { Title, Text, Paragraph } = Typography;

export default function Tenant() {
  const { Logout, user: me } = useAuth();
  const usuario: any = me
  const { userMenu, menuSelected, userTenant, } = useGeral();
  const menu = userMenu.find((x: any) => x.id == menuSelected)

  const { Option } = Select;
  const [formTenant] = Form.useForm();
  const [formUser] = Form.useForm();
  const [formRole] = Form.useForm();

  const [loading, setLoading] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [drawerUserVisible, setDrawerUserVisible] = useState(false)
  const [drawerRoleVisible, setDrawerRoleVisible] = useState(false)
  const [toggleState, setToggleState] = useState(false)

  const [rowSelected, setRowSelected] = useState(-1)
  const [lastSelected, setLastSelected] = useState(-1)
  const [isInclusao, setIsInclusao] = useState(false)

  const [rowSelectedUser, setRowSelectedUser] = useState(-1)
  const [lastSelectedUser, setLastSelectedUser] = useState(-1)
  const [isInclusaoUser, setIsInclusaoUser] = useState(false)

  const [tenants, setTenants] = useState([])
  const [tenant, setTenant] = useState(null as any)
  const [tenantBlocked, setTenantBlocked] = useState(true)

  const [users, setUsers] = useState([])
  const [user, setUser] = useState([])
  const [userBlocked, setUserBlocked] = useState(true)
  const [buttonsDisabled, setButtonsDisabled] = useState(false)

  useEffect(() => {
    setLoading(true)
    setTimeout(async function () {
      try {
        await loadTenants()
      } catch (err) {
        if (customMessage(err) === 403) Logout()
      }
      setLoading(false)
    }, 500);
  }, [toggleState])

  async function loadTenants() {
    const response = await apierp.get('tenants')
    const data = response.data as any;
    setTenants(data);
  }
  async function loadUsers() {
    // console.log(form.getFieldValue('id'))
    const response = await apierp.get(`usersbt/${formTenant.getFieldValue('id')}`)
    const data = response.data as any;
    setUsers(data);
  }

  function handleRow(record: any, index: any) {
    setLastSelected(index)
    const isBlocked = record.userName === 'root'
    if (rowSelected === index) {
      setRowSelected(-1)
      setTenantBlocked(true)
    } else {
      setRowSelected(index)
      setTenantBlocked(isBlocked)
      setIsInclusao(false)
      showDrawerTenant()
      const tenanty: any = {
        id: record.id,
        userName: record.userName,
        name: record.name,
        shortName: record.shortName,
        email: record.email,
        isInactive: record.isInactive,
      }
      fillForm(tenanty)
    }
  }

  const showDrawerTenant = () => {
    setButtonsDisabled(false)
    setDrawerVisible(true)
  };
  const showDrawerUser = () => {
    setButtonsDisabled(false)
    loadUsers()
    setDrawerUserVisible(true)
  };
  const showDrawerRole = () => {
    setButtonsDisabled(false)
    setDrawerRoleVisible(true)
  };

  const hideDrawerTenant = () => {
    setButtonsDisabled(false)
    setRowSelected(-1)
    setDrawerVisible(false)
  };
  const hideDrawerUser = () => {
    setButtonsDisabled(false)
    setDrawerUserVisible(false)
  };
  const hideDrawerRole = () => {
    setButtonsDisabled(false)
    setDrawerRoleVisible(false)
  };

  async function handleSubmitTenant(dados: any) {
    setButtonsDisabled(true)
    // console.log('dados', dados)
    // dados.userName = dados.userName.toLowerCase().trim().replaceAll(' ', '')
    dados.name = dados.name.trim()
    if (dados.id === '') {
      //CREATE
      dados.shortName = dados.shortName.trim().replace(/\s\s+/g, ' ')
      dados.email = dados.email.trim()
      try {
        const result = await apierp.post('tenants', dados);
        setToggleState(!toggleState)
        message.success('Inquilino incluido com sucesso!');

        hideDrawerTenant()
      } catch (err) {
        if (customMessage(err) === 403) Logout()
      }
    } else {
      //UPDATE
      try {
        const result = await apierp.put(`tenants/${dados.id}`, dados);
        setToggleState(!toggleState)
        message.success('Inquilino alterado com sucesso!');
        hideDrawerTenant()
      } catch (err) {
        if (customMessage(err) === 403) Logout()
      }
    }

  };

  async function handleDeleteTenant() {
    const dados = formTenant.getFieldsValue()
    try {
      await apierp.delete(`tenants/${dados.id}`);
      setToggleState(!toggleState)
      setLastSelected(-1)
      message.success('Inquilino excluido com sucesso!');
      hideDrawerTenant()
    } catch (err) {
      if (customMessage(err) === 403) Logout()
    }

  };

  function confirmDeleteTenant(e: any) {
    handleDeleteTenant();
  }

  function cancelDelete(e: any) {
    message.error('Inquilino não foi excluído');
  }

  const newTenant = () => {
    formTenant.resetFields();
    setIsInclusao(true)
    setTenantBlocked(false)
    showDrawerTenant()
    formTenant.setFieldsValue({ id: '' })
    formTenant.setFieldsValue({ isInactive: false })
    // const tenanty: any = {
    //   id: '',
    //   userName: '',
    //   name: '',
    //   shortName: '',
    //   email: '',
    //   isInactive: false,
    // }
    // fillForm(tenanty)
  }

  const novoUser = () => {
    formUser.resetFields();
    setIsInclusaoUser(true)
    setUserBlocked(false)
    showDrawerUser()
    formUser.setFieldsValue({ id: '' })
    formUser.setFieldsValue({ isInactive: false })
  }

  const fillForm = (tenanty: any) => {
    formTenant.setFieldsValue({
      id: tenanty.id,
      userName: tenanty.userName,
      name: tenanty.name,
      shortName: tenanty.shortName,
      email: tenanty.email,
      isInactive: tenanty.isInactive,
    });
  };

  const resetForm = () => {
    setRowSelected(-1)
    newTenant()
  };

  async function handleSubmitUser() {
    setButtonsDisabled(true)
    // const tenantSelected: any=tenants[rowSelected]
    // const result = await apierp.post(`xxxx/${roleSelected.id}`, {menus: menusRole})
    // message.success('Menu do Perfil atualizado com sucesso!');
    hideDrawerUser()
  }
  async function handleSubmitRole() {
    setButtonsDisabled(true)
    // const tenantSelected: any=tenants[rowSelected]
    // const result = await apierp.post(`xxxx/${roleSelected.id}`, {menus: menusRole})
    // message.success('Menu do Perfil atualizado com sucesso!');
    hideDrawerRole()
  }

  const columns = [
    // {
    //   width: '10px',
    //   onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" })
    // },
    {
      title: 'Id',
      dataIndex: 'id',
      ellipsis: true,
      width: '50px',
      className: 'text-right',
      // onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" })
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '300px',
      className: 'text-left',
      ellipsis: true,
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      width: '350px',
      className: 'text-left',
      ellipsis: true,
    },
    {
      title: 'Nome Curto',
      dataIndex: 'shortName',
      width: '150px',
      className: 'my-nowrap text-left',
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'isInactive',
      width: '90px',
      className: 'text-left',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: record.isInactive ? "text-red-500" : "text-blue-500" }),
      render(text: any, record: any, i: any) {
        return {
          children: (<div> {text ? <div><LockFilled /> Inativo</div> : <div><CheckSquareFilled /> Ativo</div>} </div>)
        }
      }
    },
  ]

  const columnsUsers = [
    {
      title: 'Id',
      dataIndex: 'id',
      width: '50px',
      onCell: (record: any, i: any) => ({ className: lastSelectedUser === i ? "lastSelected" : "" })
    },
    {
      title: 'UserName',
      dataIndex: 'userName',
      width: '180px',
      ellipsis: true,
      render(text: any, record: any, i: any) {
        return {
          children: (<div> {text} {record.isOwner ? ' (#)' : ''}</div>)
        }
      }
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      width: '240px',
      ellipsis: true,
    },
    {
      title: 'ShortNome',
      dataIndex: 'shortName',
      width: '130px',
      ellipsis: true,
    },
    {
      title: 'TSL',
      dataIndex: 'topSecurityLevel',
      width: '40px',
      ellipsis: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '250px',
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'isInactive',
      width: '90px',
      onCell: (record: any, i: any) => ({ className: record.isInactive ? "text-red-500" : "text-blue-500" }),
      render(text: any, record: any, i: any) {
        return {
          children: (<div> {text ? <div><LockFilled /> Inativo</div> : <div><CheckSquareFilled /> Ativo</div>} </div>)
        }
      }
    },
  ]

  const botoes = () => {
    return (
      <Space size={10}>
        <Button onClick={() => printDiv('printableDiv-inquilino', 'inquilino')} shape="round" size="small" icon={<PrinterOutlined />} title="Imprimir"></Button>
        <Button onClick={() => copyToClipboard('inquilino')} shape="round" size="small" icon={<CopyOutlined />} title="Copy to Clipboard"></Button>
        <Button onClick={() => downloadToCsv('inquilino', "Inquilinos.csv")} shape="round" size="small" icon={<DownloadOutlined />} title="Download CSV"></Button>
        <Button onClick={newTenant} type="primary" shape="round" size="small" icon={<PlusOutlined />} title="Novo"></Button>
      </Space>
    )
  }

  const wTab = 957 // liquido = x-17
  const htb = `calc(100vh - 149px - 110px)`
  const hsc = `calc(100vh - 180px - 109px)` // 1 a menos que htb

  // console.log('render', render, buttonsDisabled)

  return (
    <Layout titulo={menu.label} botoes={botoes()}>
      <div className="spinner mt-5 justify-center items-center " style={{ display: loading ? "flex" : "none" }} >
        <Spinner />
      </div>
      <div className="bg-blue-30 mx-5 overflow-x-auto">
        <div className="flex flex-row justify-center items-center mx-auto " style={{ maxWidth: `${wTab}px`, minWidth: `${wTab}px` }}>
          <div id="printableDiv-inquilino" className="pt-6  pb-[1px] fadein" style={{ display: loading ? "none" : "" }}>
            <h1 className='hidden mb-1 text-2xl font-bold'>Inquilinos</h1>
            {/* TABLE */}
            <div className="flex flex-col justify-between bg-[#f8f9fb]" style={{ maxHeight: htb }}> {/** ou heigth */}
              <div className="envelopetable ">
                <Table id='inquilino' dataSource={tenants} columns={columns} size="small" rowKey="id"
                  rowClassName={(record, index) => (index === rowSelected ? "rowSelected" : "")}
                  pagination={false}
                  scroll={{ y: hsc }}
                  onRow={(record: any, rowIndex: any) => {
                    return {
                      onClick: event => { handleRow(record, rowIndex) }, // click row
                      className: lastSelected === rowIndex ? "lastSelected" : ""
                    };
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* DRAWER FORM */}
      <Drawer
        title={(isInclusao ? "Criar um novo" : "Alterar") + " Inquilino"}
        width={360}
        // size="large"
        maskClosable={false}
        closable={false}
        onClose={hideDrawerTenant}
        visible={drawerVisible}
        headerStyle={{ borderRadius: "0" }}
        bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
      >
        <div className="inner-drawer-body">
          <Form layout="vertical" form={formTenant} onFinish={handleSubmitTenant} >
            <Title level={5}>{formTenant.getFieldValue('name')}</Title>
            <Form.Item name="id" hidden noStyle></Form.Item>
            
            {isInclusao ?
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[{ required: true, message: 'Entre com Email do Inquilino' }]}
                  >
                  <Input placeholder="Email do inquilino" />
                  </Form.Item>
                </Col>
              </Row>
              :
              <>
                <Form.Item name="email" hidden noStyle></Form.Item>
                <div className="mb-2">{formTenant.getFieldValue('email')}</div>
              </>
            }

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label="Nome"
                  rules={[{ required: true, message: 'Entre com o nome do inquilino' }]}
                >
                  <Input placeholder="Nome do inquilino" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  // hidden={!isInclusao}
                  name="shortName"
                  label="Nome Curto"
                  rules={[{ required: isInclusao, message: 'Entre com o nome curto do inquilino' }]}
                >
                  {/* <Input disabled={!isInclusao} placeholder="Nome curto do inquilino" /> */}
                  <Input placeholder="Nome curto do inquilino" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="isInactive"
                  label="Status"
                >
                  <Radio.Group>
                    <Radio value={true}>Inativo</Radio>
                    <Radio value={false}>Ativo</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Space className="inner-drawer-buttons">
              <Button className="bg-gray-100 rounded-full" onClick={showDrawerUser} disabled={isInclusao}>Usuarios</Button>
              <Button className="bg-gray-100 rounded-full" onClick={showDrawerRole} disabled={isInclusao}>Perfis</Button>
            </Space>
          </Form>
        </div>

        <Space className="drawer-footer flex w-full justify-end">
          <Space>
            <Button onClick={hideDrawerTenant}>Cancel</Button>
            {/* <Button onClick={resetForm}>Reset</Button> */}
            {/* <Button className="bg-ant-blue-100" onClick={showDrawerUser} disabled={isInclusao }>Usuarios</Button>
            <Button className="bg-ant-blue-100" onClick={showDrawerRole} disabled={isInclusao}>Perfis</Button> */}
            <Popconfirm disabled={tenantBlocked}
              title="Deseja realmente excluir este tenant?"
              onConfirm={confirmDeleteTenant}
              onCancel={cancelDelete}
              okText="Sim"
              cancelText="Não"
            >
              <Button onClick={() => { }} type="primary" danger disabled={isInclusao || tenantBlocked || buttonsDisabled} >Delete</Button>
            </Popconfirm>

            <Button onClick={formTenant.submit} disabled={tenantBlocked || buttonsDisabled} type="primary">Submit</Button>
          </Space>
        </Space>

        <Drawer
          title="Users"
          width={360}
          closable={false}
          onClose={hideDrawerUser}
          visible={drawerUserVisible}
          headerStyle={{ borderRadius: "0" }}
          bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
        >
          <div className="inner-drawer-body" >
            <Form layout="vertical" form={formUser} onFinish={handleSubmitUser} >
              <Title level={5}>{formTenant.getFieldValue('name')}</Title>

              {/* TABLE */}
              <div className="flex flex-col justify-between" style={{ height: "calc(100vh - 176px)" }}>
                <div className="envelopetable bg-bluegray-50 h-full overflow-y-auto">
                  <Table dataSource={users} columns={columnsUsers} size="small" rowKey="id"
                    rowClassName={(record, index) => (index === rowSelectedUser ? "rowSelected font-normal" : "font-normal")}
                    pagination={false}
                    scroll={{ y: "calc(100vh - 221px)" }}
                    onRow={(record: any, rowIndex: any) => {
                      return {
                        // onClick: event => { handleRow(record, rowIndex) }, // click row
                        // className: lastSelected === rowIndex ? "lastSelected" : ""
                      };
                    }}
                  />
                </div>
                <div className="mt-2 flex flex-column ">
                  <Button onClick={novoUser} type="primary">Novo Usuário</Button>
                </div>
              </div>
            </Form>
          </div>
          <Space className="drawer-footer flex w-full justify-end">
            <Space>
              <Button onClick={hideDrawerUser}>Cancel</Button>
              {/* <Button onClick={formMenu.submit} disabled={roleBlocked && !roleBlocked} type="primary">Submit</Button> */}
            </Space>
          </Space>
        </Drawer>

        <Drawer
          title="Perfis"
          width={360}
          closable={false}
          onClose={hideDrawerRole}
          visible={drawerRoleVisible}
          headerStyle={{ borderRadius: "0" }}
          bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
        >
          <div className="inner-drawer-body">
            <Form layout="vertical" form={formUser} onFinish={handleSubmitRole} >
              <Title level={5}>{formTenant.getFieldValue('name')}</Title>

            </Form>
          </div>
          <Space className="drawer-footer flex w-full justify-end">
            <Space>
              <Button onClick={hideDrawerRole}>Cancel</Button>
              {/* <Button onClick={formMenu.submit} disabled={roleBlocked && !roleBlocked} type="primary">Submit</Button> */}
            </Space>
          </Space>
        </Drawer>

      </Drawer>
    </Layout>
  )
}