import { useCallback, useEffect, useState } from 'react'
import useProcessando from '../../../data/hooks/useProcessando'
import { apierp } from '../../../services/api'
import { dateFormat } from '../../../utils/dateFunctions'

export default function usePedidosCompraPendentesEntradaByMonth(dateref: any) {
  const { processando, iniciarProcessamento, finalizarProcessamento } = useProcessando()
  const [pedidosCompraPendentesEntrada, setPedidosCompraPendentesEntrada] = useState<any[]>([])
  const [empresas, setEmpresas] = useState<any[]>([])

  const buscarPedidosCompraPendentesEntradaByMonth = useCallback(async function () {
    if (dateref === null) return
    const date = dateFormat(dateref, 'yyyy-MM-dd')

    try {
      iniciarProcessamento()

      const response1 = await apierp.get(`blingpedidoscomprav2pendentesentradabymonth/${date}`)
      const data1 = await response1.data as any;
      setPedidosCompraPendentesEntrada(data1)
      // console.log('pedidosCompra', data1)
      const response2 = await apierp.get('empresas')
      const data2 = await response2.data as any;
      const arr = []
      for (let emp of data2 ) {
        arr[emp.id] = emp.nome
      }
      setEmpresas(arr)

    } catch (err) {
      console.log(err)
    } finally {
      finalizarProcessamento()
    }

  }, [dateref])

  function voltar() {
    setPedidosCompraPendentesEntrada([])
    setEmpresas([])
  }
  function reload() {
    buscarPedidosCompraPendentesEntradaByMonth()
  }
  
  useEffect(() => {
    setPedidosCompraPendentesEntrada([])
    setEmpresas([])
    buscarPedidosCompraPendentesEntradaByMonth()
  }, [buscarPedidosCompraPendentesEntradaByMonth])

  return {
    pedidosCompraPendentesEntrada,
    empresas,
    processando,
    iniciarProcessamento,
    finalizarProcessamento,
    voltar,
    reload
  }

}