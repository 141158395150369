import { Button, Space } from "antd"
import { LockFilled, UnlockFilled, CheckSquareFilled, DownloadOutlined, CopyOutlined, PrinterOutlined, PlusOutlined } from '@ant-design/icons';
import Tabela from "../../../components/template/Tabela"
import { copyToClipboard, downloadToCsv, printDiv } from "../../../utils/functions"
import { useGeral } from "../../../contexts/geral";
import { columnsFichaTecnica } from "./columnsFichaTecnica";
import isPermitted from "../../../hooks/isPermitted";

export function Fichastecnicas(props: any) {
  const { userTenant } = useGeral();
  const colors = userTenant.tenant.colors.split(',')
  const isPermittedManterFT = isPermitted('manter_fichas_tecnicas')

  const { produto, novaFichaTecnica,
    handleRowFichaTecnica, rowFichaTecnicaSelected, lastFichaTecnicaSelected, loadingFichaTecnica,
  } = props
  if (!produto) { return <div>Sem fichas tecnicas</div> }
  // console.log('fichasTecnicas',produto.fichastecnicas)
  const fichasTecnicas = produto.fichastecnicas
  const dadosFlat: any[]=[]
  fichasTecnicas.forEach((it:any) => {
    const item = {
      ...it,
      // footer: false,
      addClass: it.situacao === "Ativo" ? "" : "text-gray-300" 
    }
    dadosFlat.push(item)
  })


  const wTab = 658
  const htb = `calc(100vh - 149px - 110px)`
  const hsc = `calc(100vh - 180px - 109px)`

  return (
    <div className="flex flex-col justify-between" style={{ height: "calc(100vh - 137px)" }}>
      <Tabela className="mx-0" id='fichatecnica' titulo='Fichas Técnicas' subtitulo={produto.codigo + " - " + produto.nome} wTab={wTab} htb={htb} hsc={hsc} dataSource={dadosFlat} columns={columnsFichaTecnica} handleRow={handleRowFichaTecnica} rowSelected={rowFichaTecnicaSelected} lastSelected={lastFichaTecnicaSelected} loading={loadingFichaTecnica} />
      <div className={"pageButtons"} style={{ backgroundColor: colors[3] }}>
        <Space size={10}>
          <Button onClick={() => printDiv('printableDiv-fichatecnica', 'fichatecnica')} shape="round" size="small" icon={<PrinterOutlined />} title="Imprimir"></Button>
          <Button onClick={() => copyToClipboard('fichatecnica')} shape="round" size="small" icon={<CopyOutlined />} title="Copy to Clipboard"></Button>
          <Button onClick={() => downloadToCsv('fichatecnica', "FichasTecnicas.csv")} shape="round" size="small" icon={<DownloadOutlined />} title="Download CSV"></Button>
          <Button onClick={novaFichaTecnica} type="primary" shape="round" size="small" icon={<PlusOutlined />} hidden={!isPermittedManterFT} title="Novo"></Button>
        </Space>
      </div>
    </div>
  )
}
  