import { formatDecimal, formatLength } from "../../../../utils/functions"

export  const columnsOrdemProducaoItem = [
    {
      title: 'Item',
      dataIndex: '',
      ellipsis: true,
      width: '45px',
      align: 'right' as 'right',
      className: 'text-right',
      render: (text: any, record: any, index: any) => {
        return record.codigo === 'Total' ? '' : `${index+1}`
      }
    },
    {
      title: 'Codigo',
      dataIndex: 'codigo',
      width: '90px',
      className: 'text-left',
      ellipsis: false,
      render: (text: any, record: any) => `${text}`
    },
    {
      title: 'Nome do Componente',
      dataIndex: 'descricao',
      width: '500px',
      className: 'text-left',
      ellipsis: false,
      render: (text: any, record: any) => `${text}`
    },
    {
      title: 'UN',
      dataIndex: 'unidade',
      width: '40px',
      className: 'text-left',
      ellipsis: true,
      render: (text: any, record: any) => `${text}`
    },
    {
      title: 'Deposito',
      dataIndex: 'nomeDeposito',
      width: '150px',
      className: 'text-left',
      ellipsis: true,
      render: (text: any, record: any) => `${text}`
    },
    {
      title: 'Estoque Disponivel',
      dataIndex: 'estoqueDisponivel',
      ellipsis: true,
      width: '120px',
      align: 'right' as 'right',
      className: 'text-right',
      onCell: (record: any, i: any) => ({ className: record.estoqueDisponivel < 0 ? "text-red-600 font-semibold" : "text-blue-600" }),
      // render: (text: any, record: any) => `${formatLength(+text, 8)}`
      render: (text: any, record: any) => `${(+((+text).toFixed(6))).toString().replace('.', ',')}`
    },
    {
      title: 'Consumo',
      dataIndex: 'quantConsumida',
      ellipsis: true,
      width: '120px',
      align: 'right' as 'right',
      className: 'text-right',
      onCell: (record: any, i: any) => ({ className: record.estoqueDisponivel < record.quantConsumida ? "text-red-600 font-semibold" : "text-blue-600" }),
      // render: (text: any, record: any) => `${formatLength(+text, 8)}`
      render: (text: any, record: any) => `${(+((+text).toFixed(6))).toString().replace('.', ',')}`



    },
    
    {
      title: 'PU.Cmc',
      dataIndex: 'cmcUnitario',
      width: '80px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },
    {
      title: 'PT.Cmc',
      dataIndex: 'cmcTotal',
      width: '85px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },

  ]