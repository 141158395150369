import * as React from "react"
import { Modal } from "antd"
// import { ExclamationCircleOutlined } from '@ant-design/icons';
// import Title from "antd/lib/typography/Title";
// import { apierp } from "../../services/api";
// import { useNavigate } from "react-router-dom";
// import { useAuth } from "../../contexts/auth";
const { error } = Modal;

export function customMessage(err: any) {
  // const { Logout, user } = useAuth();
  let msg: any = {}
  if (err.response) {
    msg = err.response.status === 400
    ? {status: err.response.status, message: err.response.data} 
    : err.response.data.error 
      ? {status: err.response.status, message: err.response.data.error} 
      :  {status: err.response.status, message: err.response.statusText}
  } else {
    msg ={ status: null , message: err.message}
  }
  const mensagem = msg.status ? `${msg.status} - ${msg.message}` : msg.message
  
  showError(mensagem)
  return(msg.status)
}

function showError(mensagem: any) {
  // const { Logout, user } = useAuth();
  error({
    title: ('Ocorreu um Erro!'),
    content: (<div className="bg-red-700 text-base text-white font-normal px-2 py-1 mt-3">{mensagem}</div>),
    width: 600,
    centered: true,
    onOk() {

    },
  });
}
