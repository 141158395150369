import { useEffect, useState } from "react"
import { LockFilled, UnlockFilled, CheckSquareFilled, StarFilled, DownloadOutlined, CopyOutlined, PrinterOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, message, Popconfirm, Radio, Row, Select, Space, Table, Typography } from 'antd';
import { printDiv, copyToClipboard, downloadToCsv } from "../../../utils/functions";
import { customMessage } from "../../../components/template/CustomMessage";

import { useAuth } from "../../../contexts/auth";
import { apierp } from '../../../services/api';
import { useNavigate } from "react-router-dom";
import { useGeral } from "../../../contexts/geral";

import Layout from "../../../components/template/Layout";
import Spinner from "../../../components/template/Spinner";

const { Search } = Input;
const { Title } = Typography;

export default function Menu() {
  const { Logout, user } = useAuth();
  const usuario: any = user
  const { userMenu, menuSelected, userTenant, } = useGeral();
  const menu = userMenu.find((x: any) => x.id == menuSelected)

  const { Option } = Select;
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [toggleState, setToggleState] = useState(false)
  
  const [rowSelected, setRowSelected] = useState(-1)
  const [lastSelected, setLastSelected] = useState(-1)
  const [isInclusao, setIsInclusao] = useState(false)

  const [menus, setMenus] = useState([])
  const [menuBlocked, setMenuBlocked] = useState(true)
  const [buttonsDisabled, setButtonsDisabled] = useState(false)

  const descSecurityLevel = ['root', 'master', 'admin', 'manager-0', 'manager-1', 'manager-2', 'user-nivel-6', 'user-nivel-7', 'user-nivel-8', 'user-nivel-9' ];

  useEffect(() => {
    setLoading(true)
    setTimeout(async function(){ 
      try{
        await loadMenus()
      } catch(err) {
        if (customMessage(err)===403) Logout()
      }
      setLoading(false) 
    }, 500);
  }, [toggleState])

  async function loadMenus() {
    const response = await apierp.get('menush')
    const data = response.data as any;
    const menuFlat: any = toFlat(data)
    setLastSelected(-1)
    setMenus(menuFlat);
  }

  function toFlat(menus: any) {
    const menuflat: any = []
    menus.forEach((m0: any) => {
      const { id, parentId, level, seq, icon, label, url, isInactive, securityLevel } = m0
      menuflat.push({ id, parentId, level, seq, icon, label, url, isInactive, securityLevel })
      m0.items && m0.items.forEach((m1: any) => {
        const { id, parentId, level, seq, icon, label, url, isInactive, securityLevel } = m1
        menuflat.push({ id, parentId, level, seq, icon, label, url, isInactive, securityLevel })
        m1.items && m1.items.forEach((m2: any) => {
          const { id, parentId, level, seq, icon, label, url, isInactive, securityLevel } = m2
          menuflat.push({ id, parentId, level, seq, icon, label, url, isInactive, securityLevel })
          m2.items && m2.items.forEach((m3: any) => {
            const { id, parentId, level, seq, icon, label, url, isInactive, securityLevel } = m3
            menuflat.push({ id, parentId, level, seq, icon, label, url, isInactive, securityLevel })
            m3.items && m3.items.forEach((m4: any) => {
              const { id, parentId, level, seq, icon, label, url, isInactive, securityLevel } = m4
              menuflat.push({ id, parentId, level, seq, icon, label, url, isInactive, securityLevel })
            })
          })
        })
      })
    })
    return(menuflat)
  }

  async function handleRow(record: any, index: any) {
    setLastSelected(index)
    // const block = ['root','master','admin']
    const isBlocked = menus.findIndex((x: any) => x.parentId === record.id) >= 0
    if (rowSelected === index) {
      setRowSelected(-1)
      setMenuBlocked(true)
    } else {
      setRowSelected(index)
      setMenuBlocked(isBlocked)
      setIsInclusao(false)
      showDrawer()
      const menuy: any = {
        id: record.id,
        parentId: record.parentId,
        level: record.level,
        seq: record.seq,
        icon: record.icon,
        label: record.label,
        url: record.url,
        isInactive: record.isInactive,
        securityLevel: record.securityLevel,
      }
      fillForm(menuy)
      // await loadMenu(record.id)
    }
  }

  const showDrawer = () => {
    setButtonsDisabled(false)
    setDrawerVisible(true)
  };

  const hideDrawer = () => {
    setButtonsDisabled(false)
    setRowSelected(-1)
    // setMenu(null)
    setDrawerVisible(false)
  };

  function enableButtons(wait: number) {
    setTimeout(function () {
      setButtonsDisabled(false)
      // setMsgError('')
    }, wait);
  }

  async function handleSubmit(dados: any){
    setButtonsDisabled(true)
    dados.parentId = +dados.parentId === 0 ? null : +dados.parentId
    dados.seq = +dados.seq;
    // dados.url = dados.url?.trim() === '' ? null : dados.url
    dados.label = dados.label.trim()
    dados.url = dados.url?.toLowerCase().trim()
    if (dados.id ==='') {
      //CREATE
      try {
        const result = await apierp.post('menus', dados);
        setToggleState(!toggleState)
        message.success('Menu incluido com sucesso!');

        hideDrawer()
      } catch (err) {
        if (customMessage(err)===403) Logout()
        enableButtons(1000)
      }
    } else {
      //UPDATE
      try {
        const result = await apierp.put(`menus/${dados.id}`, dados);
        setToggleState(!toggleState)
        message.success('Menu alterado com sucesso!');
        hideDrawer()
      } catch (err) {
        if (customMessage(err)===403) Logout()
      }
    }

  };

  async function handleDelete(){
    const dados = form.getFieldsValue()
    try {
      await apierp.delete(`menus/${dados.id}`);
      setToggleState(!toggleState)
      setLastSelected(-1)
      message.success('Menu excluido com sucesso!');
      hideDrawer()
    } catch (err) {
      if (customMessage(err)===403) Logout()
    }

  };

  function confirmDelete(e: any) {
    handleDelete();
  }
  
  function cancelDelete(e: any) {
    message.error('Menu não foi excluído');
  }

  const newMenu= () => {
    form.resetFields();
    setIsInclusao(true)
    setMenuBlocked(false)
    showDrawer()
    form.setFieldsValue({ id: '', seq: 99999 })
  }

  const fillForm = (menuy: any) => {
    form.setFieldsValue({
      id: menuy.id,
      parentId: menuy.parentId,
      level: menuy.level,
      seq: menuy.seq,
      icon: menuy.icon,
      label: menuy.label,
      url: menuy.url,
      isInactive: menuy.isInactive,
      securityLevel: menuy.securityLevel,
    });
  };

  const resetForm = () => {
    setRowSelected(-1)
    newMenu() // form.resetFields();
  };

  const columns = [
    // {
    //   title: '░',
    //   width: '17px',
    //   onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" })
    // },
    {
      title: 'Id',
      dataIndex: 'id',
      ellipsis: true,
      width: '50px',
      className: 'text-right',
      // onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" })
    },
    {
      title: 'Pai',
      dataIndex: 'parentId',
      ellipsis: true,
      width: '50px',
      className: 'text-right'
    },
    {
      title: 'level',
      dataIndex: 'level',
      ellipsis: true,
      width: '50px',
      className: 'text-center'
    },
    {
      title: 'seq',
      dataIndex: 'seq',
      ellipsis: true,
      width: '70px',
      align: 'right' as 'right',
      className: 'text-right'
    },
    {
      title: 'Label',
      dataIndex: 'label',
      width: '180px',
      className: 'text-left',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: record.level > 0 ? `my-pl-td-${record.level}` : "" }),
      render(text: any, record: any, i: any) {
        return {
          children: (<div className = {`my-pl-td-${record.level}`} >{text}</div>)
        }
      }
    },
    {
      title: 'url',
      dataIndex: 'url',
      width: '220px',
      className: 'text-left',
      ellipsis: true,
    },
    {
      title: 'icon',
      dataIndex: 'icon',
      width: '200px',
      className: 'text-left',
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'isInactive',
      width: '90px',
      className: 'text-left',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: record.isInactive ? "text-red-500 my-nowrap" : "text-blue-500 my-nowrap" }),
      render(text: any, record: any, i: any) {
        return {
          children: (<>{record.url ? <div> {text ? <div><LockFilled /> Inativo</div> : <div><CheckSquareFilled /> Ativo</div>} </div> : ''}</>)
        }
      }
    },
    // {
    //   title: 'Only Root',
    //   dataIndex: 'securityLevel',
    //   width: '100px',
    //   onCell: (record: any, i: any) => ({ className: record.securityLevel ? "text-red-500 my-nowrap" : "text-blue-500 my-nowrap" }),
    //   render(text: any, record: any, i: any) {
    //     return {
    //       children: (<>{record.url ? <div> {text ? <div><StarFilled /> Only Root</div> : <div><CheckSquareFilled /> All</div>} </div> : ''}</>)
    //     }
    //   }
    // },
    {
      title: 'Sec Level',
      dataIndex: 'securityLevel',
      width: '120px',
      className: 'text-left',
      ellipsis: true,
      // render : (text: any, record:any ) => `(${text}) ${descSecurityLevel[text]}`
      render(text: any, record: any, i: any) {
        return {
          children: (<>{record.url ? `(${text}) ${descSecurityLevel[text]}` : ''}</>)
        }
      }
    },
  ]

  const botoes = () => {
    return (
      <Space size={10}>
        <Button onClick={() => printDiv('printableDiv-menu', 'menu')} shape="round" size="small" icon={<PrinterOutlined />} title="Imprimir"></Button>
        <Button onClick={() => copyToClipboard('menu')} shape="round" size="small" icon={<CopyOutlined />} title="Copy to Clipboard"></Button>
        <Button onClick={() => downloadToCsv('menu', "Menu.csv")} shape="round" size="small" icon={<DownloadOutlined />} title="Download CSV"></Button>
        <Button onClick={() => newMenu()} type="primary" shape="round" size="small" icon={<PlusOutlined />} title="Novo"></Button>
      </Space>
    )
  }

  const wTab = 988 +30 +20 // liquido = x-17

  const htb = `calc(100vh - 149px - 110px)`
  const hsc = `calc(100vh - 180px - 109px)` // 1 a menos que htb

  return (
    <Layout titulo={menu.label} botoes={botoes()}>
      <div className="spinner mt-5 justify-center items-center " style={{ display: loading ? "flex" : "none" }} >
        <Spinner />
      </div>
      <div className="bg-blue-30 mx-5 overflow-x-auto">
        <div className="flex flex-row justify-center items-center mx-auto " style={{ maxWidth: `${wTab}px`, minWidth: `${wTab}px` }}>
          <div id="printableDiv-menu" className="pt-6  pb-[1px] fadein" style={{ display: loading ? "none" : "" }}>
            <h1 className='hidden mb-1 text-2xl font-bold'>Menu do Sistema</h1>
            {/* TABLE */}
            <div className="flex flex-col justify-between bg-[#f8f9fb]" style={{ maxHeight: htb }}> {/** ou heigth */}
              <div className="envelopetable ">
                <Table id='menu' dataSource={menus} columns={columns} size="small" rowKey="id"
                  rowClassName={(record, index) => {
                    let addClass= index === rowSelected ? "rowSelected" : ""
                    addClass+= record.level === 0 ? " font-bold bg-[#EEEEEE]" : ""
                    return addClass
                  }}

                  pagination={false}
                  scroll={{ y: hsc }}
                  onRow={(record: any, rowIndex: any) => {
                    return {
                      onClick: event => { handleRow(record, rowIndex) }, // click row
                      className: lastSelected === rowIndex ? "lastSelected" : ""
                    };
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* DRAWER FORM */}
      <Drawer
        title={ (isInclusao ? "Criar um novo" : "Alterar") + " Menu"}
        width={360}
        closable={false}
        maskClosable={false}
        onClose={hideDrawer}
        visible={drawerVisible}
        headerStyle={{ borderRadius: "0" }}
        bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
      >
        <div className="inner-drawer-body">
          <Form layout="vertical" form={form} onFinish={handleSubmit} >
            <Title level={5}>{form.getFieldValue('label')}</Title>
            <Form.Item name="id" hidden ></Form.Item>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="parentId"
                  label="Pai"
                  // rules={[{ required: true, message: 'Selecione Um Pai' }]}
                >
                  <Select placeholder="Quem é o Pai">
                    <Option value={null}>É Nivel Raiz</Option>
                    {menus.filter((x: any) => !x.url && x.id !== menuSelected ).map((m: any, index: any) => {
                      return <Option value={m.id}>{"-".repeat(m.level*3)} {m.label}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="seq"
                  label="Seq"
                  rules={[{ required: true, message: 'Entre com ?????' }]}
                >
                  <Input placeholder="Seq" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
            <Col span={24}>
                <Form.Item
                  name="label"
                  label="Label"
                  rules={[{ required: true, message: 'Entre com Label' }]}
                >
                  <Input placeholder="Label do menu" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
            <Col span={24}>
                <Form.Item
                  name="url"
                  label="Url"
                  // rules={[{ required: true, message: 'Entre com Url' }]}
                >
                  <Input placeholder="Url do menu" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
            <Col span={24}>
                <Form.Item
                  name="icon"
                  label="Icon"
                  // rules={[{ required: true, message: 'Entre com Url' }]}
                >
                  <Input placeholder="Icon do menu" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="isInactive"
                  label="Status"
                  hidden={!form.getFieldValue('url')}
                >
                  <Radio.Group>
                    <Radio value={true}>Inativo</Radio>
                    <Radio value={false}>Ativo</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="securityLevel"
                  label="Nível de Segurança"
                  rules={[{ required: true, message: 'Selecione Nível de segurança' }]}
                >
                  <Select placeholder="Selecione um Nível de segurança">
                    <Option value={''}>Selecione um Nível de segurança</Option>
                    {descSecurityLevel.map((sl: any, index: any) => {
                      return <Option value={index}>({index}) {sl}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

          </Form>
        </div>
        <Space className="drawer-footer">
          <Space>
            <Button onClick={hideDrawer}>Cancel</Button>
            {/* <Button onClick={resetForm}>Reset</Button> */}
            <Popconfirm disabled={ menuBlocked }
              title="Deseja realmente excluir este menu?"
              onConfirm={confirmDelete}
              onCancel={cancelDelete}
              okText="Sim"
              cancelText="Não"
            >
              <Button onClick={() => {}} type="primary" danger disabled={isInclusao || menuBlocked || buttonsDisabled} >Delete</Button>
            </Popconfirm>

            <Button disabled={buttonsDisabled} onClick={form.submit} type="primary">Submit</Button>
          </Space>
        </Space>
      </Drawer>
    </Layout>
  )
}