import { dateMySqlToPtBr } from "../../../utils/dateFunctions"
import { formatDecimalZ } from "../../../utils/functions"

export function CardClienteResumo(props: any) {
    const cliente = props.cliente
    const resumo = props.resumo
    const { pedidosCliente, totalProdutos, totalFretes, ultimoPedido, diasUltimoPedido, intervaloMinimo, intervaloMedio, intervaloMaximo, ranking, ranking12, ranking6, dataRanking } = resumo
    const rt = ranking ? ranking.substring(0, ranking.length - 1) : 0
    const r12 = ranking12 ? ranking12.substring(0, ranking12.length - 1) : 0
    const r6 = ranking6 ? ranking6.substring(0, ranking6.length - 1) : 0
    const cor12 = +r12 < +rt ? "text-green-500" : +r12 > +rt ? "text-red-500" : ""
    const cor6 = +r6 < +r12 ? "text-green-500" : +r6 > +r12 ? "text-red-500" : ""
    return (
        <div className="card-cliente-resumo p-2 flex-1 bg-bluex-100 " hidden={!cliente}>
            <div className="flex font-bold items-center" hidden={rt===0}>
                <div className="min-w-[70px] text-left">Ranking: </div>
                <div className="">{ranking}</div>
                <div className={`font-semibold ml-8`}>R12: </div>
                <div className={`font-semibold ml-2 ${cor12}`}>{ranking12}</div>
                <div className={`font-semibold ml-4`}>R6: </div>
                <div className={`font-semibold ml-2 ${cor6}`}>{ranking6}</div>
                {/* <div className="ml-10 font-normal text-xs">Rankeado em: {dataRanking}</div> */}
            </div>
            <div className="flex"><div className="min-w-[100px] text-left">Qtd Pedidos: </div><div className="">{pedidosCliente.length}</div></div>
            <div className="flex"><div className="min-w-[100px] text-left">Total Produtos:</div><div className="font-bold">{formatDecimalZ(totalProdutos, 2)}</div></div>
            <div className="flex"><div className="min-w-[100px] text-left">Total Fretes:</div><div className="font-bold">{formatDecimalZ(totalFretes, 2)}</div></div>
            <div className="flex"><div className="mr-1 text-left">Último pedido em:</div><div className="font-bold" hidden={!(diasUltimoPedido >= 0)}>{dateMySqlToPtBr(ultimoPedido?.data)} - há {diasUltimoPedido} dias</div></div>
            <div className="flex"><div className="min-w-[115px] text-left">Intervalo Mínimo:</div><div className="">{intervaloMinimo} dias</div></div>
            <div className="flex font-bold"><div className="min-w-[115px] text-left">Intervalo Médio:</div><div className="">{intervaloMedio} dias</div></div>
            <div className="flex"><div className="min-w-[115px] text-left">Intervalo Máximo:</div><div className="">{intervaloMaximo} dias</div></div>
        </div>
    )

}