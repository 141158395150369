// import { Select, Spin } from 'antd';
// import type { SelectProps } from 'antd/es/select';
// import debounce from 'lodash/debounce';
// import React, { useMemo, useRef, useState } from 'react';

import { format, parseISO } from "date-fns";
import { Button, Input, Modal, Popconfirm, Radio, RadioChangeEvent, Select, Space, Tooltip, message } from "antd";
import { FaSave, FaRegTrashAlt } from 'react-icons/fa'
import { CiSquarePlus } from "react-icons/ci";
import Layout from "../../../components/template/Layout";
import { apierp } from "../../../services/api";
import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { ellipsis, formatDecimal, formatDecimalZ, formatarCpfCnpj } from "../../../utils/functions";
import { addDaysToDate, addMonthsToDate, dateFormatBr, dateMySqlToPtBr, differenceInDaysBetweenDates } from "../../../utils/dateFunctions";
import SpinnerNew from "../../../components/template/SpinnerNew";
import { groupByTs } from "../../../utils/tsFunctions";
import { Vendas } from "./vendas";
import ModalProdutos from "./ModalProdutos";
import useWindowSize from "../../../hooks/useWindowSize";
import { CardCliente } from "../../__Shared/Clientes/CardCliente";
import { CardClienteResumo } from "../../__Shared/Clientes/CardClienteResumo";
import ModalClienteVinculado from "./ModalClienteVinculado";
import TextArea from "antd/lib/input/TextArea";


export default function DashboardClientes() {

    const [empresas, setEmpresas] = useState([])
    const [termo, setTermo] = useState(null as any);
    const [text, setText] = useState(null as any);
    const [valueDebounce] = useDebounce(text, 500);
    const [retorno, setRetorno] = useState(null as any);
    const [clientId, setClientId] = useState(null as any); // TODO - verificar se precisa
    const [cliente, setCliente] = useState(null as any);
    const [clienteVinculado, setClienteVinculado] = useState(null as any);
    const [pedidos, setPedidos] = useState(null as any);
    const [pedido, setPedido] = useState(null as any);
    const [obsInterna, setObsInterna] = useState(null as any);

    const [showModalProdutos, setShowModalProdutos] = useState(false)
    const [showModalClienteVinculado, setShowModalClienteVinculado] = useState(false)

    const [showParcelas, setShowParcelas] = useState(false)
    const [seletor, setSeletor] = useState('T' as any) // T = todos, M = Meus
    const [meusClientes, setMeusClientes] = useState([] as any)
    const [clienteSelected, setClienteSelected] = useState(null as any)

    useEffect(() => {
        loadEmpresas()
        loadMeusClientes()
    }, [])

    async function loadEmpresas() {
        const response = await apierp.get('empresas')
        const data = response.data as any;
        setEmpresas(data);
    }
    async function loadMeusClientes() {
        // console.log('loadMeusClientes')
        const response = await apierp.post('clientes/obtermeusclientes')
        const data = response.data as any;
        setMeusClientes(data);
    }

    async function loadClientes(dados: any) {
        const response = await apierp.post(`clientes/obterclientesportermo`, dados)
        const data = await response.data as any;
        // console.log(data)
        setRetorno(data)
        return data
    }

    async function loadPedidos(dados: any) {
        if (dados.clienteId === null) {
            return
        }
        const response = await apierp.post(`clientes/obterpedidosclientesvinculados`, dados)
        const data = await response.data as any;
        setPedidos(data)
    }

    const handleSearch = (newValue: string) => {
        // if (newValue.includes("'")) {
        //     newValue = newValue.replaceAll("'", "%")
        // } // resolvido no backend
        if (newValue.length < 3) {
            setRetorno(null)
            return
        }
        setTermo(newValue);
        const dados = { termo: newValue, limit: 50 }
        loadClientes(dados)
    };

    const handleChange = async (newValue: string) => {
        setObsInterna(null)
        setPedidos(null)
        setCliente(null)
        setText(newValue);
        setClientId(newValue); // TODO - verificar se precisa
        const cliente = await retorno?.clientes.find((c: any) => c.id === newValue)
        const dados = { clienteId: newValue }
        await loadPedidos(dados)
        setCliente(cliente)
    };

    const handleSearchMeus = async (value: string) => {
        // console.log('handleSearchMeus', value)
    }

    const handleChangeMeus = async (value: string) => {
        // console.log('handleChangeMeus', value)
        setClienteSelected(value)
        setObsInterna(null)
        setPedidos(null)
        setCliente(null)
        setText(null);
        setClientId(value); // TODO - verificar se precisa
        const clientex = await meusClientes.find((c: any) => c.clienteId === value)
        const dados = { termo: clientex.nome, limit: 50 }
        const retorno = await loadClientes(dados)
        const cliente = await retorno?.clientes.find((c: any) => c.id === value)
        await loadPedidos({ clienteId: value })
        setCliente(cliente)
        setText(null);
    }

    const onChangeSeletor = (e: RadioChangeEvent) => {
        setText(null);
        setCliente(null)
        setClientId(null); // TODO - verificar se precisa
        setRetorno(null);
        setPedidos(null);
        setClienteSelected(null);
        setSeletor(e.target.value);
    };

    const handleClear = () => {
        setText(null);
        setClientId(null); // TODO - verificar se precisa
        setRetorno(null);
        setPedidos(null);
    };

    const hideModalProdutos = () => {
        setShowModalProdutos(false)
    };
    const hideModalClienteVinculado = () => {
        setShowModalClienteVinculado(false)
    };

    async function handleClickPedido(p: any) {
        setPedido(p)
        setShowModalProdutos(true)
    }

    async function handleClickClienteVinculado(p: any) {
        setClienteVinculado(p)
        setShowModalClienteVinculado(true)
    }

    // inicio do código para gerar o gráfico de vendas mensais
    const ultimos = 24
    const now = new Date()
    const to = addDaysToDate(now, 1 - now.getDate())
    const from = addMonthsToDate(to, -ultimos)
    // console.log('from', from, 'to', to)
    // console.log('getdia', now.getDate(), 'getmonth', now.getMonth(), 'getyear', now.getFullYear())
    // array de meses entre from e to
    const meses: any = []
    let cur = from
    if (from) {
        for (let cur = from; cur <= to; cur = addMonthsToDate(cur, 1)) {
            const anomes = cur.substring(0, 7)
            meses.push({ anomes, totalvenda: 0, totalprodutos: 0 })
        }
    }

    const pedidosCliente: any = pedidos?.pedidosCliente ?? []
    let clientesVinculados: any = pedidos?.clientesVinculados ?? []

    const totaisVendasPorCliente = groupByTs(pedidosCliente, ['clientId'], ['totalprodutos', 'totalvenda'])

    let vendasMensais = pedidosCliente.map((p: any) => {
        return { anomes: p.data.substring(0, 7), totalvenda: p.totalvenda, totalprodutos: p.totalprodutos }
    }).filter((x: any) => x.anomes >= from.substring(0, 7) && x.anomes <= to.substring(0, 7))
    vendasMensais = vendasMensais.concat(meses)

    const vendasMensaisGrouped = groupByTs(vendasMensais, ['anomes'], ['totalprodutos', 'totalvenda'])
        .sort((a: any, b: any) => a.anomes > b.anomes ? 1 : -1)

    // fim do código para gerar o gráfico de vendas mensais

    const emp: any = empresas

    if (pedidosCliente.length > 0) pedidosCliente[pedidosCliente.length - 1].intervalo = 0
    let intervaloMinimo = pedidosCliente.length > 1 ? 999999 : 0
    let intervaloMaximo = 0
    let intervaloTotal = 0
    let totalVenda = pedidosCliente.reduce((acc: any, cur: any) => acc + cur.totalvenda, 0)
    let totalProdutos = pedidosCliente.reduce((acc: any, cur: any) => acc + cur.totalprodutos, 0)
    let totalFretes = pedidosCliente.reduce((acc: any, cur: any) => acc + cur.valorfrete, 0)

    for (let i = pedidosCliente.length - 2; i >= 0; i--) {
        const intervalo = differenceInDaysBetweenDates(pedidosCliente[i].data, pedidosCliente[i + 1].data)
        pedidosCliente[i].intervalo = intervalo
        intervaloMinimo = intervalo < intervaloMinimo ? intervalo : intervaloMinimo
        intervaloMaximo = intervalo > intervaloMaximo ? intervalo : intervaloMaximo
        intervaloTotal += intervalo
    }
    const intervaloMedio = pedidosCliente.length > 1 ? (intervaloTotal / (pedidosCliente.length - 1)).toFixed(0) : 0

    const ultimoPedido = pedidosCliente[0]
    // console.log('ultimoPedido', ultimoPedido)
    const diasUltimoPedido = differenceInDaysBetweenDates(format(new Date(), 'yyyy-MM-dd'), ultimoPedido?.data)
    // console.log('diasUltimoPedido', diasUltimoPedido)
    const ultEmpresa = emp[ultimoPedido?.empresaId - 1]?.nome.replace('Colorbrilho ', '')
    // console.log(format(new Date(), 'yyyy-MM-dd'))
    // console.log(differenceInDaysBetweenDates(format(new Date(), 'yyyy-MM-dd'), ultimoPedido?.data))

    const clientToCard = (clientesVinculados.length > 0) ? clientesVinculados[0] : cliente

    const resumo = {
        pedidosCliente,
        totalProdutos,
        totalFretes,
        ultimoPedido,
        diasUltimoPedido,
        intervaloMinimo,
        intervaloMedio,
        intervaloMaximo,
        ranking: clientToCard?.rankId ? clientToCard?.rankId.toString() + 'º' : 'Não classificado',
        ranking12: clientToCard?.rankId12 ? clientToCard?.rankId12.toString() + 'º' : '',
        ranking6: clientToCard?.rankId6 ? clientToCard?.rankId6.toString() + 'º' : '',
        dataRanking: clientToCard?.dataRanking ? dateFormatBr(clientToCard?.dataRanking, 'dd/MM/yyyy HH:mm') : '',
    }

    // TODO - INCLUIR DESCONTO E VENDEDOR NA LISTA DE PEDIDOS
    // TODO - fazer o gráfico de vendas mensais

    let [innerW, innerH] = useWindowSize()
    // const minh= innerH -208 style={{minHeight: minh}}
    const wTab = 980 + 48 // largura da tabela (está sobrando 8px)

    async function atualizaClienteObsInterna(dados: any) {
        const { clienteId, obsInterna } = dados
        const cliente = await retorno?.clientes.find((c: any) => c.id === clienteId)
        // cliente.obsInterna = obsInterna
        setCliente({ ...cliente, obsInterna })

        if (clientesVinculados.length > 0) {
            let index = clientesVinculados.findIndex((c: any) => c.id === clienteId)
            clientesVinculados[index].obsInterna = obsInterna
        }
        setObsInterna(null)
    }

    function confirmaExclusao(clientId: any) {
        confirmaInclusao(clientId, 'excluir')
        // message.error('Exclusão ainda não implementada!');
    };

    async function confirmaInclusao(clienteId: any, excluir: any = null) {
        let dados = { clienteId, obsInterna }
        if (excluir) { dados = { clienteId, obsInterna: null } }
        // console.log('confirmaInclusao', dados)

        try {
            const response = await apierp.post(`clientes/updateobsinterna`, dados)
            const data = response.data as any;
            // console.log('data', response)
            if (data.success) {
                Modal.success({ title: 'Sucesso', content: data.message, });
                await atualizaClienteObsInterna(dados) // atualiza o cliente na tela
            } else {
                Modal.info({ content: data.message, });
            }
        } catch (err: any) {
            console.log('err', err)
            Modal.error({ title: 'Erro', content: err.message, });
        } finally {
            // reload()
        }

        // message.error('Inclusão ainda não implementada!');
    };

    // const cancel = (e: any) => {
    //     // message.error('Click on No');
    // };

    // console.log('clientesVinculados', clientesVinculados)
    const temObs = clientesVinculados.filter((c: any) => c.obsInterna !== null).length > 0 || (cliente && cliente.obsInterna)
    const temObsMaster = (clientesVinculados.length > 0 && clientesVinculados[0].obsInterna !== null) || (cliente && cliente.obsInterna)
    const idMaster = clientesVinculados.length > 0 ? clientesVinculados[0].id : cliente?.id
    const idCliente = cliente ? cliente?.id : null
    const idInclusao = idMaster || idCliente
    // console.log('idMaster', idMaster, 'idCliente', idCliente, 'idInclusao', idInclusao)
    // console.log('temObs', temObs, 'temObsMaster', temObsMaster)

    const confTitle = 'Deseja realmente excluir a observação?'
    const observacoes = <>
        {clientesVinculados.length === 0 && cliente?.obsInterna !== null && <div hidden={!temObs} className="flex items-center">
            {/* <div className="w-12">#{cliente?.id}</div> */}
            <TextArea className="my-[1px]" rows={1} value={cliente?.obsInterna} />
            <Popconfirm placement="right" title={confTitle} onConfirm={() => confirmaExclusao(cliente.id)} okText="Sim" cancelText="Não">
                <div className="ml-2 pt-[2px] text-red-400 text-lg"><Tooltip placement="top" title={'Excluir observação'}><FaRegTrashAlt /></Tooltip></div>
            </Popconfirm>

        </div>
        }
        {clientesVinculados.filter((c: any) => c.obsInterna !== null).map((c: any, i: number) => <div hidden={!temObsMaster && !temObs} className="flex items-center">
            {/* <div className="w-12">#{c?.id}</div> */}
            {/* <div>{c?.obsInterna}</div> */}
            <TextArea className="my-[1px]" rows={1} value={c?.obsInterna} />
            <Popconfirm placement="right" title={confTitle} onConfirm={() => confirmaExclusao(c.id)} okText="Sim" cancelText="Não">
                <div className="ml-2 pt-[2px] text-red-400 text-lg"><Tooltip placement="top" title={'Excluir observação'}><FaRegTrashAlt /></Tooltip></div>
            </Popconfirm>

        </div>)
        }
    </>
    // console.log('observacoes', observacoes)

    const botoes = () => {
        return (
            <Space size={5}>
                <h3 className="pt-[6px] mr-3">Clientes:</h3>
                <Radio.Group className="font-semibold" onChange={onChangeSeletor} value={seletor}>
                    <Radio value={'T'}>Todos</Radio>
                    <Radio value={'M'}>Meus</Radio>
                </Radio.Group>
            </Space>
        )
    }

    return (
        <Layout titulo={'Dashboard do Cliente'} botoes={botoes()} >
            <div className="flex flex-col justify-start p-5" >
                <div className={`flex items-center mx-auto min-w-[1028px]`}>
                    <div className="flex flex-col ">
                        <div className="flex items-start h-[24px] font-semibold">
                            <div hidden={!retorno || seletor === 'M' || !text} >Termo: {termo} - Retornados {retorno?.retornados}/{retorno?.encontrados}</div>
                        </div>
                        {seletor === 'T' ? (
                            <Select
                                // allowClear
                                showSearch
                                value={valueDebounce}
                                placeholder={'Digite parte do nome ou inicio do CNPJ/CPF'}
                                style={{ width: '312px' }}
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                onSearch={handleSearch}
                                onChange={handleChange}
                                // onClear={handleClear}
                                notFoundContent={null}
                                popupClassName="border-solid border rounded border-blue-500"
                                options={(retorno?.clientes || []).map((d: any) => ({
                                    value: d.id,
                                    label: d.nome,
                                }))}
                            />
                        ) : (
                            <Select
                                // allowClear
                                showSearch
                                value={clienteSelected}
                                placeholder={'Digite parte do nome'}
                                style={{ width: '312px' }}
                                defaultActiveFirstOption={false}
                                // showArrow={false}
                                filterOption={(input, option) =>
                                    typeof option?.label === 'string' && (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                onSearch={handleSearchMeus}
                                onChange={handleChangeMeus}
                                // onClear={handleClear}
                                // notFoundContent={null}
                                // optionFilterProp="children" 
                                popupClassName="border-solid border rounded border-blue-500"
                                // {meusClientes.map((cli: any, index: any) => {
                                //     return <Option value={cli.id} className="text-blue-800 font-semibold"> {produto.codigo} - {produto.nomeComponente}</Option>
                                //   })}
                                options={(meusClientes || []).map((c: any) => ({
                                    value: c.clienteId,
                                    label: c.nome,
                                }))}
                            />
                        )}



                    </div>
                </div>
                <SpinnerNew loading={!cliente && text} />
                <div className=" overflow-x-auto" >
                    <div className="flex flex-col mx-auto" style={{ maxWidth: `${wTab}px`, minWidth: `${wTab}px` }}>
                        {/* DADOS DO CLIENTE */}
                        <div className="mt-2  rounded bg-slatex-200">
                            {/* <div className="title p-1 px-2 rounded-t bg-slate-500 text-white">DADOS CLIENTE</div> */}
                            <h2 className="title mt-3 mb-0">Dados do Cliente</h2>
                            <div className="flex gap-2 min-h-[192px] bg-slate-50" >
                                <CardCliente cliente={clientToCard} wCard={'w-[680px]'} />
                                <CardClienteResumo cliente={cliente} resumo={resumo} />
                            </div>
                        </div>

                        {/* observacoes hidden={!temObs} */}
                        <div className="mt-2 rounded bg-slatex-200">
                            {/* <button className="title mt-3 mb-1 w-32 text-center font-semibold bg-white cursor-pointer border border-solid rounded-sm" onClick={() => {}}>(+/-)</button> */}
                            <div className="flex items-center h-8 mt-4 bg-redx-400 ">
                                <h2 className="mt-1">Observações</h2>
                            </div>
                            {temObs || temObsMaster ? (
                                <div>
                                    {observacoes}
                                </div>

                            ) : (
                                idInclusao ? (
                                    <div className="flex items-center">
                                        {/* <div className="w-12">#{idInclusao}</div> */}

                                        <div className="flex flex-1">
                                            <TextArea rows={1} className="w-full" placeholder="Digite uma observação"
                                                onChange={e => setObsInterna(e.target.value)}
                                            />
                                        </div>
                                        <div className="ml-2 pt-[2px] text-red-400 text-lg">
                                            <div className="mt-0 ml-0 borderx xborder-solid px-0 pt-1 rounded" onClick={() => { confirmaInclusao(idInclusao) }}>
                                                <Tooltip placement="top" title={'Salvar observação'}><FaSave className="text-xl text-blue-500 font-bold" /></Tooltip>
                                            </div>
                                        </div>
                                    </div>) : (null)
                            )}
                        </div>

                        {/* CLIENTES VINCULADOS */}
                        <div hidden={clientesVinculados.length === 0} className="mt-2 rounded bg-slatex-200 ">
                            <h2 className="title mt-3 mb-1">Clientes Vinculados</h2>
                            <div className="">
                                <div key={'cab1'} className="cabec flex bg-[#0f172a] px-3 p-1 mb-2 text-white gap-2 font-bold">
                                    <div className="w-12 text-right">ClId</div>
                                    <div className="w-36 text-right">Cnpj/Cpf</div>
                                    <div className="w-[420px] text-left">Nome</div>
                                    <div className="w-8 text-left">UF</div>
                                    <div className="w-52 text-left">Telefones</div>
                                    <div className="w-28 text-right">Total Produtos</div>
                                </div>
                                <div className="max-h-64 overflow-y-auto">
                                    {(clientesVinculados || []).map((c: any, i: number) => {
                                        const fontMaster = (i === 0) ? 'font-bold' : ''
                                        const textSelected = (c.id === cliente?.id && c.id !== cliente?.clienteIdVinculado) ? 'text-blue-500' : ''
                                        const totalvenda = totaisVendasPorCliente.find((x: any) => x.clientId === c.id)?.totalvenda ?? 0
                                        const totalprodutos = totaisVendasPorCliente.find((x: any) => x.clientId === c.id)?.totalprodutos ?? 0
                                        return (
                                            <div key={c.id} className={`flex px-3 p-1 gap-2 border-solid border-0 border-b border-[#b0d2ff] cursor-pointer hover:bg-slate-100 ${fontMaster} ${textSelected}`}
                                                onClick={() => { handleClickClienteVinculado(c) }}>
                                                <div className="w-12 text-right">{c?.id}</div>
                                                <div className="w-36 text-right">{formatarCpfCnpj(c?.cnpj)}</div>
                                                <div className="w-[420px] text-left">{c?.nome}</div>
                                                <div className="w-8 text-left">{c?.uf}</div>
                                                <div className="w-56 text-left">{c?.fone} {c?.celular}</div>
                                                <div className="w-24 text-right">{formatDecimalZ(totalprodutos, 2)}</div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>

                        {/* PEDIDOS CLIENTE */}
                        <div className="mt-2 rounded bg-slatex-200 ">
                            {/* <h2 className="mt-6 mb-1">COLORBRILHO (AGRUPADA)</h2> */}
                            <div className="flex justify-between items-center mt-3">
                                <h2 className="title mb-1">Pedidos do Cliente</h2>
                                <div className={``} hidden={!cliente}>
                                    <button className="hover:text-black text-black w-32 bg-white cursor-pointer border rounded-sm" onClick={() => setShowParcelas(!showParcelas)}>{showParcelas ? 'Ocultar Parcelas' : 'Mostrar Parcelas'}</button>
                                </div>
                            </div>
                            <div className="">
                                <div key={'cab2'} className="cabec flex bg-[#0f172a] px-3 p-1 mb-2 text-white gap-2 font-bold">
                                    <div className="w-20 text-left">Data</div>
                                    <div className="w-12 text-right">ClId</div>
                                    <div className="w-36 text-left">Empresa</div>
                                    <div className="w-56 text-left">Vendedor</div>
                                    <div className="w-14 text-right">Pedido</div>
                                    <div className="w-[88px] text-right">Vlr. Produtos</div>
                                    <div className="w-16 text-right">Vlr. Frete</div>
                                    <div className="w-16 text-right">Desconto</div>
                                    <div className="w-20 text-right">Total Venda</div>
                                    <div className="w-20 text-right">Intervalo</div>
                                </div>
                                <div className="max-h-64 overflow-y-auto">
                                    {(pedidosCliente || []).map((p: any) => {
                                        const parcelas = p.parcelas ?? []
                                        const bg = (showParcelas) ? 'bg-slate-100 hover:bg-slate-200 font-semibold' : 'hover:bg-slate-100'
                                        return (
                                            <>
                                                <div key={p.numero} className={`flex px-3 p-1 gap-2  border-solid border-0 border-b border-[#b0d2ff] cursor-pointer ${bg}`}
                                                    onClick={() => { handleClickPedido(p) }}>
                                                    <div className="w-20 text-left">{dateFormatBr(p.data)}</div>
                                                    <div className="w-12 text-right">{p.clientId}</div>
                                                    <div className="w-36 text-left">{emp[p.empresaId - 1]?.nome.replace('Colorbrilho ', '')}</div>
                                                    <div className="w-56 text-left">{ellipsis(p.vendedor, 27)}</div>
                                                    <div className="w-14 text-right">{p.numero}</div>
                                                    <div className="w-[88px] text-right">{formatDecimalZ(p.totalprodutos, 2)}</div>
                                                    <div className="w-16 text-right">{formatDecimalZ(p.valorfrete, 2)}</div>
                                                    <div className="w-16 text-right">{formatDecimalZ(p.desconto, 2)}</div>
                                                    <div className="w-20 text-right">{formatDecimalZ(p.totalvenda, 2)}</div>
                                                    <div className="w-20 text-right">{p.intervalo} dias</div>
                                                </div>
                                                <div hidden={!showParcelas}>
                                                    {(parcelas || []).map((pa: any) => (
                                                        <div key={pa.id} className="flex px-3 p-0 gap-2 border-solid border-0 border-b border-[#b0d2ff]">
                                                            <div className="ml-6 text-right">Parcela</div>
                                                            <div className="w-6 text-right">{pa.parcela}</div>
                                                            <div className="w-20 text-left">{dateFormatBr(pa.dataVencimento)}</div>
                                                            <div className="w-[72px] text-right">{formatDecimalZ(pa.valor ?? 0, 2)}</div>
                                                            <div className="text-left">{pa.formaPagamento}</div>
                                                            <div className="w-[500px] text-left">{pa.observacoes ? "- " : ""}{pa.observacoes.split(' | ')[1]}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        )

                                    })}
                                </div>
                            </div>
                        </div>

                        {/* PRODUTOS COMPRADOS */}
                        <div className="mt-2 rounded bg-slatex-200 ">
                            {/* <div className="title p-1 px-2 rounded-t bg-slate-500 text-white">PRODUTOS COMPRADOS</div> */}
                            <h2 className="title mt-3 mb-1">Produtos Comprados</h2>
                            <div className="">
                                <div key={'cab3'} className="cabec flex bg-[#0f172a] px-3 p-1 mb-2 text-white gap-2 font-bold">
                                    <div className="w-20 text-left">Codigo</div>
                                    <div className="w-[550px] text-left">Nome</div>
                                    <div className="w-4 text-left">Un</div>
                                    <div className="w-12 text-right">Qtd</div>
                                    <div className="w-16 text-right">P.Médio</div>
                                    <div className="w-24 text-right">Total</div>
                                </div>

                                <div className="max-h-64 overflow-y-auto">
                                    {(pedidos?.produtos || []).map((p: any) => (
                                        <div key={p.codigo} className="flex px-3 p-1 gap-2 border-solid border-0 border-b border-[#b0d2ff]">
                                            <div className="w-20 text-left">{p.codigo}</div>
                                            <div className="w-[550px] text-left">{p.nome}</div>
                                            <div className="w-4 text-left">{p.unidade}</div>
                                            <div className="w-12 text-right">{p.quantidade}</div>
                                            <div className="w-16 text-right">{formatDecimalZ(p.valorTotal / p.quantidade, 2)}</div>
                                            <div className="w-24 text-right">{formatDecimalZ(p.valorTotal, 2)}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* GRÁFICO DE VENDAS MENSAIS */}
                        <div className="mt-2 rounded bg-slatex-200 ">
                            {/* <div className="title p-1 px-2 rounded-t bg-slate-500 text-white">GRÁFICO DE VENDAS MENSAIS</div> */}
                            <h2 className="title mt-3 mb-1">Vendas Mensais</h2>
                            <div className="py-2">
                                {/* <div className='flex items-center justify-center'>
                                    <Vendas titulo={'Vendas Mensais'} labels={vendasMensaisGrouped.map((x: any) => x.anomes.split('-').reverse().join('/'))} dados={vendasMensaisGrouped.map((x: any) => x.totalvenda)} />
                                </div> */}
                                <div className='h-[330px] p-4 bg-white rounded-md border-solid border-2 border-slate-200'>
                                    <Vendas titulo={`Vendas Mensais dos últimos ${ultimos} meses mais mes atual`} labels={vendasMensaisGrouped.map((x: any) => x.anomes.split('-').reverse().join('/'))} dados={vendasMensaisGrouped.map((x: any) => x.totalprodutos)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalProdutos showModalProdutos={showModalProdutos} hideModalProdutos={hideModalProdutos} pedido={pedido} empresas={emp} />
                <ModalClienteVinculado showModalClienteVinculado={showModalClienteVinculado} hideModalClienteVinculado={hideModalClienteVinculado} clienteVinculado={clienteVinculado} />
            </div>
        </Layout>
    );
}
