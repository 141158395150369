import { useCallback, useEffect, useState } from 'react'
import useProcessando from '../../../data/hooks/useProcessando'
import { apierp } from '../../../services/api'
import { dateFormat } from '../../../utils/dateFunctions'

export default function useImportPedidosCompraFromBlingV3(dateref: any) {
  const { processando, iniciarProcessamento, finalizarProcessamento } = useProcessando()
  const [pedidosCompra, setPedidosCompra] = useState<any[]>([])

  const buscarPedidosCompra = useCallback(async function () {
    if (dateref === null) return
    const date = dateFormat(dateref, 'yyyy-MM-dd')

    try {
      iniciarProcessamento()

      const dataBling = {
        "metodo": "getPedidosCompraFromBlingV3Resumido",
        "param": {
        }
        // "dateFrom": date,
        // "dateTo": date
        // "soPendentesSaida": "S",
        // "soAtendidos": "S"
      }
      // console.log(dataBling)
      const response1 = await apierp.post(`bling/v3/pedidoscompra`, dataBling) // na verdade é get
      const data1 = await response1.data as any;
      // console.log(data1)
      setPedidosCompra(data1)

    } catch (err) {
      console.log(err)
    } finally {
      finalizarProcessamento()
    }

  }, [dateref])

  function voltar() {
    setPedidosCompra([])
  }
  function reload() {
    buscarPedidosCompra()
  }

  useEffect(() => {
    setPedidosCompra([])
    buscarPedidosCompra()
  }, [buscarPedidosCompra])

  return {
    pedidosCompraV3: pedidosCompra,
    processandoV3: processando,
    iniciarProcessamentoV3: iniciarProcessamento,
    finalizarProcessamentoV3: finalizarProcessamento,
    voltarV3: voltar,
    reloadV3: reload
  }

}