import { formatDecimal, formatarCpfCnpj } from "../../../utils/functions";
import { Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { getColumnSearchProps } from "../../../components/columnSearchProps";
import { dateFormatBr } from "../../../utils/dateFunctions";

export function columnsClienteRanking(props: any) {
    // console.log(props)
    return [

        // {
        //     title: 'Id',
        //     dataIndex: 'id',
        //     ellipsis: true,
        //     width: '40px',
        // },
        {
            title: 'CNPJ/CPF',
            dataIndex: 'cnpj',
            width: '130px',
            key: "cnpj",
            // align: 'right' as 'right',
            // className: 'text-right',
            ellipsis: true,
            // ...getColumnSearchProps({
            //     dataIndex: 'cnpj',
            //     ...props
            // }),
            render: (text: any, record: any) => `${formatarCpfCnpj(text)}`,
        },
        {
            title: 'Nome',
            dataIndex: 'nome',
            width: '450px',
            className: 'text-left',
            ellipsis: false,
            // sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            // ...getColumnSearchProps({
            //     dataIndex: 'nome',
            //     ...props
            // })
        },
        {
            title: 'G.C',
            dataIndex: '',
            ellipsis: true,
            width: '74px',
            render: (text: any, record: any) => text.gcId > 0 ? text.gcsName : '',
            // filterMode: 'menu',

            // // ANTES DE UTILIZAR props.gerAtribuidos
            // // filters: [
            // //     {
            // //         text: 'Atribuidos',
            // //         value: '',
            // //     },               
            // // ],
            // // onFilter: (value: number, record: any) => record.gcId > 0,

            // filters: props.gerAtribuidos,
            // onFilter: (value: string, record: any) => record.gcsName?.includes(value),
            
        },
        {
            title: 'Status',
            dataIndex: 'statusCrm',
            ellipsis: true,
            width: '160px',
            // filters: [
            //     {
            //         text: 'Com Obs',
            //         value: '',
            //     },               
            // ],
            // onFilter: (value: string, record: any) => record.obsInterna > (value),
        },
        {
            title: 'Obs',
            dataIndex: 'obsInterna',
            ellipsis: true,
            width: '90px',
            // filters: [
            //     {
            //         text: 'Com Obs',
            //         value: '',
            //     },               
            // ],
            // onFilter: (value: string, record: any) => record.obsInterna > (value),
        },
        {
            title: 'Rank',
            dataIndex: 'rankId',
            ellipsis: true,
            width: '60px',
            align: 'right' as 'right',
            className: 'text-right',
            render: (text: any, record: any) => `${text}º`,
            sorter: (a: any, b: any) => a.rankId - b.rankId,
        },
        {
            title: 'R.12',
            dataIndex: 'rankId12',
            ellipsis: true,
            width: '56px',
            align: 'right' as 'right',
            className: 'text-right',
            render: (text: any, record: any) => `${text}º`,
            sorter: (a: any, b: any) => a.rankId12 - b.rankId12,
        },
        {
            title: 'R.6',
            dataIndex: 'rankId6',
            ellipsis: true,
            width: '56px',
            align: 'right' as 'right',
            className: 'text-right',
            render: (text: any, record: any) => `${text}º`,
            sorter: (a: any, b: any) => a.rankId6 - b.rankId6,
        },
        {
            title: 'Total Compras',
            dataIndex: 'totalprodutos',
            width: '100px',
            align: 'right' as 'right',
            className: 'text-right',
            ellipsis: true,
            render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
        },
        {
            title: '12 meses',
            dataIndex: 'totalDozeMeses',
            width: '70px',
            align: 'right' as 'right',
            className: 'text-right',
            ellipsis: true,
            render: (text: any, record: any) => `${formatDecimal(+text, 0)}`
        },
        {
            title: '6 meses',
            dataIndex: 'totalSeisMeses',
            width: '70px',
            align: 'right' as 'right',
            className: 'text-right',
            ellipsis: true,
            render: (text: any, record: any) => `${formatDecimal(+text, 0)}`
        },

        {
            title: 'Ult. Pedido',
            dataIndex: 'dataUltimaVenda',
            width: '90px',
            align: 'right' as 'right',
            className: 'text-right',
            ellipsis: true,
            render: (text: any, record: any) => `${dateFormatBr(text)}`
        },
        {
            title: 'Dias',
            dataIndex: 'diasDaUltimaVenda',
            width: '50px',
            align: 'right' as 'right',
            className: 'text-right',
            // ...getColumnSearchProps({
            //     dataIndex: 'diasDaUltimaVenda',
            //     ...props
            // }),
            // onFilter: (value: string, record: any) => record.diasDaUltimaVenda >= value,
            sorter: (a: any, b: any) => a.diasDaUltimaVenda - b.diasDaUltimaVenda,
        },
        // {
        //     title: 'Dias',
        //     dataIndex: 'diasDaUltimaVenda',
        //     width: '64px',
        //     align: 'right' as 'right',
        //     className: 'text-right',
        //     filters: [
        //         {
        //             text: '>=365',
        //             value: 365,
        //         },
        //         {
        //             text: '>=270',
        //             value: 270,
        //         },
        //         {
        //             text: '>=180',
        //             value: 180,
        //         },
        //         {
        //             text: '>=90',
        //             value: 90,
        //         },
        //     ],
        //     onFilter: (value: number, record: any) => record.diasDaUltimaVenda >= value,
        //     // filterSearch: true,
        //     // ellipsis: true,
        //     sorter: (a: any, b: any) => a.diasDaUltimaVenda - b.diasDaUltimaVenda,
        // },
    ]
}