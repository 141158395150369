import { Button, Drawer, Form, Input, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { addDaysToStrDate, dateFormatBr, differenceInDaysBetweenDates } from "../../utils/dateFunctions";
import { formatDecimalZ, limpaInteiro, limpaNumeroBr } from "../../utils/functions";
import TextArea from "antd/lib/input/TextArea";

const { Option } = Select;

export default function DrawerFormParcelaPedidoVenda(props: any) {

    const [buttonsDisabled, setButtonsDisabled] = useState(false)
    const [savebtnDisabled, setSavebtnDisabled] = useState(false)
    const [formParcelaPedidoVenda] = Form.useForm();

    const [dataVencimento, setDataVencimento] = useState(null)

    const { drawerFormParcelaPedidoVendaVisible, hideDrawerFormParcelaPedidoVenda, operacao,
        index, dataVenda, parcelasPedidoVenda, setParcelasPedidoVenda, formasPagamento, calculaTotalParcelas
    } = props

    // console.log('===parcelaPedidoVenda', parcelasPedidoVenda[index])
    // console.log('formasPagamento', formasPagamento)

    const parcela = parcelasPedidoVenda[index]
    const dias = index > -1 ? differenceInDaysBetweenDates( parcela?.dataVencimento, dataVenda) : ''
    const dataVenc = index > -1 ? parcela?.dataVencimento : ''
    const fieldsParcela = {
        id: parcela?.id || '',
        index: index,
        dias: dias,
        dataVencimento: dataVenc,
        valor: parcela?.valor.toFixed(2).toString().replace('.', ',') || '',
        formaPagamentoId: parcela?.formaPagamentoId || '',
        observacoes: parcela?.observacoes || '',
    }
    // console.log('fieldsParcela', fieldsParcela)

    const fillFormParcelaPedidoVenda = (fields: any) => {
        formParcelaPedidoVenda.setFieldsValue(fields)
    }

    useEffect(() => {
        fillFormParcelaPedidoVenda(fieldsParcela)
        setDataVencimento(fieldsParcela.dataVencimento)
    }, [drawerFormParcelaPedidoVendaVisible])

    const handleCancel = () => {
        // formPedidoVenda.resetFields()
        hideDrawerFormParcelaPedidoVenda()
    }

    const handleSubmitFormParcelaPedidoVenda = async (values: any) => {
        const index = values.index
        // console.log('values', values)
        // console.log(dataVenda)
        const parcela = {
            id: values.id,
            dataVencimento: addDaysToStrDate(dataVenda, values.dias),
            formaPagamentoId: values.formaPagamentoId,
            formaPagamento: await formasPagamento.find((x: any) => x.id === values.formaPagamentoId).nome,
            observacoes: values.observacoes,
            valor: +values.valor.replaceAll('.', '').replace(',', '.'),
        }
        // console.log('handleSubmitFormParcelaPedidoVenda-values', values)
        // console.log('handleSubmitFormParcelaPedidoVenda', parcela)

        const newParcelas = [...parcelasPedidoVenda]
        // newParcelas[index] = parcela
        
        if (index > -1 )  {
            newParcelas[index] = parcela
        } else {
            newParcelas.push(parcela)
        }
        // console.log('handleSubmitFormParcelaPedidoVenda-newParcelas', newParcelas)  
        setParcelasPedidoVenda(newParcelas)

        calculaTotalParcelas(newParcelas)
        hideDrawerFormParcelaPedidoVenda()
    }

    const changeFilelds = (changedFields: any, allFields: any) => {
        // console.log('changedFields', changedFields)
    }

    function onBlur(e: any, name: string) {
        // console.log('onBlur', e.target.value, name)
        formParcelaPedidoVenda.setFieldValue(name, formatDecimalZ(e.target.value.replaceAll('.', '').replace(',', '.'), 2))
    }

    function handleChangeInputNumber(e: any, name: string) {
        // console.log('handleChangeInputNumber', e.target.value, name)
        formParcelaPedidoVenda.setFieldValue(name, limpaNumeroBr(e.target.value))
    }
    function handleChangeInputInt(e: any, name: string) {
        // console.log('handleChangeInputNumber', e.target.value, name)
        formParcelaPedidoVenda.setFieldValue(name, limpaInteiro(e.target.value))
    }

    const layoutf = {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
    }

    return (
        <Drawer
            title={`${operacao} de Parcela do Pedido Venda`}
            width={720}
            closable={true}
            maskClosable={false}
            onClose={hideDrawerFormParcelaPedidoVenda}
            open={drawerFormParcelaPedidoVendaVisible}
            headerStyle={{ borderRadius: "0" }}
            bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
            className=""
        >
            <div className="inner-drawer-body">
                <Form {...layoutf} form={formParcelaPedidoVenda} onFinish={handleSubmitFormParcelaPedidoVenda} onFieldsChange={changeFilelds}>
                    <div className="flex"><h2 className="mx-auto text-red-500">Funcionalidade em Desenvolvimento</h2></div>
                    <Form.Item name="id" hidden noStyle></Form.Item>
                    <Form.Item name="index" hidden noStyle></Form.Item>
                    <Form.Item
                        name="formaPagamentoId"
                        label="Forma Pgto"
                    // rules={[{ required: true, message: 'Selecione Um Pai' }]}
                    >
                        <Select placeholder="Selecione um Produto" className="w-full"
                            showSearch
                            optionFilterProp="children"
                            popupClassName="border-solid border border-blue-500">
                            {formasPagamento.map((forma: any, index: any) => {
                                return <Option value={forma.id} className="text-blue-800 font-semibold"> {forma.nome}</Option>
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item label="Dias" className="pedido2 mb-0">
                        <Form.Item name="dias" label="" className="inline-block mb-2"><Input onChange={(e) => handleChangeInputInt(e, 'dias')} className="w-20" /></Form.Item>
                        <Form.Item label="Data Vencimento" name="dataVencimento" className="inline-block mb-2 ml-4">
                            <div className="w-24 h-[31px] text-center py-1 border border-solid border-[#d9d9d9] bg-slate-100" >{dateFormatBr(dataVencimento)}</div>
                        </Form.Item>
                        <Form.Item name="valor" label="Valor" className="inline-block mb-2 ml-4"><Input onBlur={(e) => onBlur(e, 'valor')} onChange={(e) => handleChangeInputNumber(e, 'valor')} placeholder="" className="w-20" /></Form.Item>
                    </Form.Item>

                    <Form.Item name="observacoes" label="Observações" className="mb-2"><TextArea placeholder="" rows={1} className="" /></Form.Item>

                </Form>
            </div>

            <Space className="drawer-footer flex w-full justify-end">
                <Space>
                    <Button hidden={buttonsDisabled} onClick={handleCancel}>Cancel</Button>
                    {/* <Button onClick={formPedidoVenda.submit} hidden={buttonsDisabled} disabled={savebtnDisabled} type="primary">Salvar PV</Button> */}
                    <Button onClick={formParcelaPedidoVenda.submit} hidden={buttonsDisabled} disabled={savebtnDisabled} type="primary">Salvar Parcela</Button>
                </Space>
            </Space>
        </Drawer>
    )

}

