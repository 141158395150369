import { useEffect, useState } from "react"
import { useAuth } from "../../../contexts/auth";
import useWindowSize from "../../../hooks/useWindowSize";
import { useGeral } from "../../../contexts/geral";
import useDre from "./useDre";
import RelDre from "./RelDre";
import Layout from "../../../components/template/Layout";
import SpinnerNew from "../../../components/template/SpinnerNew"

export default function Dre() {
  const { Logout, user } = useAuth();
  const usuario: any = user
  const [innerW, innerH] = useWindowSize();
  const { userMenu, menuSelected, userTenant, } = useGeral();
  const menu = userMenu.find((x: any) => x.id == menuSelected)

  const hoje = new Date()
  // console.log(hoje.getFullYear())
  const [ano, setAno] = useState(hoje.getFullYear())
  //TODO 2023
  const { processando, dres, dre, voltar, selecionarDre } = useDre(ano)
  
  const id = 'dre'
  const loading = false
  const titulo = `DRE - Demonstrativo de Resultado de ${ano}`
  const wTab = 1562
  // const htb = `calc(100vh - 163px + 24px)`
  // const hsc = `calc(100vh - 215px + 24px)`

  return (
    <Layout titulo={titulo} botoes={null}>
      <div className={`bg-blue-30 overflow-x-auto px-6`} >
        <div className="flex flex-row justify-center items-center mx-auto " style={{ maxWidth: `${wTab}px`, minWidth: `${wTab}px` }}>
          <div id={`printableDiv-${id}`} className="pt-6  pb-[1px] fadein" style={{ display: processando ? "none" : "" }}>
            {/* <div className="flex flex-col justify-center items-center "> */}
              {/* <Background /> */}
              <SpinnerNew loading={processando} />
              {processando ? (
                null
              ) : dres.length > 0 ? (
                <RelDre dres={dres} selecionar={selecionarDre} ano={ano} titulo={titulo} />
              ) : (
                <div>Dados não encontrados</div>
              )}
            {/* </div> */}
          </div>
        </div>
      </div>
      
    </Layout >
  )
}