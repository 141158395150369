export default function CardMenu(props: any) {

  return (
    <div  style={{ backgroundColor: props.bg}} className={props.bg + " m-4 rounded-lg shadow-gray-400 shadow-md  hover:shadow-gray-600 hover:cursor-pointer"}>
      <div className="flex flex-col w-[300px] rounded-lg " >
        {/* <img src={"/" + props.imagem} alt="img" width={296} height={150} className="rounded-t-md mx-auto mt-[2px]"/> */}
        <img src={"/" + props.imagem} alt="img"  height={142} className="rounded-t-md mx-auto mt-2 "/>
        <div className="py-1 px-3 pb-[6px]">
          <div className={props.txt + " text-lg font-semibold tracking-wider"}>{props.title}</div>
          {/* <div className="">{props.description}</div> */}
        </div>
      </div>
    </div>
  )
}