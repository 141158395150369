import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
// import faker from 'faker'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.color = "#000"; // cor dos labels

export function Vendas(props: any) {
  const { titulo, labels, dados } = props
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      // x: { ticks: { color: "red" } },
      // y: { ticks: { color: "green" } },
    },
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: titulo,
      },
    },
  };

  const data = {
    labels,
    color: 'black',
    datasets: [
      {
        label: 'Vendas Mensais',
        data: props.dados,
        backgroundColor: '#516ba1', // '#516ba1', // '#496bad', // 'rgb(73, 107, 173)', // 'rgb(81, 107, 161)', ,
        // borderColor: 'rgba(153, 102, 255, 1)',
        // borderWidth: 1,
        // hoverBackgroundColor: 'rgba(81, 107, 161, 0.8)',
        // hoverBorderColor: "rgba(255,99,132,1)",
      },
      // {
      //   label: 'Vendas Mensais',
      //   data: props.dados,
      //   backgroundColor: 'rgba(153, 102, 255, 0.2)',
      //   borderColor: 'rgba(153, 102, 255, 1)',
      //   borderWidth: 1,
      //   hoverBackgroundColor: 'rgba(153, 102, 255, 0.4)',
      //   // hoverBorderColor: "rgba(255,99,132,1)",
      // },
      // {
      //   label: 'Vendas Mensais',
      //   data: props.dados,
      //   backgroundColor: 'rgba(0,0, 255, 1)',
      //   borderColor: 'rgba(0,0, 255, 1)',
      //   borderWidth: 1,
      //   hoverBackgroundColor: 'rgba(0,0, 255, 1)',
      //   // hoverBorderColor: "rgba(255,99,132,1)",
      // },
      
      // {
      //   label: 'Dataset 2',
      //   data: labels.map(() => Math.floor(Math.random() * 1000)),
      //   backgroundColor: 'blue',
      // },
    ],
  };

  // console.log(data)
  return <Bar options={options} data={data} />;
}
