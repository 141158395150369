// import { IconCheck } from "@tabler/icons-react"

import { Button, Modal } from "antd"
import Layout from "../../../components/template/Layout"
import SpinnerNew from "../../../components/template/SpinnerNew"
import { formatDecimal } from "../../../utils/functions"
import RelCatMes from "./RelCatMes"
import { useState } from "react"

interface DresProps {
  dres: any[]
  ano: number
  titulo: string
  selecionar: (dre: any) => void

}

export default function RelDre(props: DresProps) {
  // console.log(props)
  // console.log('Entrou RelDre')
  
  // const [mes, setMes] = useState(0)
  // const [categoria, setCategoria] = useState('')

  const [catmes, setCatmes] = useState({} as any)
  const [modalCategoriaVisible, setModalCategoriaVisible] = useState(false)

  async function handleSelectCatMes(mes: number, categoria: string) {
    // console.log('clicked', mes, categoria)
    setCatmes({mes, categoria})
    setModalCategoriaVisible(true)
    // setMes(mes)
    // setCategoria(categoria)
  }

  const showModalCategoria = async () => {
    setModalCategoriaVisible(true)
  };
  const hideModalCategoria = async () => {
    setCatmes({})
    setModalCategoriaVisible(false)
  };

  // const titulo=`DRE - Demonstrativo de Resultado de ${props.ano}`
  // const subtitulo = `Exercício de ${props.ano}`
  const meses = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez', 'Total']

  const hsc = `calc(100vh - 290px )`

  return (
    // <Layout titulo={titulo} botoes={null}>
    <>
      <div className="xxx flex flex-col justify-between bg-[#f8f9fb]" > {/** ou heigth */}
          <div >
            <div className="flex bg-gray-900 px-3 pt-1 mb-2">
              <h3 className="w-[350px]  text-white text-left ">Nome</h3>
              {meses.map((x, i) => <h3 className={`${i === 12 ? 'w-[100px]' : 'w-[90px]'}  text-white text-right `}>{x}</h3>)}
            </div>
            <div style={{ maxHeight: hsc }} className='overflow-auto'>
              {props.dres.map((item: any, i) => {
                let clstr = ' ';
                clstr = item.operacao !== 'c' ? " bg-teal-100" : clstr
                clstr = item.operacao === 's' ? " bg-gray-200" : clstr
                clstr = item.operacao === 'o' ? " bg-gray-200 mt-6" : clstr
                clstr = item.operacao === '=' || i === 0 ? " bg-teal-700 " : clstr
                
                let clstd = " text-teal-800 font-bold"
                clstd = item.operacao === 'c' ? " text-blue-500 pl-5" : clstd
                clstd = item.operacao === '=' || i === 0 ? " text-white font-bold" : clstd
                clstd = item.operacao === 'x' ? " text-gray-400" : clstd

                let pointer = item.operacao === 'c' ? 'cursor-pointer' : ''
                return (
                  <div className={`flex px-3 pt-[2px] border-solid border-0 border-b border-[#b0d2ff] ${clstr}`}>
                    <h4 className={`w-[350px] mb-[3px] font text-left ${clstd}`}>{item.nome}</h4>
                    {item.valores.map((vlr: any, mes: number) =>
                      <h4
                        className={`
                        mb-0 ${clstd} ${mes < 12 ? pointer : ''}  
                        ${mes === 12 ? 'w-[100px]' : 'w-[90px]'} text-right `}
                        
                        onClick={() => { if (item.operacao === 'c') handleSelectCatMes(mes+1, item.nome)}}
                      >
                        {formatDecimal(vlr + 0.0000001, 2)}
                      </h4>
                    )}
                  </div>)
              })}
            </div>
          </div>
        {/* </div> */}
      </div>

      {/* The Modal */}
      <Modal 
        // title={`${catmes.categoria} - [${meses[catmes.mes-1]}/${props.ano}]`}
        title={<div className="text-xl">{catmes.categoria} - [{meses[catmes.mes-1]}/{props.ano}]</div>}
        closable={false}
        open={modalCategoriaVisible} 
        // onOk={hideModalCategoria} 
        onCancel={hideModalCategoria} 
        // footer={null} 
        footer={[<Button key="back" onClick={hideModalCategoria}>Fechar</Button>]} 
        centered width={1500}
        >
          <RelCatMes ano={props.ano} mes={catmes.mes} categoria={catmes.categoria} />
      </Modal>

    </>
  )

}
