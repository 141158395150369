import { Button, Checkbox, CheckboxProps, Drawer, Form, Input, InputNumber, Modal, Popconfirm, Radio, Select, Space } from "antd"
import { FaRegTrashAlt, FaPencilAlt, FaQrcode } from "react-icons/fa";
import { FaPix, FaMoneyCheck } from "react-icons/fa6";
import { RiMoneyDollarBoxLine } from "react-icons/ri";
import useWindowSize from "../../hooks/useWindowSize";
import { useEffect, useState } from "react";
import { formatarCpfCnpj, formatDecimalZ, formatPercentual, limpaInteiro, limpaNumeroBr, validateUf } from "../../utils/functions";
import { tiposFretePorConta } from "../../data/dados";
import { dateFormat, dateFormatBr, differenceInDaysBetweenDates } from "../../utils/dateFunctions";
import { on } from "events";
import DrawerFormParcelaPedidoVenda from "./DrawerFormParcelaPedidoVenda";
import DrawerFormItemPedidoVenda from "./DrawerFormItemPedidoVenda";
import { useMutatePedidoVenda } from "./useMutatePedidoVenda";
import { useMutateSituacaoPedidoVenda } from "./useMutateSituacaoPedidoVenda";
import { useMutateGerarPixCopiaECola } from "./useMutateGerarPixCopiaECola";
import formataPedidoVendaBling from "./formataPedidoVendaBling";
import { useMutateConfirmarPagamentoParcela } from "./useMutateConfirmarPagamentoParcela";

const { TextArea } = Input
const { Option } = Select;

export default function DrawerFormPedidoVenda(props: any) {
  const { mutate: mutatePV, isSuccess } = useMutatePedidoVenda()
  const { mutate: mutateSPV, isSuccess: isSuccessSPV } = useMutateSituacaoPedidoVenda()
  const { mutate: mutateCPP, isSuccess: isSuccessCPP } = useMutateConfirmarPagamentoParcela()
  const { mutate: mutateGPCC, isSuccess: isSuccessGPCC } = useMutateGerarPixCopiaECola()
  const [toggle, setToggle] = useState(false)
  const [houveMudanca, setHouveMudanca] = useState(false)
  const [actionsDisabled, setActionsDisabled] = useState(true)
  const [formDisabled, setFormDisabled] = useState(true)
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const [savebtnDisabled, setSavebtnDisabled] = useState(false)
  const [formPedidoVenda] = Form.useForm();
  const [situacaoPedidoVendaId, setSituacaoPedidoVendaId] = useState(1)

  const [totalProdutos, setTotalProdutos] = useState(0)
  const [desconto, setDesconto] = useState(0)
  const [frete, setFrete] = useState(0)
  const [outrasDespesas, setOutrasDespesas] = useState(0)
  const [totalPedido, setTotalPedido] = useState(0)
  const [totalParcelas, setTotalParcelas] = useState(0)
  const [data, setData] = useState(null)
  const [itensPedidoVenda, setItensPedidoVenda] = useState([{}])
  const [parcelasPedidoVenda, setParcelasPedidoVenda] = useState([{}])
  const [endEntregaDiferente, setEndEntregaDiferente] = useState(false)

  const [drawerFormItemPedidoVendaVisible, setDrawerFormItemPedidoVendaVisible] = useState(false)
  const [drawerFormParcelaPedidoVendaVisible, setDrawerFormParcelaPedidoVendaVisible] = useState(false)
  const [operacaoItem, setOperacaoItem] = useState(null as string | null)
  const [operacaoParcela, setOperacaoParcela] = useState(null as string | null)
  const [indexItem, setIndexItem] = useState(-1)
  const [indexParcela, setIndexParcela] = useState(-1)

  const [modalPixCopColaVisible, setModalPixCopColaVisible] = useState(false)
  const [modalPixParcVisible, setModalPixParcVisible] = useState(false)
  const [formaPagamento, setFormaPagamento] = useState(null as any | null)
  const [parcelaPedidoVenda, setParcelaPedidoVenda] = useState(null as any | null)

  const { hideDrawerFormPedidoVenda, drawerFormPedidoVendaVisible,
    operacao, formasPagamento, situacoesPedidoVenda, vendedores, empresas, pedidoVenda, pixPedidoVenda, produtos, pixWebhook,
    clientes, termoCli, transportadores, termoTra, vendedorId,
    setTransportadores, setTermoCli, setTermoTra, setEmpresaId, setClienteId, setTransportadorId, setVendedorId, setTxId, setPedidoVendaId,
    blingCliente, blingTransportador, blingProdutos, blingFormasPagamento, blingCategoriasReceitaDespesa, blingVendedores } = props

  // const temBling = blingCliente || BlingTransportador || blingProdutos || blingFormasPagamento || blingCategoriasReceitaDespesa || blingVendedores
  const temBlingCliente = blingCliente
  const temBlingTransportador = blingTransportador || blingTransportador === undefined
  const temBlingVendedor = blingVendedores?.find((v: any) => v.vendedorId === vendedorId)?.idbling
  // console.log('temBlingVendedor', temBlingVendedor, blingVendedores, vendedorId)
  // const temBlingCategoriasReceitaDespesa = blingCategoriasReceitaDespesa

  // const temBlingProdutos = blingProdutos
  // const temBlingFormasPagamento = blingFormasPagamento

  const hideDrawerFormItemPedidoVenda = () => {
    // setItemPedidoVendaId(0)
    setDrawerFormItemPedidoVendaVisible(false)
  }
  const hideDrawerFormParcelaPedidoVenda = () => {
    // setParcelaPedidoVendaId(0)
    setDrawerFormParcelaPedidoVendaVisible(false)
  }

  useEffect(() => {
    setActionsDisabled(true)
    setFormDisabled(true)
    if (situacaoPedidoVendaId < 5) {
      setActionsDisabled(false)
      setFormDisabled(false)
    }
  }, [situacaoPedidoVendaId])

  useEffect(() => {
    const itens = pedidoVenda?.itens?.map((item: any) => {
      return {
        id: item.id,
        produtoId: item.produtoId,
        descricao: item.descricao,
        codigo: item.codigo,
        unidade: item.unidade,
        quantidade: item.quantidade,
        precoLista: item.precoLista,
        precoUnitario: item.precoUnitario,
        precoTotal: item.quantidade * item.precoUnitario
      }
    })
    const parcelas = pedidoVenda?.parcelas?.map((parcela: any) => {
      return {
        id: parcela.id,
        dataVencimento: parcela.dataVencimento,
        dataPagamento: parcela.dataPagamento,
        valor: parcela.valor,
        observacoes: parcela.observacoes,
        formaPagamentoId: parcela.formaPagamentoId,
        formaPagamento: parcela.formaPagamento,
        txId: parcela.txId,
      }
    })
    // console.log('itens', itens || [])
    setEndEntregaDiferente(Boolean(pedidoVenda?.endEntregaDiferente))
    setItensPedidoVenda(itens || [])
    setParcelasPedidoVenda(parcelas || [])
    setData(pedidoVenda?.data || dateFormat(new Date(), "yyyy-MM-dd"))

    setDesconto(pedidoVenda?.desconto || 0)
    setFrete(pedidoVenda?.frete || 0)
    setOutrasDespesas(pedidoVenda?.outrasDespesas || 0)
    // console.log('pedidoVenda', pedidoVenda)
    const totalProdutos = itens?.reduce((acc: number, item: any) => acc + item.precoTotal, 0) || 0
    const totalParcelas = parcelas?.reduce((acc: number, item: any) => acc + item.valor, 0) || 0
    setTotalProdutos(totalProdutos)
    const totalPedido = +(totalProdutos - pedidoVenda?.desconto + pedidoVenda?.frete + pedidoVenda?.outrasDespesas).toFixed(2)
    setTotalPedido(totalPedido || 0)
    setTotalParcelas(totalParcelas)
  }, [pedidoVenda])

  const calculaTotalPedido = (itens: any) => {
    const totalProdutos = itens?.reduce((acc: number, item: any) => acc + item.precoTotal, 0) || 0
    setTotalProdutos(totalProdutos)
    const totalPedido = +(totalProdutos - desconto + frete + outrasDespesas).toFixed(2)
    // console.log('==totais', totalProdutos, desconto, frete, outrasDespesas, totalPedido)
    // console.log('==totalPedido', totalPedido)
    setTotalPedido(totalPedido)
    setHouveMudanca(true)
  }

  const calculaTotalParcelas = (parcelas: any) => {
    const totalParcelas = parcelas?.reduce((acc: number, item: any) => acc + item.valor, 0) || 0
    setTotalParcelas(totalParcelas)
    setHouveMudanca(true)
  }

  const adicionaItem = () => {
    // setItemPedidoVendaId(0)
    setIndexItem(-1)
    setDrawerFormItemPedidoVendaVisible(true)
    setOperacaoItem('Inclusão')
  }
  const alteraItem = (index: number) => {
    // console.log('alteraItem', index)
    setIndexItem(index)
    setDrawerFormItemPedidoVendaVisible(true)
    setOperacaoItem('Alteração')
  }
  const removeItem = (index: number) => {
    const temp = [...itensPedidoVenda]
    temp.splice(index, 1)
    setItensPedidoVenda(temp)
    calculaTotalPedido(temp)
  }

  const adicionaParcela = () => {
    // setParcelaPedidoVendaId(0)
    setIndexParcela(-1)
    setDrawerFormParcelaPedidoVendaVisible(true)
    setOperacaoParcela('Inclusão')
  }
  const alteraParcela = (index: number) => {
    // console.log('alteraParcela', index)
    setIndexParcela(index)
    setDrawerFormParcelaPedidoVendaVisible(true)
    setOperacaoParcela('Alteração')
  }
  const removeParcela = (index: number) => {
    const temp = [...parcelasPedidoVenda]
    temp.splice(index, 1)
    setParcelasPedidoVenda(temp)
    calculaTotalParcelas(temp)
  }

  // const handleEditItem = async (itemId: any) => {
  //     // console.log('handleEditItem', itemId)
  //     // setItemPedidoVendaId(itemId.id)
  //     // setTimeout(() => {
  //     setDrawerFormItemPedidoVendaVisible(true)
  //     setOperacaoItem('Alteração')
  //     // }, 200)
  // }

  const fields = {
    id: pedidoVenda?.id,
    // tenantId: 3,
    empresaId: pedidoVenda?.empresaId || null,
    // createdAt: "2024-05-02T14:01:57.604Z",
    // updatedAt: "2024-05-02T14:01:57.604Z",
    data: pedidoVenda?.data || dateFormat(new Date(), 'yyyy-MM-dd'),
    // totalProdutos: pedidoVenda?.totalProdutos?.toFixed(2).toString().replace('.', ','),
    // total: (pedidoVenda?.totalProdutos - pedidoVenda?.desconto + pedidoVenda?.frete + pedidoVenda?.outrasDespesas).toFixed(2).toString().replace('.', ','),
    situacaoPedidoVendaId: pedidoVenda?.situacaoPedidoVendaId || 1,
    // blingLojaId: null,
    clienteId: pedidoVenda?.clienteId,
    blingPedidoVendaId: pedidoVenda?.blingPedidoVendaId,
    blingPedidoVendaNumero: pedidoVenda?.blingPedidoVendaNumero,
    notaFiscalNumero: pedidoVenda?.notaFiscalNumero,
    observacoes: pedidoVenda?.observacoes,
    observacoesInternas: pedidoVenda?.observacoesInternas,
    desconto: pedidoVenda?.desconto?.toFixed(2).toString().replace('.', ',') || '0,00',
    outrasDespesas: pedidoVenda?.outrasDespesas?.toFixed(2).toString().replace('.', ',') || '0,00',
    prazoEntrega: pedidoVenda?.prazoEntrega,
    // blingCategoriaReceitaDespesaId: "2611836175",
    transportadorId: pedidoVenda?.transportadorId,
    fretePorConta: pedidoVenda?.fretePorConta,
    frete: pedidoVenda?.frete?.toFixed(2).toString().replace('.', ',') || '0,00',
    quantidadeVolumes: pedidoVenda?.quantidadeVolumes,
    pesoBruto: pedidoVenda?.pesoBruto?.toString().replace('.', ','),
    blingNotaFiscalId: pedidoVenda?.blingNotaFiscalId,
    blingNotaFiscalNumero: pedidoVenda?.blingNotaFiscalNumero,
    vendedorId: pedidoVenda?.vendedorId,
    // cliente: "FERNANDO RODRIGUES PACHECO",
    // vendedor: "CLEBER",
    // situacao: "Em Andamento"
    endEntregaDiferente: pedidoVenda?.endEntregaDiferente,
    nome: pedidoVenda?.nome || '',
    cep: pedidoVenda?.cep || '',
    endereco: pedidoVenda?.endereco || '',
    numero: pedidoVenda?.numero || '',
    complemento: pedidoVenda?.complemento || '',
    bairro: pedidoVenda?.bairro || '',
    municipio: pedidoVenda?.cidade || '',
    uf: pedidoVenda?.uf || '',

  }
  // console.log('PedidoVenda', pedidoVenda)
  // console.log('fields', fields)

  const fillFormPedidoVenda = (fields: any) => {
    formPedidoVenda.setFieldsValue(fields)
  }

  const handleSearchCli = (newValue: string) => {
    if (newValue.length < 3) {
      setTermoCli(null)
      return
    }
    setTermoCli(newValue);
  };

  const handleSelectEmpresa = (value: any) => {
    // console.log('handleSelectEmpresa', value)
    setEmpresaId(value)
  }
  const handleSelectCliente = (value: any) => {
    // console.log('handleSelectCliente', value)
    setClienteId(value)
  }
  const handleSelectTransportador = (value: any) => {
    // console.log('handleSelectTransportador', value)
    setTransportadorId(value)
  }
  const handleSelectVendedor = (value: any) => {
    // console.log('handleSelectVendedor', value)
    setVendedorId(value)
  }

  const handleSearchTra = (newValue: string) => {
    if (newValue.length < 3) {
      setTermoTra(null)
      return
    }
    setTermoTra(newValue);
  };

  function handleChangeFpc(value: any) {
    if (value !== 1) {
      formPedidoVenda.setFieldsValue({ transportadorId: null })
      formPedidoVenda.setFieldsValue({ frete: '0,00' })
    }
  }

  function onWhell(e: any) {
    (e.target as HTMLInputElement).blur()
  }

  const changeFilelds = (changedFields: any, allFields: any) => {
    // console.log('changeFilelds')
    const desconto = Number(formPedidoVenda.getFieldValue('desconto').toString().replaceAll('.', '').replace(',', '.'))
    const frete = Number(formPedidoVenda.getFieldValue('frete').toString().replaceAll('.', '').replace(',', '.'))
    const outrasDespesas = Number(formPedidoVenda.getFieldValue('outrasDespesas').toString().replaceAll('.', '').replace(',', '.'))
    setDesconto(desconto)
    setFrete(frete)
    setOutrasDespesas(outrasDespesas)
    setTotalPedido(+(totalProdutos - desconto + frete + outrasDespesas).toFixed(2))
    setHouveMudanca(true)
  }

  function handleChangeInputNumber(e: any, name: string) {
    // console.log('handleChangeInputNumber', e.target.value, name)
    formPedidoVenda.setFieldValue(name, limpaNumeroBr(e.target.value))
  }
  function onBlur(e: any, name: string) {
    // console.log('onBlur', e.target.value, name)
    formPedidoVenda.setFieldValue(name, formatDecimalZ(e.target.value.replaceAll('.', '').replace(',', '.'), 2))
  }

  function handleChangeInputInt(e: any, name: string) {
    // console.log('handleChangeInputNumber', e.target.value, name)
    formPedidoVenda.setFieldValue(name, limpaInteiro(e.target.value))
  }

  const regexCep = /^\d{8}$/
  const handleCep = async (e: any) => {
    // const cep = e.target.value.replace(/\D/g, '') //.substring(0, 8)
    const cep = e.target.value
    if (cep.length === 8 && regexCep.test(cep)) {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`)
      const data = await response.json()
      formPedidoVenda.setFieldsValue({
        endereco: data.logradouro,
        bairro: data.bairro,
        municipio: data.localidade,
        uf: data.uf,
      });
    } else {
      formPedidoVenda.setFieldsValue({
        endereco: '',
        bairro: '',
        municipio: '',
        uf: '',
      });
    }
  }

  const checkUf = (_: any, value: string) => {
    // console.log('checkUf', value, endEntregaDiferente)
    if (!endEntregaDiferente) {
      return Promise.resolve();
    }
    if (validateUf(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('UF Inv!'));
  };

  //provisorio
  useEffect(() => {
    fillFormPedidoVenda(fields)
    setSituacaoPedidoVendaId(fields.situacaoPedidoVendaId)
  }, [drawerFormPedidoVendaVisible])

  const handleSubmitFormPedidoVenda = async (values: any) => {
    setButtonsDisabled(true)
    const pedidoVenda = {
      id: values.id || null,
      tenantId: 0,
      empresaId: values.empresaId,
      clienteId: values.clienteId,
      vendedorId: values.vendedorId,
      transportadorId: values.transportadorId,
      situacaoPedidoVendaId: 2,
      data: values.data,
      totalprodutos: totalProdutos,
      desconto: Number(values.desconto.toString().replaceAll('.', '').replace(',', '.')),
      frete: Number(values.frete.toString().replaceAll('.', '').replace(',', '.')),
      outrasDespesas: Number(values.outrasDespesas.toString().replaceAll('.', '').replace(',', '.')),
      total: totalPedido,
      observacoes: values.observacoes || null,
      observacoesInternas: values.observacoesInternas || null,
      prazoEntrega: values.prazoEntrega || null,
      fretePorConta: values.fretePorConta,
      quantidadeVolumes: values.quantidadeVolumes || 0,
      pesoBruto: Number(values.pesoBruto?.toString().replaceAll('.', '').replace(',', '.')) || 0,
      blingPedidoVendaId: values.blingPedidoVendaId || null,
      blingPedidoVendaNumero: values.blingPedidoVendaNumero || null,
      blingNotaFiscalId: values.blingNotaFiscalId || null,
      blingNotaFiscalNumero: values.blingNotaFiscalNumero || null,
      endEntregaDiferente: values.endEntregaDiferente ? true : false,
      nome: values.endEntregaDiferente ? values.nome : null,
      endereco: values.endEntregaDiferente ? values.endereco : null,
      numero: values.endEntregaDiferente ? values.numero : null,
      complemento: values.endEntregaDiferente ? values.complemento : null,
      cidade: values.endEntregaDiferente ? values.municipio : null,
      bairro: values.endEntregaDiferente ? values.bairro : null,
      cep: values.endEntregaDiferente ? values.cep : null,
      uf: values.endEntregaDiferente ? values.uf : null,
      itens: itensPedidoVenda,
      parcelas: parcelasPedidoVenda,
    }
    // console.log('handleSubmitFormPedidoVenda', values)
    // console.clear()
    // console.log('handleSubmitFormPedidoVenda', pedidoVenda)

    // setAlerta('Salvando os Dados !!!')
    mutatePV(pedidoVenda as any)
    setSituacaoPedidoVendaId(2) // Em Andamento
    handleSair()
    setHouveMudanca(false)
    setButtonsDisabled(false)
  }

  const handleSair = () => {
    formPedidoVenda.resetFields()
    hideDrawerFormPedidoVenda()
  }

  const handleCancelarPedido = () => {
    setButtonsDisabled(true)
    mutateSPV({ id: formPedidoVenda.getFieldValue('id'), situacaoPedidoVendaId: 8 })
    setSituacaoPedidoVendaId(8) // Cancelado
    // formPedidoVenda.resetFields()
    // hideDrawerFormPedidoVenda()
    setButtonsDisabled(false)
  }

  const handleFinalizarPedido = () => {
    const temPCC = pedidoVenda?.parcelas?.find((p: any) => p.tipoForma == "PCC")
    // console.log('handleFinalizarPedido', pedidoVenda, !!temPCC)
    setButtonsDisabled(true)
    if (temPCC) {
      // console.log('temPCC', pedidoVenda.id, formPedidoVenda.getFieldValue('id'))
      mutateSPV({ id: pedidoVenda.id, situacaoPedidoVendaId: 4 })
      setSituacaoPedidoVendaId(4) // Aguardando Gerar Pix
    } else {
      // console.log('temPCC', pedidoVenda.id, formPedidoVenda.getFieldValue('id'))
      mutateSPV({ id: pedidoVenda.id, situacaoPedidoVendaId: 5 })
      setSituacaoPedidoVendaId(5) // Aguardando Pagamento
    }
    // formPedidoVenda.resetFields()
    // hideDrawerFormPedidoVenda()
    setButtonsDisabled(false)
  }

  const handleGerarPix = () => {
    setButtonsDisabled(true)
    mutateGPCC({ id: formPedidoVenda.getFieldValue('id'), situacaoPedidoVendaId: 5 })
    setSituacaoPedidoVendaId(5) // Aguardando Pagamento
    // formPedidoVenda.resetFields()
    // hideDrawerFormPedidoVenda()
    setButtonsDisabled(false)
  }

  // const handleConfirmarPagamento = () => {
  //   setButtonsDisabled(true)
  //   mutateSPV({ id: formPedidoVenda.getFieldValue('id'), situacaoPedidoVendaId: 6 })
  //   setSituacaoPedidoVendaId(6) // Pagamento Confirmado 
  //   // formPedidoVenda.resetFields()
  //   // hideDrawerFormPedidoVenda()
  //   setButtonsDisabled(false)
  // }

  const handleConfirmarPagamentoParcela = () => {
    // console.log('handleConfirmarPagamentoParcela', parcelaPedidoVenda)
    const parcelasAbertas = parcelasPedidoVenda.filter((p: any) => p.dataPagamento === null)
    mutateCPP({ id: pedidoVenda.id, parcelaId: parcelaPedidoVenda.id, valorPago: parcelaPedidoVenda.valor })
    setModalPixParcVisible(false)
    //  handleSair()
    //  setSituacaoPedidoVendaId(6) // Pagamento Confirmado
    //  setPedidoVendaId(0)
    //  mutateCPP({ id: pedidoVenda.id, parcelaId: parcelaPedidoVenda.id, valorPago: parcelaPedidoVenda.valor })
    //  const parcelasAbertas = parcelasPedidoVenda.filter((p: any) => p.dataPagamento === null)
    if (parcelasAbertas.length === 1) {
      setSituacaoPedidoVendaId(6) // Pagamento Confirmado
    }
  }

  const handleObterPixCopiaECola = () => {
    // console.log('handleObterPixCopiaECola', pedidoVenda.parcelas)
    setModalPixCopColaVisible(true)
  }

  const hideModalPixCopCola = async () => {
    setModalPixCopColaVisible(false)
  };

  const handleObterPixParc = (parcela: any) => {
    if (pedidoVenda?.situacaoPedidoVendaId < 5) return
    setTxId(parcela.txId)
    setParcelaPedidoVenda(parcela)
    setFormaPagamento(formasPagamento.find((f: any) => f.id === parcela.formaPagamentoId))
    // console.log('handleObterPixParc', parcela, parcela.formaPagamentoId, formaPagamento)
    setModalPixParcVisible(true)
  }

  const hideModalPixParc = async () => {
    setModalPixParcVisible(false)
  };

  const handleSalvarNoBling = () => {
    setButtonsDisabled(true)

    const pedidoVendaBling = formataPedidoVendaBling(pedidoVenda, blingCliente, blingTransportador, blingVendedores, blingProdutos, blingFormasPagamento)
    console.log('pedidoVendaBling======', pedidoVendaBling)
    // TODO SALVAR NO BLING
    // .....

    mutateSPV({ id: formPedidoVenda.getFieldValue('id'), situacaoPedidoVendaId: 9 })
    setSituacaoPedidoVendaId(9) // Salvo no Bling

    // formPedidoVenda.resetFields()
    // hideDrawerFormPedidoVenda()
    setButtonsDisabled(false)
  }

  const onChangeEndEntregaDiferente: CheckboxProps['onChange'] = (e) => {
    // console.log(`checked = ${e.target.checked}`);
    setEndEntregaDiferente(e.target.checked)
    formPedidoVenda.setFieldsValue({
      endEntregaDiferente: e.target.checked,
    })
  };


  const [innerW, innerH] = useWindowSize();
  const wDrawerx = innerW > 1280 ? 1280 : innerW > 840 ? 840 : 360
  const layoutf = {
    labelCol: { span: 3 },
    wrapperCol: { span: 21 },
  }
  const layout0 = {
    labelCol: { span: 0 },
    wrapperCol: { span: 24 }
  }

  const pev = actionsDisabled ? 'pointer-events-none' : 'pointer-events-auto'
  const cordis = actionsDisabled ? 'text-slate-300' : ''
  // console.log('totais', totalProdutos, desconto, frete, outrasDespesas, totalPedido)
  const corBcli = temBlingCliente ? '' : 'border-solid border-1 border-red-500'
  const corBtra = temBlingTransportador ? '' : 'border-solid border-1 border-red-500'
  const corBven = temBlingVendedor ? '' : 'border-solid border-1 border-red-500'

  // console.log('\npedidoVenda', pedidoVenda)
  // console.log('flags', buttonsDisabled,savebtnDisabled,totalParcelas,totalPedido,itensPedidoVenda.length,houveMudanca,situacaoPedidoVendaId)
  return (
    <Drawer
      title={`${operacao} de Pedido Venda`}
      width={wDrawerx}
      closable={true}
      maskClosable={false}
      onClose={hideDrawerFormPedidoVenda}
      open={drawerFormPedidoVendaVisible}
      headerStyle={{ borderRadius: "0" }}
      bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
      className=""
    >
      <div className="inner-drawer-body">
        <Form {...layoutf} form={formPedidoVenda} onFinish={handleSubmitFormPedidoVenda} onFieldsChange={changeFilelds} disabled={formDisabled}>
          <div className="flex"><h2 className="mx-auto text-red-500">Funcionalidade em Desenvolvimento</h2></div>
          <Form.Item name="id" hidden noStyle></Form.Item>
          {/* <Form.Item name="categoriaReceitaDespesaId" hidden noStyle></Form.Item> */}

          <div className="font-bold mb-2">Dados do Pedido</div>

          <div className="w-full overflow-x-scroll">
            <div className="min-w-[1224px] max-w-[1232px]">
              <Form.Item label="Empresa" className="empresa mb-0">
                <Form.Item name="empresaId" label="" rules={[{ required: true }]} className="inline-block mb-2 bg-red-300">
                  <Select onChange={handleSelectEmpresa} optionFilterProp="children" placeholder="Empresa" style={{ width: '290px' }} popupClassName="border-solid border rounded border-blue-500">
                    {/* <Option value="">Selecione ...</Option> */}
                    {empresas?.map((empresa: any) => { return <Option value={empresa.id}>{empresa.nome}</Option> })}
                  </Select>
                </Form.Item>
                <Form.Item label="Data" name="data" className="inline-block mb-2 ml-4">
                  <div className="w-24 h-[31px] text-center font-bold py-1 border border-solid border-[#d9d9d9] bg-slate-100" >{dateFormatBr(data)}</div>
                </Form.Item>
                <Form.Item label="Nº Pedido Bling" className="inline-block mb-2 ml-4">
                  <div className="w-20 h-8 text-center py-1 border border-solid border-[#d9d9d9] bg-slate-100">{formPedidoVenda.getFieldValue('blingPedidoVendaNumero')}</div>
                </Form.Item>
                <Form.Item name="situacaoPedidoVendaId" label="Situação" rules={[{ required: true }]} className="inline-block mb-2 ml-6">
                  {/* <Select disabled placeholder="Situação" style={{ width: '160px' }} popupClassName="border-solid border rounded border-blue-500" >
                                        {situacoesPedidoVenda && situacoesPedidoVenda?.map((situacao: any) => { return <Option value={situacao.id}>{situacao.nome}</Option> })}
                                    </Select> */}
                  <div className="w-[160px] h-8 text-left font-bold pl-3 py-1 border border-solid border-[#d9d9d9] bg-slate-100">{situacoesPedidoVenda?.filter((situacao: any) => situacao.id === situacaoPedidoVendaId)[0].nome}</div>
                </Form.Item>
                <Form.Item label="Nº ZAPP" className="inline-block mb-2 ml-4">
                  <div className="w-[74px] h-8 text-left font-bold pl-3 py-1 border border-solid border-[#d9d9d9] bg-slate-100">{formPedidoVenda.getFieldValue('id')}</div>
                </Form.Item>
              </Form.Item>

              <Form.Item label="Cliente" className="cliente1 mb-0">
                <Form.Item name="clienteId" rules={[{ required: true }]} className={`inline-block mb-2 xbg-violet-300`}>
                  <Select
                    allowClear
                    showSearch
                    value={termoCli}
                    placeholder={'Digite parte do nome ou inicio do CNPJ/CPF'}
                    style={{ width: '651px' }}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleSearchCli}
                    onChange={handleSelectCliente}
                    notFoundContent={null}
                    popupClassName="border-solid border rounded border-blue-500"
                    className={`${corBcli}`}
                    options={(clientes || []).map((d: any) => ({
                      value: d.id,
                      label: d.nome,
                    }))}
                  />
                </Form.Item>
                <Form.Item name="vendedorId" label="Vendedor" rules={[{ required: true }]} className="inline-block mb-2 ml-4">
                  <Select onChange={handleSelectVendedor} placeholder="Vendedor" style={{ width: '316px' }} popupClassName="border-solid border rounded border-blue-500" className={`${corBven}`}>
                    {vendedores && vendedores?.map((vendedor: any) => <Option key={vendedor.id} value={vendedor.id}>{vendedor.nome}</Option>)}
                  </Select>
                </Form.Item>
              </Form.Item>
            </div>
          </div>

          <div className="font-bold mb-2">Itens do Pedido</div>

          <div className="w-full overflow-x-scroll">
            <div className="min-w-[1224px] max-w-[1232px]">
              <div className="flex flex-col mb-2 mr-1">
                <div className="cabecalho flex py-1 gap-[1px] mt-x bg-white font-semibold border-solid border-0 border-b border-t">
                  <div className={`px-2 py-0 w-8 text-left`}></div>
                  <div className={`px-2 py-0 w-[540px] text-left`}>Produto</div>
                  {/* <div className={`px-2 py-0 w-10 text-center`}>Dias</div> */}
                  <div className={`px-2 py-0 w-[84px] text-left`}>Código</div>
                  <div className={`px-2 py-0 w-10 text-center`}>UN</div>
                  <div className={`px-2 py-0 w-10 text-left`}>Qtd.</div>
                  <div className={`px-2 py-0 w-[87px] text-right`}>Preço Lista</div>
                  <div className={`px-2 py-0 w-20 text-right`}>Desc. %</div>
                  <div className={`px-2 py-0 w-[88px] text-right`}>Preço Unit.</div>
                  <div className={`px-2 py-0 w-24 text-right`}>Preço Total</div>
                  {/* <div className={`px-2 py-0 w-64 text-left`}>Observação</div> */}
                  <div className={`px-2 py-0 w-[132px] text-center`}>Ações</div>
                </div>
                {itensPedidoVenda?.map((item: any, index: number) => {
                  const temBlingProduto = blingProdutos?.find((bp: any) => bp.produtoId === item.produtoId)?.idbling
                  const corBprod = temBlingProduto ? 'pt-1' : 'border-solid border-1 border-red-500'
                  const descontoPerc = item.precoLista ? (item.precoLista - item.precoUnitario) / item.precoLista : 0
                  const corPunit = item.precoUnitario < item.precoLista ? 'bg-yellow-200' : ''
                  // const precoTotal = item.precoTotal
                  return (
                    <div key={index} className="item flex items-center h-8 py-0 gap-[1px] bg-slate-300  font-normal border-solid border-0 border-b ">
                      <div className={`px-2 h-[31px] pt-1 w-8 text-center bg-slate-100`}>{index + 1}</div>
                      <div className={`px-2 h-[31px] w-[540px] text-left bg-white ${corBprod}`}>{item.descricao}</div>
                      {/* <div className={`px-2 h-[31px] pt-1 w-10 text-center bg-white`}><input type="number" className="input-number w-9 border-0 border-l border-r px-1 py-[2px]" onWheel={(e) => onWhell(e)} onChange={(e) => handleChangeInputInt(e, 'dias')} /></div> */}
                      <div className={`px-2 h-[31px] pt-1 w-[84px] text-left bg-slate-100`}>{item.codigo}</div>
                      <div className={`px-2 h-[31px] pt-1 w-10 text-center bg-slate-100`}>{item.unidade}</div>
                      <div className={`px-2 h-[31px] pt-1 w-10 text-left bg-white`}>{item.quantidade}</div>
                      <div className={`px-2 h-[31px] pt-1 w-[87px] text-right bg-slate-100`}>{formatDecimalZ(item.precoLista, 2)}</div>
                      <div className={`px-2 h-[31px] pt-1 w-20 text-right bg-slate-100`}>{formatPercentual(descontoPerc, 1)}</div>
                      <div className={`px-2 h-[31px] pt-1 w-[88px] text-right bg-white ${corPunit}`}>{formatDecimalZ(item.precoUnitario, 2)}</div>
                      <div className={`px-2 h-[31px] pt-1 w-24 text-right bg-slate-100`}>{formatDecimalZ(item.precoTotal, 2)}</div>
                      {/* <div className={`px-2 h-[31px] pt-1 w-64 text-left bg-white`}>item</div> */}
                      <div className={`px-2 h-[31px] pt-1 w-[33px] text-center bg-white`}></div>
                      <div className={`px-2 h-[31px] pt-1 w-[33px] text-center bg-white`}></div>
                      <div className={`px-2 h-[31px] pt-1 w-[33px] text-center bg-white cursor-pointer ${pev}`} onClick={() => alteraItem(index)}><FaPencilAlt className={`text-blue-500 ${cordis}`} /></div>
                      <Popconfirm placement="right" title={'Confirma Exclusão do item?'} onConfirm={() => removeItem(index)} okText="Sim" cancelText="Não">
                        <div className={`px-2 h-[31px] pt-1 w-[33px] text-center bg-white cursor-pointer ${pev}`}><FaRegTrashAlt className={`text-red-500 ${cordis}`} /></div>
                      </Popconfirm>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div onClick={adicionaItem} className={`${pev} mt-1 mr-1 text-right cursor-pointer font-bold text-green-600${cordis} ${cordis}`}>+ Adicionar {itensPedidoVenda?.length > 0 ? 'outro' : 'um'} item</div>

          <Form.Item label="Desconto" className="pedido2 mb-0">
            <Form.Item name="desconto" label="" className="inline-block mb-2"><Input onBlur={(e) => onBlur(e, 'desconto')} onChange={(e) => handleChangeInputNumber(e, 'desconto')} className="w-20" /></Form.Item>
            <Form.Item name="outrasDespesas" label="Outras Desp." className="inline-block mb-2 ml-4"><Input onBlur={(e) => onBlur(e, 'outrasDespesas')} onChange={(e) => handleChangeInputNumber(e, 'outrasDespesas')} placeholder="" className="w-20" /></Form.Item>
          </Form.Item>

          <div className="font-bold mb-2">Dados do Transporte</div>

          <div className="w-full overflow-x-scroll">
            <div className="min-w-[1224px] max-w-[1232px]">

              <Form.Item name="transportadorId" label="Transportadora" className=" mb-2">
                <Select
                  allowClear
                  showSearch
                  value={termoTra}
                  placeholder={'Digite parte do nome ou inicio do CNPJ/CPF'}
                  style={{ width: '651px' }}
                  defaultActiveFirstOption={true}
                  showArrow={false}
                  filterOption={false}
                  onSearch={handleSearchTra}
                  onChange={handleSelectTransportador}
                  notFoundContent={null}
                  popupClassName="border-solid border rounded border-blue-500"
                  className={`${corBtra}`}
                  options={(transportadores || []).map((d: any) => ({
                    value: d.id,
                    label: d.nome,
                  }))}
                />
              </Form.Item>
              <Form.Item label="Frete Por Conta" rules={[{ required: true }]} className="transporte1 mb-0">
                <Form.Item name="fretePorConta" label="" rules={[{ required: true }]} className="inline-block mb-2">
                  <Select placeholder="Frete Por Conta" onChange={handleChangeFpc} style={{ width: '270px' }}
                    popupClassName="border-solid border rounded border-blue-500">
                    {tiposFretePorConta && tiposFretePorConta?.map((tipo: any) => <Option key={tipo.value} value={+tipo.value}>{tipo.value} - {tipo.label}</Option>)}
                  </Select>
                </Form.Item>
                <Form.Item name="frete" label="Frete" className="inline-block mb-2 ml-4"><Input onBlur={(e) => onBlur(e, 'frete')} onChange={(e) => handleChangeInputNumber(e, 'frete')} placeholder="" className="w-20" /></Form.Item>
              </Form.Item>

              <Form.Item label="Qtd. Volumes" className="volumes1 mb-0">
                <Form.Item name="quantidadeVolumes" label="" className="inline-block mb-2"><Input onChange={(e) => handleChangeInputInt(e, 'quantidadeVolumes')} className="w-14" /></Form.Item>
                <Form.Item name="pesoBruto" label="Peso Bruto" className="inline-block mb-2 ml-4"><Input onChange={(e) => handleChangeInputNumber(e, 'pesoBruto')} placeholder="" className="w-20" /></Form.Item>
                <Form.Item label="Nº Nota Fiscal" className="inline-block mb-2 ml-4">
                  {/* <Input placeholder="" className="w-20" /> */}
                  <div className="w-20 h-8 text-center py-1 border border-solid border-[#d9d9d9] bg-slate-100">{formPedidoVenda.getFieldValue('blingNotaFiscalNumero')}</div>
                </Form.Item>
                <Form.Item name="prazoEntrega" label="Prazo Entrega" className="inline-block mb-2 ml-4"><Input onChange={(e) => handleChangeInputInt(e, 'prazoEntrega')} className="w-14" /></Form.Item>
                <div className="inline-block mb-0 py-1 ml-1">dias</div>
              </Form.Item>

              <Form.Item name="observacoes" label="Observações" className="mb-2 mr-1"><TextArea placeholder="" rows={1} className="" /></Form.Item>
              <Form.Item name="observacoesInternas" label="Obs. Internas" className="mb-2 mr-1"><TextArea placeholder="" rows={1} className="" /></Form.Item>

              {/* // Endereço de Entrega - hidden (na prática não será utilizado) */}
              <div hidden className="mb-4">
                <Form.Item name="endEntregaDiferente" label="End. Entrega" >
                  <Checkbox checked={endEntregaDiferente} className="mt-1" onChange={onChangeEndEntregaDiferente}>Endereço de Entrega diferente da cobrança</Checkbox>
                </Form.Item>
                {/* <Checkbox checked={endEntregaDiferente} className="mb-2" onChange={onChangeEndEntregaDiferente}>Endereço de Entrega diferente da cobrança</Checkbox> */}
                <div className="" hidden={!endEntregaDiferente}>
                  {/* <div className="" hidden={!formPedidoVenda.getFieldValue('endEntregaDiferente')}> */}
                  {/* regex para cep  pattern: /^[0-9]{8}/  */}
                  <Form.Item name="nome" label="Nome" className="mb-2">
                    <Input className="w-[410px]" />
                  </Form.Item>
                  <Form.Item label="Cep" className="mb-2">
                    <Form.Item name="cep" className="inline-block mb-2" rules={[{ required: endEntregaDiferente ? true : false, pattern: /^\d{8}$/, message: '8 digitos numéricos' }]}>
                      <Input className="w-[100px]" onChange={handleCep} />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item label="Endereço" className="mb-0">
                    <Form.Item name="endereco" className="inline-block mb-2" label="" rules={[{ required: endEntregaDiferente ? true : false, message: 'Endereço Obrigatório' }]}>
                      <TextArea className="w-[410px]" placeholder="" rows={1} />
                    </Form.Item>
                    <Form.Item name="numero" label="Nº" className="inline-block mb-2 ml-4" rules={[{ required: endEntregaDiferente ? true : false, message: 'Obrigatório' }]} >
                      <Input className="w-20" placeholder="" />
                    </Form.Item>
                    <Form.Item name="complemento" label="Complemento" className="inline-block mb-2 ml-4">
                      <TextArea className="w-[400px]" placeholder="" rows={1} />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item label="Bairro" className="mb-0">
                    <Form.Item name="bairro" className="inline-block mb-2" >
                      <TextArea className="w-[410px]" placeholder="" rows={1} />
                    </Form.Item>
                    <Form.Item label="Município" name="municipio" className="inline-block mb-2 ml-4" rules={[{ required: endEntregaDiferente ? true : false, message: 'Município Obrigatório' }]} >
                      <Input className="w-[410px]" placeholder="" />
                    </Form.Item>
                    <Form.Item name="uf" label="UF" rules={[{ validator: checkUf }]} className="inline-block mb-2 ml-4" >
                      <Input className="w-[44px]" maxLength={2} placeholder="" />
                    </Form.Item>
                  </Form.Item>
                </div>
              </div>

            </div>
          </div>

          <div className="font-bold mb-2">Forma de Pagamento</div>

          <div className="w-full overflow-x-scroll">
            <div className="min-w-[1224px] max-w-[1232px]">
              <div className="flex flex-col mb-2 mr-1">
                <div className="cabecalho flex py-1 gap-[1px] mt-x bg-white font-semibold border-solid border-0 border-b border-t">
                  <div className={`px-2 py-0 w-8 text-left`}></div>
                  <div className={`px-2 py-0 w-12 text-left`}>Dias</div>
                  <div className={`px-2 py-0 w-24 text-left`}>Data</div>
                  <div className={`px-2 py-0 w-24 text-right`}>Valor</div>
                  <div className={`px-2 py-0 w-72 text-left`}>Forma de Pagamento</div>
                  <div className={`px-2 py-0 w-[470px] text-left`}>Observação</div>
                  <div className={`px-2 py-0 w-[33px] text-center`}></div>
                  <div className={`px-2 py-0 w-[93px] text-center`}>Dt.Pag.</div>
                  <div className={`px-2 py-0 w-[66px] text-center`}>Ações</div>
                  {/* <div className={`px-2 py-0 w-[33px] text-center`}></div> */}

                </div>
                {parcelasPedidoVenda?.map((item: any, index: number) => {
                  const forma = formasPagamento.find((f: any) => f.id === item.formaPagamentoId)
                  // console.log('parcelasPedidoVenda', index, item, forma)
                  const temBlingFormaPagamento = blingFormasPagamento?.find((b: any) => b.formaPagamentoId === item.formaPagamentoId)?.idbling
                  const corBfpa = temBlingFormaPagamento ? 'pt-1' : 'border-solid border-1 border-red-500'
                  const corIcon = situacaoPedidoVendaId < 5 ? '' : 'text-blue-500'
                  const pev1 = situacaoPedidoVendaId < 5 ? 'pointer-events-none' : 'pointer-events-auto'
                  const dias = differenceInDaysBetweenDates(item.dataVencimento, pedidoVenda?.data)
                  return (
                    <div key={index} className="item flex items-center h-8 py-0 gap-[1px] bg-slate-300  font-normal border-solid border-0 border-b ">
                      <div className={`px-2 pt-1 h-[31px] w-8 text-center bg-slate-100`}>{index + 1}</div>
                      <div className={`px-2 pt-1 h-[31px] w-12 text-left bg-white`}>{dias}</div>
                      <div className={`px-2 pt-1 h-[31px] w-24 text-left bg-white`}>{dateFormatBr(item.dataVencimento)}</div>
                      <div className={`px-2 pt-1 h-[31px] w-24 text-right bg-white`}>{formatDecimalZ(item.valor, 2)}</div>
                      <div className={`px-2 h-[31px] w-72 text-left bg-white ${corBfpa}`}>{item.formaPagamento}</div>
                      <div className={`px-2 pt-1 h-[31px] w-[470px] text-left bg-white`}>{item.observacoes}</div>
                      <div className={`px-2 pt-1 h-[31px] w-[33px] text-center bg-white cursor-pointer ${pev1}`} onClick={() => handleObterPixParc(item)} >{item.txId || forma?.tipoForma === "PCC" ? <FaQrcode className={`${corIcon}`} /> : forma?.tipoForma === "PSC" ? <FaPix className={`${corIcon}`} /> : forma?.tipoForma === "OAV" ? <RiMoneyDollarBoxLine className={`${corIcon} text-lg`} /> : <FaMoneyCheck className={`${corIcon} text-lg`} />}</div>
                      {/* <div className={`px-2 pt-1 h-[31px] w-[93px] text-center bg-white`}>{dateFormatBr(item.dataPagamento, 'dd/MM/yyyy HH:mm:ss')}</div> */}
                      <div className={`px-2 pt-1 h-[31px] w-[93px] text-center bg-white`}>{dateFormatBr(item.dataPagamento)}</div>
                      <div className={`px-2 h-[31px] pt-1 w-[33px] text-center bg-white cursor-pointer ${pev}`} onClick={() => alteraParcela(index)}><FaPencilAlt className={`text-blue-500 ${cordis}`} /></div>
                      <Popconfirm placement="right" title={'Confirma Exclusão da parcela?'} onConfirm={() => removeParcela(index)} okText="Sim" cancelText="Não">
                        <div className={`px-2 h-[31px] pt-1 w-[33px] text-center bg-white cursor-pointer ${pev}`} ><FaRegTrashAlt className={`text-red-500 ${cordis}`} /></div>
                      </Popconfirm>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div onClick={adicionaParcela} className={`${pev} mt-1 mr-1 text-right cursor-pointer font-bold text-green-600${cordis} ${cordis}`}>+ Adicionar {parcelasPedidoVenda?.length > 0 ? 'outra' : 'uma'} parcela</div>

          <div className="font-bold mb-2">Totais</div>

          <div className="w-full overflow-x-scroll">
            <div className="min-w-[784px] max-w-[1232px]">
              <div className="flex gap-0 mr-1">
                <div className="flex flex-col w-1/5 text-right">
                  <div className=" py-1 px-2 mt-x bg-slate-000 font-semibold border-solid border-0 border-b border-t">Total Produtos</div>
                  <div className=" py-1 px-2 mb-2 bg-slate-100  font-bold border-solid border-0 border-b ">{formatDecimalZ(totalProdutos, 2)}</div>
                </div>
                <div className="flex flex-col w-1/5 text-right">
                  <div className=" py-1 px-2 mt-x bg-slate-000 font-semibold border-solid border-0 border-b border-t">Desconto</div>
                  <div className=" py-1 px-2 mb-2 bg-slate-100  font-bold border-solid border-0 border-b ">{formatDecimalZ(desconto, 2)}</div>
                </div>
                <div className="flex flex-col w-1/5 text-right">
                  <div className=" py-1 px-2 mt-x bg-slate-000 font-semibold border-solid border-0 border-b border-t">Frete</div>
                  <div className=" py-1 px-2 mb-2 bg-slate-100  font-bold border-solid border-0 border-b ">{formatDecimalZ(frete, 2)}</div>
                </div>
                <div className="flex flex-col w-1/5 text-right">
                  <div className=" py-1 px-2 mt-x bg-slate-000 font-semibold border-solid border-0 border-b border-t">Outras Despesas</div>
                  <div className=" py-1 px-2 mb-2 bg-slate-100  font-bold border-solid border-0 border-b ">{formatDecimalZ(outrasDespesas, 2)}</div>
                </div>
                <div className="flex flex-col w-1/5 text-right">
                  <div className=" py-1 px-2 mt-x bg-slate-000 font-semibold border-solid border-0 border-b border-t">Total do Pedido</div>
                  <div className=" py-1 px-2 mb-2 bg-slate-100  font-bold border-solid border-0 border-b ">{formatDecimalZ(totalPedido, 2)}</div>
                </div>
                <div className="flex flex-col w-1/5 text-right">
                  <div className=" py-1 px-2 mt-x bg-slate-000 font-semibold border-solid border-0 border-b border-t">Total Parcelas</div>
                  <div className={`py-1 px-2 mb-2 bg-slate-100  font-bold border-solid border-0 border-b ${totalParcelas !== totalPedido ? 'text-red-500' : ''}`}>{formatDecimalZ(totalParcelas, 2)}</div>
                </div>
              </div>
            </div>
          </div>

        </Form>
      </div>
      <Space className="drawer-footer flex w-full justify-between">
        <Button onClick={handleSair} hidden={buttonsDisabled}>Fechar</Button>
        <div className="flex gap-2">
          <Popconfirm disabled={buttonsDisabled}
            title="Deseja realmente cancelar este pedido?"
            onConfirm={handleCancelarPedido}
            // onCancel={cancelDelete}
            okText="Sim"
            cancelText="Não"
          >
            <Button hidden={buttonsDisabled || (situacaoPedidoVendaId !== 2 && situacaoPedidoVendaId !== 4 && situacaoPedidoVendaId !== 5)} type="primary" danger >Cancelar Pedido</Button>
          </Popconfirm>
          <Button onClick={formPedidoVenda.submit} hidden={buttonsDisabled || savebtnDisabled || totalParcelas !== totalPedido || itensPedidoVenda.length === 0 || !houveMudanca || situacaoPedidoVendaId > 4} type="primary">Salvar Pedido</Button>
          <Button onClick={handleFinalizarPedido} hidden={buttonsDisabled || houveMudanca || situacaoPedidoVendaId !== 2} type="primary" className="border-blue-700 bg-blue-700 hover:bg-blue-500 hover:border-blue-500">Finalizar Pedido</Button>
          <Button onClick={handleGerarPix} hidden={buttonsDisabled || houveMudanca || (situacaoPedidoVendaId !== 4)} type="primary" className="border-blue-700 bg-blue-700 hover:bg-blue-500 hover:border-blue-500" >Gerar Pix</Button>
          {/* <Button onClick={handleConfirmarPagamento} hidden={buttonsDisabled || houveMudanca || (situacaoPedidoVendaId !== 5)} type="primary" className="border-blue-700 bg-blue-700 hover:bg-blue-500 hover:border-blue-500" >Confirmar Pagamento</Button> */}
          <Button onClick={handleObterPixCopiaECola} hidden={buttonsDisabled || houveMudanca || (situacaoPedidoVendaId !== 5)} type="primary" className="border-blue-700 bg-blue-700 hover:bg-blue-500 hover:border-blue-500" >VerPixCopiaECola</Button>
          <Button onClick={handleSalvarNoBling} hidden={buttonsDisabled || houveMudanca || (situacaoPedidoVendaId !== 6)} type="primary" className="border-green-700 bg-green-700 hover:bg-green-500 hover:border-green-500" >Salvar no Bling</Button>
        </div>
      </Space>
      {drawerFormItemPedidoVendaVisible ?
        <DrawerFormItemPedidoVenda
          drawerFormItemPedidoVendaVisible={drawerFormItemPedidoVendaVisible}
          hideDrawerFormItemPedidoVenda={hideDrawerFormItemPedidoVenda}
          operacao={operacaoItem} index={indexItem} produtos={produtos}
          itensPedidoVenda={itensPedidoVenda} setItensPedidoVenda={setItensPedidoVenda} calculaTotalPedido={calculaTotalPedido}
        />
        : null
      }
      {drawerFormParcelaPedidoVendaVisible ?
        <DrawerFormParcelaPedidoVenda
          drawerFormParcelaPedidoVendaVisible={drawerFormParcelaPedidoVendaVisible}
          hideDrawerFormParcelaPedidoVenda={hideDrawerFormParcelaPedidoVenda}
          operacao={operacaoParcela} index={indexParcela} dataVenda={data} formasPagamento={formasPagamento}
          parcelasPedidoVenda={parcelasPedidoVenda} setParcelasPedidoVenda={setParcelasPedidoVenda} calculaTotalParcelas={calculaTotalParcelas}
        />
        : null
      }
      <Modal
        width={800}
        open={modalPixCopColaVisible}
        maskClosable={false}
        cancelButtonProps={{ hidden: true }}
        onOk={hideModalPixCopCola}
        onCancel={() => setModalPixCopColaVisible(false)}
      >
        <>
          <div className="flex flex-col gap-2">
            <div className="font-bold select-none">Pix</div>
            {pixPedidoVenda && pixPedidoVenda?.length > 0 && pixPedidoVenda.map((pix: any, index: number) => {
              return (
                <div className="flex gap-2 w-full">
                  {/* <div className="w-[400px]  bg-gray-100 py-2 px-4">
                    <div className="font-bold select-none">QR Code</div>
                  </div> */}
                  <div className="w-full bg-gray-100 py-2 px-4">
                    {/* <div className="font-bold select-none">Copia e Cola</div> */}
                    <div key={index} className=" my-4">
                      {pix.pixCopiaECola}
                    </div>
                    <div> Valor: {formatDecimalZ(pix.valor,2)}</div>
                  </div>
                </div>

              )
            })}
            {pedidoVenda?.parcelas && pedidoVenda?.parcelas.map((parcela: any, index: number) => {
              if (parcela.tipoForma === "PSC") {
                return (
                  <div className="flex gap-2 w-full">
                    <div className="w-full bg-gray-100 py-2 px-4">
                      {/* <div className='select-none'>{parcela.formaPagamento}</div> */}
                      <div className=" my-4">Chave Pix: {'XXXXXXXXXXXXXXXXXXX'}</div>
                      <div> Valor: {formatDecimalZ(parcela.valor,2)}</div>
                    </div>
                  </div>
                )
              }
            })}
          </div>
        </>
      </Modal>
      <Modal
        width={800}
        open={modalPixParcVisible}
        maskClosable={false}
        cancelButtonProps={{ hidden: true }}
        onOk={hideModalPixParc}
        onCancel={() => setModalPixParcVisible(false)}
      >
        <div className="flex flex-col gap-2">
          <div className="font-bold select-none">Parcela</div>
          <div className="flex gap-2 w-full">
            <div className="w-full bg-gray-100 py-2 px-4">
              {formaPagamento?.tipoForma === "PCC" ? (
                <>
                  <div className="flex "> <div className="w-36 font-semibold">Empresa: </div> <div>{pixWebhook?.empresaId} - {empresas.find((e: any) => e.id === pixWebhook?.empresaId)?.nome}</div> </div>
                  <div className="flex "> <div className="w-36 font-semibold">Pix TxId: </div> <div>{pixWebhook?.txId}</div> </div>
                  <div className="flex "> <div className="w-36 font-semibold">Banco/Agencia/CC: </div> <div>{pixWebhook?.bancoId}/{pixWebhook?.agencia}/{pixWebhook?.contaCorrente}</div> </div>
                  <div className="flex "> <div className="w-36 font-semibold">Pix Criado em: </div> <div>{dateFormatBr(pixWebhook?.dataCriacao, 'dd/MM/yyyy HH:mm:ss')}</div> </div>
                  <div className="flex "> <div className="w-36 font-semibold">Data Expiração: </div> <div>{dateFormatBr(pixWebhook?.dataExpiracao, 'dd/MM/yyyy HH:mm:ss')}</div> </div>
                  {pixWebhook && pixWebhook.itens ? (
                    <>
                      <div className="flex "> <div className="w-36 font-semibold">Pix Valor: </div> <div>{formatDecimalZ(pixWebhook?.itens[0].valor, 2)}</div> </div>
                      <div className="flex "> <div className="w-36 font-semibold">Pago em: </div> <div>{dateFormatBr(pixWebhook?.itens[0].horario, 'dd/MM/yyyy HH:mm:ss')}</div> </div>
                      <div className="flex "> <div className="w-36 font-semibold">Pagador: </div> <div>{formatarCpfCnpj(pixWebhook?.itens[0].pagador.cpf)} - {pixWebhook?.itens[0].pagador.nome}</div> </div>
                    </>) : null}
                </>
              ) : (
                <>
                  <div className="flex "> <div className="w-36 font-semibold">Empresa: </div> <div>{pedidoVenda?.empresaId} - {empresas.find((e: any) => e.id === pedidoVenda?.empresaId)?.nome}</div> </div>
                  <div className="flex "> <div className="w-36 font-semibold">Forma Pagamento: </div> <div>{formaPagamento?.nome}</div> </div>
                  <div className="flex "> <div className="w-36 font-semibold">Valor: </div> <div>{formatDecimalZ(parcelaPedidoVenda?.valor, 2)}</div> </div>
                  {pedidoVenda?.situacaoPedidoVendaId === 5 && !parcelaPedidoVenda?.dataPagamento ?
                    <Button onClick={handleConfirmarPagamentoParcela} type="primary" className="mt-4  border-blue-700 bg-blue-700 hover:bg-blue-500 hover:border-blue-500" >Confirmar Pagamento</Button>
                    : null
                  }
                </>
              )}
            </div>
          </div>

        </div>
      </Modal>

    </Drawer>
  );
}