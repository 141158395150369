import React from "react";

export default function SpinnerNew(props: any) {
  const htb = `calc(100vh - 149px - 110px - 100px)` //
  return (
    <div className={`spinner justify-center items-center ${props.htb}`} style={{ display: props.loading ? "flex" : "none",   minHeight: htb }} >
            <div  >
        <div className="lds-spinner">
          <div></div><div></div><div></div><div></div><div></div><div></div>
          <div></div><div></div><div></div><div></div><div></div><div></div>
        </div>
      </div>
    </div>
  )
}
