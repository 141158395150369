import { formatDecimal, formatLength } from "../../../utils/functions"

export  const columnsFichaTecnicaItem = [
    {
      title: 'Item',
      dataIndex: '',
      ellipsis: true,
      width: '45px',
      align: 'right' as 'right',
      className: 'text-right',
      render: (text: any, record: any, index: any) => {
        return record.codigo === 'Total' ? '' : `${index+1}`
      }
    },
    {
      title: 'Codigo',
      dataIndex: 'codigo',
      width: '90px',
      className: 'text-left',
      ellipsis: false,
      render: (text: any, record: any) => `${text}`
    },
    {
      title: 'Nome do Componente',
      dataIndex: 'nomeComponente',
      width: '300px',
      className: 'text-left',
      ellipsis: false,
      render: (text: any, record: any) => `${text}`
    },
    {
      title: 'UN',
      dataIndex: 'unidade',
      width: '40px',
      className: 'text-left',
      ellipsis: true,
      render: (text: any, record: any) => `${text}`
    },
    {
      title: 'Consumo',
      dataIndex: 'quantConsumida',
      ellipsis: true,
      width: '90px',
      align: 'right' as 'right',
      className: 'text-right',
      render: (text: any, record: any) => `${formatLength(+text, 8)}`
    },
    
    {
      title: 'PU.Compra',
      dataIndex: 'precoCompra',
      width: '80px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: record.temPrecoInformado ? "text-red-600 font-semibold" : " " }),
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },
    {
      title: 'PT.Compra',
      dataIndex: 'totalCompra',
      width: '85px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },

    {
      title: 'PU.Cmc',
      dataIndex: 'precoCmc',
      width: '80px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },
    {
      title: 'PT.Cmc',
      dataIndex: 'totalCmc',
      width: '85px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },

    {
      title: 'PU.Info',
      dataIndex: 'precoInformado',
      width: '80px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },
    {
      title: 'PT.Info',
      dataIndex: 'totalInformado',
      width: '85px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },
    {
      title: 'Situação',
      dataIndex: 'situacao',
      width: '80px',
      className: 'text-left',
      ellipsis: true,
      render: (text: any, record: any) => `${text}`
    },
  ]