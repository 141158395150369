//o objetivo desta função e comparar alguns campos de dois objetos cliente

import { apierp } from "../../../services/api"

//e retornar um array com os campos que são diferentes
export function comparar2Clientes(cliente1: any, cliente2: any) {
    const keys = ['cnpj', 'nome', 'nomeFantasia', 'endereco', 'numero', 'complemento', 'bairro', 'cep', 'cidade', 'uf', 'fone', 'celular', 'email', 'situacao', 'tipoPessoa', 'ie', 'rg', 'indicadorIe']
    const diffs: any = []
    keys.forEach(k => {
        if (cliente1 === undefined || cliente2 === undefined) { return }
        if (cliente1[k] !== cliente2[k]) {
            diffs.push({ k, c1: cliente1[k], c2: cliente2[k] })
        }
    })
    return diffs
}

//esta função compara os clientes de dois arrays (bling1 com zapp1 e bling2 com zapp2)
export function comparar(clientesBling: any, clientesBlingZapp: any) {
    const compBlingZap = []
    for (let i = 0; i < clientesBling.length; i++) {
        const cBling = clientesBling[i]
        const cBlingZapp = clientesBlingZapp[i]
        const diffs = comparar2Clientes(cBling, cBlingZapp)
        compBlingZap.push(diffs)
    }
    return compBlingZap
}

export async function cadastrarClienteBling(cliente: any, empresaId: any) {
    const dataBling: any = {
        "metodo": "cadastrarCliente",
        "param": {
            "empresaId": empresaId,
            "dados": cliente
        },
    }
    // console.log('inclusao', cliente.numeroDocumento)
    const response1 = await apierp.post(`bling/v3/clientes`, dataBling) 
    const data1 = await response1.data as any;
    // console.log(data1.data.id)
    return data1.data.id
}

export async function alterarClienteBling(cliente: any, empresaId: any, clienteId: any) {
    const dataBling: any = {
        "metodo": "alterarCliente",
        "param": {
            "empresaId": empresaId,
            "id": clienteId, // id do cliente no bling 
            "dados": cliente
        },
    }
    // console.log('atualizacao', clienteId)
    const response1 = await apierp.post(`bling/v3/clientes`, dataBling) // na verdade é put
    const data1 = await response1.data as any;
    return 'ok'
}
