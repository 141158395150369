import { Modal } from "antd"
import { dateFormatBr, dateMySqlToPtBr } from "../../../utils/dateFunctions"
import { ellipsis, formatDecimal, formatDecimalZ } from "../../../utils/functions"

export default function ModalProdutos(props: any) {
    const showModalProdutos = props.showModalProdutos
    const hideModalProdutos = props.hideModalProdutos;
    const pedido = props.pedido;
    const emp = props.empresas
    const pedidosItensVendedor = props.pedidosItensVendedor;

    let totalModal = 0
    let qtModal = 0
    return (
        <Modal title="Produtos" open={showModalProdutos} onOk={hideModalProdutos} onCancel={hideModalProdutos} footer={[]} centered width={1040}>
            <div id="printableModal" className="">
                <div className="text-center">
                    {/* The Modal Content */}
                    <div className="">

                            {/* TITULO */}
                            <div className="linha-titulo flex flex-wrap text-center mb-2.5 font-semibold" >
                                <div className='flex '>
                                    <div className="w-14 text-left">Pedido:</div>
                                    <div className="w-14 text-left">{pedido?.numero}</div>
                                </div>
                                <div className='flex '>
                                    <div className="w-10 text-left">Data:</div>
                                    <div className="w-24 text-left">{dateMySqlToPtBr(pedido?.data)}</div>
                                </div>
                                <div className='flex '>
                                    <div className="w-16 text-left">Empresa:</div>
                                    <div className="w-40 text-left">{emp[pedido?.empresaId - 1]?.nome.replace('Colorbrilho ', '')}</div>
                                </div>
                                <div className='flex '>
                                    <div className="w-20 text-left">Vendedor:</div>
                                    <div className="w-56 text-left">{pedido?.vendedor}</div>
                                </div>
                            </div>

                        <div className="overflow-x-auto">
                            {/* CABECALHO */}
                            <div className="flex min-w-[972px] font-semibold border-0 border-b border-solid border-zinc-700 bg-gray-900 text-white"
                                key={"modcab"}
                            >
                                <div className="w-[84px]  px-2 py-1.5 text-left">Codigo</div>
                                <div className="w-[550px] px-2 py-1.5 text-left">Nome</div>
                                <div className="w-10      px-2 py-1.5 text-left">Un</div>
                                <div className="w-12      px-2 py-1.5 text-right">Qtd</div>
                                <div className="w-20      px-2 py-1.5 text-right">P.Unit</div>
                                <div className="w-20      px-2 py-1.5 text-right">Desc%</div>
                                <div className="w-24      px-2 py-1.5 text-right">Total</div>
                            </div>

                            {/* CORPO */}
                            <div className="max-h-[400px] min-w-[972px] overflow-y-auto">
                                {(pedido?.itens || []).map((p: any) => (
                                    <div key={p.codigo} className="flex border-solid border-0 border-b border-[#b0d2ff]">
                                        <div className="w-[84px]  px-2 py-1.5 text-left">{p.codigo}</div>
                                        <div className="w-[550px] px-2 py-1.5 text-left">{p.nome}</div>
                                        <div className="w-10      px-2 py-1.5 text-left">{p.unidade}</div>
                                        <div className="w-12      px-2 py-1.5 text-right">{p.quantidade}</div>
                                        <div className="w-20      px-2 py-1.5 text-right">{formatDecimalZ(p.valorunidade, 2)}</div>
                                        <div className="w-20      px-2 py-1.5 text-right">{formatDecimalZ(p.descontoItem, 2)}%</div>
                                        <div className="w-24      px-2 py-1.5 text-right">{formatDecimalZ(p.valorTotal, 2)}</div>
                                    </div>
                                ))}
                                <div className="text-center pr-1.5 bg-zinc-300">Final</div>
                                {/* RODAPÉ */}
                                {/* <div className="flex mb-5 bg-zinc-500 font-bold" key={"modrod"} >
                                    <div className="w-[70px]  min-w-[70px]  px-2 py-1.5">Total</div>
                                    <div className="w-[95px]  min-w-[95px]  px-2 py-1.5 text-right"></div>
                                    <div className="w-[60px]  min-w-[60px]  px-2 py-1.5 text-right">{formatDecimal(qtModal, 0)}</div>
                                    <div className="w-[70px]  min-w-[70px]  px-2 py-1.5 text-right">{formatDecimal(totalModal / qtModal, 2)}</div>
                                    <div className="w-[100px] min-w-[100px]  px-2 py-1.5 text-right">{formatDecimal(totalModal, 2)}</div>
                                    <div className="w-[145px] min-w-[145px]  px-2 py-1.5"></div>
                                    <div className="w-[435px] min-w-[435px]  px-2 py-1.5"></div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}