import { ColumnsType } from "antd/lib/table";
import { formatDecimal } from "../../../../utils/functions";
import { Input } from "antd";
import { getColumnSearchProps } from "../../../../components/columnSearchProps";
import { WarningOutlined } from '@ant-design/icons';

// interface DataType {
//   // key: React.Key;
//   categoria: string;
//   codigo: string;
//   nome: string;
//   unidade: string;
//   saldoAcumulado: number;
//   cmcUnitario: number;
//   cmcTotal: number;
// }

export function columnsEstoqueDivididoSped(props: any) {
  return [
   
    {
      title: 'Codigo',
      dataIndex: 'codigo',
      ellipsis: true,
      width: '97px',
      className: 'text-left',
    },
    {
      title: 'Produto',
      dataIndex: 'nome',
      width: '520px',
      className: 'text-left',
      ellipsis: false,
      render: (text: any, record: any) => `${text}`
    },
    {
      title: 'UN',
      dataIndex: 'unidade',
      width: '40px',
      className: 'text-left',
      ellipsis: true,
      render: (text: any, record: any) => `${text}`
    },
    {
      title: 'NCM',
      dataIndex: 'ncm',
      ellipsis: true,
      width: '97px',
      className: 'text-left',
    },

    {
      title: 'Estoque Final (Total)',
      align: 'right' as 'right',
      className: 'text-right',
      children: [
    {
      title: 'Qtd',
      dataIndex: 'sldestv2ft',
      width: '90px',
      key: "sldestv2ft",
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      // sorter: (a: any, b: any) => a.sldestft - b.sldestft,
      onCell: (record: any, i: any) => ({ className: record.sldestft < 0 ? "text-red-600 font-semibold" : "text-blue-500" }),
      render: (text: any, record: any) => `${record.unidade === 'UN' ? +text : formatDecimal(+text, 3)}` // formatDecimal(+text, 0)
    },
    {
      title: 'CmcUnit.',
      dataIndex: 'cmcUnitario',
      width: '100px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      render: (text: any, record: any) => `${formatDecimal((record.vlrestv2ft / record.sldestv2ft), 6)}`
    },
    {
      title: 'Total',
      dataIndex: 'vlrestv2ft',
      width: '110px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: record.vlrestft < 0 ? "text-red-600 font-semibold" : " " }),
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    }]},

    {
      title: 'Estoque Final P.Automotivos',
      align: 'right' as 'right',
      className: 'text-right',
      children: [
    {
      title: 'Qtd',
      dataIndex: 'sldestv2f1',
      width: '90px',
      key: "sldestv2f1",
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      // sorter: (a: any, b: any) => a.sldestf1 - b.sldestf1,
      onCell: (record: any, i: any) => ({ className: "text-blue-500" }),
      render: (text: any, record: any) => `${record.unidade === 'UN' ? +text : formatDecimal(+text, 3)}` // formatDecimal(+text, 0)
    },
    {
      title: 'CmcUnit.',
      dataIndex: 'cmcUnitario',
      width: '100px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      render: (text: any, record: any) => `${formatDecimal((record.vlrestv2f1 / record.sldestv2f1), 6)}`
    },
    {
      title: 'Total',
      dataIndex: 'vlrestv2f1',
      width: '110px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: "" }),
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    }]},

    {
      title: 'Estoque Final S.Químicas',
      align: 'right' as 'right',
      className: 'text-right',
      children: [
    {
      title: 'Qtd',
      dataIndex: 'sldestv2f2',
      width: '90px',
      key: "sldestv2f2",
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      // sorter: (a: any, b: any) => a.sldestf2 - b.sldestf2,
      onCell: (record: any, i: any) => ({ className: "text-blue-500" }),
      render: (text: any, record: any) => `${record.unidade === 'UN' ? +text : formatDecimal(+text, 3)}` // formatDecimal(+text, 0)
    },
    {
      title: 'CmcUnit.',
      dataIndex: 'cmcUnitario',
      width: '100px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      render: (text: any, record: any) => `${formatDecimal((record.vlrestv2f2 / record.sldestv2f2), 6)}`
    },
    {
      title: 'Total',
      dataIndex: 'vlrestv2f2',
      width: '110px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: "text-green-700" }),
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    }]},
    {
      title: 'Tipo Produto',
      dataIndex: 'spedTipoItem',
      ellipsis: true,
      width: '180px',
      align: 'right' as 'right',
      className: 'text-right',
      render: (text: any, record: any) => `${text}`,
      // filters: [
      //   {
      //     text: 'Embalagem',
      //     value: 'Embalagem',
      //   },
      //   {
      //     text: 'Matéria Prima',
      //     value: 'Matéria Prima',
      //   },
      //   {
      //     text: 'Produto Acabado',
      //     value: 'Produto Acabado',
      //   },
      // ],
      // filterMode: 'menu',
      // // filterSearch: true,
      // onFilter: (value: string, record: any) => record.spedTipoItem?.includes(value),
    },
  ]
}