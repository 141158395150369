
import * as React from "react"
import { useEffect, useState } from "react"

import { Button, message, Modal, Typography } from "antd";

import { useAuth } from "../../../../contexts/auth";
import { useGeral } from "../../../../contexts/geral";
import { customMessage } from "../../../../components/template/CustomMessage";
import { apierp } from "../../../../services/api";
import Layout from "../../../../components/template/Layout"
import { dateFormat, dateMySqlToPtBr } from "../../../../utils/dateFunctions";

const { Title, Text, Paragraph } = Typography;

export default function ImportaBlingPedidosVendaV2() {
  const { Logout, user } = useAuth();
  const usuario: any = user
  const { userMenu, menuSelected, userTenant, } = useGeral();
  const menu = userMenu.find((x: any) => x.id == menuSelected)

  const [toggleState, setToggleState] = useState(false)
  const [parametro, setParametro] = useState(null as any)
  const [updatedAt, setUpdatedAt] = useState('')
  const [resultado, setResultado] = useState([])

  useEffect(() => {
    // setLoading(true)
    setTimeout(async function () {
      try {
        await loadParametro()
      } catch (err) {
        if (customMessage(err) === 403) Logout()
      }
      // setLoading(false)
    }, 500);
  }, [toggleState])

  async function loadParametro() {
    const response = await apierp.get(`parametros/bycode?code=getPedidosVendaFromBling`)
    const data = response.data as any;
    // console.log('data-----', data.updatedAt)
    setParametro(data);
    setUpdatedAt(dateFormat(data.updatedAt, 'dd/MM/yyyy HH:mm:ss'))
    // console.log('loadParametro',data)
  }

  async function handleClick() {
    const final: any = ['===== Atualização iniciada (V2)  =====']
    const finalizada: any = ['===== Atualização Finalizada (V2) =====']
    setResultado(final)
    try {
      const ret: any = await apierp.get(`blingpedidosvendav2`);
      for (let i = 0; i < ret.data.length; i++) { final.push(ret.data[i]) }
      final.push(finalizada)
      setResultado(final)
      loadParametro()
      // setToggleState(!toggleState)
      // message.success('Inquilino excluido com sucesso!');
    } catch (err) {
      if (customMessage(err) === 403) Logout()
    } finally {
      Modal.success({
      title: 'Sucesso', content: (
        <>
          <p>Sua solicitação foi processada com sucesso!</p>
          <p className="text-red-500 font-semibold">Por favor, Procure, se possível, dar um intervalo maior entre as atualizações!</p>
          <p className="text-red-500 font-semibold">OBRIGADO!</p>
        </>
      )
    });
    }

  };

  const htb = `calc(100vh - 360px)`
  // console.log(menu.label)
  return (
    <Layout titulo={menu.label} botoes={null}>
      <div className="mx-auto flex flex-col">
        <h1 className='mb-1 text-xl font-bold mx-auto'>Ultima atualização em:</h1>
        <h1 className='h-8 mb-4 text-2xl font-bold mx-auto whitespace-nowrap'>{updatedAt}</h1>
        <Button className="mx-auto" onClick={handleClick} disabled={false} type="primary">Atualizar agora (V2)</Button>
      </div>
      <div className="flex overflow-x-auto mx-5">
        <div className="min-w-[600px] max-w-[600px] mx-auto" >
          <div className="min-h-[300px] mt-4 px-4 py-2 bg-zinc-100 overflow-y-auto " style={{ maxHeight: htb }}>
            {resultado.map((r, index) => {
              return (
                <div className="">{r}</div>
              )
            })
            }
            {/* <div>XXssssss Xssssss Xssssss Xssssss Xssssss Xssssss Xssssss </div> */}
          </div>

        </div>
      </div>
    </Layout>
  )
}
