import { useCallback, useEffect, useState } from 'react'
import useProcessando from '../../../../data/hooks/useProcessando'
import { apierp } from '../../../../services/api'
import { dateFormat } from '../../../../utils/dateFunctions'

export default function useEstoqueDivididoByDateSped(dateref: any) {
  const { processando, iniciarProcessamento, finalizarProcessamento } = useProcessando()
  const [estoqueDividido, setEstoqueDividido] = useState<any[]>([])
  const [empresas, setEmpresas] = useState<any[]>([])
  const buscarEstoqueDividido = useCallback(async function () {
    if (dateref === null) return
    const date = dateFormat(dateref, 'yyyy-MM-dd')
    const depositoId=1
    try {
      iniciarProcessamento()

      const response1 = await apierp.get(`relatorios/estoquedivididosped?data=${date}&depositoId=${depositoId}`)
      const data1 = await response1.data as any;
      setEstoqueDividido(data1)
      // console.log(data1)
      const response2 = await apierp.get('empresas')
      const data2 = await response2.data as any;
      // setEmpresas(data2)
      const arr = []
      for (let emp of data2 ) {
        arr[emp.id] = emp.nome
      }
      setEmpresas(arr)

    } catch (err) {
      console.log(err)
    } finally {
      finalizarProcessamento()
    }

  }, [dateref])

  function voltar() {
    setEstoqueDividido([])
    setEmpresas([])
  }
  function reload() {
    buscarEstoqueDividido()
  }
  
  useEffect(() => {
    setEstoqueDividido([])
    setEmpresas([])
    buscarEstoqueDividido()
  }, [buscarEstoqueDividido])

  return {
    estoqueDividido,
    empresas,
    processando,
    iniciarProcessamento,
    finalizarProcessamento,
    voltar,
    reload
  }

}