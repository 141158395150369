import { dateFormatBr } from "../../utils/dateFunctions"
import { formatDecimalZ } from "../../utils/functions"

export function columnsPrePedidoVenda(props: any) {

    return [
        {
            title: 'Id',
            dataIndex: 'id',
            ellipsis: true,
            width: '80px',
            className: 'text-left',
        },
        {
            title: 'Nº Bling',
            dataIndex: 'blingPedidoNumero',
            width: '80px',
            className: 'text-left',
            ellipsis: true,
        },
        {
            title: 'Data',
            dataIndex: 'data',
            width: '100px',
            className: 'text-left',
            ellipsis: true,
            render: (text: any, record: any) => `${dateFormatBr(text)}`
        },
        {
            title: 'Cliente',
            dataIndex: 'cliente',
            width: '450px',
            className: 'text-left',
            ellipsis: false,
        },
        {
            title: 'Vendedor',
            dataIndex: 'vendedor',
            width: '100px',
            className: 'text-left',
            ellipsis: false,
        },
        // {
        //     title: 'Total Produtos',
        //     dataIndex: 'totalprodutos',
        //     width: '150px',
        //     className: 'text-right',
        //     ellipsis: true,
        // },
        {
            title: 'Total',
            dataIndex: 'total',
            width: '110px',
            align: 'right' as 'right',
            className: 'text-right',
            ellipsis: true,
            render: (text: any, record: any) => `${formatDecimalZ(+text, 2)}`
        },
        // {
        //     title: 'Observações',
        //     dataIndex: 'observacoes',
        //     width: '450px',
        //     className: 'text-left',
        //     ellipsis: false,
        // },
        // {
        //     title: 'Observações Internas',
        //     dataIndex: 'observacoesInternas',
        //     width: '450px',
        //     className: 'text-left',
        //     ellipsis: false,
        // },
        {
            title: 'Desconto',
            dataIndex: 'desconto',
            width: '80px',
            align: 'right' as 'right',
            className: 'text-right',
            ellipsis: true,
            render: (text: any, record: any) => `${formatDecimalZ(+text, 2)}`
        },
        // frete
        {
            title: 'Frete',
            dataIndex: 'frete',
            width: '80px',
            align: 'right' as 'right',
            className: 'text-right',
            ellipsis: true,
            render: (text: any, record: any) => `${formatDecimalZ(+text, 2)}`
        },
        {
            title: 'Situação',
            dataIndex: 'situacao',
            width: '150px',
            className: 'text-left',
            ellipsis: true,
        },

    ]
}

const columns =
{
    "id": 1,
    "tenantId": 3,
    "empresaId": 1,
    "createdAt": "2024-05-02T14:01:57.604Z",
    "updatedAt": "2024-05-02T14:01:57.604Z",
    "clienteId": 6699,
    "vendedorId": 10,
    "data": "2024-04-08",
    "totalprodutos": 1020,
    "total": 1020,
    "situacaoId": 1,
    "blingLojaId": null,
    "blingClienteId": "16588688540",
    "blingPedidoVendaId": null,
    "blingPedidoNumero": null,
    "observacoes": "Observação exemplo",
    "observacoesInternas": "Observação interna exemplo",
    "desconto": 0,
    "outrasDespesas": 0,
    "prazoEntrega": null,
    "blingCategoriaReceitaDespesaId": "2611836175",
    "blingTransportadoraId": null,
    "fretePorConta": 0,
    "frete": 50,
    "quantidadeVolumes": 2,
    "pesoBruto": 34,
    "blingNotaFiscalId": null,
    "blingVendedorId": "15596338666",
    "cliente": "FERNANDO RODRIGUES PACHECO",
    "vendedor": "CLEBER",
    "situacao": "Em Andamento"
}
