import { useEffect, useRef, useState } from "react"
import Highlighter from 'react-highlight-words';
import { LockFilled, UnlockFilled, CheckSquareFilled, DownloadOutlined, CopyOutlined, PrinterOutlined, PlusOutlined, FastBackwardOutlined } from '@ant-design/icons';
import { InputRef, Modal } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useAuth } from "../../contexts/auth";
import { apierp } from '../../services/api';
import { customMessage } from "../../components/template/CustomMessage";

import useWindowSize from "../../hooks/useWindowSize";

import { Button, Col, Drawer, Form, Input, message, Popconfirm, Radio, Row, Select, Space, Table, Typography } from 'antd';
import SpinnerNew from "../../components/template/SpinnerNew";
import { copyToClipboard, downloadToCsv, formatDecimal, printDiv } from "../../utils/functions";
import Layout from "../../components/template/Layout";
import { useGeral } from "../../contexts/geral";
import Tabela from "../../components/template/Tabela";
import { Fichastecnicas } from "./FichaTecnica";
import { Produtosbling } from "./ProdutoBling";
import { FichaTecnicaItens } from "./FichaTecnicaItem";
import { columnsProduto } from "./columnsProduto";
import isPermitted from "../../hooks/isPermitted";
import { ColumnType, FilterConfirmProps } from "antd/lib/table/interface";
import { HistoricoCompras } from "./HistoricoCompras";

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
const { Option } = Select;

export default function Produto() {

  //ESTADOS Gerais
  const { Logout, user } = useAuth();
  const usuario: any = user
  const [innerW, innerH] = useWindowSize();

  const { userMenu, menuSelected, userTenant, } = useGeral();
  const menu = userMenu.find((x: any) => x.id == menuSelected)

  const [buttonsDisabled, setButtonsDisabled] = useState(false)

  const [toggleState, setToggleState] = useState(false)

  //ESTADOS Produto
  const [formProduto] = Form.useForm();
  const [loadingProduto, setLoadingProduto] = useState(false)
  const [drawerFormProdutoVisible, setDrawerFormProdutoVisible] = useState(false)
  const [produtos, setProdutos] = useState([])
  const [componentes, setComponentes] = useState([])
  const [categorias, setCategorias] = useState([])
  const [produto, setProduto] = useState(null as any)
  const [rowProdutoSelected, setRowProdutoSelected] = useState(-1)
  const [lastProdutoSelected, setLastProdutoSelected] = useState(-1)
  const [isInclusaoProduto, setIsInclusaoProduto] = useState(false)
  const [produtoBlocked, setProdutoBlocked] = useState(false)

  //ESTADOS FichaTecnica
  const [formFichaTecnica] = Form.useForm();
  const [fichasTecnicas, setFichasTecnicas] = useState([])
  const [loadingFichaTecnica, setLoadingFichaTecnica] = useState(false)
  const [drawerFichasTecnicasVisible, setDrawerFichasTecnicasVisible] = useState(false)
  const [drawerFormFichaTecnicaVisible, setDrawerFormFichaTecnicaVisible] = useState(false)
  const [fichaTecnica, setFichaTecnica] = useState(null as any)
  const [rowFichaTecnicaSelected, setRowFichaTecnicaSelected] = useState(-1)
  const [lastFichaTecnicaSelected, setLastFichaTecnicaSelected] = useState(-1)
  const [isInclusaoFichaTecnica, setIsInclusaoFichaTecnica] = useState(false)
  const [fichaTecnicaBlocked, setFichaTecnicaBlocked] = useState(false)

  //ESTADOS FichaTecnicaItem
  const [formFichaTecnicaItem] = Form.useForm();
  const [loadingFichaTecnicaItem, setLoadingFichaTecnicaItem] = useState(false)
  const [drawerFichaTecnicaItensVisible, setDrawerFichaTecnicaItensVisible] = useState(false)
  const [drawerFormFichaTecnicaItemVisible, setDrawerFormFichaTecnicaItemVisible] = useState(false)
  const [fichaTecnicaItens, setFichaTecnicaItens] = useState([])
  const [fichaTecnicaItem, setFichaTecnicaItem] = useState(null as any)
  const [fichaTecnicaItemBlocked, setFichaTecnicaItemBlocked] = useState(false)
  const [rowFichaTecnicaItemSelected, setRowFichaTecnicaItemSelected] = useState(-1)
  const [lastFichaTecnicaItemSelected, setLastFichaTecnicaItemSelected] = useState(-1)
  const [isInclusaoFichaTecnicaItem, setIsInclusaoFichaTecnicaItem] = useState(false)

  //ESTADOS ProdutoBling
  const [loadingProdutoBling, setLoadingProdutoBling] = useState(false)
  const [drawerProdutosBlingVisible, setDrawerProdutosBlingVisible] = useState(false)
  const [produtosBling, setProdutosBling] = useState([])
  const [produtoBling, setProdutoBling] = useState(null as any)
  const [rowProdutoBlingSelected, setRowProdutoBlingSelected] = useState(-1)
  const [lastProdutoBlingSelected, setLastProdutoBlingSelected] = useState(-1)
  
  //ESTADOS DIVERSOS
  const [modalComprasVisible, setModalComprasVisible] = useState(false)
  const [compras, setCompras] = useState([])

  //PERMISSOES
  const isPermittedManterProdutos = isPermitted('manter_produtos')
  const isPermittedManterFT = isPermitted('manter_fichas_tecnicas')
  const isPermittedVisualizarFT = isPermitted('visualizar_fichas_tecnicas')
  const isPermittedVisualizarProdutosBling = isPermitted('visualizar_produtos_bling')
  const isPermittedVisualizarCompras = isPermitted('visualizar_compras')
  const isPermittedVisualizarEstoque = isPermitted('visualizar_estoque')

  // search filter produto
  interface DataType {
    nome: string;
    nomeComponente: string;
  }
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [selectedKeys, setSelectedKeys] = useState('');
  const searchInput = useRef<InputRef>(null);
  type DataIndex = keyof DataType;
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };
  //end

  const colunas = columnsProduto({
    setSearchText, setSearchedColumn, setSelectedKeys, selectedKeys, searchInput, searchedColumn, searchText,
    categorias
  })

  useEffect(() => {
    setLoadingProduto(true)
    setTimeout(async function () {
      try {
        await loadProdutos()
      } catch (err) {
        if (customMessage(err) === 403) Logout()
      }
      setLoadingProduto(false)
    }, 500);
  }, [toggleState])

  async function loadProdutos() {
    const response = await apierp.get('produtosbt')
    const data = response.data as any;
    setProdutos(data.sort((a: any, b: any) => (a.codigo < b.codigo ? -1 : a.seq === b.seq ? 0 : 1)));
    // setComponentes(data.filter((x: any) => x.situacao==='Ativo' && (x.categoria === 'Matéria Prima' || x.categoria === 'Embalagens' || x.categoria === 'Rótulos'))
    //   .sort((a: any, b: any) => (a.nomeComponente < b.nomeComponente ? -1 : a.seq === b.seq ? 0 : 1)));
    setComponentes(data.filter((x: any) => x.situacao==='Ativo')
      .sort((a: any, b: any) => (a.nomeComponente < b.nomeComponente ? -1 : a.seq === b.seq ? 0 : 1)));
    // obter categorias de produtos para filtro
    const response2 = await apierp.get('categorias')
    const categorias = response2.data as any;
    // console.log(categorias);
    setCategorias(categorias);
  }
  async function loadProduto(produtoId: any) {
    const response = await apierp.get(`produtosxd/${produtoId}`)
    const data = response.data as any;
    setProduto(data);
  }
  async function loadFichasTecnicas(produtoId: any) {
    const response = await apierp.get(`produtosxd/${produtoId}`)
    const data = response.data as any;
    setProduto(data);
  }
  async function loadFichaTecnicaItens(produtoId: any) {
    const response = await apierp.get(`produtosxd/${produtoId}`)
    const data = response.data as any;
    setProduto(data);
  }
  async function loadCompras(codigo: any) {
    // console.log('loading historico compras')
    const response = await apierp.get(`historicocompras/${codigo}`)
    const data = response.data as any;
    // console.log(data.length)
    setCompras(data);
  }

  async function handleRowProduto(record: any, index: any) {
    setLastProdutoSelected(index)
    setRowProdutoSelected(index)
    setIsInclusaoProduto(false)
    await loadProduto(record.id)
    showDrawerFormProduto()
    const produto: any = {
      id: record.id,
      codigo: record.codigo,
      nome: record.nome,
      unidade: record.unidade,
      nomeComponente: record.nomeComponente,
      precoInformado: record.precoInformado,
      estoqueMinimo: record.estoqueMinimo,
      prazoReposicao: record.prazoReposicao,
      situacao: record.situacao,
    }
    fillFormProduto(produto)
  }

  async function handleRowFichaTecnica(record: any, index: any) {
    setFichaTecnica(record)
    setLastFichaTecnicaSelected(index)
    setRowFichaTecnicaSelected(index)
    setIsInclusaoFichaTecnica(false)
    showDrawerFormFichaTecnica()
    // console.log('record', record)
    const fichaTecnica: any = {
      id: record.id,
      produtoId: record.produtoId,
      nome: record.nome,
      unidade: record.unidade,
      quantProduzida: record.quantProduzida,
      isForCusto: record.isForCusto,
      situacao: record.situacao,
    }
    fillFormFichaTecnica(fichaTecnica)
  }

  async function handleRowFichaTecnicaItem(record: any, index: any) {
    if (record.id === "#") { return }

    setLastFichaTecnicaItemSelected(index)
    setRowFichaTecnicaItemSelected(index)
    setIsInclusaoFichaTecnicaItem(false)
    showDrawerFormFichaTecnicaItem()
    // console.log('record', record)
    const fichaTecnicaItem: any = {
      id: record.id,
      fichatecnicaId: record.fichatecnicaId,
      produtoId: record.produtoId,
      quantConsumida: record.quantConsumida,
    }
    // console.log(fichaTecnicaItem)
    fillFormFichaTecnicaItem(fichaTecnicaItem)
  }

  async function handleRowProdutoBling(record: any, index: any) {
    if (rowProdutoBlingSelected === index) {
      setLastProdutoBlingSelected(-1)
      setRowProdutoBlingSelected(-1)
    } else {
      setLastProdutoBlingSelected(index)
      setRowProdutoBlingSelected(index)
    }
  }

  const fillFormProduto = (produtoy: any) => {
    formProduto.setFieldsValue({
      id: produtoy.id,
      codigo: produtoy.codigo,
      nome: produtoy.nome,
      unidade: produtoy.unidade,
      nomeComponente: produtoy.nomeComponente,
      precoInformado: formatDecimal(produtoy.precoInformado, 2),
      estoqueMinimo: formatDecimal(produtoy.estoqueMinimo, 0),
      prazoReposicao: formatDecimal(produtoy.prazoReposicao, 0),
      situacao: produtoy.situacao,
    });
  };
  const fillFormFichaTecnica = (fichatecnica: any) => {
    formFichaTecnica.setFieldsValue({
      id: fichatecnica.id,
      produtoId: fichatecnica.produtoId,
      nome: fichatecnica.nome,
      unidade: fichatecnica.unidade,
      quantProduzida: fichatecnica.quantProduzida.toString().replace(".", ","),
      isForCusto: fichatecnica.isForCusto,
      situacao: fichatecnica.situacao,
    });
  };
  const fillFormFichaTecnicaItem = (fichatecnicaItem: any) => {
    formFichaTecnicaItem.setFieldsValue({
      id: fichatecnicaItem.id,
      fichatecnicaId: fichatecnicaItem.fichatecnicaId,
      produtoId: fichatecnicaItem.produtoId,
      quantConsumida: fichatecnicaItem.quantConsumida,
      // situacao: fichatecnicaItem.situacao,
    });
  };

  const resetFormProduto = () => {
    setRowProdutoSelected(-1)
    novoProduto() // form.resetFields();
  };

  const resetFormFichaTecnica = () => {
    setRowFichaTecnicaSelected(-1)
    novaFichaTecnica() // form.resetFields();
  };

  const resetFormFichaTecnicaItem = () => {
    setRowFichaTecnicaItemSelected(-1)
    novaFichaTecnicaItem() // form.resetFields();
  };

  const showDrawerFormProduto = () => {
    setButtonsDisabled(false)
    setDrawerFormProdutoVisible(true)
  };
  const hideDrawerFormProduto = () => {
    setButtonsDisabled(false)
    setRowProdutoSelected(-1)
    setProduto(null)
    setDrawerFormProdutoVisible(false)
  };

  const showDrawerFichasTecnicas = async () => {
    await loadFichasTecnicas(produto.id)
    setButtonsDisabled(false)
    setRowFichaTecnicaSelected(-1)
    setLastFichaTecnicaSelected(-1)
    setDrawerFichasTecnicasVisible(true)
  };
  const hideDrawerFichasTecnicas = async () => {
    setButtonsDisabled(false)
    setDrawerFichasTecnicasVisible(false)
  };

  // Drawers Form FichaTecnica
  const showDrawerFormFichaTecnica = () => {
    setButtonsDisabled(false)
    setDrawerFormFichaTecnicaVisible(true)
  };
  const hideDrawerFormFichaTecnica = async () => {
    setButtonsDisabled(false)
    await loadFichasTecnicas(produto.id)
    setRowFichaTecnicaSelected(-1)
    setDrawerFormFichaTecnicaVisible(false)
  }

  // Drawers Table FichaTecnicaItem
  const showDrawerFichaTecnicaItens = async () => {
    await loadFichaTecnicaItens(produto.id)
    setButtonsDisabled(false)
    setRowFichaTecnicaItemSelected(-1)
    setLastFichaTecnicaItemSelected(-1)
    setDrawerFichaTecnicaItensVisible(true)
  };
  const hideDrawerFichaTecnicaItens = () => {
    setButtonsDisabled(false)
    setDrawerFichaTecnicaItensVisible(false)
  };

  // Drawers Form FichaTecnicaItem
  const showDrawerFormFichaTecnicaItem = () => {
    setButtonsDisabled(false)
    setDrawerFormFichaTecnicaItemVisible(true)
  };
  const hideDrawerFormFichaTecnicaItem = async () => {
    setButtonsDisabled(false)
    await loadFichasTecnicas(produto.id)
    setRowFichaTecnicaItemSelected(-1)
    setDrawerFormFichaTecnicaItemVisible(false)
  };

  // Drawers Table ProdutosBling
  const showDrawerProdutosBling = () => {
    setButtonsDisabled(false)
    setRowProdutoBlingSelected(-1)
    setLastProdutoBlingSelected(-1)
    setDrawerProdutosBlingVisible(true)
  };
  const hideDrawerProdutosBling = () => {
    setButtonsDisabled(false)
    setDrawerProdutosBlingVisible(false)
  };

  const showModalCompras = async () => {
    await loadCompras(produto.codigo)
    // setButtonsDisabled(false)
    // setRowProdutoBlingSelected(-1)
    // setLastProdutoBlingSelected(-1)
    setModalComprasVisible(true)
  };

  const hideModalCompras = () => {
    // setButtonsDisabled(false)
    setModalComprasVisible(false)
  };


  const showModalEstoques = () => {
    // setButtonsDisabled(false)
    // setRowProdutoBlingSelected(-1)
    // setLastProdutoBlingSelected(-1)
    // setDrawerProdutosBlingVisible(true)
  };

  const hideModalEstoques = () => {
    // setButtonsDisabled(false)
    // setDrawerProdutosBlingVisible(false)
  };

  async function handleSubmit(dados: any) {
    setButtonsDisabled(true)
    dados.nomeComponente = dados.nomeComponente.toUpperCase().trim().replaceAll('  ', ' ').replaceAll('  ', ' ').replaceAll('  ', ' ')
    dados.precoInformado = dados.precoInformado.toString().replace(",", ".")
    dados.estoqueMinimo = dados.estoqueMinimo.toString().replace(",", ".")
    dados.prazoReposicao = dados.prazoReposicao.toString().replace(",", ".")
    // console.log('dados', dados)
    if (dados.id === '') {
      //CREATE
      try {
        // const result = await apierp.post('produtos', dados);
        setToggleState(!toggleState)
        message.success('Produto incluido com sucesso!');

        hideDrawerFormProduto()
      } catch (err) {
        if (customMessage(err) === 403) Logout()
      }
    } else {
      //UPDATE
      try {
        const result = await apierp.put(`produtos/${dados.id}`, dados);
        setToggleState(!toggleState)
        message.success('Produto alterado com sucesso!');
        hideDrawerFormProduto()
      } catch (err) {
        if (customMessage(err) === 403) Logout()
      }
    }

  };
  async function handleSubmitFichaTecnica(dados: any) {
    setButtonsDisabled(true)
    // console.log('dados', dados)
    dados.nome = dados.nome.toUpperCase()
    dados.unidade = dados.unidade.toUpperCase()
    dados.quantProduzida = dados.quantProduzida.toString().replace(",", ".")
    if (dados.id === '') {
      delete dados.id
      //CREATE
      try {
        const result = await apierp.post('fichastecnicas', dados);
        setToggleState(!toggleState)
        message.success('Ficha Tecnica incluida com sucesso!');
        hideDrawerFormFichaTecnica()
      } catch (err) {
        if (customMessage(err) === 403) Logout()
      }
    } else {
      //UPDATE
      try {
        const result = await apierp.put(`fichastecnicas/${dados.id}`, dados);
        setToggleState(!toggleState)
        message.success('Ficha Técnica alterada com sucesso!');
        hideDrawerFormFichaTecnica()
      } catch (err) {
        if (customMessage(err) === 403) Logout()
      }
    }

  };

  async function handleSubmitFichaTecnicaItem(dados: any) {
    setButtonsDisabled(true)
    // console.log('dados', dados)
    dados.quantConsumida = dados.quantConsumida.toString().replace(",", ".")
    // dados.precoInformado=dados.precoInformado.toString().replace(",",".")
    if (dados.id === '') {
      delete dados.id
      //CREATE
      try {
        const result = await apierp.post('fichatecnicaitens', dados);
        setToggleState(!toggleState)
        message.success('Item da Ficha Tecnica incluida com sucesso!');
        hideDrawerFormFichaTecnicaItem()
      } catch (err) {
        if (customMessage(err) === 403) Logout()
      }
    } else {
      //UPDATE
      try {
        const result = await apierp.put(`fichatecnicaitens/${dados.id}`, dados);
        setToggleState(!toggleState)
        message.success('Item da Ficha Técnica alterada com sucesso!');
        hideDrawerFormFichaTecnicaItem()
      } catch (err) {
        if (customMessage(err) === 403) Logout()
      }
    }
  };

  async function handleDelete() {
    const dados = formProduto.getFieldsValue()
    try {
      await apierp.delete(`produtos/${dados.id}`);
      setToggleState(!toggleState)
      setLastProdutoSelected(-1)
      message.success('Produto excluido com sucesso!');
      hideDrawerFormProduto()
    } catch (err) {
      if (customMessage(err) === 403) Logout()
    }
  };

  function confirmDelete(e: any) {
    handleDelete();
  }

  function cancelDelete(e: any) {
    message.error('Produto não foi excluído');
  }

  const novoProduto = () => {
    setIsInclusaoProduto(true)
    showDrawerFormProduto()
    const produtoy: any = {
      id: '',
      codigo: '',
      nome: '',
      unidade: '',
      nomeComponente: '',
      precoInformado: '',
      estoqueMinimo: '',
      prazoReposicao: '',
      situacao: '',
    }
    fillFormProduto(produtoy)
  }

  const novaFichaTecnica = () => {
    setIsInclusaoFichaTecnica(true)
    showDrawerFormFichaTecnica()
    const fichatecnica: any = {
      id: '',
      produtoId: produto.id,
      nome: '',
      unidade: '',
      quantProduzida: '',
      isForCusto: false,
      situacao: 'Ativo',
      // produtoId: produto.id,
    }
    fillFormFichaTecnica(fichatecnica)
  }

  const novaFichaTecnicaItem = () => {
    setIsInclusaoFichaTecnicaItem(true)
    showDrawerFormFichaTecnicaItem()
    const fichatecnicaItem: any = {
      id: '',
      fichatecnicaId: fichaTecnica.id,
      codigo: '',
      nomeComponente: '',
      unidade: '',
      quantConsumida: '',
      // precoInformado: '',
      situacao: '',
    }
    fillFormFichaTecnicaItem(fichatecnicaItem)
  }

  async function handleDeleteFichaTecnicaItem() {
    const dados = formFichaTecnicaItem.getFieldsValue()
    try {
      await apierp.delete(`fichatecnicaitens/${dados.id}`);
      setToggleState(!toggleState)
      setLastFichaTecnicaItemSelected(-1)
      message.success('Item excluido com sucesso!');
      hideDrawerFormFichaTecnicaItem()
    } catch (err) {
      if (customMessage(err) === 403) Logout()
    }
  };

  function confirmDeleteFichaTecnicaItem(e: any) {
    handleDeleteFichaTecnicaItem();
  }

  function cancelDeleteFichaTecnicaItem(e: any) {
    message.error('Item não foi excluído');
  }

  const divTotalLeft = innerW > 640 ? 'left-[144px]' : ''


  const botoes = () => {
    return (
      <Space size={10}>
        <Button onClick={() => printDiv('printableDiv-produto', 'produto')} shape="round" size="small" icon={<PrinterOutlined />} title="Imprimir"></Button>
        <Button onClick={() => copyToClipboard('produto')} shape="round" size="small" icon={<CopyOutlined />} title="Copy to Clipboard"></Button>
        <Button onClick={() => downloadToCsv('produto', "Inquilinos.csv")} shape="round" size="small" icon={<DownloadOutlined />} title="Download CSV"></Button>
        {/* <Button onClick={novoProduto} type="primary" shape="round" size="small" icon={<PlusOutlined />} title="Novo"></Button> */}
        {/* <div>{innerW}</div> */}
      </Space>
    )
  }

  const wTab = 1546 // liquido = x-17
  const htb = `calc(100vh - 149px - 110px)`
  const hsc = `calc(100vh - 180px - 109px)` // 1 a menos que htb
  const wDrawer = innerW > 640 ? innerW - 144 : 360 // - 144
  const wDrawerx = innerW > 720 && innerW > 360 ? 720 : 360 // - 144

  return (
    <Layout titulo={menu.label} botoes={botoes()}>
      <SpinnerNew loading={loadingProduto} />
      <Tabela className="mx-5" id='produto' titulo='Produtos' wTab={wTab} htb={htb} hsc={hsc} dataSource={produtos} columns={colunas} handleRow={handleRowProduto} rowSelected={rowProdutoSelected} lastSelected={lastProdutoSelected} loading={loadingProduto} />

      {/* DRAWER FORM PRODUTO */}
      <Drawer
        title={(isInclusaoProduto ? "Criar um novo" : "Alterar") + " produto"}
        width={360}
        closable={true}
        maskClosable={false}
        onClose={hideDrawerFormProduto}
        open={drawerFormProdutoVisible}
        headerStyle={{ borderRadius: "0" }}
        bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
      >
        <div className="inner-drawer-body">
          <Form layout="vertical" form={formProduto} onFinish={handleSubmit} disabled={!isPermittedManterProdutos} >
            <Title level={5}>{formProduto.getFieldValue('codigo')} - {formProduto.getFieldValue('nome')}</Title>
            <Form.Item name="id" hidden noStyle></Form.Item>
            <Form.Item name="codigo" hidden noStyle></Form.Item>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="nomeComponente"
                  label="Nome Componente"
                  rules={[{ required: true, message: 'Entre com uma Descrição do produto' }]}
                >
                  <TextArea rows={3} placeholder="Nome do Componente" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="precoInformado"
                  label="Preço de Custo Informado"
                // rules={[{ required: false}]}
                >
                  <Input placeholder="Preço de Custo Informado" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="estoqueMinimo"
                  label={`Estoque Mínimo em (${formProduto.getFieldValue('unidade')})`}
                // rules={[{ required: false}]}
                >
                  <Input placeholder="Estoque Mínimo" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item 
                  name="prazoReposicao"
                  label="Prazo de reposição (em dias)"
                // rules={[{ required: false}]}
                >
                  <Input placeholder="Prazo de Reposicao" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="situacao"
                  label="Situação"
                >
                  <Radio.Group>
                    <Radio value={"Inativo"}>Inativo</Radio>
                    <Radio value={"Ativo"}>Ativo</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Space className="inner-drawer-buttons">
              <Button size="small" className="bg-gray-100 rounded-full" onClick={showDrawerFichasTecnicas} hidden={!isPermittedVisualizarFT} disabled={isInclusaoProduto}>FichasTecnicas</Button>
              <Button size="small" className="bg-gray-100 rounded-full" onClick={showDrawerProdutosBling} hidden={!isPermittedVisualizarProdutosBling} disabled={isInclusaoProduto}>Bling</Button>
              <Button size="small" className="bg-gray-100 rounded-full" onClick={showModalCompras} hidden={!isPermittedVisualizarCompras} disabled={isInclusaoProduto}>Compras</Button>
              <Button size="small" className="bg-gray-100 rounded-full" onClick={showModalEstoques} hidden={!isPermittedVisualizarEstoque} disabled={isInclusaoProduto}>Estoque</Button>
            </Space>

          </Form>
        </div>

        <Space className="drawer-footer flex w-full justify-end">
          <Space>
            <Button onClick={() => { hideDrawerFormProduto() }} type="primary" icon={<FastBackwardOutlined />} />
            <Button onClick={hideDrawerFormProduto}>Cancel</Button>
            {/* <Button onClick={resetFormProduto}>Reset</Button> */}
            <Popconfirm disabled={produtoBlocked}
              title="Deseja realmente excluir este produto?"
              onConfirm={confirmDelete}
              onCancel={cancelDelete}
              okText="Sim"
              cancelText="Não"
            >
              {/* <Button onClick={() => {}} type="primary" danger disabled={isInclusao || produtoBlocked || buttonsDisabled} >Delete</Button> */}
              <Button onClick={() => { }} type="primary" danger disabled >Delete</Button>
            </Popconfirm>

            <Button onClick={formProduto.submit} disabled={produtoBlocked || buttonsDisabled || !isPermittedManterProdutos} type="primary">Submit</Button>
          </Space>
        </Space>
      </Drawer>

      {/* DRAWER TABELA FICHAS TECNICAS */}
      <Drawer
        title="Fichas Técnicas"
        width={wDrawer}
        closable={true}
        maskClosable={false}
        onClose={hideDrawerFichasTecnicas}
        open={drawerFichasTecnicasVisible}
        headerStyle={{ borderRadius: "0" }}
        bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
      >
        <div className="inner-drawer-body justify-start">
          <Fichastecnicas produto={produto} novaFichaTecnica={novaFichaTecnica}
            handleRowFichaTecnica={handleRowFichaTecnica}
            rowFichaTecnicaSelected={rowFichaTecnicaSelected}
            lastFichaTecnicaSelected={lastFichaTecnicaSelected}
            loadingFichaTecnica={loadingFichaTecnica}
          />
        </div>
        <Space className="drawer-footer flex w-full justify-start">
          <Space>
            <Button onClick={() => { hideDrawerFichasTecnicas(); hideDrawerFormProduto() }} type="primary" icon={<FastBackwardOutlined />} />
            <Button onClick={hideDrawerFichasTecnicas}>Cancel</Button>
            {/* <Button onClick={resetForm}>Reset</Button> */}
            {/* <Popconfirm disabled={ produtoBlocked }
            title="Deseja realmente excluir esta ficha técnica?"
            onConfirm={confirmDeleteFichaTecnica}
            onCancel={cancelDeleteFichaTecnica}
            okText="Sim"
            cancelText="Não"
          >
            <Button onClick={() => {}} type="primary" danger disabled >Delete</Button>
          </Popconfirm> */}

            {/* <Button onClick={form.submit} disabled={produtoBlocked || buttonsDisabled} type="primary">Submit</Button> */}
          </Space>
        </Space>
      </Drawer>

      {/* DRAWER FORM FICHA TECNICA */}
      <Drawer
        title={(isInclusaoFichaTecnica ? "Criar um novo" : "Alterar") + " fichaTecnica"}
        width={360}
        closable={true}
        maskClosable={false}
        onClose={hideDrawerFormFichaTecnica}
        open={drawerFormFichaTecnicaVisible}
        headerStyle={{ borderRadius: "0" }}
        bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
      >
        <div className="inner-drawer-body">
          <Form layout="vertical" form={formFichaTecnica} onFinish={handleSubmitFichaTecnica} disabled={!isPermittedManterFT} >
            <Title level={5}>{formFichaTecnica.getFieldValue('codigo')}</Title>
            <Title level={5}>{formFichaTecnica.getFieldValue('nome')}</Title>
            <Form.Item name="id" hidden noStyle></Form.Item>
            <Form.Item name="produtoId" hidden noStyle></Form.Item>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="nome"
                  label="Nome Ficha Tecnica"
                  rules={[{ required: true, message: 'Nome obrigatório' }]}
                >
                  <Input placeholder="Nome Ficha Tecnica" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="unidade"
                  label="Unidade"
                  rules={[{ required: true, message: 'Unidade Obrigatória' }]}
                >
                  <Input placeholder="Unidade" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="quantProduzida"
                  label="Quantida Produzida"
                  rules={[{ required: true, message: 'Quantidade Obrigatória' }]}
                >
                  <Input placeholder="Quantidade" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="isForCusto"
                  label="Para Custo"
                >
                  <Radio.Group>
                    <Radio value={false}>Não</Radio>
                    <Radio value={true}>Sim</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="situacao"
                  label="Situação"
                >
                  <Radio.Group>
                    <Radio value={"Inativo"}>Inativo</Radio>
                    <Radio value={"Ativo"}>Ativo</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Space className="inner-drawer-buttons">
              <Button className="bg-gray-100 rounded-full" onClick={showDrawerFichaTecnicaItens} disabled={isInclusaoFichaTecnica}>Itens da Ficha Técnica</Button>
            </Space>

          </Form>
        </div>

        <Space className="drawer-footer flex w-full justify-end">
          <Space>
            <Button onClick={() => { hideDrawerFormFichaTecnica(); hideDrawerFichasTecnicas(); hideDrawerFormProduto() }} type="primary" icon={<FastBackwardOutlined />} />
            <Button onClick={hideDrawerFormFichaTecnica}>Cancel</Button>
            {/* <Button onClick={resetFormFichaTecnica}>Reset</Button> */}
            <Popconfirm disabled={fichaTecnicaBlocked}
              title="Deseja realmente excluir esta ficha técnica?"
              onConfirm={confirmDelete}
              onCancel={cancelDelete}
              okText="Sim"
              cancelText="Não"
            >
              {/* <Button onClick={() => {}} type="primary" danger disabled={isInclusao || fichaTecnicaBlocked || buttonsDisabled} >Delete</Button> */}
              <Button onClick={() => { }} type="primary" danger disabled >Delete</Button>
            </Popconfirm>

            <Button onClick={formFichaTecnica.submit} disabled={fichaTecnicaBlocked || buttonsDisabled || !isPermittedManterFT} type="primary">Submit</Button>
          </Space>
        </Space>
      </Drawer>

      {/* DRAWER TABELA FICHAS TECNICAS ITENS */}
      <Drawer
        title="Itens da Ficha Técnica"
        width={wDrawer}
        closable={true}
        maskClosable={false}
        onClose={hideDrawerFichaTecnicaItens}
        open={drawerFichaTecnicaItensVisible}
        headerStyle={{ borderRadius: "0" }}
        bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
      >
        <div className="inner-drawer-body justify-start">
          <FichaTecnicaItens produto={produto} novaFichaTecnicaItem={novaFichaTecnicaItem}
            rowFichaTecnicaSelected={rowFichaTecnicaSelected}
            handleRowFichaTecnicaItem={handleRowFichaTecnicaItem}
            rowFichaTecnicaItemSelected={rowFichaTecnicaItemSelected}
            lastFichaTecnicaItemSelected={lastFichaTecnicaItemSelected}
            loadingFichaTecnicaItem={loadingFichaTecnicaItem}
          />
        </div>
        <Space className="drawer-footer flex w-full justify-start">
          <Space>
            <Button onClick={() => { hideDrawerFichaTecnicaItens(); hideDrawerFormFichaTecnica(); hideDrawerFichasTecnicas(); hideDrawerFormProduto() }} type="primary" icon={<FastBackwardOutlined />} />
            <Button onClick={hideDrawerFichaTecnicaItens}>Cancel</Button>
            {/* <Button onClick={resetForm}>Reset</Button> */}
            {/* <Popconfirm disabled={ produtoBlocked }
            title="Deseja realmente excluir esta ficha técnica?"
            onConfirm={confirmDeleteFichaTecnica}
            onCancel={cancelDeleteFichaTecnica}
            okText="Sim"
            cancelText="Não"
          >
            <Button onClick={() => {}} type="primary" danger disabled >Delete</Button>
          </Popconfirm> */}

            {/* <Button onClick={form.submit} disabled={produtoBlocked || buttonsDisabled} type="primary">Submit</Button> */}
          </Space>
        </Space>
      </Drawer>

      {/* DRAWER FORM FICHA TECNICA ITENS */}
      <Drawer
        title={(isInclusaoFichaTecnicaItem ? "Criar um novo" : "Alterar") + " fichaTecnicaItem"}
        width={wDrawerx}
        closable={true}
        maskClosable={false}
        onClose={hideDrawerFormFichaTecnicaItem}
        open={drawerFormFichaTecnicaItemVisible}
        headerStyle={{ borderRadius: "0" }}
        bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
      >
        <div className="inner-drawer-body">
          <Form layout="vertical" form={formFichaTecnicaItem} onFinish={handleSubmitFichaTecnicaItem} disabled={!isPermittedManterFT} >
            <Title level={5}>{formFichaTecnicaItem.getFieldValue('codigo')}</Title>
            <Title level={5}>{formFichaTecnicaItem.getFieldValue('nome')}</Title>
            <Form.Item name="id" hidden noStyle></Form.Item>
            <Form.Item name="fichatecnicaId" hidden noStyle></Form.Item>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="produtoId"
                  label="Componente"
                // rules={[{ required: true, message: 'Selecione Um Pai' }]}
                >
                  <Select placeholder="Componente" 
                  showSearch 
                  optionFilterProp="children" 
                  popupClassName="border-solid border border-blue-500">
                    {/* <Option value={null}>É Nivel Raiz</Option> */}
                    {componentes.map((produto: any, index: any) => {
                      return <Option value={produto.id} className="text-blue-800 font-semibold"> {produto.codigo} - {produto.nomeComponente}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="quantConsumida"
                  label="Quantidade Consumida"
                  rules={[{ required: true, message: 'Quantidade Obrigatória' }]}
                >
                  <Input placeholder="Quantidade" />
                </Form.Item>
              </Col>
            </Row>

            {/* <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="precoInformado"
                  label="Preço Informado"
                  rules={[{ required: false }]}
                >
                  <Input placeholder="Preço Informado" />
                </Form.Item>
              </Col>
            </Row> */}

          </Form>
        </div>

        <Space className="drawer-footer flex w-full justify-end">
          <Space>
            <Button onClick={() => { hideDrawerFormFichaTecnicaItem(); hideDrawerFichaTecnicaItens(); hideDrawerFormFichaTecnica(); hideDrawerFichasTecnicas(); hideDrawerFormProduto() }} type="primary" icon={<FastBackwardOutlined />} />
            <Button onClick={hideDrawerFormFichaTecnicaItem}>Cancel</Button>
            {/* <Button onClick={resetFormFichaTecnicaItem}>Reset</Button> */}
            {/* <Space className="w-[500px]">x</Space> */}
            <Popconfirm disabled={fichaTecnicaItemBlocked}
              title="Deseja realmente excluir este componente?"
              onConfirm={confirmDeleteFichaTecnicaItem}
              onCancel={cancelDeleteFichaTecnicaItem}
              okText="Sim"
              cancelText="Não"
            >
              <Button onClick={() => {}} type="primary" danger disabled={isInclusaoFichaTecnicaItem || fichaTecnicaItemBlocked || buttonsDisabled} >Delete</Button>
              {/* <Button onClick={() => { }} type="primary" danger disabled >Delete</Button> */}
            </Popconfirm>

            <Button onClick={formFichaTecnicaItem.submit} disabled={fichaTecnicaItemBlocked || buttonsDisabled || !isPermittedManterFT} type="primary">Submit</Button>
          </Space>
        </Space>
      </Drawer>

      {/* DRAWER TABELA PRODUTOS BLING */}
      <Drawer
        title="Produtos Bling"
        width={wDrawer}
        closable={true}
        maskClosable={false}
        onClose={hideDrawerProdutosBling}
        open={drawerProdutosBlingVisible}
        headerStyle={{ borderRadius: "0" }}
        bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
      >
        <div className="inner-drawer-body justify-start">
          <Produtosbling produto={produto}
            handleRowProdutoBling={handleRowProdutoBling}
            rowProdutoBlingSelected={rowProdutoBlingSelected}
            lastProdutoBlingSelected={lastProdutoBlingSelected}
            loadingProdutoBling={loadingProdutoBling}
          />

        </div>
        <Space className="drawer-footer flex w-full justify-start">
          <Space>
            <Button onClick={hideDrawerProdutosBling}>Cancel</Button>
          </Space>
        </Space>
      </Drawer>

      {/* MODAL COMPRAS */}
      <Modal title="Historico de Compras" open={modalComprasVisible} onOk={hideModalCompras} onCancel={() => setModalComprasVisible(false)} footer={[]} centered width={1000}>
      <HistoricoCompras produto={produto} compras={compras} novaFichaTecnicaItem={novaFichaTecnicaItem}
            rowFichaTecnicaSelected={rowFichaTecnicaSelected}
            handleRowFichaTecnicaItem={handleRowFichaTecnicaItem}
            rowFichaTecnicaItemSelected={rowFichaTecnicaItemSelected}
            lastFichaTecnicaItemSelected={lastFichaTecnicaItemSelected}
            loadingFichaTecnicaItem={loadingFichaTecnicaItem}
          />

      </Modal>
      <div className={`divtotal absolute flex items-center h-10 px-4 bottom-8 ${divTotalLeft} text-white`}>{produtos?.length} Produtos</div>

    </Layout>
  )

}
