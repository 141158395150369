import { dateFormat } from "../../../utils/dateFunctions"
import { formatDecimal } from "../../../utils/functions"
import useCatMes from "./useCatMes"

interface CatMesProps {
  ano: number
  mes: number
  categoria: string
}

export default function RelCatMes(props: CatMesProps) {

  const { processando, catMes, voltar } = useCatMes(props.ano, props.mes, props.categoria)

  const meses = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez', 'Total']

  // console.log('relCatMes', catMes)
  const hsc = `calc(100vh - 250px)`
  // const wTab = 
  return (
    <>
      {/* <div className="w-full bg-blue-200 text-center text-lg py-2 font-semibold">{props.categoria} [{props.mes.toString().padStart(2, '0')}/{props.ano}]</div> */}
      {/* <div className="w-full bg-blue-200 text-center text-lg py-2 font-semibold">{props.categoria} [{meses[props.mes-1]}/{props.ano}]</div> */}
      <div className="xxx flex flex-col justify-between bg-[#f8f9fb] " > {/** ou heigth */}
        <div className={`tabela overflow-x-auto`} >
          <div className="flex bg-gray-900 px-3 pt-1 mb-2 min-w-[1452px]">
            <h3 className="w-[75px]  px-1 text-white text-left ">Situacao</h3>
            <h3 className="w-[100px]  px-1 text-white text-right" >Nro Doc</h3>
            <h3 className="w-[90px] px-1 text-white text-right" >Emissão</h3>
            <h3 className="w-[90px] px-1 text-white text-right" >Dt. Venc.</h3>
            <h3 className="w-[110px] px-1 text-white text-right" >Competencia</h3>
            <h3 className="w-[110px] px-1 text-white text-right" >Valor</h3>
            <h3 className="w-[410px] px-1 text-white text-left" >Fornecedor</h3>
            <h3 className="w-[434px] px-1 text-white text-left" >Histórico</h3>
          </div>
          <div style={{ maxHeight: hsc }} className='overflow-y-auto min-w-[1452px]'>
            {catMes.map((item: any, i) => {
                return (
                  <div className="flex px-3 pt-[2px] border-solid border-0 border-b border-[#b0d2ff]">
                    <h4 className="w-[75px]  px-1 text-left ">{item.situacao}</h4>
                    <h4 className="w-[100px]  px-1 text-right" >{item.nroDocumento}</h4>
                    <h4 className="w-[90px] px-1 text-right" >{dateFormat(item.dataEmissao, "dd/MM/yyyy")}</h4>
                    <h4 className="w-[90px] px-1 text-right" >{dateFormat(item.dataVencimento, "dd/MM/yyyy")}</h4>
                    <h4 className="w-[110px] px-1 text-right" >{dateFormat(item.dataCompetencia, "dd/MM/yyyy")}</h4>
                    <h4 className="w-[110px] px-1 text-right" >{formatDecimal(item.valor + 0.0000001, 2)}</h4>
                    <h4 className="w-[410px] px-1 text-left" >{item.fornecedor}</h4>
                    <h4 className="w-[434px] px-1 text-left" >{item.historico}</h4>                
                  </div>
                )
              })
            }
              
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  )

}