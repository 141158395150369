import { useCallback, useEffect, useState } from 'react'
import useProcessando from '../../../data/hooks/useProcessando'
import { apierp } from '../../../services/api'
import { dateFormat } from '../../../utils/dateFunctions'

export default function useClientes(tamanho: any) {
  const { processando, iniciarProcessamento, finalizarProcessamento } = useProcessando()
  const [clientes, setClientes] = useState<any[]>([])
  // const [empresas, setEmpresas] = useState<any[]>([])

  const obterClientes = useCallback(async function () {
    if (!tamanho) return
    const dados = { tamanho }

    try {
      iniciarProcessamento()

      const response1 = await apierp.post(`clientes/obtertodosclientes`, dados)
      const data1 = await response1.data as any;

      setClientes(data1.clientes)

    } catch (err) {
      console.log(err)
    } finally {
      finalizarProcessamento()
    }

  }, [tamanho])

  function voltar() {
    setClientes([])
  }
  function reload() {
    obterClientes()
  }
  
  useEffect(() => {
    setClientes([])
    obterClientes()
  }, [obterClientes])

  return {
    clientes,
    processando,
    iniciarProcessamento,
    finalizarProcessamento,
    voltar,
    reload
  }

}