import { situacaoCliente } from "../../../data/dados"
import { dateFormatBr } from "../../../utils/dateFunctions"
import { formatarCep, formatarCpfCnpj } from "../../../utils/functions"

export function CardClienteThin(props: any) {
    const { cliente, wCard, key, className, diff, alterarCliente } = props
    // console.log('props.cliente',props.cliente)
    const clsLbl = "min-w-[60px] text-left font-medium"
    const email = cliente?.email ? cliente?.email.toLowerCase() : ''
    const emailNfe = cliente?.emailNfe ? cliente?.emailNfe.toLowerCase() : ''
    const dtAlteracao =  dateFormatBr(cliente?.dataAlteracao || cliente?.updatedAt, 'dd/MM/yyyy HH:mm:ss')
    const situacao = situacaoCliente.find(s => s.value === cliente?.situacao)
    const natOper = cliente?.naturezaOperacao ? cliente?.naturezaOperacao : 'Não informada'
    const bg = diff ? 'bg-zinc-200' : situacao?.value === 'A' ? 'bg-green-100' : situacao?.value === 'I' ? 'bg-yellow-100' : situacao?.value === 'E' ? 'bg-red-200' : 'bg-zinc-200'
    const bgsit = situacao?.value === 'A' ? 'bg-green-200' : situacao?.value === 'I' ? 'bg-yellow-200' : situacao?.value === 'E' ? 'bg-red-200' : 'bg-zinc-200'
    // console.log('renderizou CardClienteThin')
    return (
        <div key={key} className={`card-cliente p-2 ${wCard} ${className} ${bg}`} hidden={!cliente}>
            <div className="flex linha">
                <div className={clsLbl}>Cnpj/Cpf:</div>
                <div className="flex flex-1 justify-between">
                    <div className="flex">
                        <div className="pl-2 w-[150px] font-bold">{formatarCpfCnpj(cliente?.cnpj)}</div>
                        <div className={`font-bold rounded-md border-solid border px-1 h-[22px] ${bgsit}`}>{situacao?.label}</div>
                        <div className="flex" hidden={cliente?.tipoPessoa === 'F'}>
                            <div className="ml-2 font-medium">Ie: </div>
                            <div className="ml-2" w-20>{cliente?.ie}</div>
                            <div className="ml-2 font-medium">IndIe: </div>
                            <div className="ml-2">{cliente?.indicadorIe}</div>
                        </div>
                        <div className="flex" hidden={cliente?.tipoPessoa === 'J'}>
                            <div className="ml-2 font-medium">Rg:</div>
                            <div className="ml-2 w-20">{cliente?.rg}</div>
                            <div className="ml-2 font-medium">IndIe: </div>
                            <div className="ml-2">{cliente?.indicadorIe}</div>
                        </div>
                    </div>
                    {Boolean(alterarCliente && (cliente.id))
                        ? <div className="cursor-pointer" onClick={() => alterarCliente(cliente?.empresaId)}>#{cliente?.id}{cliente?.idbling}</div>
                        : <div>#{cliente?.id}{cliente?.idbling}</div>
                    }

                </div>
            </div>
            <div className="flex"><div className={clsLbl}>Nome:</div><div className="flex flex-1 justify-between"><div className="font-bold pl-2">{cliente?.nome}</div><div></div></div></div>
            <div className="flex"><div className={clsLbl}>Fantasia:</div><div className="flex flex-1 justify-between"><div className="font-bold pl-2">{cliente?.nomeFantasia}</div><div></div></div></div>
            <div className="flex"><div className={clsLbl}>Endereço:</div><div className="pl-2">{cliente?.endereco}, nº {cliente?.numero} - {cliente?.complemento} - {cliente?.bairro}</div></div>
            <div className="flex"><div className={clsLbl}>CEP:</div><div className="pl-2 pr-2">{cliente?.cep}</div><div className={clsLbl}>Cidade/UF:</div><div className="pl-2">{cliente?.cidade} - {cliente?.uf}</div></div>
            <div className="flex"><div className={clsLbl}>Email:</div><div className="flex flex-1 justify-between"><div className="pl-2">{email}</div><div></div></div></div>
            <div className="flex"><div className={clsLbl}>EmailNfe:</div><div className="flex flex-1 justify-between"><div className="pl-2">{emailNfe}</div><div></div></div></div>
            <div className="flex"><div className={clsLbl}>Natureza Operação Padrão:</div><div className="flex flex-1 justify-between"><div className="pl-2">{natOper}</div><div></div></div></div>
            <div className="flex"><div className={clsLbl}>Telefones:</div><div className="pl-2 pr-2">{cliente?.fone} {cliente?.celular}</div></div>
            <div className="flex"><div className={clsLbl}>Atualizado em:</div><div className="pl-2 pr-2">{dtAlteracao}</div><div className={'text-left font-medium'}>Atualizado por:</div><div className="pl-2">{cliente?.updatedBy}</div></div>

            <div className="flex" hidden={!cliente?.gcNome && !cliente?.statusCrm && !cliente?.dataStatusCrm}>
                <div className={clsLbl}>G. Conta:</div><div className="font-bold pl-2 w-full">{cliente?.gcNome}</div>
                <div className="pl-2 text-left font-medium">Status:</div><div className="font-bold min-w-[200px] pl-2">{cliente?.statusCrm}</div>
                <div className="pl-2 text-left font-medium">Data:</div><div className="font-bold w-[90px] pl-2">{dateFormatBr(cliente?.dataStatusCrm)}</div>
            </div>
        </div>
    )

}