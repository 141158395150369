import { ColumnsType } from "antd/lib/table";
import { formatDecimal } from "../../../utils/functions";
import { Input } from "antd";
import { getColumnSearchProps } from "../../../components/columnSearchProps";
import { WarningOutlined } from '@ant-design/icons';

// interface DataType {
//   // key: React.Key;
//   categoria: string;
//   codigo: string;
//   descricao: string;
//   unidade: string;
//   saldoAcumulado: number;
//   cmcUnitario: number;
//   cmcTotal: number;
// }

export function columnsConsumoMedioMensal(props: any) {
  return [
    {
      title: 'Categoria',
      dataIndex: 'categoria',
      ellipsis: true,
      width: '140px',
      className: 'text-left',
      filters: [
        {
          text: 'Acessórios',
          value: 'Acessórios',
        },
        {
          text: 'Boinas',
          value: 'Boinas',
        },
        {
          text: 'Caixas',
          value: 'Caixas',
        },
        {
          text: 'Ceras e Polidores',
          value: 'Ceras e Polidores',
        },
        {
          text: 'Embalagens',
          value: 'Embalagens',
        },
        {
          text: 'Higienização',
          value: 'Higienização',
        },
        {
          text: 'Impermeabilizantes',
          value: 'Impermeabilizantes',
        },
        {
          text: 'Matéria Prima',
          value: 'Matéria Prima',
        },
        {
          text: 'Máquinas',
          value: 'Máquinas',
        },
        {
          text: 'Rótulos',
          value: 'Rótulos',
        },

      ],
      filterMode: 'menu',
      // filterSearch: true,
      onFilter: (value: string, record: any) => record.categoria.includes(value),
    },
    {
      title: 'Codigo',
      dataIndex: 'codigo',
      ellipsis: true,
      width: '97px',
      className: 'text-left',
      sorter: (a: any, b: any) => a.codigo.localeCompare(b.codigo),
      ...getColumnSearchProps({
        dataIndex: 'codigo',
        ...props
      })
    },
    {
      title: 'Produto',
      dataIndex: 'descricao',
      width: '450px',
      className: 'text-left',
      ellipsis: false,
      sorter: (a: any, b: any) => a.descricao.localeCompare(b.descricao),
      ...getColumnSearchProps({
        dataIndex: 'descricao',
        ...props
      }),
      render: (text: any, record: any) => `${text}`
    },
    {
      title: 'UN',
      dataIndex: 'unidade',
      width: '40px',
      className: 'text-left',
      ellipsis: true,
      render: (text: any, record: any) => `${text}`
    },
    {
      title: 'Est.Atual.',
      dataIndex: 'estoqueAtual',
      width: '90px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      sorter: (a: any, b: any) => a.estoqueAtual - b.estoqueAtual,
      onCell: (record: any, i: any) => ({ className: record.estoqueAtual < record.estoqueMinimo ? "text-red-500 font-semibold" : "text-blue-500" }),
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },
    {
      title: '<',
      dataIndex: 'alertEstMin',
      width: '35px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      // sorter: (a: any, b: any) => a.alertEstMin.localeCompare(b.alertEstMin),
      onCell: (record: any, i: any) => ({ className: record.alertEstMin ? "text-yellow-500 font-extrabold" : " " }),
      render: (text: any, record: any) => `${record.alertEstMin ? "▲" : ""}`,
      // render: (text: any, record: any) => <WarningOutlined /> ,
      filters: [
        {
          text: '▲',
          value: '▲',
        },
      ],
      filterMode: 'menu',
      onFilter: (value: string, record: any) => record.alertEstMin === "*",
    },
    {
      title: 'Est.Min.',
      dataIndex: 'estoqueMinimo',
      width: '90px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      sorter: (a: any, b: any) => a.estoqueMinimo - b.estoqueMinimo,
      onCell: (record: any, i: any) => ({ className: record.estoqueMinimo < record.consNoPrazoReposicao ? "text-red-500 font-semibold" : "text-blue-500" }),
      render: (text: any, record: any) => `${formatDecimal(+text, 0)} ${record.unidade}`
    },
    {
      title: 'Prz.Rep.',
      dataIndex: 'prazoReposicao',
      width: '90px',
      align: 'right' as 'right',
      className: 'text-right text-black/[.85]',
      ellipsis: true,
      render: (text: any, record: any) => `${formatDecimal(+text, 0)} D`
    },
    {
      title: 'MinMensal',
      dataIndex: 'consMensalMinimo',
      width: '90px',
      align: 'right' as 'right',
      className: 'text-right text-black/[.85]',
      ellipsis: true,
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },
    {
      title: 'MedMensal',
      dataIndex: 'consMensalMedio',
      width: '92px',
      align: 'right' as 'right',
      className: 'text-right text-black/[.85]',
      ellipsis: true,
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },
    {
      title: 'MaxMensal',
      dataIndex: 'consMensalMaximo',
      width: '90px',
      align: 'right' as 'right',
      className: 'text-right text-black/[.85]',
      ellipsis: true,
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },
    {
      title: 'NoPrzRep',
      dataIndex: 'consNoPrazoReposicao',
      width: '90px',
      align: 'right' as 'right',
      className: 'text-right text-blue-500',
      ellipsis: true,
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },

  ]
}