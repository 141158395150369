
const modelo = 
{
    "contato": {
      "id": "<integer>",
      "nome": "<string>",
      "tipoPessoa": "E",
      "numeroDocumento": "<string>"
    },
    "data": "<date>",
    "dataPrevista": "<date>",
    "dataSaida": "<date>",
    "itens": [
      {
        "id": "<integer>",
        "quantidade": "<float>",
        "valor": "<float>",
        "descricao": "<string>",
        "codigo": "<string>",
        "unidade": "<string>",
        "desconto": "<float>",
        "aliquotaIPI": "<float>",
        "descricaoDetalhada": "<string>",
        "produto": {
          "id": "<integer>"
        },
        "comissao": {
          "base": "<float>",
          "aliquota": "<float>",
          "valor": "<float>"
        }
      },
      {
        "id": "<integer>",
        "quantidade": "<float>",
        "valor": "<float>",
        "descricao": "<string>",
        "codigo": "<string>",
        "unidade": "<string>",
        "desconto": "<float>",
        "aliquotaIPI": "<float>",
        "descricaoDetalhada": "<string>",
        "produto": {
          "id": "<integer>"
        },
        "comissao": {
          "base": "<float>",
          "aliquota": "<float>",
          "valor": "<float>"
        }
      }
    ],
    "parcelas": [
      {
        "id": "<integer>",
        "dataVencimento": "<date>",
        "valor": "<float>",
        "formaPagamento": {
          "id": "<integer>"
        },
        "observacoes": "<string>"
      },
      {
        "id": "<integer>",
        "dataVencimento": "<date>",
        "valor": "<float>",
        "formaPagamento": {
          "id": "<integer>"
        },
        "observacoes": "<string>"
      }
    ],
    "id": "<integer>",
    "numero": "<integer>",
    "numeroLoja": "<string>",
    "totalProdutos": "<float>",
    "total": "<float>",
    "situacao": {
      "id": "<integer>",
      "valor": "<integer>"
    },
    "loja": {
      "id": "<integer>"
    },
    "numeroPedidoCompra": "<string>",
    "outrasDespesas": "<float>",
    "observacoes": "<string>",
    "observacoesInternas": "<string>",
    "desconto": {
      "valor": "<float>",
      "unidade": "REAL"
    },
    "categoria": {
      "id": "<integer>"
    },
    "notaFiscal": {
      "id": "<integer>"
    },
    "tributacao": {
      "totalICMS": "<float>",
      "totalIPI": "<float>"
    },
    "transporte": {
      "fretePorConta": 1,
      "frete": "<float>",
      "quantidadeVolumes": "<integer>",
      "pesoBruto": "<float>",
      "prazoEntrega": "<integer>",
      "contato": {
        "nome": "<string>",
        "id": "<integer>"
      },
      "etiqueta": {
        "nome": "<string>",
        "endereco": "<string>",
        "numero": "<string>",
        "complemento": "<string>",
        "municipio": "<string>",
        "uf": "<string>",
        "cep": "<string>",
        "bairro": "<string>",
        "nomePais": "<string>"
      },
      "volumes": [
        {
          "id": "<integer>",
          "servico": "<string>",
          "codigoRastreamento": "<string>"
        },
        {
          "id": "<integer>",
          "servico": "<string>",
          "codigoRastreamento": "<string>"
        }
      ]
    },
    "vendedor": {
      "id": "<integer>"
    },
    "intermediador": {
      "cnpj": "<string>",
      "nomeUsuario": "<string>"
    },
    "taxas": {
      "taxaComissao": "<float>",
      "custoFrete": "<float>",
      "valorBase": "<float>"
    }
  }

const modelo2 =
{
    contato: {
      id: '<integer>',
      nome: '<string>',
      tipoPessoa: 'E',
      numeroDocumento: '<string>'
    },
    data: '<date>',
    dataPrevista: '<date>',
    dataSaida: '<date>',
    itens: [
      {
        id: '<integer>',
        quantidade: '<float>',
        valor: '<float>',
        descricao: '<string>',
        codigo: '<string>',
        unidade: '<string>',
        desconto: '<float>',
        aliquotaIPI: '<float>',
        descricaoDetalhada: '<string>',
        produto: [Object],
        comissao: [Object]
      },
      {
        id: '<integer>',
        quantidade: '<float>',
        valor: '<float>',
        descricao: '<string>',
        codigo: '<string>',
        unidade: '<string>',
        desconto: '<float>',
        aliquotaIPI: '<float>',
        descricaoDetalhada: '<string>',
        produto: [Object],
        comissao: [Object]
      }
    ],
    parcelas: [
      {
        id: '<integer>',
        dataVencimento: '<date>',
        valor: '<float>',
        formaPagamento: [Object],
        observacoes: '<string>'
      },
      {
        id: '<integer>',
        dataVencimento: '<date>',
        valor: '<float>',
        formaPagamento: [Object],
        observacoes: '<string>'
      }
    ],
    id: '<integer>',
    numero: '<integer>',
    numeroLoja: '<string>',
    totalProdutos: '<float>',
    total: '<float>',
    situacao: { id: '<integer>', valor: '<integer>' },
    loja: { id: '<integer>' },
    numeroPedidoCompra: '<string>',
    outrasDespesas: '<float>',
    observacoes: '<string>',
    observacoesInternas: '<string>',
    desconto: { valor: '<float>', unidade: 'REAL' },
    categoria: { id: '<integer>' },
    notaFiscal: { id: '<integer>' },
    tributacao: { totalICMS: '<float>', totalIPI: '<float>' },
    transporte: {
      fretePorConta: 1,
      frete: '<float>',
      quantidadeVolumes: '<integer>',
      pesoBruto: '<float>',
      prazoEntrega: '<integer>',
      contato: { nome: '<string>', id: '<integer>' },
      etiqueta: {
        nome: '<string>',
        endereco: '<string>',
        numero: '<string>',
        complemento: '<string>',
        municipio: '<string>',
        uf: '<string>',
        cep: '<string>',
        bairro: '<string>',
        nomePais: '<string>'
      },
      volumes: [ [Object], [Object] ]
    },
    vendedor: { id: '<integer>' },
    intermediador: { cnpj: '<string>', nomeUsuario: '<string>' },
    taxas: {
      taxaComissao: '<float>',
      custoFrete: '<float>',
      valorBase: '<float>'
    }
  }

// const obj = JSON.parse(JSON.stringify(modelo))
// console.log(obj)


export default function formataPedidoVendaBling(pv, blingCliente, blingTransportador, blingVendedores, blingProdutos, blingFormasPagamento) {
    const blingVendedor = blingVendedores?.find((v) => v.vendedorId === pv.vendedorId)
    const itens = pv.itens.map(item => {
        const produto = blingProdutos.find(p => p.produtoId === item.produtoId)
        return {
            id: produto.idbling,
            quantidade: item.quantidade,
            valor: item.precoUnitario,
            descricao: produto.descricao,
            codigo: item.codigo,
            unidade: item.unidade,
            // desconto: item.desconto,
            // aliquotaIPI: item.aliquotaIPI,
            // descricaoDetalhada: item.descricaoDetalhada,
            // produto: { id: item.produto.id },
            // comissao: { base: item.comissao.base, aliquota: item.comissao.aliquota, valor: item.comissao.valor }
        }
    })
    const parcelas = pv.parcelas.map(parcela => {
        const formaPagamento = blingFormasPagamento.find(fp => fp.formaPagamentoId === parcela.formaPagamentoId)
        return {
            // id: index+0, ESCLARECER COM O SUPORTE BLING
            dataVencimento: parcela.dataVencimento,
            valor: parcela.valor,
            formaPagamento: { id: formaPagamento.idbling },
            observacoes: parcela.observacoes
        }
    })


    const pedidoVendaBling = {
        contato: {
          id: blingCliente.idbling,
          nome: blingCliente.nome,
          tipoPessoa: blingCliente.tipoPessoa,
          numeroDocumento: blingCliente.cnpj
        },
        data: pv.data,
        // dataPrevista: '<date>',
        dataSaida: pv.data,
        itens: itens,
        parcelas: parcelas,
        // id: '<integer>',
        // numero: '<integer>',
        // numeroLoja: '<string>',
        totalProdutos: pv.totalprodutos,
        total: pv.total,
        situacao: { id: 15, valor: 3 }, // Em andamento
        // loja: { id: '<integer>' },
        // numeroPedidoCompra: '<string>',
        outrasDespesas: pv.outrasDespesas,
        observacoes: pv.observacoes,
        observacoesInternas: pv.observacoesInternas,
        desconto: { valor: pv.desconto, unidade: 'REAL' },
        categoria: { id: '<integer>' }, 
        // notaFiscal: { id: '<integer>' },
        // tributacao: { totalICMS: '<float>', totalIPI: '<float>' },
        transporte: {
          fretePorConta: pv.fretePorConta,
          frete: pv.frete,
          quantidadeVolumes: pv.quantidadeVolumes,
          pesoBruto: pv.pesoBruto,
          prazoEntrega: pv.prazoEntrega,
          contato: blingTransportador?.nome ? { nome: blingTransportador?.nome, id: blingTransportador?.idbling } : null,
        //   etiqueta: {
        //     nome: '<string>',
        //     endereco: '<string>',
        //     numero: '<string>',
        //     complemento: '<string>',
        //     municipio: '<string>',
        //     uf: '<string>',
        //     cep: '<string>',
        //     bairro: '<string>',
        //     nomePais: '<string>'
        //   },
        //   volumes: [ [Object], [Object] ]
        },
        vendedor: { id: blingVendedor?.idbling },
        // intermediador: { cnpj: '<string>', nomeUsuario: '<string>' },
        // taxas: {
        //   taxaComissao: '<float>',
        //   custoFrete: '<float>',
        //   valorBase: '<float>'
        // }
      }
      return pedidoVendaBling
}
