import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
// import faker from 'faker'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.color = "#000"; // cor dos labels

export function Vendas(props: any) {
  const { titulo } = props
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Dia do mês'
        }
      }
    },
    plugins: {
      legend: {
        position: 'right' as const,
      },
      title: {
        display: true,
        text: titulo,
      },
    },
  };

  const colors=['#000', '#FF0000','#CC0099','#CC00FF', '#FF00FF','#00CCFF','#0066FF', '#3366CC', '#0099FF', '#339900', '#33CC00', '#33CC99', '#CC9900', '#2c3e50']
  const borders=[5,2,2,2,2,2,2,2,2,2,2,2,5,2,2,2,2]
  const pointRadius=[3,2,2,2,2,2,2,2,2,2,2,2,3,2,2,2,2]

  const labels = props.labels
  const datasets: any = []
  props.datasets.map((dslabel: any, index: number) => {
    const dados = props.dados.filter((x: any) => x.anomes === dslabel).map((x: any) => x.totalVenda)
    const dadosAcc: any = []
    let acc = 0
    dados.forEach((element: number) => {
      acc += element
      dadosAcc.push(acc)
    });

    const dsx = {
      label: dslabel.split('/').reverse().join('/'),
      data: dadosAcc,
      pointBackgroundColor: '#fff',
      backgroundColor: colors[index],
      borderColor: colors[index],
      borderWidth: borders[index],
      pointRadius: pointRadius[index],
      pointBorderWidth: 1,
      hidden: index > 5 && index !== 12, // mostra apenas o atual mais os 5 anteriores e o mesmo mês do ano anterior
    }
    datasets.push(dsx)

  })

  const data = {
    labels,
    color: 'black',
    datasets,
  };

  // console.log(data)
  return <Line options={options} data={data} />;
}
