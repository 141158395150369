
import * as React from "react"
import { useEffect, useState } from "react"
import { format, parseISO, toDate, add, addSeconds } from 'date-fns'

import Layout from "../../../components/template/Layout"

import { Button, Typography } from "antd";
import { apierp } from "../../../services/api";
import { dateFormat, dateFormatBr } from "../../../utils/dateFunctions";
import useTabActive from "../../../hooks/isTabActive";
const { Title, Text, Paragraph } = Typography;

export default function TokensApiBlingV3() {
  const [tokens, setTokens] = useState([] as any)
  const [empresas, setEmpresas] = useState([] as any)
  const [btnDisabled, setBtnDisabled] = useState(true)
  const isTabActive = useTabActive();

  useEffect(() => {
    // console.log('useEffect unico')
    async function loadEmpresas() {
      const response = await apierp.get('empresas')
      const data = response.data as any;
      // console.log(data)
      setEmpresas(data)
    }
    loadEmpresas()
    setBtnDisabled(false)
    //
  }, [])
  
  useEffect(() => {
    // console.log('useEffect')
    async function loadTokens() {
      const response = await apierp.get('/bling/v3/alltokens')
      const data = response.data as any;
      // console.log(data)
      setTokens(data)
    }
    if (isTabActive) {
      // console.log('isTabActive', isTabActive, dateFormat(new Date(), 'dd/MM/yyyy HH:mm:ss'))
      loadTokens()
    }
  }, [isTabActive])

  async function loginbling(empresaId: number) {
    console.log('loginbling', empresaId)
    const e = empresas.find((e: any) => e.id === empresaId)
    const url = `https://www.bling.com.br/Api/v3/oauth/authorize?response_type=code&client_id=${e.blingClientId}&state=${e.blingState}`
    // window.location.href = url;
    // console.log(url)
    // return
    window.open(url, '_blank'); // abre em nova aba

  }

  // async function loginbling1() {
  //   const e = empresas.find((e: any) => e.id === 1)
  //   const url = `https://www.bling.com.br/Api/v3/oauth/authorize?response_type=code&client_id=${e.blingClientId}&state=${e.blingState}`
  //   // window.location.href = url;
  //   window.open(url, '_blank'); // abre em nova aba
  // }
  
  // async function loginbling2() {
  //   const e = empresas.find((e: any) => e.id === 2)
  //   const url = `https://www.bling.com.br/Api/v3/oauth/authorize?response_type=code&client_id=${e.blingClientId}&state=${e.blingState}`
  //   // window.location.href = url;
  //   window.open(url, '_blank'); // abre em nova aba
  // }

  async function refreshtoken(e: number) {
    const resposta: any = await apierp.get(`/bling/v3/refresh/${e}`);
    // console.log(resposta.data)
    setTokens(resposta.data)
  }

  async function refreshAll() {
    setBtnDisabled(true)
    const resposta: any = await apierp.get(`/bling/v3/refreshall`);
    // console.log(resposta.data)
    setTokens(resposta.data)
    setBtnDisabled(false)
  }

  let min = tokens[0]?.data
  for (let i = 0; i < tokens.length; i++) {
    if (tokens[i].data < min) {
      min = tokens[i].data
    }
  }

  const dt_now = new Date()
  const dt_exp = add(toDate(parseISO(min)), { seconds: tokens[0]?.expires_in })
  const expired = dt_exp < dt_now



  // console.log('render')
  return (
    <Layout titulo={"Tokens Api Bling V3"} botoes={null}>
      {/* <Title className="mx-auto" >T O K E N S</Title> */}

      <div className="flex flex-col mx-auto justify-center">
        {/* <div className="flex justify-center mt-4 text-lg font-bold">TOKENS</div> */}
        {tokens && tokens.map((t: any) => {
          const empresa = empresas.filter((e: any) => e.id === t.empresaId)[0]
          const dt_now = new Date()
          const dt_exp = add(toDate(parseISO(t.data)), { seconds: t.expires_in })
          const expired = dt_exp < dt_now
          // console.log('expired', expired, t.data, dt_exp, dt_now)
          const clsExpired = expired ? "font-semibold text-red-500" : ""
          // const revalidadoEm = dateFormatBr(t.data, 'dd/MM/yyyy HH:mm:ss')
          const revalidadoEm = format(toDate(parseISO(t.data)), 'dd/MM/yyyy HH:mm:ss') // equivalente ao de cima
          const expiraEm = format(add(toDate(parseISO(t.data)), { seconds: t.expires_in }), 'dd/MM/yyyy HH:mm:ss')

          return (
            <div key={t.empresaId} className="my-2" >
              <div className="flex font-bold"><div className="w-24">Empresa:</div>{empresa?.nome}</div>
              <div className="flex">
                <div className="w-24">Renovado em:</div>
                {revalidadoEm}
              </div>
              <div className={`flex ${clsExpired}`}>
                <div className={`flex w-24`}>{expired ? 'Expirado em:' : 'Expira em:'}</div>
                {expiraEm}
                {expired
                  ? <a onClick={() => refreshtoken(t.empresaId)} className="ml-2 underline text-blue-500 font-semibold">Renovar</a>
                  : (null)
                }
              </div>
              <Button disabled={btnDisabled} onClick={() => loginbling(t.empresaId)} type="primary" danger className="w-30 mb-4 font-bold">Login {empresa?.nome}</Button>

            </div>
          )
        })

        }
      </div>
      <div className="flex gap-5 mx-auto justify-center">
        {/* <Button hidden={Boolean(tokens[0]?.data)} disabled={btnDisabled} onClick={loginbling1} type="primary" danger className="w-30 font-bold">Login PA</Button> */}
        {/* <Button hidden={Boolean(tokens[1]?.data)} disabled={btnDisabled} onClick={loginbling2} type="primary" danger className="w-30 font-bold">Login SQ</Button> */}
        {/* <Button disabled={btnDisabled} onClick={loginbling1} type="primary" danger className="w-30 font-bold">Login PA</Button>
        <Button disabled={btnDisabled} onClick={loginbling2} type="primary" danger className="w-30 font-bold">Login SQ</Button> */}
        <Button hidden={!expired} disabled={btnDisabled} onClick={refreshAll} type="primary" className="w-50 font-bold ">Renovar Todos</Button>
      </div>
    </Layout>
  )
}

