import { Modal } from "antd"
import { dateFormatBr } from "../../../../utils/dateFunctions"
import { ellipsis, formatDecimal } from "../../../../utils/functions"

export default function ModalPedidos(props: any) {
    const showModal = props.showModal
    const hideModalPedidos = props.hideModalPedidos;
    const pedidoItem = props.pedidoItem;
    const pedidosItensVendedor = props.pedidosItensVendedor;

    let totalModal = 0
    let qtModal = 0
    return (
      <Modal title="Pedidos" open={showModal} onOk={hideModalPedidos} onCancel={hideModalPedidos} footer={[]} centered width={1240}>
      <div id="printableModal" className="">
        <div className="text-center">
          {/* The Modal Content */}
          <div className="">

            {/* <div className="flex justify-end font-semibold text-xl"> */}
            {/* Botao Print */}
            {/* <Button m="10px 0 0 0" h="20px]" onClick={handlePrint} buttonScheme="c-button__light">
                <AiTwotonePrinter title="Imprimir Relatório" size="20px]" cursor="pointer" color="var(--color-chakra-blue-900-dark)" />
              </Button> */}
            {/* Botao X (FECHAR) */}
            {/* <div className="mt-2.5 -mr-4 h-[20px] cursor-pointer text-zinc-900"  title="Fechar"
                onClick={() => setPedidosItensVendedor([])}>
                <div className="-mt-2.5 text-lg">&times;</div>
              </div> */}
            {/* </div> */}
            <div className="text-center mb-2.5 font-semibold" >
              {pedidoItem.vendedor}
            </div>
            <div className="text-center mb-2.5 font-semibold" >
              {pedidoItem.codigo} - {pedidoItem.produto}
            </div>
            <div className="overflow-x-auto">
              {/* CABECALHO */}
              <div className="flex min-w-[1192px] font-semibold border-0 border-b border-solid border-zinc-700 bg-gray-900 text-white"
                key={"modcab"}
              >
                <div className="w-[70px] min-w-[70px]  px-2 py-1.5 text-right">Pedido</div>
                <div className="w-[95px] min-w-[95px]  px-2 py-1.5 text-right">Data</div>
                <div className="w-[60px] min-w-[60px]  px-2 py-1.5 text-right">Qtd</div>
                <div className="w-[70px] min-w-[70px]  px-2 py-1.5 text-right">p.Unit</div>
                <div className="w-[100px] min-w-[100px]  px-2 py-1.5 text-right">Total</div>
                <div className="w-[145px] min-w-[145px]  px-2 py-1.5 text-left">Plataforma</div>
                <div className="w-[435px] min-w-[435px]  px-2 py-1.5 text-left">Cliente</div>
                <div className="w-[165px] min-w-[165px]  px-2 py-1.5 text-left">Cidade</div>
                <div className="w-[50px] min-w-[50px]  px-2 py-1.5 text-left">UF</div>
              </div>

              {/* CORPO */}
              <div className="max-h-[400px] min-w-[1192px] overflow-y-auto">
                {pedidosItensVendedor.map((d: any, index: number) => {
                  totalModal += d.totalProd
                  qtModal += d.quantidade
                  return (
                    <div className="flex font-semibold border-0 border-b border-solid border-zinc-700"
                      key={"modlin" + index}
                    >
                      <div className="w-[70px] min-w-[70px]  px-2 py-1.5 text-right">{d.pedido}</div>
                      <div className="w-[95px] min-w-[95px]  px-2 py-1.5 text-right">{dateFormatBr(d.data)}</div>
                      <div className="w-[60px] min-w-[60px]  px-2 py-1.5 text-right">{formatDecimal(d.quantidade, 0)}</div>
                      <div className="w-[70px] min-w-[70px]  px-2 py-1.5 text-right">{formatDecimal(d.pUnit, 2)}</div>
                      <div className="w-[100px] min-w-[100px]  px-2 py-1.5 text-right">{formatDecimal(d.totalProd, 2)}</div>
                      <div className="w-[145px] min-w-[145px]  px-2 py-1.5 text-left">{ellipsis(d.plataforma, 50)}</div>
                      <div className="w-[435px] min-w-[435px]  px-2 py-1.5 text-left" title={d.cliente}>{ellipsis(d.cliente, 50)}</div>
                      <div className="w-[165px] min-w-[165px]  px-2 py-1.5 text-left">{ellipsis(d.cidade, 20)}</div>
                      <div className="w-[40px] min-w-[40px]  px-2 py-1.5 text-left">{d.uf}</div>
                    </div>
                  )
                })}
                <div className="text-center pr-1.5 bg-zinc-300">Final</div>
                {/* RODAPÉ */}
                <div className="flex mb-5 bg-zinc-500 font-bold" key={"modrod"} >
                  <div className="w-[70px] min-w-[70px]  px-2 py-1.5">Total</div>
                  <div className="w-[95px] min-w-[95px]  px-2 py-1.5 text-right"></div>
                  <div className="w-[60px] min-w-[60px]  px-2 py-1.5 text-right">{formatDecimal(qtModal, 0)}</div>
                  <div className="w-[70px] min-w-[70px]  px-2 py-1.5 text-right">{formatDecimal(totalModal / qtModal, 2)}</div>
                  <div className="w-[100px] min-w-[100px]  px-2 py-1.5 text-right">{formatDecimal(totalModal, 2)}</div>
                  <div className="w-[145px] min-w-[145px]  px-2 py-1.5"></div>
                  <div className="w-[435px] min-w-[435px]  px-2 py-1.5"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    )
}