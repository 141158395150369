import {  Modal } from "antd"
import { CardCliente } from "../../__Shared/Clientes/CardCliente";

export default function ModalClienteVinculado(props: any) {
    const { clienteVinculado, showModalClienteVinculado, hideModalClienteVinculado } = props

    return (
        <Modal title="ClienteVinculado" open={showModalClienteVinculado} onOk={hideModalClienteVinculado} onCancel={hideModalClienteVinculado} footer={[]} centered width={1040}>
            <div id="printableModal" className="">
                <div className="text-center">
                    {/* The Modal Content */}
                    <CardCliente cliente={clienteVinculado} wCard="w-full" />
                </div>
            </div>
        </Modal>
    )
}