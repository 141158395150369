import { Button, Drawer, Form, Input, Radio, Select, Space } from "antd"
import { useEffect, useState } from "react"
import useWindowSize from "../../../hooks/useWindowSize";
import TextArea from "antd/lib/input/TextArea";
import { categoriasProdutos, origens, tipoProducao, tipoProduto, tipositem } from "../../../data/dados";
import { useMutateMapa } from "./useMutateMapa";
import Spinner from "../../../components/template/Spinner";
const { Option } = Select;

export default function DrawerFormProduto(props: any) {
    const { mutate, isSuccess } = useMutateMapa()

    const { hideDrawerFormProduto, drawerFormProdutoVisible, produto, operacao, idsBling, codigos } = props
    const [buttonsDisabled, setButtonsDisabled] = useState(false)
    const op = (operacao && operacao[0]) || 'I'

    const regexCodigo = /^(PRD|SRV)\d{5}$/;
    const regexNcm = /^\d{4}\.\d{2}\.\d{2}$/;
    const regexUnidade = /^[A-Z]{1,2}$/;

    const [alerta, setAlerta] = useState('')

    const [formProduto] = Form.useForm();
    const fields = {
        id: '',
        situacao: 'Ativo',
        codigo: '',
        nome: '',
        unidade: '',
        precoVenda: '',
        pesoBruto: '',
        pesoLiquido: '',
        largura: '',
        altura: '',
        profundidade: '',
        categoriaId: '',
        categoria: '',
        tipo: 'P',
        producao: '',
        ncm: '',
        origem: '0',
        spedTipoItem: '',
    }
    const fillFormProduto = (produto: any) => {
        if (op === 'I') {
            formProduto.setFieldsValue(fields)
            return
        }
        // console.log('fillFormProduto', produto)
        formProduto.setFieldsValue({
            id: op === 'A' ? produto?.id : '',
            situacao: produto?.situacao || 'Ativo',
            codigo: op === 'A' ? produto?.codigo : '',
            nome: op === 'A' ? produto?.nome : `(clone) ${produto?.nome}`,
            unidade: produto?.unidade || '',
            precoVenda: produto?.precoVenda?.toString().replace(".", ",") || '',
            pesoBruto: produto?.pesoBruto?.toString().replace(".", ",") || '',
            pesoLiquido: produto?.pesoLiquido?.toString().replace(".", ",") || '',
            largura: produto?.largura?.toString().replace(".", ",") || '',
            altura: produto?.altura?.toString().replace(".", ",") || '',
            profundidade: produto?.profundidade?.toString().replace(".", ",") || '',
            categoriaId: produto?.categoria.id || '',
            categoria: produto?.categoria === 'Categoria padrão' ? '' : produto?.categoria,
            tipo: produto?.tipo || '',
            producao: produto?.producao || '',
            ncm: produto?.ncm || '',
            origem: String(produto?.origem) || '',
            spedTipoItem: produto?.spedTipoItem || '',
        });
    };

    const savebtnDisabled = false

    useEffect(() => {
        fillFormProduto(produto)
    }, [drawerFormProdutoVisible])
    
    useEffect(() => {
        // console.log('useEffect isSuccess', isSuccess)
        if (isSuccess) {
            setButtonsDisabled(false)
            setAlerta('')
            hideDrawerFormProduto()
        }
    }, [isSuccess])

    async function handleSubmitFormProduto(dados: any,) {
        // alert('Função em implementação')
        const data = { dados, idsBling, op }
        // console.log('handleSubmitFormProduto - data', data)
        // se op !== 'A' então é inclusão, então verificar se o codigo já existe cadastrado em produto
        if (op !== 'A') {
            
            const codigo = dados.codigo
            const existe = codigos.find((c: any) => c === codigo)

            if (existe) {
                alert('Codigo já cadastrado! Não pode ser incluído!')
                return
            }
        }
        // console.log('handleSubmitFormProduto', dados.categoria, typeof dados.categoria, dados.categoria === '')

        if (!dados.categoria) {
            alert('Categoria não informada! Não pode ser incluído!')
            return
        }

        setButtonsDisabled(true)
        setAlerta('Salvando os Dados !!!')
        mutate(data)
    }

    const handleCancel = () => {
        formProduto.resetFields()
        hideDrawerFormProduto()
        fillFormProduto(produto)
    }

    const [innerW, innerH] = useWindowSize();
    const wDrawerx = innerW > 640 && innerW > 360 ? 640 : 360 // - 144
    const layout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 19 },
    };

    return (
        <Drawer
            title={`${operacao} de Produto`}
            width={wDrawerx}
            closable={true}
            maskClosable={false}
            onClose={hideDrawerFormProduto}
            open={drawerFormProdutoVisible}
            headerStyle={{ borderRadius: "0" }}
            bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
        >
            <div className="inner-drawer-body">
                <Form {...layout} form={formProduto} onFinish={handleSubmitFormProduto}>
                    <Form.Item name="id" hidden noStyle></Form.Item>
                    <Form.Item label="Codigo" >
                        <Form.Item name="codigo" rules={[{ required: true, pattern: regexCodigo, message: 'Formato aceito: PRDnnnnn' }]} className="inline-block my-0">
                            <Input disabled={op === 'A'} placeholder="" />
                        </Form.Item>
                        <Form.Item name="situacao" label="Situação" rules={[{ required: true }]} style={{ display: 'inline-block', margin: '0 0 0 8px' }}>
                            <Radio.Group >
                                <Radio value={"Ativo"}>Ativo</Radio>
                                <Radio value={"Inativo"}>Inativo</Radio>
                                {/* <Radio value={"E"}>Excluido</Radio> */}
                            </Radio.Group>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item name="nome" label="Nome" rules={[{ required: true }]}><TextArea placeholder="" rows={2} /></Form.Item>
                    <Form.Item label="Unidade">
                        <Form.Item name="unidade" rules={[{ required: true, pattern: regexUnidade, message: 'Formato XX ou X' }]} className="inline-block my-0">
                            <Input placeholder="" />
                        </Form.Item>
                        <Form.Item name="precoVenda" label="Preço Venda" style={{ display: 'inline-block', margin: '0 0 0 12px' }}>
                            <Input placeholder="" />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="Peso Bruto">
                        <Form.Item name="pesoBruto" className="inline-block my-0">
                            <Input placeholder="" />
                        </Form.Item>
                        <Form.Item name="pesoLiquido" label="Peso Liquido" style={{ display: 'inline-block', margin: '0 0 0 10px' }}>
                            <Input placeholder="" />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="Largura">
                        <Form.Item name="largura" className="inline-block my-0" style={{ display: 'inline-block', width: 'calc(24% - 8px)', margin: '0 0 0 ' }}>
                            <Input placeholder="" />
                        </Form.Item>
                        <Form.Item name="altura" label="Altura" style={{ display: 'inline-block', width: 'calc(33% - 8px)', margin: '0 0 0 12px' }}>
                            <Input placeholder="" />
                        </Form.Item>
                        <Form.Item name="profundidade" label="Profundidade" style={{ display: 'inline-block', width: 'calc(43% - 8px)', margin: '0 0 0 12px' }}>
                            <Input placeholder="" />
                        </Form.Item>
                    </Form.Item>

                    {/* <Form.Item name="categoriaId" hidden noStyle></Form.Item> */}

                    <Form.Item name="categoria" label="Categoria" rules={[{ required: true }]}>
                        <Select placeholder="Categoria"
                            showSearch
                            optionFilterProp="children"
                            popupClassName="border-solid border border-blue-500">
                            {/* <Option value={null}>É Nivel Raiz</Option> */}
                            {categoriasProdutos.filter(x => x.empresaId === 1)
                                .sort((a: any, b: any) => a.descricao.localeCompare(b.descricao))
                                .map((cat: any, index: any) => {
                                    return <Option value={cat.descricao} className="text-blue-800 font-semibold"> {cat.descricao}</Option>
                                })}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Tipo" >
                        <Form.Item name="tipo" rules={[{ required: true }]} className="inline-block my-0" style={{ display: 'inline-block', width: 'calc(35% - 4px)', margin: '0 0 0 ' }}>
                            <Select placeholder="Tipo Produto"
                                showSearch
                                optionFilterProp="children"
                                popupClassName="border-solid border border-blue-500">
                                {/* <Option value={null}>É Nivel Raiz</Option> */}
                                {tipoProduto.map((tipo: any, index: any) => {
                                    return <Option value={tipo.value} className="text-blue-800 font-semibold"> {tipo.label}</Option>
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item name="producao" label="Tipo Producao" rules={[{ required: true }]} style={{ display: 'inline-block', width: 'calc(65% - 8px)', margin: '0 0 0 12px' }}>
                            <Select placeholder="Tipo Produção"
                                showSearch
                                optionFilterProp="children"
                                popupClassName="border-solid border border-blue-500">
                                {/* <Option value={null}>É Nivel Raiz</Option> */}
                                {tipoProducao.map((tipo: any, index: any) => {
                                    return <Option value={tipo.value} className="text-blue-800 font-semibold"> {tipo.label}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="NCM">
                        <Form.Item name="ncm" className="inline-block my-0" rules={[{ required: false, pattern: regexNcm, message: 'Formato: nnnn.nn.nn' }]} style={{ display: 'inline-block', width: 'calc(20%)', margin: '0 0 0 ' }}>
                            <Input placeholder="" />
                        </Form.Item>
                        <Form.Item name="spedTipoItem" label="Tipo Item" rules={[{ required: true }]} style={{ display: 'inline-block', width: 'calc(78% - 8px)', margin: '0 0 0 16px' }}>
                            <Select placeholder="Tipo Produto"
                                showSearch
                                optionFilterProp="children"
                                popupClassName="border-solid border border-blue-500">
                                {tipositem.map((tipo: any, index: any) => {
                                    return <Option value={tipo.value} className="text-blue-800 font-semibold"> {tipo.value} - {tipo.label}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item name="origem" label="Origem" rules={[{ required: true }]}>
                        {/* <Input placeholder="" /> */}
                        <Select placeholder="Tipo Produto"
                            showSearch
                            optionFilterProp="children"
                            popupClassName="border-solid border border-blue-500">
                            {origens.map((tipo: any, index: any) => {
                                return <Option value={tipo.value} className="text-blue-800 font-semibold"> {tipo.value} - {tipo.label}</Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Spinner loading={alerta} >Salvando os dados</Spinner>
                </Form>
            </div>
            <Space className="drawer-footer flex w-full justify-end">
                <Space>
                    <Button hidden={buttonsDisabled} onClick={handleCancel}>Cancel</Button>
                    <Button onClick={formProduto.submit} hidden={buttonsDisabled} disabled={savebtnDisabled} type="primary">Salvar</Button>
                </Space>
            </Space>
        </Drawer>
    )
}