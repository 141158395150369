
const empresa = ['','Automotivos','Soluções']

export  const columnsProdutoBling = [
    {
      title: 'EmpId',
      dataIndex: '',
      ellipsis: true,
      width: '60px',
      align: 'right' as 'right',
      className: 'text-right',
      render: (text: any, record: any, index: any) => `${index+1}`
    },
    {
      title: 'EMP',
      dataIndex: 'empresaId',
      width: '100px',
      className: 'text-left',
      ellipsis: true,
      render: (text: any, record: any) => `${empresa[text]}`
    },
    {
      title: 'Codigo',
      dataIndex: 'codigo',
      ellipsis: true,
      width: '90px',
      className: 'text-left',
    },
    {
      title: 'Nome',
      dataIndex: 'nome',
      width: '500px',
      className: 'text-left',
      ellipsis: false,
      render: (text: any, record: any) => `${text}`
    },
    {
      title: 'UN',
      dataIndex: 'unidade',
      width: '40px',
      className: 'text-left',
      ellipsis: true,
      render: (text: any, record: any) => `${text}`
    },
    {
      title: 'Situação',
      dataIndex: 'situacao',
      width: '80px',
      className: 'text-left',
      ellipsis: true,
      render: (text: any, record: any) => `${text}`
    },
  ]