import React, { createContext, useState, useContext } from 'react';

import { apierp } from '../services/api';
import { getStoreEncrypted, removeStoreEncrypted, setStoreEncrypted } from '../utils/session';

interface AuthContextData {
  signed: boolean;
  user: object | null;
  // Login({ email, password, localizacao }: ISignIn): Promise<void>;
  Login({ email, password }: ISignIn): Promise<void>;
  Logout(): void;
}

interface IAuthState {
  user: object;
  token: string;
}

interface ISignIn {
  email: string;
  password: string;
  // localizacao: string;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

function getProfile(user: any) {
  if (user) {
    const profile = {
      isRoot: user.isRoot,
      isMaster: user.isMaster,
      isAdmin: user.isAdmin,
      isFinanceiro: user.isFinanceiro,
    }
    return profile
  }
}

interface Props {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<Props> = ({ children }) => {

  const [data, setData] = useState<IAuthState>(() => {
    const zstrUser = getStoreEncrypted('@zappauth:user')
    const zstrToken = getStoreEncrypted('@zappauth:token')
    // console.log('auth.tsx called')
    if (zstrUser && zstrToken) {
      let user = JSON.parse(zstrUser)
      let token = JSON.parse(zstrToken)
      const profile = getProfile(user)
      user.profile = profile
      apierp.defaults.headers.topSecurityLevel=user.topSecurityLevel;
      apierp.defaults.headers.Authorization = `Bearer ${token}`;
      return { user, token }
    }

    return {} as IAuthState
  });

  async function Login(loginData: ISignIn): Promise<any> {
    try {
      const response = await apierp.post('login', loginData);
      const { token, user } = response.data;
      // console.log('user', user)
      setStoreEncrypted('@zappauth:user', JSON.stringify(user))
      setStoreEncrypted('@zappauth:token', JSON.stringify(token))

      const profile = getProfile(user)
      user.profile = profile

      setData({ user, token });

      apierp.defaults.headers.topSecurityLevel=user.topSecurityLevel
      apierp.defaults.headers.Authorization = `Bearer ${token}`;

      return { status: 0, message: 'OK', user }
      // return { status: 0, message: 'OK' }
    } catch (err: any) {
      if (err.response) {
        return { status: err.response.status, message: err.response.statusText }
      }
      return { status: 500, message: err.message }
    }
  }

  async function Logout() {
    removeStoreEncrypted('@zappauth:user');
    removeStoreEncrypted('@zappauth:token');
    removeStoreEncrypted('@zappgeral:data');
    apierp.defaults.headers.Authorization = '';
    delete apierp.defaults.headers.tenantid;
    delete apierp.defaults.headers.userid;
    delete apierp.defaults.headers.topSecurityLevel;
    await setData({} as IAuthState);
  }

  return (
    <AuthContext.Provider
      value={{ signed: Boolean(data.user), user: data.user, Login, Logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
