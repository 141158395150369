import { useCallback, useEffect, useState } from 'react'
import useProcessando from '../../../data/hooks/useProcessando'
import { apierp } from '../../../services/api'
import { dateFormat } from '../../../utils/dateFunctions'

export default function useDashboardVendas(dateref: any) {
  const { processando, iniciarProcessamento, finalizarProcessamento } = useProcessando()
  const [dasboardVendas, setDasboardVendas] = useState<any[]>([])
  const [empresas, setEmpresas] = useState<any[]>([])
  // console.log('chamou')
  const buscarDasboardVendas = useCallback(async function () {
    if (dateref === null) return
    const date = dateFormat(dateref, 'yyyy-MM-dd')
    const depositoId=1
    try {
      iniciarProcessamento()
      // console.log('chamouapi')
      const response1 = await apierp.get(`dashboard/vendaspordia`)
      const data1 = await response1.data as any;
      setDasboardVendas(data1)
      // console.log(data1)
      const response2 = await apierp.get('empresas')
      const data2 = await response2.data as any;
      // setEmpresas(data2)
      const arr = []
      for (let emp of data2 ) {
        arr[emp.id] = emp.nome
      }
      setEmpresas(arr)

    } catch (err) {
      console.log(err)
    } finally {
      finalizarProcessamento()
    }

  }, [dateref])

  function voltar() {
    setDasboardVendas([])
    setEmpresas([])
  }
  function reload() {
    buscarDasboardVendas()
  }
  
  useEffect(() => {
    setDasboardVendas([])
    setEmpresas([])
    buscarDasboardVendas()
  }, [buscarDasboardVendas])

  return {
    dasboardVendas,
    empresas,
    processando,
    iniciarProcessamento,
    finalizarProcessamento,
    voltar,
    reload
  }

}