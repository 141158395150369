import CryptoJS from 'crypto-js';

const SECRET = "PpZB9mJPDxpFDhd"

export function setStoreEncrypted(item: string, data: any) {
    const encrypted = CryptoJS.DES.encrypt(data, SECRET);
    sessionStorage.setItem(item, encrypted.toString());
}

export function getStoreEncrypted(item: string) {
    const encrypted = sessionStorage.getItem(item)
    if (encrypted) {
        const bytes = CryptoJS.DES.decrypt(encrypted!, SECRET);
        const decrypted = bytes.toString(CryptoJS.enc.Utf8)
        return decrypted
    } else {
        return null
    }
}

export function removeStoreEncrypted(item: string) {
    sessionStorage.removeItem(item);
}