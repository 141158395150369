import { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import Layout from "../../../components/template/Layout";
import useVendasVendedor from "./useVendasVendedor";
import SpinnerNew from "../../../components/template/SpinnerNew";
import Spinner from "../../../components/template/Spinner";
import ProdutoPedido from "./Partial/ProdutoPedido";
import ProdutoCidade from "./Partial/ProdutoCidade";
import CidadeProduto from "./Partial/CidadeProduto";
import { groupBy } from "../../../utils/functions";
export default function ProdutoVendedor() {

  const [mesref, setMesRef] = useState(null as any)
  const [vendedor, setVendedor] = useState('')

  const {dados, processando, reload } = useVendasVendedor(mesref, vendedor)
  const {pedidos, empresas, pedidosItens, totaisVendedor, totaisProdutosMes} = dados

  const now=new Date()
  useEffect(() => {
    // console.log('useEffect unico')
    setMesRef(now)
  }, [])

  // let totaisProdutosMes:any = []
  // let totaisVendedor:any = []
  // async function totaliza() {
  //   totaisProdutosMes = await groupBy(pedidosItens, ['vendedor', 'mes', 'codigo', 'produto'], ['quantidade', 'totalProd'])
  //     .sort((a: any, b: any) => (a.produto < b.produto ? -1 : a.produto === b.produto ? 0 : 1));
  //   totaisVendedor = await groupBy(totaisProdutosMes, ['vendedor',], ['quantidade', 'totalProd'])
  //     .sort((a: any, b: any) => (a.vendedor < b.vendedor ? -1 : a.vendedor === b.vendedor ? 0 : 1));
  //   // console.log(totaisVendedor)
  //   // console.log(pedidosItens)
  // }

  useEffect(() => {
    // console.log('Reload...')
    reload()
    // totaliza()
  }, [mesref])

  const [activeIndex, setActiveIndex] = useState(0);

  // const ExampleCustomInput = forwardRef<HTMLButtonElement>(({ value, onClick }, ref) => (
  //   <button className="example-custom-input" onClick={onClick} ref={ref}>
  //     {value}
  //   </button>
  // ));

  return (
    <Layout titulo={'Vendas por Vendedor'} botoes={() => { }}>
      <div className="text-center">
        <div className="mx-auto">
          <DatePicker
            // wrapperClassName="btmonth"
            className="select-none w-20 text-center uppercase border-none rounded-md font-medium py-[2px] cursor-pointer text-white bg-blue-600"
            selected={mesref}
            locale="pt-BR"
            onChange={(date: any) => setMesRef(date)}
            dateFormat="MMM/yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
            popperPlacement="auto"
          />
        </div>

        {/* <div className="mx-auto"> */}
        <div className="border-0 border-b-4 border-solid border-zinc-200 flex  justify-center mb-4 mx-6 text-lg">
          <div className={`cursor-pointer select-none p-2 px-4 -mb-1 font-bold border-0 border-b-4 border-solid ${activeIndex === 0 ? 'border-blue-700' : 'border-zinc-200'}`} onClick={() => setActiveIndex(0)}>Produto/Pedido</div>
          <div className={`cursor-pointer select-none p-2 px-4 -mb-1 font-bold border-0 border-b-4 border-solid ${activeIndex === 1 ? 'border-blue-700' : 'border-zinc-200'}`} onClick={() => setActiveIndex(1)}>Produto/Cidade</div>
          <div className={`cursor-pointer select-none p-2 px-4 -mb-1 font-bold border-0 border-b-4 border-solid ${activeIndex === 2 ? 'border-blue-700' : 'border-zinc-200'}`} onClick={() => setActiveIndex(2)}>Cidade/Produto</div>
        </div>
        {/* </div> */}

        <div id="tabcontent" className="">
          {activeIndex === 0 && dados.totaisVendedor ? (<ProdutoPedido
            dados={dados} mesref={mesref}
          />) : (null)}
          {activeIndex === 1 && dados.totaisVendedor ? (<ProdutoCidade
            dados={dados} mesref={mesref}
          />) : (null)}
          {activeIndex === 2 && dados.totaisVendedor ? (<CidadeProduto
            dados={dados} mesref={mesref}
          />) : (null)}
        </div>
      </div>
      <SpinnerNew loading={processando} />

      {/* <div>Pedidos:{pedidos?.length} Itens:{pedidosItens?.length}</div>
      <div className="border-b-2 border-red-500">ActiveIndex:{activeIndex}</div> */}
    </Layout>
  )
}