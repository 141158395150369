import { ColumnsType } from "antd/lib/table";
import { formatDecimal } from "../../../utils/functions";
import { Input } from "antd";
import { getColumnSearchProps } from "../../../components/columnSearchProps";
import { WarningOutlined } from '@ant-design/icons';

// interface DataType {
//   // key: React.Key;
//   categoria: string;
//   codigo: string;
//   descricao: string;
//   unidade: string;
//   saldoAcumulado: number;
//   cmcUnitario: number;
//   cmcTotal: number;
// }

export function columnsEstoqueDividido(props: any) {
  return [
    {
      title: 'Categoria',
      dataIndex: 'categoria',
      ellipsis: true,
      width: '140px',
      className: 'text-left',
      filters: [
        {
          text: 'Acessórios',
          value: 'Acessórios',
        },
        {
          text: 'Boinas',
          value: 'Boinas',
        },
        {
          text: 'Caixas',
          value: 'Caixas',
        },
        {
          text: 'Ceras e Polidores',
          value: 'Ceras e Polidores',
        },
        {
          text: 'Embalagens',
          value: 'Embalagens',
        },
        {
          text: 'Higienização',
          value: 'Higienização',
        },
        {
          text: 'Impermeabilizantes',
          value: 'Impermeabilizantes',
        },
        {
          text: 'Matéria Prima',
          value: 'Matéria Prima',
        },
        {
          text: 'Máquinas',
          value: 'Máquinas',
        },
        {
          text: 'Rótulos',
          value: 'Rótulos',
        },

      ],
      filterMode: 'menu',
      // filterSearch: true,
      onFilter: (value: string, record: any) => record.categoria.includes(value),
    },
    {
      title: 'Codigo',
      dataIndex: 'codigo',
      ellipsis: true,
      width: '97px',
      className: 'text-left',
      // sorter: (a: any, b: any) => a.codigo.localeCompare(b.codigo),
      // ...getColumnSearchProps({
      //   dataIndex: 'codigo',
      //   ...props
      // })
    },
    {
      title: 'Produto',
      dataIndex: 'descricao',
      width: '520px',
      className: 'text-left',
      ellipsis: false,
      // sorter: (a: any, b: any) => a.descricao.localeCompare(b.descricao),
      // ...getColumnSearchProps({
      //   dataIndex: 'descricao',
      //   ...props
      // }),
      render: (text: any, record: any) => `${text}`
    },
    {
      title: 'UN',
      dataIndex: 'unidade',
      width: '40px',
      className: 'text-left',
      ellipsis: true,
      render: (text: any, record: any) => `${text}`
    },

    {
      title: 'Sld 2022',
      dataIndex: 'qtdsldbt',
      width: '100px',
      key: "qtdsldbt",
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: "text-purple-500" }),
      render: (text: any, record: any) => `${record.unidade === 'UN' ? +text : formatDecimal(+text, 3)}` // formatDecimal(+text, 0)
    },
    {
      title: 'Vlr 2022',
      dataIndex: 'vlrsldbt',
      width: '110px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: "text-purple-500" }),
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },

    {
      title: 'QtMvE 23',
      dataIndex: 'qtdmovet',
      width: '100px',
      key: "qtdmovet",
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: "text-indigo-500" }),
      render: (text: any, record: any) => `${record.unidade === 'UN' ? +text : formatDecimal(+text, 3)}` // formatDecimal(+text, 0)
    },
    {
      title: 'VlMvE 23',
      dataIndex: 'vlrmovet',
      width: '110px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: "text-indigo-500" }),
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },

    {
      title: 'QtMvS 23',
      dataIndex: 'qtdmovst',
      width: '100px',
      key: "qtdmovst",
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: "text-orange-500" }),
      render: (text: any, record: any) => `${record.unidade === 'UN' ? +text : formatDecimal(+text, 3)}` // formatDecimal(+text, 0)
    },
    {
      title: 'VlMvS 23',
      dataIndex: 'vlrmovst',
      width: '110px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: "text-orange-500" }),
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },

    {
      title: 'QtCom23',
      dataIndex: 'qtdcompt',
      width: '100px',
      key: "qtdcompt",
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: "text-teal-500" }),
      render: (text: any, record: any) => `${record.unidade === 'UN' ? +text : formatDecimal(+text, 3)}` // formatDecimal(+text, 0)
    },
    {
      title: 'VlCom23',
      dataIndex: 'vlrcompt',
      width: '110px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: "text-teal-500" }),
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },

    {
      title: 'QtCom23PA',
      dataIndex: 'qtdcomp1',
      width: '100px',
      key: "qtdcomp1",
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: "text-blue-500" }),
      render: (text: any, record: any) => `${record.unidade === 'UN' ? +text : formatDecimal(+text, 3)}` // formatDecimal(+text, 0)
    },
    {
      title: 'VlCom23PA',
      dataIndex: 'vlrcomp1',
      width: '110px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: "text-blue-500" }),
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },

    {
      title: 'QtCom23SQ',
      dataIndex: 'qtdcomp2',
      width: '100px',
      key: "qtdcomp2",
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: "text-green-700" }),
      render: (text: any, record: any) => `${record.unidade === 'UN' ? +text : formatDecimal(+text, 3)}` // formatDecimal(+text, 0)
    },
    {
      title: 'VlCom23SQ',
      dataIndex: 'vlrcomp2',
      width: '110px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: "text-green-700" }),
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },

    {
      title: 'QtPrdz23',
      dataIndex: 'qtdeprdt',
      width: '100px',
      key: "qtdeprdt",
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: "text-green-500" }),
      render: (text: any, record: any) => `${record.unidade === 'UN' ? +text : formatDecimal(+text, 3)}` // formatDecimal(+text, 0)
    },
    {
      title: 'VlPrdz23',
      dataIndex: 'vlreprdt',
      width: '110px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: "text-green-500" }),
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },

    {
      title: 'QtCons23',
      dataIndex: 'qtdconst',
      width: '100px',
      key: "qtdconst",
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: "text-orange-500" }),
      render: (text: any, record: any) => `${record.unidade === 'UN' ? +text : formatDecimal(+text, 3)}` // formatDecimal(+text, 0)
    },
    // {
    //   title: 'VlCons23',
    //   dataIndex: 'vlrconst',
    //   width: '110px',
    //   align: 'right' as 'right',
    //   className: 'text-right',
    //   ellipsis: true,
    //   onCell: (record: any, i: any) => ({ className: "text-teal-500" }),
    //   render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    // },
    {
      title: 'QtCons23PA',
      dataIndex: 'qtdcons1',
      width: '100px',
      key: "qtdcons1",
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: "text-blue-500" }),
      render: (text: any, record: any) => `${record.unidade === 'UN' ? +text : formatDecimal(+text, 3)}` // formatDecimal(+text, 0)
    },
    {
      title: '%ConsPA',
      dataIndex: 'pctcons1',
      width: '80px',
      key: "pctcons1",
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      // sorter: (a: any, b: any) => a.pctcons1 - b.pctcons1,
      onCell: (record: any, i: any) => ({ className: "text-blue-500" }),
      render: (text: any, record: any) => `${formatDecimal(+text*100, 1)}%` // formatDecimal(+text, 0)
    },

    {
      title: 'QtCons23SQ',
      dataIndex: 'qtdcons2',
      width: '100px',
      key: "qtdcons2",
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: "text-green-700" }),
      render: (text: any, record: any) => `${record.unidade === 'UN' ? +text : formatDecimal(+text, 3)}` // formatDecimal(+text, 0)
    },
    {
      title: '%ConsSQ',
      dataIndex: 'pctcons2',
      width: '80px',
      key: "pctcons2",
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      // sorter: (a: any, b: any) => a.pctcons2 - b.pctcons2,
      onCell: (record: any, i: any) => ({ className: "text-green-700" }),
      render: (text: any, record: any) => `${formatDecimal(+text*100, 1)}%` // formatDecimal(+text, 0)
    },

    {
      title: 'Saldo Fin',
      dataIndex: 'sldestft',
      width: '100px',
      key: "sldestft",
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      // sorter: (a: any, b: any) => a.sldestft - b.sldestft,
      onCell: (record: any, i: any) => ({ className: record.sldestft < 0 ? "text-red-600 font-semibold" : "" }),
      render: (text: any, record: any) => `${record.unidade === 'UN' ? +text : formatDecimal(+text, 3)}` // formatDecimal(+text, 0)
    },
    // {
    //   title: 'CmcUnit.',
    //   dataIndex: 'cmcUnitario',
    //   width: '80px',
    //   align: 'right' as 'right',
    //   className: 'text-right',
    //   ellipsis: true,
    //   render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    // },
    {
      title: 'Vlr Final',
      dataIndex: 'vlrestft',
      width: '110px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: record.vlrestft < 0 ? "text-red-600 font-semibold" : " " }),
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },
    {
      title: 'QuantPA',
      dataIndex: 'sldestf1',
      width: '100px',
      key: "sldestf1",
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      // sorter: (a: any, b: any) => a.sldestf1 - b.sldestf1,
      onCell: (record: any, i: any) => ({ className: "text-blue-500" }),
      render: (text: any, record: any) => `${record.unidade === 'UN' ? +text : formatDecimal(+text, 3)}` // formatDecimal(+text, 0)
    },
    {
      title: 'CmcTotalPA',
      dataIndex: 'vlrestf1',
      width: '110px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: "text-blue-500" }),
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },
    
    {
      title: 'QuantSQ',
      dataIndex: 'sldestf2',
      width: '100px',
      key: "sldestf2",
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      // sorter: (a: any, b: any) => a.sldestf2 - b.sldestf2,
      onCell: (record: any, i: any) => ({ className: "text-green-700" }),
      render: (text: any, record: any) => `${record.unidade === 'UN' ? +text : formatDecimal(+text, 3)}` // formatDecimal(+text, 0)
    },
    {
      title: 'CmcTotalSQ',
      dataIndex: 'vlrestf2',
      width: '110px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: "text-green-700" }),
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },
  ]
}