import { useCallback, useEffect, useState } from 'react'
import useProcessando from '../../../data/hooks/useProcessando'
import { apierp } from '../../../services/api'
import { dateFormat } from '../../../utils/dateFunctions'

export default function usePedidosPendentesSaidaByDate(dateref: any) {
  const { processando, iniciarProcessamento, finalizarProcessamento } = useProcessando()
  const [pedidosPendentesSaida, setPedidosPendentesSaida] = useState<any[]>([])
  const [empresas, setEmpresas] = useState<any[]>([])
  const [caixas, setCaixas] = useState<any[]>([])

  const buscarPedidosPendentesSaida = useCallback(async function () {
    if (dateref === null) return
    const date = dateFormat(dateref, 'yyyy-MM-dd')

    try {
      iniciarProcessamento()

      const response1 = await apierp.get(`blingpedidosvendav2pendentessaidabydate/${date}`)
      const data1 = await response1.data as any;
      setPedidosPendentesSaida(data1)
      // console.log(data1)
      const response2 = await apierp.get('empresas')
      const data2 = await response2.data as any;
      // setEmpresas(data2)
      const arr = []
      for (let emp of data2 ) {
        arr[emp.id] = emp.nome
      }
      setEmpresas(arr)

      const response3 = await apierp.get('produtosbtcaixas')
      const data3 = await response3.data as any;
      setCaixas(data3)

    } catch (err) {
      console.log(err)
    } finally {
      finalizarProcessamento()
    }

  }, [dateref])

  function voltar() {
    setPedidosPendentesSaida([])
    setEmpresas([])
    setCaixas([])
  }
  function reload() {
    buscarPedidosPendentesSaida()
  }
  
  useEffect(() => {
    setPedidosPendentesSaida([])
    setEmpresas([])
    setCaixas([])
    buscarPedidosPendentesSaida()
  }, [buscarPedidosPendentesSaida])

  return {
    pedidosPendentesSaida,
    empresas,
    caixas,
    processando,
    iniciarProcessamento,
    finalizarProcessamento,
    voltar,
    reload
  }

}