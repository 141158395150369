import { useState } from "react";
import useWindowSize from "../../../../hooks/useWindowSize";
import { dateFormatBr } from "../../../../utils/dateFunctions";
import { ellipsis, formatDecimal } from "../../../../utils/functions"
import { Modal } from "antd";
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import ModalPedidos from "./ModalPedidos";

export default function CidadeProduto(props: any) {
  const { dados } = props
  const { pedidos, pedidosItens, cidades, totaisVendedor: ttV, totaisCidadeProduto: ttCP, totaisCidade: ttC } = dados
  const [totaisVendedor, setTotaisVendedor] = useState(ttV)
  const [totaisCidadeProduto, setTotaisCidadeProduto] = useState(ttCP)
  const [totaisCidade, setTotaisCidade] = useState(ttC)
  // totaisVendedor.forEach((v: any) => {
  //   v.filtered = false
  // })
  // totaisCidade.forEach((c: any) => {
  //   c.filtered = false
  // })

  const [pedidosItensVendedor, setPedidosItensVendedor] = useState([])
  const [pedidoItem, setPedidoItem] = useState({} as any)
  const [showModal, setShowModal] = useState(false)

  const [innerW, innerH] = useWindowSize();

  async function handleClickLinhaProduto(v: any) {
    setShowModal(true)
    // console.log('handleClickLinhaProduto', v.vendedor, v.codigo, v.mes)
    // console.log('handleClickLinhaProduto', dados.pedidoitems[0])
    setPedidoItem(v)
    setPedidosItensVendedor(
      pedidosItens.filter((x: any) => x.vendedor === v.vendedor && x.codigo === v.codigo && x.cidade === v.cidade && x.uf === v.uf)
    )
  }

  const showModalPedidos = async () => {
    setShowModal(true)
  };
  const hideModalPedidos = () => {
    // setButtonsDisabled(false)
    setShowModal(false)
  };

  function handlePrint() {
    // printdiv("printableModal")
  }

  function handleFilterAllVendedor(all: string) {
    const newvend: any = totaisVendedor.map((x: any) => {
      if (all === 'all') { return { ...x, filtered: true } }
      else { return { ...x, filtered: false } }
    })
    handleFilterAllCidade('not')
    setTotaisVendedor(newvend)
  }

  function handleFilterVendedor(v: string) {
    const newvend: any = totaisVendedor.map((x: any) => {
      if (x.vendedor === v) { return { ...x, filtered: !x.filtered } }
      else { return x }
    })
    setTotaisVendedor(newvend)
  }

  function handleFilterAllCidade(all: string) {
    const newcid: any = totaisCidade.map((x: any) => {
      if (all === 'all') { return { ...x, filtered: true } }
      else { return { ...x, filtered: false } }
    })
    setTotaisCidade(newcid)
  }
  function handleFilterCidade(c: any) {
    const newcid: any = totaisCidade.map((x: any) => {
      if (x.cidade === c.cidade && x.uf === c.uf) { return { ...x, filtered: !x.filtered } }
      else { return x }
    })
    setTotaisCidade(newcid)
  }

  const wTab = 905 + 8 // 8 scroll bar
  const paddH = 0 // soma do padding vertical do frame da renderização
  const hCab = 60 // altura do cabecalho
  const hRod = 40 + 32 // altura do rodapé
  const hTit = 78// 45 // algura do titulo da pagina
  const hPartialTabs = 26 + 48 + 22// 45 // algura do titulo da pagina
  const hContainer = innerH - paddH - hCab - hRod - hTit - hPartialTabs - 65

  const hTabFinal = hContainer  // altura util utilizavel pela tabela
  // console.log(hTabFinal)

  return (
    // <div className="w-full">
    <div className={``}>
      <div id="printablediv" className="flex flex-col relative mx-auto" >
        <h4 className="font-bold">
        VENDEDOR/CIDADE/PRODUTO/PEDIDO
        </h4>
        <div key="L0" id='L0' className="flex flex-col overflow-x-auto">
          <div key="L1-cab" id='licab' className="flex mx-auto text-base font-medium border-0 border-b border-solid border-zinc-700 bg-blue-700 text-white"
            style={{ width: wTab }}
          >
            <div className="w-[80px] min-w-[80px] p-1 text-left">Vendedor</div>
            <div className="w-[80px] min-w-[80px] p-1 text-left">Código</div>
            <div className="w-[445px] min-w-[445px] p-1 text-left">Produto</div>
            <div className="w-[60px] min-w-[60px] p-1 text-right">Quant</div>
            <div className="w-[120px] min-w-[120px] p-1 text-right">Preço Médio</div>
            <div className="w-[120px] min-w-[120px] p-1 text-right">Total</div>
          </div>
          <div className={`mx-auto`}>
            <div className={`flex flex-col items-start overflow-y-auto overflow-x-hidden`} style={{width: wTab, height: hTabFinal }}>
              {totaisVendedor.map((tv: any) => {
                let bg2 = "white"
                let fc = "black"
                return (
                  <div key={"L1-vend"+tv.vendedor} className="">
                    <div key={tv.vendedor} className="flex text-sm font-medium mt-[1px] bg-blue-400 text-black border-0 border-b border-solid border-zinc-700 cursor-pointer"
                      onClick={() => handleFilterVendedor(tv.vendedor)}
                    >
                      <div className="w-[30px] min-w-[30px] pt-[5px]">
                        <div >{tv.filtered ? <FaEye  /> : <FaEyeSlash /> }</div>
                      </div>
                      <div className="w-[425px] min-w-[425px] p-1 text-left">{tv.vendedor}</div>
                      <div className="w-[80px] min-w-[80px] p-1 text-left"></div>
                      <div className="w-[70px] min-w-[70px] p-1 text-left"></div>
                      <div className="w-[60px] min-w-[60px] p-1 text-right"></div>
                      <div className="w-[120px] min-w-[120px] p-1 text-right"></div>
                      <div className="w-[128px] min-w-[128px] p-1 pr-3 text-right">{formatDecimal(tv.totalProd, 2)}</div>
                    </div>
                    {totaisCidade.filter((tc: any) => tc.vendedor === tv.vendedor && tv.filtered).map((z: any) => {
                      return (
                        <div key={"L3-vend" + z.cidade}  className="mx-auto">
                          <div key={z.cidade} className="flex text-sm font-medium mt-[1px] bg-blue-200 border-0 border-b border-solid border-zinc-700 cursor-pointer"
                          onClick={() => handleFilterCidade(z) }
                          >
                            <div className="w-[30px] min-w-[30px] pt-[5px]">
                              <div >{z.filtered ? <FaEye  /> : <FaEyeSlash /> }</div>
                            </div>
                            <div className="w-[30px] min-w-[30px] p-1 text-left">{z.uf}</div>
                            <div className="w-[425px] min-w-[425px] p-1 text-left">{z.cidade !== '' ? z.cidade : 'CIDADE NÃO INFORMADA'}</div>
                            <div className="w-[50px] min-w-[50px] p-1 text-left"></div>
                            <div className="w-[70px] min-w-[70px] p-1 text-left"></div>
                            <div className="w-[60px] min-w-[60px] p-1 text-right"></div>
                            <div className="w-[120px] min-w-[120px] p-1 text-right"></div>
                            <div className="w-[120px] min-w-[120px] p-1 text-right">{formatDecimal(z.totalProd, 2)}</div>
                          </div>
                          {totaisCidadeProduto.filter((x: any) => x.vendedor === z.vendedor && x.cidade === z.cidade && x.uf === z.uf && z.filtered).map((v: any) => {
                            return (
                              <div key={"L2-vend"+v.codigo} className="flex text-sm font-medium mt-[0px]  border-0 border-b border-solid border-zinc-300 cursor-pointer"
                                onClick={() => { handleClickLinhaProduto(v) }}
                              >
                                <div className="w-[80px] min-w-[80px] p-1 text-left"></div>
                                <div className="w-[80px] min-w-[80px] p-1 text-left">{v.codigo}</div>
                                <div className="w-[445px] min-w-[445px] p-1 text-left">{v.produto}</div>
                                <div className="w-[60px] min-w-[60px] p-1 text-right">{formatDecimal(v.quantidade, 0)}</div>
                                <div className="w-[120px] min-w-[120px] p-1 text-right">{formatDecimal(v.totalProd / v.quantidade, 2)}</div>
                                <div className="w-[120px] min-w-[120px] p-1 text-right">{formatDecimal(v.totalProd, 2)}</div>
                              </div>
                            )
                          })
                          }
                        </div>
                      )
                    })}
                  </div>
                )
              })
              }
            </div>
          </div>
        </div>
      </div>

      <ModalPedidos showModal={showModal} hideModalPedidos={hideModalPedidos} pedidoItem={pedidoItem} pedidosItensVendedor={pedidosItensVendedor} />

    </div>
    // </div>
  )
}