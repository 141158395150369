import { useCallback, useEffect, useState } from 'react'
import useProcessando from '../../../data/hooks/useProcessando'
import { apierp } from '../../../services/api'

export default function useConsumoMensalMedio(dataInicial: string, dataFinal: string, dataEstoque: string, depositoId: number) {
  const { processando, iniciarProcessamento, finalizarProcessamento } = useProcessando()
  const [estoquesMedios, setEstoquesMedios] = useState<any[]>([])

  const buscarEstoquesMedios = useCallback(async function () {
    if (!dataInicial || !dataFinal || !dataEstoque || !depositoId) {
      setEstoquesMedios([])
      return
    }
    try {
      iniciarProcessamento()
      const body = {
        dataInicial,
        dataFinal,
        dataEstoque,
        depositoId
      }
      
      const response = await apierp.post('consumomensalmedio', body)
      const data = await response.data as any;
      setEstoquesMedios(data)
    } catch (err) {
      console.log(err)
    } finally {
      finalizarProcessamento()
    }

  // }, [dataInicial, dataFinal, dataEstoque, depositoId, iniciarProcessamento, finalizarProcessamento])
  }, [])

  function voltar() {
    setEstoquesMedios([])
  }
  function reload() {
    buscarEstoquesMedios()
  }

  useEffect(() => {
    setEstoquesMedios([])
    buscarEstoquesMedios()
  }, [buscarEstoquesMedios])

  return {
    estoquesMedios,
    processando,
    iniciarProcessamento,
    finalizarProcessamento,
    voltar,
    reload
  }

}