import { Button, Modal } from "antd"
import { dateFormatBr } from "../../../utils/dateFunctions"
import { ellipsis, formatDecimal } from "../../../utils/functions"
import { useState } from "react"

export default function ModalMensagens(props: any) {
  const showModal = props.showModal
  const hideModalMensagens = props.hideModalMensagens
  const conversas = props.conversas
  const userId = props.userId

  const [loading, setLoading] = useState(false);

  const handleCancel = () => { hideModalMensagens() }
  const handleNovaConversa = () => { }
  const handleNovaMensagem = () => { }
  const handleMarcarLida = (statusleituraId: number) => { 
    console.log(statusleituraId)
  }
  const footer = [
    <Button key="back" size="small" onClick={handleCancel}>
      Sair
    </Button>,
    <Button key="submit" size="small" type="primary" loading={loading} onClick={handleNovaConversa}>
      Novo Assunto
    </Button>
  ]

  return (
    <Modal title="Mensagens" open={showModal} onOk={hideModalMensagens} onCancel={hideModalMensagens} footer={footer} centered width={800}>
      <div className="text-center">
        {/* The Modal Content */}
        <div className="">
          <div className="text-center mb-2.5 font-semibold" >
            {'ALGUM TÍTULO'}
          </div>
          <div className="overflow-x-auto">
            <div className="flex flex-col gap-2 max-h-[400px] max-w-[752px]  overflow-y-auto">
              {conversas?.map((c: any, index: number) => {
                const participantes = JSON.stringify(c.participantes.map((p: any) => p.user.shortName)).replaceAll('"', '').replaceAll(',', ', ')
                return (
                  <div className="card-conv flex flex-col rounded-md items-start bg-slate-400 max-w-[728px] p-2">
                    <div className="flex">
                      <div className="mr-2">{c.id} Assunto:</div>
                      <div className="font-bold">{c.assunto}</div>
                    </div>
                    <div className="flex text-[11px] mb-1">
                      <div className="mr-2">Participantes:</div>
                      <div className="">{participantes}</div>
                    </div>
                    {c.mensagens.map((m: any, index: number) => {
                      const isMy = m.user.id === userId
                      const myStat = m.statusleitura.find((s: any) => s.userId === userId)
                      const isRead = myStat?.readAt
                      // let lidaEm = dateFormatBr(isRead, 'dd/MM/yyyy HH:mm')
                      const mx = isMy ? 'self-end' : 'self-start'
                      const bg = isMy ? 'bg-teal-700 text-white' : isRead ? 'bg-cyan-100' : 'bg-white'
                      return (
                        <div className={`card-msg flex flex-col sel items-start px-2 py-1 mb-1 w-[90%] rounded-md ${mx} ${bg}  `}>
                          <div className="flex flex-row items-start mr-4 text-[11px] font-semibold">
                            <div>{m.id} {dateFormatBr(m.createdAt, 'dd/MM/yyyy HH:mm')}</div>
                            <div className="ml-2">{m.user.shortName}</div>
                          </div>
                          <p className="font-medium leading-tight text-left mt-1">{m.texto}</p>
                          <div className="barbutton flex w-full justify-end">
                            <div className="text-[11px] text-teal-900 font-semibold" hidden={isMy || !isRead}>Lí em {dateFormatBr(isRead, 'dd/MM/yyyy HH:mm')}</div>
                            <Button hidden={isMy || isRead} key="submit" size="small" danger loading={loading} onClick={() => handleMarcarLida(myStat?.id)}>
                              {myStat?.id} Marcar Lida
                            </Button>
                          </div>
                        </div>
                      )
                    })}
                    <div className="barbutton flex w-full justify-end">
                      <Button key="submit" size="small" type="primary" loading={loading} onClick={handleNovaMensagem}>
                        Nova Mensagem
                      </Button>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}