import { useQuery } from "@tanstack/react-query";
import { apierp } from "../../services/api";
import { categoriasProdutos } from "../../data/dados";

const fetchEmpresas = async () => {
    // console.log('fetchEmpresas')
    const response = await apierp.get('empresas')
    // console.log('fetchEmpresas2')
    return response?.data
}

const fetchSituacoesPedidosVenda = async () => {
    // console.log('fetchSituacoesPedidosVenda')
    const response = await apierp.get('situacoespedidosvenda')
    // console.log('fetchSituacoesPedidosVenda2')
    return response?.data
}

const fetchBlingCategoriasReceitaDespesa = async (empresaId?: any) => {
    // console.log('fetchBlingCategoriasReceitaDespesa', id)
    const dados = {
        "metodo": "getCategoriasReceitaDespesa",
        "param": {
            // "empresaId": empresaId
        }
    }
    // console.log('fetchBlingCategoriasReceitaDespesa', dados)
    const response = await apierp.post('bling/v3/pedidosvenda', dados)
    // console.log('fetchBlingCategoriasReceitaDespesa2', id)
    return response?.data
}

const fetchBlingVendedores = async (empresaId?: any) => {
    // console.log('fetchBlingVendedores', id)
    const dados = {
        "metodo": "getVendedores",
        "param": {
            // "empresaId": empresaId
        }
    }
    // console.log('fetchBlingVendedores', dados)
    const response = await apierp.post('bling/v3/pedidosvenda', dados)
    // console.log('fetchBlingVendedores2', id)
    return response?.data
}

const fetchBlingFormasPagamento = async (empresaId?: any) => {
    // console.log('fetchBlingFormasPagamento', id)
    const dados = {
        "metodo": "getFormasPagamento",
        "param": {
            // "empresaId": empresaId
        }
    }
    // console.log('fetchBlingFormasPagamento', dados)
    const response = await apierp.post('bling/v3/pedidosvenda', dados)
    // console.log('fetchBlingFormasPagamento2', id)
    return response?.data
}

const fetchBlingProdutos = async () => {
    // console.log('fetchBlingProdutos', id)
    const response = await apierp.get(`blingprodutosbt`)
    // console.log('fetchBlingProdutos2', id)
    return response?.data
}

const fetchPrePedidosVenda = async (data: any) => {
    // console.log('fetchPrePedidosVenda', data)
    const dados = {
        "metodo": "obterPrePedidosVendasPorFiltros",
        "param": {
            "data": data,
        }
    }
    // console.log('fetchPrePedidosVenda', dados)
    const response = await apierp.post('prepedidosvenda', dados)
    // console.log('fetchPrePedidosVenda2')
    return response?.data
}

export function useDadosPrePedidosVenda(data?: any, empresaId?: any) {
    // console.log('useDadosPrePedidosVenda', data)
    const queryEmpresas = useQuery({
        queryKey: ['empresas'],
        queryFn: fetchEmpresas,
        retry: false
        // enabled: !!params,
    });

    const queryBlingCategoriasReceitaDespesa = useQuery({
        queryKey: ['blingcategoriasreceitadespesa', empresaId],
        queryFn: () => fetchBlingCategoriasReceitaDespesa(empresaId),
        retry: false,
        refetchOnWindowFocus: true
    });
    
    const queryBlingVendedores = useQuery({
        queryKey: ['blingvendedores', empresaId],
        queryFn: () => fetchBlingVendedores(empresaId),
        retry: false,
        refetchOnWindowFocus: true
    });
    
    const queryBlingFormasPagamento = useQuery({
        queryKey: ['blingformaspagamento', empresaId],
        queryFn: () => fetchBlingFormasPagamento(empresaId),
        retry: false,
        refetchOnWindowFocus: true
    });

    const queryBlingProdutos = useQuery({
        queryKey: ['blingprodutos'],
        queryFn: fetchBlingProdutos,
        retry: false,
        refetchOnWindowFocus: true
    });

    const queryPrePedidosVenda = useQuery({
        queryKey: ['prepedidosvenda', data],
        queryFn: () => fetchPrePedidosVenda(data),
        retry: false,
        refetchOnWindowFocus: true
    });

    return {
        qrEmpresas: queryEmpresas,
        qrBlingCategoriasReceitaDespesa: queryBlingCategoriasReceitaDespesa,
        qrBlingVendedores: queryBlingVendedores,
        qrBlingFormasPagamento: queryBlingFormasPagamento,
        qrBlingProdutos: queryBlingProdutos,
        qrPrePedidosVenda: queryPrePedidosVenda,
    };

}