import { formatDecimal } from "../../../utils/functions";
import { Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { getColumnSearchProps } from "../../../components/columnSearchProps";

export function columnsPrecoMedioGeral(props: any) {
  // const { setSearchText, setSearchedColumn, setSelectedKeys, selectedKeys, searchInput, searchedColumn, searchText } = props;
  return [
    {
      title: 'Categoria',
      dataIndex: 'categoria',
      width: '140px',
      className: 'text-left',
      ellipsis: true,
      render: (text: any, record: any) => `${text}`,
      filters: [
        {
          text: 'Acessórios',
          value: 'Acessórios',
        },
        {
          text: 'Boinas',
          value: 'Boinas',
        },
        {
          text: 'Caixas',
          value: 'Caixas',
        },
        {
          text: 'Ceras e Polidores',
          value: 'Ceras e Polidores',
        },
        {
          text: 'Embalagens',
          value: 'Embalagens',
        },
        {
          text: 'Higienização',
          value: 'Higienização',
        },
        {
          text: 'Impermeabilizantes',
          value: 'Impermeabilizantes',
        },
        {
          text: 'Matéria Prima',
          value: 'Matéria Prima',
        },
        {
          text: 'Máquinas',
          value: 'Máquinas',
        },
        {
          text: 'Rótulos',
          value: 'Rótulos',
        },

      ],
      filterMode: 'menu',
      // filterSearch: true,
      onFilter: (value: string, record: any) => record.categoria.includes(value),
    },
    {
      title: 'Codigo',
      dataIndex: 'codigo',
      ellipsis: true,
      width: '87px',
      className: 'text-left',
      ...getColumnSearchProps({
        dataIndex: 'codigo',
        ...props
      })
    },
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      width: '450px',
      className: 'text-left',
      ellipsis: false,
      // sorter: (a: any, b: any) => a.descricao.localeCompare(b.descricao),
      ...getColumnSearchProps({
        dataIndex: 'descricao',
        ...props
      })
    },
    {
      title: 'UN',
      dataIndex: 'unidade',
      width: '40px',
      className: 'text-left',
      ellipsis: true,
      render: (text: any, record: any) => `${text}`
    },
    {
      title: 'Cliente',
      dataIndex: 'nome',
      width: '310px',
      className: 'text-left',
      ellipsis: false,
      sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
      ...getColumnSearchProps({
        dataIndex: 'nome',
        ...props
      })
    },
    
    {
      title: 'Quant',
      dataIndex: 'quantidade',
      width: '80px',
      key: "quantidade",
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      sorter: (a: any, b: any) => a.quantidade - b.quantidade,
      render: (text: any, record: any) => `${formatDecimal(+text, 0)}`
    },
    {
      title: 'P.Médio',
      dataIndex: 'precomedio',
      width: '80px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      sorter: (a: any, b: any) => a.precomedio - b.precomedio,
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },
    {
      title: 'TotalVenda',
      dataIndex: 'totalvenda',
      width: '100px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      sorter: (a: any, b: any) => a.totalvenda - b.totalvenda,
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },
    {
      title: 'CmcUnitário',
      dataIndex: 'cmcUnitario',
      width: '100px',
      align: 'right' as 'right',
      className: 'text-right',
      ellipsis: true,
      sorter: (a: any, b: any) => a.cmcUnitario - b.cmcUnitario,
      render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
    },
  ]
}